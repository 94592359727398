import React from 'react';
import { PropTypes } from 'prop-types';

import AppBody from './AppBody';

const AppBodyWithSidebar = ({ children, sidebarContent }) => {
  return (
    <>
      <AppBody>
        {children}
      </AppBody>
      <aside>
        <div className="padding15">
          {sidebarContent}
        </div>
      </aside>
    </>
  );
};

AppBodyWithSidebar.defaultProps = {
  sidebarContent: null,
};

AppBodyWithSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  sidebarContent: PropTypes.object,
};

export default AppBodyWithSidebar;
