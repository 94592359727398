import React from 'react';
import { PropTypes } from 'prop-types';
import reactStringReplace from 'react-string-replace';

import PaymentButtonPreview from './PaymentButtonPreview';
import GoodyBagButtonPreview from './GoodyBagButtonPreview';

const decoratedContent = (postBody, currentUserId, isOwner) => {
  const urlBase = document.location.href.match(/\bhttps?:\/\/[^\/]+/i)[0];
  const localUrlRegex = new RegExp(`(${urlBase}\\/\\S*)`, 'gi');

  const paymentButtonRegex = /\/messages\/click_payment_button\?id=\d+/gi;
  const goodyBagRegex = /\/goodies\/click\/\d+-\d+/gi;

  return reactStringReplace(
    postBody,
    localUrlRegex,
    (url, i) => (
      (url.match(paymentButtonRegex) && <PaymentButtonPreview url={url} loggedIn={!!currentUserId} isOwner={isOwner} key={`pb${i}`} />) ||
      (url.match(goodyBagRegex) && <GoodyBagButtonPreview url={url} currentUserId={currentUserId} key={`gb${i}`} />) ||
      (<a href={url} target="_blank" key={`url${i}`} rel="noreferrer">{url}</a>)
    ),
  );
};

const PostBody = ({ post, currentUserId }) => {
  const isOwner = post.main_user_id === currentUserId;
  return (
    <div className="blockOfText">
      { post.body && decoratedContent(post.body, currentUserId, isOwner) }
      { post.body && post.paid_body && <hr /> }
      { post.paid_body && decoratedContent(post.paid_body, currentUserId, isOwner) }
    </div>
  );
};

PostBody.propTypes = {
  post: PropTypes.object.isRequired,
  currentUserId: PropTypes.number,
};

PostBody.defaultProps = {
  currentUserId: null,
};

export default PostBody;
