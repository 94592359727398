import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const DetailWrapper = styled(Paper)(() => ({
  borderRadius: '0px',
}));

const ItemDetail = ({ children, className }) => {
  return (
    <DetailWrapper className={className}>
      {children}
    </DetailWrapper>
  );
};

ItemDetail.defaultProps = {
  className: '',
};

ItemDetail.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ItemDetail;
