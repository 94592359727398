import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';
import PositionDialog from 'app/components/favorite_listings/PositionDialog';

function MenuKebab({ favoriteListing }) {
  const { removeFavoriteListings, updateFavoriteListing, isSortable, isUpdating } = useContext(FavoriteListingsContext);
  const { listing_id: listingId } = favoriteListing;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!isUpdating) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFavorite = () => {
    removeFavoriteListings({ listings: [listingId] });
    handleClose();
  };

  const moveFavorite = (position) => {
    updateFavoriteListing({ id: favoriteListing.id, position });
    handleClose();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
    setAnchorEl(null); // Close the menu when dialog opens
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <a
        href="#"
        aria-controls={open ? 'basic-menu' : undefined}
        // aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert sx={{ color: 'var(--color_gray_nine)', mt: 1, display: 'inline-block' }} />
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isSortable && (<MenuItem onClick={() => { moveFavorite(1); }}>Move to top</MenuItem>)}
        {isSortable && (<MenuItem onClick={handleDialogOpen}>Move to...</MenuItem>)}
        <MenuItem onClick={removeFavorite}>Remove</MenuItem>
      </Menu>
      <PositionDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        moveFavorite={moveFavorite}
        defaultPosition={favoriteListing.position}
      />
    </div>
  );
}

MenuKebab.propTypes = {
  favoriteListing: PropTypes.shape({
    listing_id: PropTypes.number.isRequired,
  }).isRequired,
};
export default MenuKebab;
