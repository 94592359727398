import React from 'react';
import { PropTypes } from 'prop-types';

const ActionRequiredModal = ({ closeModal, onClickAction, copy }) => {
  return (
    <>
      <div className="modal-header">
        <h4>{ copy.headerCopy }</h4>
      </div>
      <div className="modal-body">
        <p>
          { copy.bodyCopy }
        </p>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          { copy.cancelButtonCopy || 'Cancel' }
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            closeModal();
            onClickAction();
          }}
        >
          { copy.submitButtonCopy }
        </button>
      </div>
    </>
  );
};

ActionRequiredModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  copy: PropTypes.object.isRequired,
};

export default ActionRequiredModal;
