import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@mui/material';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import AvailabilityShape from 'app/shapes/AvailabilityShape';

const BusyStatus = ({ availability, styles }) => {
  if (!availability?.busy_on_call) return null;

  const [ releaseBusyOnCall ] = useUpdateAvailabilityMutation();
  const handleOnClick = () => {
    releaseBusyOnCall({ availability: { release_busy_on_call: true } });
  };

  return (
    <Stack
      data-test-id="busy-status"
      direction="row"
      spacing={2}
      sx={{ ...styles }}
    >
      <Typography variant="body3" sx={{ color: 'black' }}>
        Your status is currently:&nbsp;
        <span style={{ fontWeight: 'bold' }}>BUSY</span>
      </Typography>
      <Typography variant="body3">
        <a href="#" onClick={handleOnClick}>
          Reset Status
        </a>
      </Typography>
    </Stack>
  );
};

BusyStatus.defaultProps = {
  availability: {},
  styles: { justifyContent: { xs: 'flex-start', sm: 'flex-end' } },
};

BusyStatus.propTypes = {
  availability: AvailabilityShape,
  styles: PropTypes.object,
};

export default BusyStatus;
