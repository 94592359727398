import React from 'react';
import PropTypes from 'prop-types';

import HowItWorks from 'app/components/call/HowItWorks';

const HowItWorksAsideContainer = ({ showHowItWorks }) => {
  return (
    <aside>
      { showHowItWorks && <HowItWorks /> }
    </aside>
  );
};

HowItWorksAsideContainer.propTypes = {
  showHowItWorks: PropTypes.bool.isRequired,
};

export default HowItWorksAsideContainer;
