import React from 'react';
import { PropTypes } from 'prop-types';

const SubscribeModal = ({
  closeModal,
  offering,
  openManageCardsModal,
  clickAction,
  pendingSubRequest,
}) => {
  if (!offering) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        <h4>Subscribe to get these benefits:</h4>
      </div>
      <div className="modal-body">
        <div className="container-benefits margintop30">
          <div className="row">
            <div className="col-sm-12">
              <div className="container-fluid marginlftminus20">
                <div className="row middle-xs marginbot10">
                  <div className="paddingrgt15">
                    <img src="/plus_frontend/assets/svg/checkmark-purple.svg" alt="checkmark" />
                  </div>
                  <div className="hd-benefit">
                    Exclusive subscriber content
                  </div>
                </div>
                <div className="row middle-xs marginbot10">
                  <div className="paddingrgt15">
                    <img src="/plus_frontend/assets/svg/checkmark-purple.svg" alt="checkmark" />
                  </div>
                  <div className="hd-benefit">
                    Hand-picked back catalog
                  </div>
                </div>
                <div className="row middle-xs marginbot10">
                  <div className="paddingrgt15">
                    <img src="/plus_frontend/assets/svg/checkmark-purple.svg" alt="checkmark" />
                  </div>
                  <div className="hd-benefit">
                    Insider access -  interact with subscriber-only posts
                  </div>
                </div>
              </div>
              <p>
                Starting today, $
                {offering.rate}
                &nbsp;will be deducted from your NiteFlirt balance or charged to your card every month. Cancel any time.
              </p>
              <p>
                <a role="link" tabIndex={0} onClick={openManageCardsModal}>Use a different card</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a
          role="button"
          tabIndex={0}
          className={`btn secondary btn-cancel ${pendingSubRequest ? 'disabled' : ''}`}
          onClick={() => {
            if (pendingSubRequest) { return; }
            closeModal();
          }}
        >
          Cancel
        </a>
        <button
          type="button"
          className="btn btn-submit"
          onClick={() => clickAction()}
          disabled={pendingSubRequest}
        >
          Confirm Subscription
        </button>
      </div>
    </>
  );
};

SubscribeModal.defaultProps = {
  offering: null,
  pendingSubRequest: false,
};

SubscribeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  offering: PropTypes.object,
  clickAction: PropTypes.func.isRequired,
  openManageCardsModal: PropTypes.func.isRequired,
  pendingSubRequest: PropTypes.bool,
};

export default SubscribeModal;
