import PropTypes from 'prop-types';

const GoodyBagShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  thumbnail_url: PropTypes.string,
  total_ratings: PropTypes.number,
  up_ratings: PropTypes.number,
  approval: PropTypes.number,
});

export default GoodyBagShape;
