import React from 'react';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TextField, FormHelperText, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';


const DatePickerCustom = styled(DesktopDatePicker)(() => ({
  '&.MuiFormControl-root': {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '0px 14px',
    },
    '& .MuiIconButton-root': {
      marginRight: '-3px',
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: '-3px',
    '&.MuiInputLabel-shrink': {
      marginTop: '0px',
    },
  },
}));

const validateDateOfBirth = (value) => {
  const maxDate = dayjs().subtract(18, 'year');
  const selectedDate = dayjs(value);

  if (!selectedDate.isValid()) {
    return 'Please enter a valid date of birth.';
  } else if (selectedDate.isAfter(maxDate)) {
    return 'You must be over 18 to use NiteFlirt.';
  }
  return true;
};

const DateOfBirthField = ({ field, fieldState }) => {
  const { value, onChange, ...DatePickerProps } = field;
  const { error } = fieldState;

  return (
    <Box sx={{ width: '100%' }}>
      <DatePickerCustom
        {...DatePickerProps}
        value={value || null}
        onChange={(date) => onChange(date)}
        label="Date of Birth"
        maxDate={dayjs()}
        inputFormat="MM/DD/YYYY"
        mask="__/__/____"
        desktopModeMediaQuery="@media (pointer: fine)"  // Always use desktop picker
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!error}
            inputProps={{
              ...params.inputProps,
              inputMode: 'numeric',
            }}
          />
        )}
      />
      {error && (
        <FormHelperText error>
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

const DateOfBirth = () => {
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} style={{ width: '100%' }}>
        <Controller
          name="dateOfBirth"
          control={control}
          rules={{
            required: 'Please enter a valid date of birth.',
            validate: validateDateOfBirth,
          }}
          render={DateOfBirthField}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateOfBirth;
