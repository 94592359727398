import React from 'react';
import PropTypes from 'prop-types';

const IconLink = ({ title, href, iconSrc, altText, imgClasses, role, iconStyle}) => {
  // Currently used only for external links
  return (
    <a
      title={title}
      href={href}
      role={role}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={iconSrc}
        alt={altText}
        className={imgClasses}
        style={iconStyle}
      />
    </a>
  );
};

IconLink.defaultProps = {
  title: null,
  altText: null,
  imgClasses: null,
  role: null,
  iconStyle: null,
};

IconLink.propTypes = {
  title: PropTypes.string,
  role: PropTypes.string,
  iconStyle: PropTypes.object,
  href: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  imgClasses: PropTypes.string,
};

export default IconLink;
