import React from 'react';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const QuietHourTimePicker = ({ value, onChange, views, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TimePicker
        value={value}
        label={label}
        views={views}
        onOpen={() => {
          window.setTimeout(
            () => { document.getElementsByClassName('MuiMultiSectionDigitalClockSection-root')[0].scrollBy({ top: -100 }); },
            100,
          );
        }}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

QuietHourTimePicker.defaultProps = {
  value: null,
  views: ['hours'],
  label: 'Select',
};

QuietHourTimePicker.propTypes = {
  value: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
};

export default QuietHourTimePicker;
