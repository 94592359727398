import React from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const ConfirmTribute = ({
  amount,
  cardType,
  lastFour,
  isLoading,
  confirmTributeClick,
  useDifferentCardClick,
  closeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Confirm
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Starting today,&nbsp;
              {amount}
              &nbsp;will be deducted from your NiteFlirt balance or
              charged to your default card every month. Cancel any time.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Current default card:&nbsp;
              {cardType}
              &nbsp;ending in&nbsp;
              {lastFour}
            </Typography>
            <Button
              variant="text"
              onClick={useDifferentCardClick}
            >
              Update your default card
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeClick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={confirmTributeClick}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmTribute.propTypes = {
  amount: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmTributeClick: PropTypes.func.isRequired,
  useDifferentCardClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
};

export default ConfirmTribute;
