import React, { useContext } from 'react';

import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import ModalsContext from 'app/contexts/ModalsContext';
import GenericModal from 'app/components/modals/GenericModal';

import { useDestroySocialMediaConnectionMutation } from 'app/api/mainApi';

import { capitalize } from 'app/helpers/stringHelpers';

import {
  Button,
} from '@mui/material';

const UnlinkSocialMediaConnection = ({ connection }) => {
  const [destroySocialMediaConnection] = useDestroySocialMediaConnectionMutation();
  const { openModal } = useContext(ModalsContext);

  const handleClick = () => {
    openModal({
      component: GenericModal,
      props: {
        callToAction: () => {
          destroySocialMediaConnection({ id: connection.id });
        },
        ctaButtonLabel: 'Confirm',
        body: `Are you sure you want to unlink ${capitalize(connection.provider)}?`,
      },
    });
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
    >
      Unlink
    </Button>
  );
};

UnlinkSocialMediaConnection.propTypes = {
  connection: SocialMediaConnectionShape.isRequired,
};

export default UnlinkSocialMediaConnection;
