import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import Lightbox from 'lightbox-react';

import usePaginatedRecords from 'app/hooks/usePaginatedRecords';
import MediaItemsPageContainer from './MediaItemsPageContainer';
import { useGetFlirtMediaQuery } from 'app/api/feedsApi';
import useFlirtMedia from 'app/hooks/useFlirtMedia';
import { postItemToMediaParams } from 'app/helpers/flirtMediaHelpers';
import MediaFilters from 'app/components/MediaFilters';
import { ALL_MEDIA } from 'app/constants/mediaFilterTypes';
import { resetFlirtMedia, doneResettingFlirtMedia } from 'app/slices/resetRecordsSlice';

const FlirtMediaContainer = ({ advisor }) => {
  const [mediaFilter, setMediaFilter] = useState(ALL_MEDIA);
  const dispatch = useDispatch();

  const setMediaFilterAndResetCursors = async (newMediaFilter) => {
    await dispatch(resetFlirtMedia({ advisorId: advisor.id }));
    setMediaFilter(newMediaFilter);
  };

  const mediaItems = useRef([]);

  // reaches into the slice to determine if the flag has been set telling
  // us to reload the entire feed
  const { resetFlirtMediaRequest } = useSelector(state => state.resetRecords);

  const {
    nextItem,
    prevItem,
    currentItem,
    onNextClick,
    onPrevClick,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    setLightboxIsOpen,
    lightboxMediaDecorator,
  } = useFlirtMedia({ mediaItems: mediaItems.current });

  // usePaginatedRecords uses this function to determine if it should reset the whole feed
  // it's passed into the custom hook
  const shouldResetMediaTestFunc = () => {
    return resetFlirtMediaRequest && resetFlirtMediaRequest === advisor.id;
  };

  const {
    cursors: mediaCursors,
    setMoreRecords: setMoreMediaRecords,
    setOldestCursor: setOldestMediaCursor,
  } = usePaginatedRecords({
    resetRecordsRequest: resetFlirtMediaRequest,
    doneResettingRecordsFunc: doneResettingFlirtMedia,
    shouldResetRecordsTestFunc: shouldResetMediaTestFunc,
  });

  // this is a bit brittle... look at the dynamic key name:
  // `getFlirtMedia({"advisorId":${advisor.id},"cursor":"${mediaCursor}"})`
  // if anything changes in the query structure, this will break!
  const getMediaItems = (state, transformFn) => {
    return mediaCursors.map((mediaCursor) => {
      const key = `getFlirtMedia({"advisorId":${advisor.id},"cursor":"${mediaCursor}","filter":"${mediaFilter}"})`;
      const slice = state.feeds.queries[key];

      if (slice?.status !== 'fulfilled') return [];

      return slice.data.records.map((record) => {
        return record.unlocked ? transformFn(record) : null;
      });
    }).flat().filter(n => n);
  };

  // every time this component renders, we reach into the redux store
  // and build up a collection of all the media items that have been loaded so far
  // for use in the lightbox. if there's a better way to do this, I haven't found it yet.
  mediaItems.current = useSelector((state) => getMediaItems(state, postItemToMediaParams));

  const onMediaItemClick = (mediaItem) => {
    const index = mediaItems.current.findIndex((item) => {
      return item.fm_item_id === mediaItem.fm_item_id;
    });
    setMediaIndex(index);
    setLightboxIsOpen(true);
  };

  return (
    <>
      <MediaFilters
        mediaFilter={mediaFilter}
        setMediaFilterAndResetCursors={setMediaFilterAndResetCursors}
      />
      <div className="media-container">
        {mediaCursors.map((mediaCursor) => {
          return (
            <MediaItemsPageContainer
              advisor={advisor}
              key={mediaCursor}
              setOldestCursor={setOldestMediaCursor}
              feedQuery={() => useGetFlirtMediaQuery({
                cursor: mediaCursor,
                advisorId: advisor.id,
                filter: mediaFilter,
              }, {
                refetchOnMountOrArgChange: true,
                keepUnusedDataFor: 0,
              })}
              onMediaItemClick={onMediaItemClick}
              setMoreRecords={setMoreMediaRecords}
            />
          );
        })}
      </div>

      {lightboxIsOpen && (
        <Lightbox
          onCloseRequest={onCloseClick}
          onMovePrevRequest={onPrevClick}
          onMoveNextRequest={onNextClick}
          nextSrc={lightboxMediaDecorator(nextItem)}
          prevSrc={lightboxMediaDecorator(prevItem)}
          mainSrc={lightboxMediaDecorator(currentItem)}
        />
      )}
    </>
  );
};

FlirtMediaContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default FlirtMediaContainer;
