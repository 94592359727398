import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import DialogsContext from 'app/contexts/DialogsContext';
import { sendViewModalEvent } from '../util/gtmUtil';
import theme from 'app/redesign/theme';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
    'input[type=text].MuiInputBase-inputAdornedStart': {
      borderLeft: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c4c4c4',
    },
    '& .MuiButtonGroup-root': {
      border: '1px solid #ddd',
      color: '#06c',
      '& .MuiButtonGroup-grouped': {
        '&.MuiButton-root': {
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
          '&.active': {
            '&:hover': {
              backgroundColor: '#e8e9fb',
            },
          },
        },
      },
      '& .MuiButtonGroup-grouped:not(:last-of-type)': {
        borderColor: '#ccc',
      },
    },
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialog-paperWidthSm': {
    width: '100%',
    maxWidth: '700px',
    margin: '0px',
    borderRadius: '0px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '600px',
    },
  },
}));

const DialogsContainer = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [dialogProps, setDialogProps] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const DialogComponent = dialogProps?.component;

  const openDialog = (dialogProps) => {
    setDialogProps(dialogProps);
    setOpen(true);
    sendViewModalEvent(dialogProps?.component);
  };

  const closeDialog = () => {
    setDialogProps(null);
    setOpen(false);
  };

  return (
    <DialogsContext.Provider value={{ openDialog, closeDialog }}>
      {dialogProps && (
        <StyledDialog
          open={open}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
          className="tribute-main-css"
          onClose={(_event, reason) => {
            if (
              dialogProps.props?.hideCancel &&
              (reason === 'escapeKeyDown' || reason === 'backdropClick')
            ) {
              // do nothing- this dialog should not be closeable
            } else {
              closeDialog();
            }
          }}
          sx={{
            zIndex: dialogProps?.zIndex || '1300',
          }}
        >
          <DialogComponent {...dialogProps.props} />
        </StyledDialog>
      )}
      {children}
    </DialogsContext.Provider>
  );
};

DialogsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default DialogsContainer;
