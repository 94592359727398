import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import IconSocialTwitter from 'app/components/customSvgs/IconSocialTwitter';
import IconSocialTumblr from 'app/components/customSvgs/IconSocialTumblr';

const SocialMediaManualShoutSubstitutions = ['{profile_link}', '{goodies_link}'];

const NewSocialMediaManualShoutForm = ({
  createSocialMediaManualShout,
  twitterConnection,
  tumblrConnection,
  submissionError,
}) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [latestPotentialSubstitute, setLatestPotentialSubstitute] = useState('');
  const [twitterChecked, setTwitterChecked] = useState(false);
  const [tumblrChecked, setTumblrChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const hasValidTwitterConnection = twitterConnection?.id && !twitterConnection?.authentication_error;
  const hasValidTumblrConnection = tumblrConnection?.id && !tumblrConnection?.authentication_error;
  const atLeastOneValidConnection = hasValidTwitterConnection || hasValidTumblrConnection;

  const handleClick = () => {
    const manualShout = {
      content: textFieldValue,
      send_to_twitter: twitterChecked,
      send_to_tumblr: tumblrChecked,
    };

    createSocialMediaManualShout({ manualShout });
  };

  // TODO: Refactor the autocomplete fields here and in SocialMediaEventTemplate to share the same logic
  const textFieldRef = useRef(null);
  const hiddenTextFieldRef = useRef(null);
  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);

    const match = event.target.value.match(/\{[^\}]*$/);
    if (match) {
      const lastOpenBraceWithoutCloseOrSpace = match[0];
      setLatestPotentialSubstitute(lastOpenBraceWithoutCloseOrSpace);
    } else {
      setLatestPotentialSubstitute('');
    }
  };

  const handleSelectAutoComplete = (_event, value) => {
    const lastOpenBraceIndex = textFieldValue.lastIndexOf('{');

    if (lastOpenBraceIndex !== -1) {
      const newValue = textFieldValue.slice(0, lastOpenBraceIndex) + value;

      setTextFieldValue(newValue);
      setLatestPotentialSubstitute('');
      hiddenTextFieldRef.current.focus();
    }
  };

  const handleTwitterChange = (event) => {
    setTwitterChecked(event.target.checked);
  };

  const handleTumblrChange = (event) => {
    setTumblrChecked(event.target.checked);
  };

  useEffect(() => {
    setTwitterChecked(hasValidTwitterConnection);
    setTumblrChecked(hasValidTumblrConnection);
  }, [twitterConnection, tumblrConnection]);

  return (
    <>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Grid container alignItems="center" mb={-3}>
          <Grid item sm={10} xs={9}>
            <Typography variant="subtitle1">
              Write something, we’ll post it for you:
            </Typography>
          </Grid>
          <Grid item sm={2} xs={3} alignItems="center">
            <Stack direction="row" spacing={2.6} justifyContent="center" alignItems="center">
              <IconSocialTwitter alt="Twitter" style={{ height: '20px', width: '20px' }} />
              <IconSocialTumblr alt="Tumblr" style={{ height: '20px', width: '20px' }} />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Grid container>
          <Grid item sm={10} xs={9}>
            <Box display="block" sx={{ position: 'relative' }}>
              <Box
                sx={latestPotentialSubstitute.length > 0 ? { zIndex: 1, position: 'absolute', width: '100%' } : { position: 'absolute', width: '100%', visibility: 'hidden' }}
              >
                <Autocomplete
                  options={SocialMediaManualShoutSubstitutions}
                  onChange={handleSelectAutoComplete}
                  blurOnSelect
                  disableClearable
                  freeSolo
                  open={latestPotentialSubstitute.length > 0}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      type="hidden"
                      inputRef={hiddenTextFieldRef}
                    />
                  )}
                />
              </Box>

              <TextField
                value={textFieldValue}
                error={!!submissionError?.data?.errors}
                helperText={submissionError?.data?.errors?.content}
                multiline
                rows={isFocused ? 5 : 1}
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 280, style: { fontSize: '0.875rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' } }}
                size="small"
                placeholder={atLeastOneValidConnection ? 'Write something.' : 'Please connect at least one social media account to post.'}
                disabled={!atLeastOneValidConnection}
                onChange={handleTextFieldChange}
                inputRef={textFieldRef}
                onFocus={() => {
                  textFieldRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                  setIsFocused(true);
                }}
                onBlur={() => setIsFocused(false)}
              />
            </Box>
          </Grid>
          <Grid item sm={2} xs={3} alignItems="center" sx={{ border: '1px solid transparent' }}>
            <Stack direction="row" justifyContent="center" alignItems="center" mt={0.5}>
              {
                // TODO: Would this be a good candidate for a new component? Maybe something obnoxious like WaitingForServerResponseCheckbox?
                //
                // NOTE: We need to check for the existence of the connection before we we render the checkbox in order
                // to correctly render the defaultChecked state.  If we render without waiting for a connection object,
                // we will always get an unchecked box because the component only renders once with the defaultChecked
                // state.  Since we are waiting for an api return it will always be false at first until the api returns
                // the connection.
              }
              { hasValidTwitterConnection ? (
                <Checkbox
                  defaultChecked
                  onChange={handleTwitterChange}
                />
              ) : (
                <>
                  {
                    // NOTE: We need to wrap this in a fragment to prevent react from optimizing the checkboxes and
                    // considering them a single entity and caching the defaultChecked state between them.
                  }
                  <Checkbox
                    disabled
                  />
                </>
              )}

              {
                // NOTE: We need to check for the existence of the connection before we we render the checkbox in order
                // to correctly render the defaultChecked state.  If we render without waiting for a connection object,
                // we will always get an unchecked box because the component only renders once with the defaultChecked
                // state.  Since we are waiting for an api return it will always be false at first until the api returns
                // the connection.
              }
              { hasValidTumblrConnection ? (
                <Checkbox
                  defaultChecked
                  onChange={handleTumblrChange}
                />
              ) : (
                <>
                  {
                    // NOTE: We need to wrap this in a fragment to prevent react from optimizing the checkboxes and
                    // considering them a single entity and caching the defaultChecked state between them.
                  }
                  <Checkbox
                    disabled
                  />
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            size="medium"
            onClick={handleClick}
          >
            Post
          </Button>
        </Stack>
      </Paper>
    </>
  );
};

NewSocialMediaManualShoutForm.defaultProps = {
  twitterConnection: null,
  tumblrConnection: null,
  submissionError: null,
};

NewSocialMediaManualShoutForm.propTypes = {
  createSocialMediaManualShout: PropTypes.func.isRequired,
  twitterConnection: SocialMediaConnectionShape,
  tumblrConnection: SocialMediaConnectionShape,
  submissionError: PropTypes.object,
};

export default NewSocialMediaManualShoutForm;
