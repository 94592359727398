import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  COMBINED_FEED_CONTENT_AVAILABLE_TAG,
  FLIRT_FEED_CONTENT_AVAILABLE_TAG,
  EXPLORE_FEED_TAG,
  APP_CONFIG_TAG,
  ADVISOR_TAG,
  FOLLOW_TAG,
  POST_TAG,
  PACKAGES_TAG,
} from './sharedApiTags';

import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const FLIRT_FEED_PAGE_TAG = 'FlirtFeedPage';
const FLIRT_MEDIA_TAG = 'FlirtMedia';
const PROCESSING_POSTS_TAG = 'ProcessingPosts';
const REMOVED_POSTS_TAG = 'RemovedPosts';
const SCHEDULED_POSTS_TAG = 'ScheduledPosts';

const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

const feedsApi = createApi({
  reducerPath: 'feeds',

  baseQuery: fetchBaseQuery({ baseUrl: '/feeds/' }),

  endpoints: (builder) => ({
    // feeds endpoints
    homeFeedNewPostsCheck: builder.query({
      query: ({ cursor }) => `new_posts_check?cursor=${cursor || ''}`,
    }),

    getFollow: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: FOLLOW_TAG, id: `advisor-${args.advisorId}` }];
      },
      query: ({ advisorId }) => {
        return `follows/${advisorId}`;
      },
    }),

    getFollows: builder.query({
      providesTags: [FOLLOW_TAG],
      query: ({ advisorIds }) => {
        return `follows?advisor_ids=${advisorIds.join(',')}`;
      },
    }),

    followAdvisor: builder.mutation({
      invalidatesTags: (result, err, originalArgs) => {
        if (!err && originalArgs.refetchPost) {
          return [
            FOLLOW_TAG,
            COMBINED_FEED_CONTENT_AVAILABLE_TAG,
            { type: POST_TAG, id: originalArgs.postId },
          ];
        }

        return [FOLLOW_TAG, COMBINED_FEED_CONTENT_AVAILABLE_TAG];
      },
      query: ({ advisorId }) => ({
        url: 'follows',
        method: 'POST',
        body: { advisor_id: advisorId },
      }),
    }),

    unfollowAdvisor: builder.mutation({
      invalidatesTags: (result, err, originalArgs) => {
        if (!err && originalArgs.refetchPost) {
          return [
            FOLLOW_TAG,
            { type: POST_TAG, id: originalArgs.postId },
          ];
        }
        return [FOLLOW_TAG];
      },
      query: ({ advisorId }) => {
        return {
          url: `follows/${advisorId}`,
          method: 'DELETE',
        };
      },
    }),

    getHomeFeed: builder.query({
      providesTags: (response) => {
        if (!response) return [];

        const tags = [];
        response.records.forEach((r) => {
          tags.push({ type: POST_TAG, id: r.id });
        });

        return tags;
      },
      query: ({ cursor }) => `home_feed?cursor=${cursor || ''}`,
      keepUnusedDataFor: 0,
    }),

    getExploreFeed: builder.query({
      providesTags: [EXPLORE_FEED_TAG],
      query: ({ cursor }) => `explore_feed?cursor=${cursor || ''}`,
      keepUnusedDataFor: 0,
    }),

    getFlirtFeed: builder.query({
      query: ({ advisorId, cursor }) => {
        return `creator_feed/${advisorId}?cursor=${cursor || ''}`;
      },
      providesTags: (response, err, originalArgs) => {
        if (!response) return [];

        const tags = [{
          type: FLIRT_FEED_PAGE_TAG,
          id: `${response.advisorId}-${originalArgs.cursor}`,
        }];

        response.records.forEach((r) => {
          tags.push({ type: POST_TAG, id: r.id });
        });

        return tags;
      },
      keepUnusedDataFor: 0,
      transformResponse: (response, meta, args) => {
        const newRecords = response.records.map((r) => {
          const newRecord = { ...r };
          newRecord.cursorPage = args.cursor;
          return newRecord;
        });

        return {
          records: newRecords,
          cursor: response.cursor,
          moreRecords: response.moreRecords,
          advisorId: response.advisorId,
        };
      },
    }),

    getFlirtMedia: builder.query({
      query: ({ advisorId, cursor, filter }) => {
        return `creator_media_feed/${advisorId}?cursor=${cursor || ''}&item_type=${filter || ''}`;
      },
      providesTags: (response, error, args) => {
        return response ? [{
          type: FLIRT_MEDIA_TAG,
          id: args.advisorId,
        }] : [];
      },
    }),

    //  post GET    /posts/:id(.:format)  posts#show
    getPost: builder.query({
      providesTags: (result) => {
        return result ? [{ type: POST_TAG, id: result.id }] : [];
      },
      query: ({ postId }) => `posts/${postId}`,
    }),

    getProcessingPosts: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [PROCESSING_POSTS_TAG] : [];
      },
      query: ({ userId }) => {
        return `users/${userId}/processing_posts`;
      },
    }),

    getRemovedPosts: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [REMOVED_POSTS_TAG] : [];
      },
      query: ({ userId }) => {
        return `users/${userId}/removed_posts`;
      },
    }),

    getScheduledPosts: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [SCHEDULED_POSTS_TAG] : [];
      },
      query: () => {
        return 'scheduled_posts';
      },
    }),

    performCreatePost: builder.mutation({
      invalidatesTags: (result, err, originalArgs) => {
        if (!err && originalArgs.onboarding) {
          // cross-slice cache tag invalidation
          dispatchInvalidateCacheTagsEvent([
            APP_CONFIG_TAG,
            PACKAGES_TAG,
            { type: ADVISOR_TAG, id: originalArgs.advisorId },
          ]);

          return [
            { type: FLIRT_MEDIA_TAG, id: originalArgs.advisorId },
            { type: FLIRT_FEED_CONTENT_AVAILABLE_TAG, id: originalArgs.advisorId },
          ];
        }
        return [];
      },
      query: ({ post }) => {
        return {
          method: 'POST',
          url: 'posts',
          body: { post },
        };
      },
    }),

    performUpdatePost: builder.mutation({
      invalidatesTags: (result, err, originalArgs) => {
        return err ? [] : [
          { type: POST_TAG, id: originalArgs.postId },
          { type: FLIRT_FEED_PAGE_TAG, id: `${originalArgs.advisorId}-${originalArgs.cursorPage}` },
          SCHEDULED_POSTS_TAG, PROCESSING_POSTS_TAG, REMOVED_POSTS_TAG,
        ];
      },
      query: ({ postId, post }) => {
        return {
          method: 'PUT',
          url: `posts/${postId}`,
          body: { post },
        };
      },
    }),

    performPublishPost: builder.mutation({
      invalidatesTags: (result, err, originalArgs) => {
        return err ? [] : [
          { type: POST_TAG, id: originalArgs.postId },
          { type: FLIRT_FEED_PAGE_TAG, id: `${originalArgs.advisorId}-${originalArgs.cursorPage}` },
          SCHEDULED_POSTS_TAG,
        ];
      },
      query: ({ postId }) => {
        return {
          method: 'PUT',
          url: `posts/${postId}/publish`,
        };
      },
    }),

    performDeletePost: builder.mutation({
      invalidatesTags: (r, err, o) => {
        // NOTE: We can optimize this later so we don't have to invalidate the
        // entire queue of processing and removed posts.
        return err ? null : [{ type: POST_TAG, id: o.postId }, PROCESSING_POSTS_TAG, SCHEDULED_POSTS_TAG, REMOVED_POSTS_TAG];
      },
      query: ({ postId }) => ({
        method: 'DELETE',
        url: `posts/${postId}`,
      }),
    }),

    togglePostPin: builder.mutation({
      query: ({ postId, pinned }) => ({
        url: `posts/${postId}`,
        method: 'PUT',
        body: {
          post: {
            pinned,
          },
        },
      }),
    }),

    performCreateComment: builder.mutation({
      invalidatesTags: (r, err, o) => {
        return err ? [] : [{ type: POST_TAG, id: o.postId }];
      },
      query: ({ body, postId }) => {
        return ({
          url: `posts/${postId}/comments`,
          method: 'POST',
          body: { comment: { body } },
        });
      },
    }),

    getComments: builder.query({
      query: ({ postId, cursor }) => {
        return `posts/${postId}/comments?cursor=${cursor || ''}`;
      },
      keepUnusedDataFor: 0,
    }),

    performAddLike: builder.mutation({
      invalidatesTags: (r, err, o) => {
        return err ? [] : [{ type: POST_TAG, id: o.postId }];
      },
      query: ({ postId }) => ({
        url: `posts/${postId}/like`,
        method: 'POST',
      }),
    }),

    performRemoveLike: builder.mutation({
      invalidatesTags: (r, err, o) => {
        return err ? [] : [{ type: POST_TAG, id: o.postId }];
      },
      query: ({ postId }) => ({
        url: `posts/${postId}/like`,
        method: 'DELETE',
      }),
    }),

    getPostLikes: builder.query({
      query: ({ postId }) => ({
        url: `posts/${postId}/likes`,
      }),
    }),

    getCombinedFeedContentAvailable: builder.query({
      query: () => ({
        url: 'accounts/combined_feed_content_check',
        method: 'POST',
      }),
      providesTags: () => {
        return [COMBINED_FEED_CONTENT_AVAILABLE_TAG];
      },
    }),

    getCreatorFeedContentAvailable: builder.query({
      providesTags: (response, error, args) => {
        return response ? [{ type: FLIRT_FEED_CONTENT_AVAILABLE_TAG, id: args.advisorId }] : [];
      },
      query: ({ advisorId }) => {
        return {
          url: 'accounts/creator_feed_content_check',
          method: 'POST',
          body: { creator_main_user_id: advisorId },
        };
      },
    }),
  }),
});

export default feedsApi;

export const {
  useGetFollowQuery,
  useGetFollowsQuery,
  useGetFlirtFeedQuery,
  useGetFlirtMediaQuery,
  useGetPostQuery,
  useGetCommentsQuery,
  useGetCombinedFeedContentAvailableQuery,
  useGetCreatorFeedContentAvailableQuery,
  useGetScheduledPostsQuery,
  useGetProcessingPostsQuery,
  useGetRemovedPostsQuery,
  useGetHomeFeedQuery,
  useGetExploreFeedQuery,
  useLazyHomeFeedNewPostsCheckQuery,
  usePerformAddLikeMutation,
  usePerformRemoveLikeMutation,
  useGetPostLikesQuery,
  useFollowAdvisorMutation,
  useUnfollowAdvisorMutation,
  usePerformCreatePostMutation,
  usePerformCreateCommentMutation,
  useTogglePostPinMutation,
  usePerformUpdatePostMutation,
  usePerformDeletePostMutation,
  usePerformPublishPostMutation,
} = feedsApi;
