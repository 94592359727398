const buildTrackingString = (params) => {
  const trackingArray = [];

  for (const [key, value] of Object.entries(params)) {
    trackingArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }

  return trackingArray.join('+');
};

export default buildTrackingString;
