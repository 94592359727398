import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
  useGetRecordedNameQuery,
  useUploadRecordedNameMutation,
} from 'app/api/mainApi';

import DrawerHeader from '../shared/DrawerHeader';
import { showFlash } from 'app/slices/flashSlice';
import numberToHumanSize from 'app/helpers/fileSizeHelper';

const RecordNameDrawer = ({
  anchor,
  isOpen,
  closeDrawer,
  currentUser,
}) => {
  const fileInputRef = useRef(null);
  const [ uploadRecordedNameMutation, {
    isSuccess: isUploadRecordedNameSuccess,
    error: uploadRecordedNameError,
  } ] = useUploadRecordedNameMutation();
  const dispatch = useDispatch();

  const { data: recordedName } = useGetRecordedNameQuery();

  const handleFileChange = async (event) => {
    const file = event.target.files[ 0 ];
    if (file) {
      const formData = new FormData();
      formData.append('recorded_name[audio_file]', file);
      uploadRecordedNameMutation(formData);
    }
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (isUploadRecordedNameSuccess) {
      dispatch(showFlash({
        flashType: 'success',
        props: { message: 'Your file has been uploaded successfully!' },
      }));
    }

    if (uploadRecordedNameError) {
      dispatch(showFlash({
        flashType: 'error',
        props: { message: 'There was an error uploading your file.  Please try again with a .wav file.' },
      }));
    }
  }, [ isUploadRecordedNameSuccess, uploadRecordedNameError ]);

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={closeDrawer}
    >
      <Grid
        container
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <DrawerHeader
          closeDrawer={closeDrawer}
          headerCopy="Record your Member Name"
        />
        <Grid item xs={12}>
          <Grid container sx={{ mb: 10, lineHeight: '1.5em' }}>
            <Grid item xs={12} sx={{ mb: 2, mt: 0.25 }}>
              <Paper sx={{ p: 3, pr: 4, mb: 1.5 }} elevation={0}>
                Your recorded Member Name is what customers will hear over the phone.
                <br />
                <div className="margintop5">There are 3 ways to record your Member Name:</div>
              </Paper>
              <Paper data-test-id="option-1" sx={{ p: 3, mb: 1.5, borderRadius: 0 }} elevation={0}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={9} xs={8}>
                    <Typography variant="body1" gutterBottom>
                      <span style={{ color: '#A0A0A0' }}>Option 1</span>
                      <br />
                      We&apos;ll call you!
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={4} className="pull-right">
                    <a
                      href={currentUser.record_name_path}
                      target="_blank"
                      style={{ color: '#fff' }}
                      rel="noreferrer"
                    >
                      <Button variant="contained" size="medium">
                        Call Me
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Paper>
              <Paper data-test-id="option-2" sx={{ p: 3, mb: 1.5, borderRadius: 0 }} elevation={0}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={9} xs={8}>
                    <Typography variant="body1" gutterBottom>
                      <span style={{ color: '#A0A0A0' }}>Option 2</span>
                      <br />
                      Upload a .wav file
                    </Typography>
                  </Grid>
                  <Grid item sm={3} xs={4} className="pull-right">
                    <input
                      accept=".wav"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={openFileInput}
                    >
                      Upload
                    </Button>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={5} className="pull-right ellipsis">
                      <span style={{ color: '#A0A0A0', fontSize: '0.875em' }}>
                        {recordedName?.audio_file_name}
                      </span>
                    </Grid>
                    <Grid item>
                      <span style={{ color: '#A0A0A0', fontSize: '0.875em' }}>
                        {recordedName?.audio_file_size && `(${numberToHumanSize(recordedName?.audio_file_size)})`}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper data-test-id="option-3" sx={{ p: 3, mb: 1.5, borderRadius: 0 }} elevation={0}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item sm={12} xs={12}>
                    <Typography variant="body1" gutterBottom>
                      <span style={{ color: '#888' }}>Option 3</span>
                      <br />
                      Call the Flirt Hotline at&nbsp;
                      <a href="tel:18007755898">1-800-775-5898</a>
                      &nbsp;and select Option 3.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {recordedName?.audio_file_name && (
                <Paper sx={{ p: 3, pr: 4, mb: 1.5 }} elevation={0}>
                  Download to listen to your current recording. &nbsp;
                  <br />
                  <a download href="/account/recorded_name">
                    <Button variant="outlined" size="medium" sx={{ mt: 2 }}>
                      Download
                    </Button>
                  </a>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

RecordNameDrawer.defaultProps = {
  currentUser: {},
};

RecordNameDrawer.propTypes = {
  anchor: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default RecordNameDrawer;
