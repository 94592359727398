import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CategoryListings from 'app/components/categories/CategoryListings';
import { useGetCategoryListingsQuery } from 'app/api/mainApi';
import CategoryHeader from 'app/components/categories/CategoryHeader';
import NumberlessPagination from 'app/components/NumberlessPagination';
import CategoryBreadcrumbs from 'app/components/categories/CategoryBreadcrumbs';
import DecisionScreen from 'app/components/DecisionScreen';

import {
  setCategoryListingType,
  setCategoryListingSort,
  categoryListingTypeSelector,
  categoryListingSortSelector,
} from 'app/slices/miscUIStateSlice';

const CategoryListingsContainer = () => {
  const TABS = [
    { label: 'Live Phone Sex', value: 1 },
    { label: 'Recorded Phone Sex', value: 2 },
    { label: 'Chat', value: 3 },
  ];

  const SORTS = [
    { label: 'Featured Listings', value: 0 },
    { label: 'Top Available', value: 1 },
    { label: 'Highest Rated', value: 4 },
    { label: 'Less than $3', value: 2 },
    { label: 'Less than $2', value: 3 },
  ];

  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const decisionScreen = searchParams.get('decision_screen');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const listingType = useSelector(categoryListingTypeSelector);
  const sort = useSelector(categoryListingSortSelector);

  const categoryListingsParams = {
    category,
    page,
    listingType,
    perPage: 12,
    sort,
  };

  if (decisionScreen) {
    categoryListingsParams.decisionScreen = decisionScreen;
  }

  const {
    data: listingsData,
    isLoading,
    isSuccess,
  } = useGetCategoryListingsQuery(categoryListingsParams);

  // listingType and tab map to the same data (TABS.value)
  const handleTabChange = (event, newTab) => {
    dispatch(setCategoryListingType({ categoryListingType: newTab }));
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (event, value) => {
    dispatch(setCategoryListingSort({ categoryListingSort: value.props.value }));
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'scroll',
        height: 'calc(100vh - 173px)',
      }}
    >
      <CategoryHeader />
      {listingsData?.listings && (
        <>
          <CategoryBreadcrumbs breadcrumbs={listingsData?.category_breadcrumbs} />
          <CategoryListings
            tabs={TABS}
            sorts={SORTS}
            currentTab={listingType}
            currentSort={sort}
            handleTabChange={handleTabChange}
            listingsData={listingsData}
            handleSortChange={handleSortChange}
          />
        </>
      )}

      {(isSuccess && listingsData?.listings?.length === 0) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No listings found
        </Box>
      )}

      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Loading...
        </Box>
      )}

      {listingsData?.decision_screen && (
        <DecisionScreen />
      )}
      {listingsData?.listings && (
        <Grid container sx={{ mt: 1, pr: 1 }}>
          <Grid item xs={12} container justifyContent="flex-end">
            <NumberlessPagination
              hasNext={listingsData?.has_next}
              page={listingsData?.current_page}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CategoryListingsContainer;
