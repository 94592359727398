import React from 'react';

import TaxForms from "app/components/account/TaxForms";
import { useGetTaxFormsQuery } from 'app/api/mainApi';

const TaxFormsContainer = () => {
  const { data } = useGetTaxFormsQuery();
  return <TaxForms tax_forms={data || []} />;
}

export default TaxFormsContainer;
