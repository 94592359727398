import React from 'react';
import { PropTypes } from 'prop-types';

import NewTributeForm from 'app/components/modals/tributes/NewTributeForm';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

import { featureFlag } from 'app/util/featureFlag';
import DeprecatedNewTributeFormContainer from './DeprecatedNewTributeFormContainer';

const NewTributeFormContainer = ({ advisor }) => {
  if (!featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537')) {
    return <DeprecatedNewTributeFormContainer advisor={advisor} />;
  }

  const {
    dispatch,
    recurring,
    setRecurring,
    closeDialogAndResetTributeSlice,
  } = useRecurringTributeManagement({ advisor });

  const setRecurringClick = (recurring) => {
    dispatch(setRecurring({ recurring }));
  };

  const closeClick = closeDialogAndResetTributeSlice;

  return (
    <NewTributeForm
      advisor={advisor}
      recurring={recurring}
      setRecurringClick={setRecurringClick}
      closeClick={closeClick}
    />
  );
};

NewTributeFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default NewTributeFormContainer;
