import {
  List,
  styled,
} from '@mui/material';

const AvailabilityList = styled(List)(() => ({
  '&.MuiList-root': {
    padding: '3px 0px',
    '& .Mui-selected': {
      '.MuiListItemIcon-root': {
        display: 'block',
      },
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: '#99c',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
    },
    '& .MuiDivider-root': {
      margin: '3px 0px',
    },
    '& .MuiSvgIcon-root': {
      '&.ico-list-avail': {
        height: '18px',
        padding: '2.8px',
        cursor: 'pointer',
      },
    },
  },
  '& .MuiListItem-root': {
    padding: '8px 22px',
  },
}));

export default AvailabilityList;
