import React from 'react';

import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import ListingShape from 'app/shapes/ListingShape';

const ListingOffer = ({ listing }) => {
  const freeMinutes = listing && parseInt(listing.free_minutes);
  return freeMinutes > 0 ? (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: 550,
        margin: 'auto',
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          Offer
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography variant="body1">
            {freeMinutes}
            &nbsp;free&nbsp;
            {freeMinutes === 1 ? 'minute' : 'minutes'}
            &nbsp;from your Flirt
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  ) : null;
};

ListingOffer.propTypes = {
  listing: ListingShape.isRequired,
};

export default ListingOffer;
