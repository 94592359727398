import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@mui/material';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const ConfirmCancelMonetaryTribute = ({
  advisor,
  closeDialog,
  onKeepTributeClick,
  onCancelTributeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Are you sure you want to cancel this tribute?
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              You will no longer send&nbsp;
              {advisor.login}
              &nbsp;a recurring tribute.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onKeepTributeClick}
        >
          Keep Tribute
        </Button>
        <Button
          variant="contained"
          onClick={onCancelTributeClick}
        >
          Cancel Tribute
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmCancelMonetaryTribute.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  advisor: PropTypes.object.isRequired,
  onKeepTributeClick: PropTypes.func.isRequired,
  onCancelTributeClick: PropTypes.func.isRequired,
};

export default ConfirmCancelMonetaryTribute;
