import { featureFlag } from 'app/util/featureFlag';

export const NEW = 'new';
export const ACCEPTED_2257 = 'accepted_2257';
export const HAS_OFFERING = 'has_offering';
export const HAS_PACKAGE = 'has_package';
export const COMPLETE = 'complete';

export const isLiabilityAccepted = (onboardingStep) => {
  const steps = [ACCEPTED_2257, COMPLETE];

  if (featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537')) {
    steps.push(HAS_PACKAGE);
  } else {
    steps.push(HAS_OFFERING);
  }

  return steps.includes(onboardingStep);
};

export const isSubscriptionPriceSet = (onboardingStep) => {
  const steps = [COMPLETE];

  if (featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537')) {
    steps.push(HAS_PACKAGE);
  } else {
    steps.push(HAS_OFFERING);
  }

  return steps.includes(onboardingStep);
};

export const hasCreatedWelcomePost = (onboardingStep) => {
  return onboardingStep === COMPLETE;
};
