import React from 'react';
import { PropTypes } from 'prop-types';

import SubscriptionButtonContainer from 'app/containers/SubscriptionButtonContainer';

// subscription object may or may not be passed in here, depending on the context.
// the subscriptions page will already have called the api for all subscriptions
// for the current user. If we're viewing a flirt feed, the subscription will not
// be passed in, and the SubscriptionButton will handle fetching the data
const Subscription = ({ subscriptionData, fetchStateFromApi, advisorLogin }) => {
  return (
    <>
      <div className="row middle-xs marginbot10">
        <div className="col-xs-12">
          <div className="subhead">Subscription</div>
        </div>
      </div>
      <SubscriptionButtonContainer
        subscriptionData={subscriptionData}
        fetchStateFromApi={fetchStateFromApi}
        advisorLogin={advisorLogin}
        displayOnHiddenPost={false}
      />
      {/* <SubscriptionBundles /> */}
    </>
  );
};

Subscription.defaultProps = {
  subscriptionData: null,
  advisorLogin: null,
};

Subscription.propTypes = {
  subscriptionData: PropTypes.object,
  fetchStateFromApi: PropTypes.bool.isRequired,
  advisorLogin: PropTypes.string,
};

export default Subscription;
