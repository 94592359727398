import { styled } from '@mui/material/styles';

const RatingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  marginTop: '5px',
});

export default RatingContainer;
