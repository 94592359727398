import React from 'react';
import { PropTypes } from 'prop-types';

import {
  DialogTitle,
  DialogContent,
} from '@mui/material';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

import { featureFlag } from 'app/util/featureFlag';

const EditMonetaryTribute = ({
  activeMonetaryTribute,
  advisor,
  amount,
  amountError,
  amountInvalid,
  closeDialog,
  message,
  onCancelTributeClick,
  onUpdateTributeClick,
  setAmount,
  setAmountError,
  setMessage,
  useDifferentCardClick,
}) => {
  const chargeFailed = activeMonetaryTribute?.status === 'charge_failed';

  return (
    <>
      <DialogTitle>
        Edit Recurring Tribute
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">
              You are currently sending the following recurring tribute to&nbsp;
              {advisor.login}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {`${activeMonetaryTribute.rate} per month`}
            </Typography>
            { featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
              <Typography variant="body1">
                There are no perks associated with this tribute.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                {chargeFailed && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body3">
                        Renewal Failed
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body3">
                        <Link
                          role="button"
                          tabIndex={0}
                          onClick={useDifferentCardClick}
                          style={{ cursor: 'pointer' }}
                        >
                          Update Card Now
                        </Link>
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="body3">
                    {chargeFailed ? 'Expires ' : 'Renews '}
                    {activeMonetaryTribute.renewalDate}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3">
              <Link
                role="button"
                tabIndex={0}
                onClick={onCancelTributeClick}
                style={{ cursor: 'pointer' }}
              >
                Cancel Recurring Tribute
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Update Recurring Tribute
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Update your current recurring tribute amount below. We&apos;ll charge the new amount today and then monthly until you cancel.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={amount}
              type="number"
              inputMode="decimal"
              label="Tribute Amount"
              error={!!amountError}
              helperText={amountError}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(e) => {
                setAmountError('');
                setAmount(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={message}
              label="Personal Message (optional)"
              onChange={(e) => { setMessage(e.currentTarget.value); }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                disabled={amountInvalid(amount)}
                onClick={onUpdateTributeClick}
              >
                Update
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

EditMonetaryTribute.propTypes = {
  activeMonetaryTribute: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
  amountError: PropTypes.string.isRequired,
  amountInvalid: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onCancelTributeClick: PropTypes.func.isRequired,
  onUpdateTributeClick: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setAmountError: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  useDifferentCardClick: PropTypes.func.isRequired,
};

export default EditMonetaryTribute;
