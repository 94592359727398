export const createMoreCommentsAnchor = () => {
  const moreCommentsElement = document.getElementById('more-comments');

  if (moreCommentsElement) {
    moreCommentsElement.style.display = 'block';

    setTimeout(() => {
      moreCommentsElement.style.display = 'none';
    }, 500);
  }
};

export const adjustCommentsAnchor = () => {
  const commentsElement = document.querySelector('.nfp-chat-body #comments');

  if (commentsElement) {
    commentsElement.classList.add('height');

    setTimeout(() => {
      commentsElement.classList.remove('height');
    }, 3000);
  }
};
