import React from 'react';
import { PropTypes } from 'prop-types';

const AppBody = ({ children }) => {
  return (
    <section
      className="body-content"
      id="body-content"
    >
      {children}
    </section>
  );
};

AppBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppBody;
