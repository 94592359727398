import { showFlash } from 'app/slices/flashSlice';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

/*
  NB: In order to accommodate both status: :ok and status: :bad_request-type responses,
  our middleware has to look for the flash object at different levels in the action's
  payload. for example, an unsuccessful request will nest the flash data like so:
  {
    "type": "plus/executeMutation/rejected",
    "payload": {
      "status": 400,
      "data": {
        "flash": {
          "type": "success",
          "message": "You are now following britney"
        }
      }
    }
    [...]
  }
  while a successful request looks like this:
  {
    "type": "plus/executeMutation/fulfilled",
    "payload": {
      "flash": {
        "type": "success",
        "message": "You are now following britney"
      }
    }
    [...]
  }

  valid flashType vals: 'global', 'feed', 'error', 'success'
*/

export const flashMiddleware = store => next => action => {
  if (action && (action.payload?.flash || action.payload?.data?.flash)) {
    const flashObject = action.payload?.flash || action.payload?.data?.flash;

    store.dispatch(showFlash({
      flashType: flashObject.type,
      props: { message: flashObject.message },
    }));
  }

  return next(action);
};

/*
  Required keys in snackbarObject are message and severity.
  They must be wrapped in a top-level "snackbar" object in the action's payload.

  example:
      render json: {
        snackbar: {
          message: 'You are no longer shown as busy.',
          severity: 'success'
        }
      }

  valid severity vals: 'success', 'error', 'info'
*/
export const snackbarMiddleware = store => next => action => {
  if (action?.payload?.snackbar || action?.payload?.data?.snackbar) {
    const snackbarObject = action.payload.snackbar || action.payload.data.snackbar;

    store.dispatch(showSnackbarMessage(snackbarObject));
  }

  return next(action);
};
