import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormHelperText, Rating, TextField, Typography } from '@mui/material';
import ThumbsUpDown from 'app/components/feedback/ThumbsUpDown';

const THUMBS_RATING_TYPES = [ 'PurchasedGoodyBag' ];

const FeedbackSection = ({ feedback, onFeedbackChange }) => {
  const [ rating, setRating ] = useState(feedback.rating);
  const [ comment, setComment ] = useState(feedback.comment ?? '');
  const [ initialValues ] = useState({
    rating: feedback.rating,
    comment: feedback.comment ?? '',
  });

  const isValidRating = () => rating !== 0 && rating !== null;

  const isValidFeedback = () => {
    if (!isValidRating(rating)) {
      return comment.trim() === '';
    }
    return true;
  };

  const hasFeedbackChanged = () => {
    return (rating !== initialValues.rating) || (comment.trim() !== initialValues.comment.trim());
  };

  useEffect(() => {
    onFeedbackChange({ ...feedback, valid: isValidFeedback(), changed: hasFeedbackChanged() });
  }, [ rating, comment ]);

  const updateFeedback = (newRating, newComment) => {
    setRating(newRating);
    setComment(newComment);
    onFeedbackChange({
      ...feedback,
      rating: newRating,
      comment: newComment,
      errors: {},
      valid: isValidFeedback(),
      changed: hasFeedbackChanged(),
    });
  };

  const handleRatingChange = (event, newRating) => {
    updateFeedback(newRating, comment);
  };

  const handleCommentChange = (event) => {
    updateFeedback(rating, event.target.value);
  };

  const required = comment.trim() !== '';
  const readOnlyRating = initialValues.rating !== 0 && initialValues.rating != null;
  const readOnlyComment = initialValues.comment !== '';

  return (
    <Box>
      <Typography variant="subtitle1">Rating</Typography>
      <Box sx={{ mb: 2 }}>
        {THUMBS_RATING_TYPES.includes(feedback.object_type) ? (
          <ThumbsUpDown rating={rating} onRatingChange={handleRatingChange} readOnly={readOnlyRating} />
        ) : (
          <Rating value={rating} onChange={handleRatingChange} readOnly={readOnlyRating} />
        )}
        {!isValidRating() && (
          <Box>
            <Typography variant="body3" color={required ? 'error' : 'inherit'}>
              Please select a rating.
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant="body1" style={{ marginBottom: '5px' }}>Feedback (optional)</Typography>
        <TextField
          placeholder="How was your experience?"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={handleCommentChange}
          inputProps={{
            maxLength: 255,
            readOnly: readOnlyComment,
          }}
          error={feedback.errors?.comment?.length > 0}
          helperText={
            feedback.errors?.comment?.join(', ') ||
            (readOnlyComment && 'You have already left feedback.')
          }
        />
        {(!feedback.errors?.comment || feedback.errors?.comment.length === 0) && !readOnlyComment && (
          <FormHelperText sx={{ textAlign: 'right' }}>
            <span data-test-id="character-counter">{`${comment.length} / 255 characters`}</span>
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

FeedbackSection.propTypes = {
  feedback: PropTypes.shape({
    rating: PropTypes.number,
    comment: PropTypes.string,
    errors: PropTypes.object,
  }).isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
};

export default FeedbackSection;
