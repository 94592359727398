import React from 'react';
import { useSelector } from 'react-redux';

import { useGetExploreFeedQuery } from 'app/api/feedsApi';

import { doneResettingExploreFeed } from 'app/slices/resetRecordsSlice';
import PostsPageContainer from './PostsPageContainer';
import usePaginatedRecords from 'app/hooks/usePaginatedRecords';

const ExploreFeedContainer = () => {
  const { resetExploreFeedRequest } = useSelector(state => state.resetRecords);

  const shouldResetExploreFeedTestFunc = () => {
    return resetExploreFeedRequest;
  };
  // custom hook for managing our cursors
  const {
    cursors,
    setOldestCursor,
    setMoreRecords,
  } = usePaginatedRecords({
    resetRecordsRequest: resetExploreFeedRequest,
    doneResettingRecordsFunc: doneResettingExploreFeed,
    shouldResetRecordsTestFunc: shouldResetExploreFeedTestFunc,
  });

  return (
    <>
      {cursors.map((cursor) => {
        return (
          <PostsPageContainer
            key={cursor}
            setOldestCursor={setOldestCursor}
            feedQuery={() => (useGetExploreFeedQuery({ cursor }))}
            setMoreRecords={setMoreRecords}
          />
        );
      })}
    </>
  );
};

export default ExploreFeedContainer;
