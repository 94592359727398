import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

export default function TemplateShell2({ header, children, goBackPath }) {
  const goBack = () => {
    if (goBackPath) {
      window.location.href = goBackPath;
      return;
    }
    window.history.back(); // This will go back one step in the browsing history
  };
  return (
    <>
      {/* redo this later to use MUI */}
      <div className="container-hdr-sticky2">
        <div className="row middle-xs start-xs nowrap-xs">
          <div className="col-xs-12">
            <div className="nav-icon-sticky">
              <a href="#" onClick={goBack}>
                <img src="/plus_frontend/assets/svg/ico-back-arrow.svg" alt="Back" />
              </a>
            </div>
            <h2>{header}</h2>
          </div>
        </div>
      </div>
      {children}
    </>
  );
}

// Define PropTypes for the props
TemplateShell2.propTypes = {
  header: PropTypes.string.isRequired, // Header should be a required string
  children: PropTypes.node.isRequired, // Children should be a required React node
  goBackPath: PropTypes.string, // goBackPath should be a string
};

TemplateShell2.defaultProps = {
  goBackPath: null, // goBackPath default value is null
}
