
// Device of interest constants
export const IOS = 1;
export const ANDROID = 2;
export const SAFARI_DESKTOP = 3;
export const CHROME_DESKTOP = 4;
export const FIREFOX = 5;
export const OTHER = 6;

export const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const isAndroid = userAgent.match(/android/);
  if (isAndroid) return ANDROID;

  const isIOS =  userAgent.match(/iphone|ipad/);
  if (isIOS) return IOS;

  const isFirefox = userAgent.match(/firefox/);
  if (isFirefox) return FIREFOX;

  const isChromeDesktop = userAgent.match(/chrome/) && !isAndroid;
  // the !isChromeDesktop is because the word chrome and safari appear together in Chrome but not in Safari
  const isSafariDesktop = userAgent.match(/safari/) && !isIOS && !isChromeDesktop;
  // Safari needs to be checked first.
  if (isSafariDesktop) return SAFARI_DESKTOP;
  if (isChromeDesktop) return CHROME_DESKTOP;

  return OTHER;
}

export const isNotificationApiSupported = () => {
  return 'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window;
}

export const currentSystemPushPermissions = () => {
  if (!isNotificationApiSupported()) return 'denied';
  return Notification.permission;
}

// Returns a promise that resolves to a PermissionStatus object which has a `state` attribute
// that can be 'granted', 'denied', or 'prompt'.
export const pushPermissionFromNavigator = () => {
  return navigator.permissions.query({ name: 'notifications' })
}

export const enablePushNotifications = async () => {
  const status = await Notification.requestPermission();
  localStorage.setItem('pn-remind-later', 'never');

  window.location.reload();
};

