import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { toCurrency } from '../../helpers/currencyHelpers';

const PaymentButtonsModal = ({ paymentButtons, onSave, closeModal }) => {
  const [selectedButton, setSelectedButton] = useState(null);

  return (
    <div>
      <div className="modal-header">
        <h4>Select Payment Mail Button</h4>
        <p>
          Select a Payment Mail Button to preview in a post.
          To manage your Payment Mail Buttons,
          {' '}
          <a href="https://www.niteflirt.com/messages/payment_buttons" target="_blank" rel="noreferrer">visit the Mail center</a>
        </p>
      </div>
      <div className="modal-body">
        <div className="scrollable-height-300">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: '55%' }}>
                  Subject
                </th>
                <th>
                  Type
                </th>
                <th>
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentButtons.map((button) => (
                <tr
                  key={button.id}
                  onClick={() => setSelectedButton(button.id)}
                  style={{ cursor: 'pointer' }}
                  className={selectedButton === button.id ? 'selected' : ''}
                >
                  <td>{button.subject}</td>
                  <td>{button.message_type_label}</td>
                  <td>{toCurrency(button.payment_amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
        <button
          type="button"
          className="btn"
          disabled={!selectedButton}
          onClick={() => { onSave(selectedButton); closeModal(); }}
        >
          Select Payment Mail Button
        </button>
      </div>
    </div>
  );
};

PaymentButtonsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  paymentButtons: PropTypes.array.isRequired,
};

export default PaymentButtonsModal;
