import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import OneTimeForm from 'app/components/modals/tributes/OneTimeForm';

import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import { useCreateBonusMutation } from 'app/api/mainApi';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const OneTimeTributeFormContainer = ({ advisor }) => {
  const [createBonus, createBonusResult] = useCreateBonusMutation();

  const {
    dispatch,
    closeDialog,
    setAmount,
    setMessage,
    amountError,
    tributeAmount,
    amountInvalid,
    setAmountError,
    tributeMessage,
    setCurrentStep,
    setTributeDeficit,
    currentUserHasCard,
    closeDialogAndResetTributeSlice,
  } = useRecurringTributeManagement({ advisor });

  const closeClick = closeDialogAndResetTributeSlice;

  const sendTributeClick = () => {
    const fromNfChat = window.location.pathname.includes('/chat');

    return createBonus({
      listingId: advisor.top_listing_id,
      message: tributeMessage,
      amount: parseFloat(tributeAmount),
      fromNfChat,
    });
  };

  const handleCreateBonusSuccess = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS,
    }));
  };

  const handleCreateBonusError = (data) => {
    const { errors, deficit } = data;

    if (errors.amount && errors.amount[0] === 'You don\'t have enough money in your account.') {
      if (currentUserHasCard) {
        setTributeDeficit(deficit);

        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.ADD_TO_BALANCE,
        }));
      } else {
        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
        }));
      }
    }

    const recipientError = errors.not_send_to_denied_recipients || errors.not_send_to_blocked_recipients;

    if (recipientError) {
      closeDialog();

      dispatch(showSnackbarMessage({
        message: recipientError[0],
        severity: 'error',
      }));
    }
  };

  useEffect(() => {
    if (createBonusResult.isSuccess) {
      handleCreateBonusSuccess();
    }

    if (
      createBonusResult.isError &&
      createBonusResult.error.status === 422
    ) {
      handleCreateBonusError(createBonusResult.error.data);
    }
  }, [createBonusResult]);

  return (
    <OneTimeForm
      amountError={amountError}
      amountInvalid={amountInvalid}
      closeClick={closeClick}
      isLoading={createBonusResult.isLoading}
      sendTributeClick={sendTributeClick}
      setAmount={setAmount}
      setAmountError={setAmountError}
      setMessage={setMessage}
      tributeAmount={tributeAmount}
      tributeMessage={tributeMessage}
    />
  );
};

OneTimeTributeFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default OneTimeTributeFormContainer;
