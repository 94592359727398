import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import DialogsContext from 'app/contexts/DialogsContext';
import ListingSearchFilters from 'app/components/modals/ListingSearchFilters';

const ListingSearchFiltersContainer = ({
  allPriceOptions,
  initialSelectedPrices,
  initialLimitToAvailable,
  resetFilters,
  onFiltersApplied,
}) => {
  const { closeDialog } = useContext(DialogsContext);
  const [selectedPrices, setSelectedPrices] = useState(initialSelectedPrices);
  const [checkboxValue, setCheckboxValue] = useState(initialLimitToAvailable);

  const closeClick = () => {
    closeDialog();
  };

  const handlePriceChange = (event, value) => {
    setSelectedPrices(value);
  };

  const handleCheckboxChange = ({ target: { checked } }) => {
    setCheckboxValue(checked);
  };

  const handleReset = () => {
    setSelectedPrices(allPriceOptions);
    setCheckboxValue(false);
    resetFilters();
  };

  const handleApply = () => {
    closeDialog();

    if (onFiltersApplied) {
      onFiltersApplied(selectedPrices, checkboxValue);
    }
  };

  return (
    <ListingSearchFilters
      allPriceOptions={allPriceOptions}
      selectedPrices={selectedPrices}
      handlePriceChange={handlePriceChange}
      checkboxValue={checkboxValue}
      handleCheckboxChange={handleCheckboxChange}
      handleReset={handleReset}
      handleApply={handleApply}
      closeClick={closeClick}
    />
  );
};

ListingSearchFiltersContainer.propTypes = {
  allPriceOptions: PropTypes.array.isRequired,
  initialSelectedPrices: PropTypes.array.isRequired,
  initialLimitToAvailable: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  onFiltersApplied: PropTypes.func.isRequired,
};

export default ListingSearchFiltersContainer;
