import React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Link,
} from '@mui/material';

const TransientMobileNav = () => {
  const { hasUnreadMessages } = useSelector(state => state.chat);
  return (
    <Box
      data-test-id="transient-mobile-nav"
      sx={{
        position: 'fixed',
        bottom: 0,
        height: '55px',
        width: '100%',
        paddingBottom: 'env(safe-area-inset-bottom)',
        display: {
          xs: 'block',
          md: 'none',
        },
        zIndex: 12,
      }}
    >
      <Box
        sx={{
          background: 'black',
          fontSize: '15px',
          height: '100%',
          width: '100%',
        }}
        display="flex"
        alignItems="stretch"
        justifyContent="space-evenly"
      >
        <Link
          title="Phone Sex"
          href="/search"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            lineHeight: '2.8',
            fontFamily: '"Roboto", sans-serif',
          }}
        >
          PHONE SEX
        </Link>

        <Box sx={{ position: 'relative' }}>
          <Link
            title="Chat"
            href="/chat"
            sx={{
              color: 'white !important',
              textDecoration: 'none',
              lineHeight: '2.8',
              fontFamily: '"Roboto", sans-serif',
              padding: '9px 6px 19px',
            }}
          >
            CHAT
          </Link>
          {hasUnreadMessages && (
            <span style={{
              display: 'inline-block',
              width: '8px',
              height: '8px',
              backgroundColor: 'red',
              borderRadius: '50%',
              position: 'absolute',
              margin: '8px -8px'
            }}
            >
              &nbsp;
            </span>
          )}
        </Box>

        <Link
          title="Goodies"
          href="/goody/search#recent_additions"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            lineHeight: '2.8',
            fontFamily: '"Roboto", sans-serif',
          }}
        >
          GOODIES
        </Link>

        <Link
          title="Feeds"
          href="/feed"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            lineHeight: '2.8',
            fontFamily: '"Roboto", sans-serif',
          }}
        >
          FEEDS
        </Link>

        <Link
          title="Account"
          href="/account"
          sx={{
            color: 'white !important',
            textDecoration: 'none',
            lineHeight: '2.8',
            fontFamily: '"Roboto", sans-serif',
          }}
        >
          ACCOUNT
        </Link>
      </Box>
    </Box>
  );
};

export default TransientMobileNav;
