import React from 'react';
import { useSelector } from 'react-redux';

import usePaginatedRecords from 'app/hooks/usePaginatedRecords';
import PostsPageContainer from './PostsPageContainer';
import { useGetFlirtFeedQuery } from 'app/api/feedsApi';
import { doneResettingFlirtFeed } from 'app/slices/resetRecordsSlice';

const FlirtPostsContainer = ({ advisor }) => {
  // reaches into the slice to determine if the flag has been set telling
  // us to reload this entire flirt feed (the flag is the flirt's id)
  const { resetFlirtFeedRequest } = useSelector(state => state.resetRecords);

  // usePaginatedRecords uses this function to determine if it should reset the whole feed
  // it's passed into the custom hook
  const shouldResetFeedTestFunc = () => {
    return resetFlirtFeedRequest && resetFlirtFeedRequest === advisor.id;
  };

  const {
    cursors: postCursors,
    setOldestCursor: setOldestPostCursor,
    setMoreRecords: setMorePostRecords,
  } = usePaginatedRecords({
    resetRecordsRequest: resetFlirtFeedRequest,
    doneResettingRecordsFunc: doneResettingFlirtFeed,
    shouldResetRecordsTestFunc: shouldResetFeedTestFunc,
  });

  return (
    postCursors.map((postCursor) => (
      <PostsPageContainer
        key={postCursor}
        setOldestCursor={setOldestPostCursor}
        feedQuery={() => useGetFlirtFeedQuery({
          cursor: postCursor,
          advisorId: advisor.id,
        })}
        showPin
        setMoreRecords={setMorePostRecords}
      />
    ))
  );
};

export default FlirtPostsContainer;
