import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconSocialTwitter = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="32px" height="32px" viewBox="0 0 32 32">
    {/* Your SVG Path */}
    <g id="icons2" stroke="none" strokeWidth="1" fill="none">
      <g id="ico-twitter2">
        <circle id="Oval" fill="#000000" cx="16" cy="16" r="16" />
        <path d="M6.14397899,6.73006554 L13.7917882,16.9557319 L6.09577921,25.2696953 L7.82797957,25.2696953 L14.5659764,17.9905313 L20.0098922,25.2696953 L25.9042266,25.2696953 L17.8259435,14.4689557 L24.9894281,6.73006554 L23.2572277,6.73006554 L17.0520878,13.4338239 L12.0383134,6.73006554 L6.14397899,6.73006554 Z M8.69125425,8.00586385 L11.3990846,8.00586385 L23.356619,23.993897 L20.6487886,23.993897 L8.69125425,8.00586385 Z" id="path1009" fill="#FFFFFF" />
      </g>
    </g>
  </SvgIcon>
);

export default IconSocialTwitter;
