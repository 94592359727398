import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: '#222',
  color: 'white',
});

export default StyledCard;
