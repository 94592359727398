import PropTypes from 'prop-types';

const GoodyBagItemShape = PropTypes.shape({
  id: PropTypes.number,
  file_name: PropTypes.string,
  content_type: PropTypes.string,
  item_type: PropTypes.string,
  rating: PropTypes.string,
  thumbnail_url: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
});

export default GoodyBagItemShape;
