import React from 'react';
import { PropTypes } from 'prop-types';

const GenericModal = ({
  closeModal,
  callToAction,
  ctaButtonLabel,
  header,
  body,
}) => {
  return (
    <>
      <div className="modal-header">
        <h4>{header}</h4>
      </div>
      <div className="modal-body">
        <p>{body}</p>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={() => { closeModal(); }}
        >
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            callToAction();
            closeModal();
          }}
        >
          {ctaButtonLabel}
        </button>
      </div>
    </>
  );
};

GenericModal.defaultProps = {
  callToAction: () => {},
};

GenericModal.propTypes = {
  ctaButtonLabel: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  callToAction: PropTypes.func,
};

export default GenericModal;
