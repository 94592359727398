import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { FormButton, FormButtonBox, FormTextField, FormWrapper, FormLevelErrors } from 'app/components/form_components';
import useServerErrors from 'app/hooks/form/useServerErrors';
import { useGetUserQuery, useUpdateUserMutation } from 'app/api/mainApi';
import PasswordFields from 'app/components/account_info/PasswordFields';

const validatePin = (pin) => {
  if (!/^[0-9]*$/.test(pin)) {
    return 'PIN must be numeric.';
  }
  if (pin.length !== 4) {
    return 'Please enter 4 digits.';
  }

  const p = pin.split('').map(ch => parseInt(ch, 10));

  const isSequential = (arr) => {
    const isIncreasing = arr.every((val, index, array) => !index || val === array[index - 1] + 1);
    const isDecreasing = arr.every((val, index, array) => !index || val === array[index - 1] - 1);
    return isIncreasing || isDecreasing;
  };

  if (isSequential(p)) {
    return 'Your PIN cannot be sequential.';
  }

  if (p.every(val => val === p[0])) {
    return 'Your PIN cannot have the same 4 digits.';
  }

  return true;
};

// Default values for the form
const defaultValues = {
  old_password: '',
  password: '',
  password_confirmation: '',
  pin: '',
};

// Transform server response to form values
const getFieldValues = (record) => ({
  pin: record.pin,
});

const PasswordSecurityContainer = () => {
  // form setup
  const recordKey = 'user';
  const query = useGetUserQuery();
  const record = query.data && query.data[recordKey];
  const [mutationUpdate, mutationUpdateStatus] = useUpdateUserMutation();
  const mutation = { update: mutationUpdate, updateStatus: mutationUpdateStatus };
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });
  useServerErrors(mutation.updateStatus, recordKey, form); // handle server errors

  // Use effect to update form values when server data is available
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      form.reset({
        ...defaultValues,
        ...initialFieldValues,
      });
    }
  }, [record, form]);

  const onSubmit = (formData) => {
    mutation.update(formData);
  };
  useEffect(() => {
    if (mutation.updateStatus.isSuccess) {
      window.location.href = '/account';
    }
  }, [mutation.updateStatus]);
  // end of form setup

  const validateCurrentPassword = (value) => {
    const { password } = form.getValues();
    if (password && !value) {
      return 'Incorrect Password';
    }
    return true;
  };

  const isSaveButtonDisabled = () => {
    const { dirtyFields } = form.formState;
    const isPinChanged = !!dirtyFields.pin;
    const isPasswordFieldsChanged = !!dirtyFields.password && !!dirtyFields.password_confirmation;
    const isFormModified = isPinChanged || isPasswordFieldsChanged;
    const currentPasswordProvided = !!form.getValues('old_password');
    const hasErrors = Object.keys(form.formState.errors).length > 0;

    if (isPasswordFieldsChanged && !currentPasswordProvided) {
      return true;
    }

    return !isFormModified || hasErrors;
  };

  // part of standard form, maybe can be included in the formWrapper
  if (query.isLoading || query.isError) {
    return null;
  }

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '550px' }}>
      <FormWrapper form={form}>
        <Stack spacing={3} mt={2}>
          <FormTextField
            label="Current Password"
            name="old_password"
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            autoComplete="current-password"
            validation={{ validate: validateCurrentPassword }}
          />
          <PasswordFields required={false} />
          <Typography variant="body2">
            This Personal Identification Number (PIN) lets you use 1-800-TO-FLIRT connect directly to any Flirt.
          </Typography>
          <FormTextField
            label="PIN"
            name="pin"
            type="tel"
            variant="outlined"
            size="small"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
            validation={{ validate: validatePin }}
            sx={{ maxWidth: 150 }}
          />
        </Stack>
        <FormLevelErrors />
        <br clear="all" />
        <FormButtonBox>
          <FormButton
            text="Save"
            variant="contained"
            // rhf only calls onSubmit if form is valid
            onClick={form.handleSubmit(onSubmit)}
            disabled={isSaveButtonDisabled()}
            order={{ xs: 2, sm: 2 }}
          />
          <FormButton
            text="Cancel"
            variant="outlined"
            onClick={() => {
              window.location.href = '/account';
            }}
            order={{ xs: 1, sm: 1 }}
          />
        </FormButtonBox>
      </FormWrapper>
    </Box>
  );
};

export default PasswordSecurityContainer;
