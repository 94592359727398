import React from 'react';
import { PropTypes } from 'prop-types';

import SharedCenterNav from './SharedCenterNav';

const UnauthorizedNav = ({
  navigateTo,
  addMoneyUrl,
}) => {
  const display = 'block';
  return (
    <div className="more-menu more-menu-in-nav" style={{ display }}>
      <ul className="nav-top paddingtop0">
        <li className="marginbot20 marginleftminus8">
          <div className="menu-initials menu-avatar" style={{ backgroundImage: 'url(/plus_frontend/assets/ico-no-photo.png)' }} />
        </li>
        <SharedCenterNav
          closeAndNavigateTo={navigateTo}
          currentUser={null}
          addMoneyUrl={addMoneyUrl}
        />
      </ul>
    </div>
  );
};

UnauthorizedNav.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
};

export default UnauthorizedNav;
