import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import withTooltip from 'app/hoc/withTooltip';
import { getWatermarkText } from 'app/helpers/watermarkText';

const UploadWatermarkTooltip = ({
  currentUser,
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  return (
    <Tooltip
      content={(
        <div
          ref={(el) => setTipContentRef(el)}
          className="nfp-tooltip"
        >
          <div className="row">
            <div className="col-xs-11">
              <div className="menu-text">
                <div className="subhead">Your Watermark</div>
                {getWatermarkText(currentUser)}
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a
                role="link"
                tabIndex={0}
                onClick={toggleTip}
              >
                <img
                  src="/plus_frontend/assets/svg/x-close.svg"
                  className="marginlftminus5"
                  alt="close"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
      >
        <img src="/plus_frontend/assets/svg/ico-info.svg" className="marginlft8 margintop4" alt="info" />
      </a>
    </Tooltip>
  );
};

UploadWatermarkTooltip.propTypes = {
  currentUser: PropTypes.object.isRequired,
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
};

export default withTooltip(UploadWatermarkTooltip);
