/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  position: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  message: '',
  severity: 'info',
};

const snackbarMessageSlice = createSlice({
  name: 'snackbarMessages',
  initialState,
  reducers: {
    showSnackbarMessage: (state, action) => {
      state.isOpen = true;
      state.position = action.payload.position || state.position;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    closeSnackBarMessage: (state) => {
      state.isOpen = false;
    },
  },
});

export default snackbarMessageSlice;

export const {
  showSnackbarMessage,
  closeSnackBarMessage,
} = snackbarMessageSlice.actions;
