import { useEffect } from 'react';

export const useServerErrors = (updateStatus, recordKey, form) => {
  const { setError, clearErrors, getValues, watch } = form;

  useEffect(() => {
    if (updateStatus?.isError) {
      const errorData = updateStatus.error?.data;
      const recordErrors = errorData?.[recordKey]?.errors;

      if (recordErrors && typeof recordErrors === 'object') {
        const formFields = getValues();

        Object.entries(recordErrors).forEach(([field, messages]) => {
          // Check if the field is in our form; if not, handle it as a form-level error
          const errorField = formFields.hasOwnProperty(field) ? field : 'form';

          setError(errorField, { type: 'server', message: messages?.join(', ') });
        });
      } else {
        console.error('Error parsing server errors: ', errorData);
      }
    }
  }, [updateStatus, setError, getValues, recordKey]);

  useEffect(() => {
    const subscription = watch(() => {
      clearErrors('form');
    });
    return () => subscription.unsubscribe();
  }, [watch, clearErrors]);
};

export default useServerErrors;
