import React from 'react';
import TaxForm from "app/components/account/TaxForm";
import { useParams } from 'react-router';
import { useGetTaxFormQuery } from 'app/api/mainApi';

const TaxFormContainer = (props) => {
  const { id } = useParams();
  const { data } = useGetTaxFormQuery(id);
  return(
    <TaxForm data={data} />
  )
}

export default TaxFormContainer;
