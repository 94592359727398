import React from 'react';
import { PropTypes } from 'prop-types';
import CategoryListing from './CategoryListing';
import UnreadMailBadge from 'app/components/shared/UnreadMailBadge';

const SharedCenterNav = ({ closeAndNavigateTo, currentUser, addMoneyUrl }) => {
  return (
    <>
      {currentUser && (
        <>
          <li className="delin">
            <a
              title="Add Money"
              onClick={() => closeAndNavigateTo(addMoneyUrl, true)}
              role="link"
              tabIndex={0}
            >
              <div className="row margin0">
                <div>
                  <img src="/plus_frontend/assets/svg/ico-add-money.svg" alt="Add Money" className="nav-icon" />
                </div>
                <div>
                  <div className="nav-text">Add Money</div>
                </div>
              </div>
            </a>
          </li>
          <li className="delin">
            <UnreadMailBadge />
          </li>
          <li>
            <a
              title="Favorite Flirts"
              onClick={() => closeAndNavigateTo('/account/favorite', true)}
              role="link"
              tabIndex={0}
            >
              <img src="/plus_frontend/assets/svg/ico-favorite.svg" alt="Favorite Flirts" className="nav-icon" />
              <span className="nav-text">Favorite Flirts</span>
            </a>
          </li>
          <li>
            <a
              title="File Player"
              onClick={() => closeAndNavigateTo('/account/fileplayer', true)}
              role="link"
              tabIndex={0}
            >
              <img src="/plus_frontend/assets/svg/ico-fileplayer.svg" alt="File Player" className="nav-icon" />
              <span className="nav-text">File Player</span>
            </a>
          </li>
          <li>
            <a
              title="Subscriptions"
              onClick={() => closeAndNavigateTo('/feed/subscriptions')}
              role="link"
              tabIndex={0}
            >
              <img src="/plus_frontend/assets/svg/ico-subscriptions.svg" alt="Subscriptions" className="nav-icon" />
              <span className="nav-text">Subscriptions</span>
            </a>
          </li>
        </>
      )}
      {!currentUser && (
        <li className="delin">
          <a
            title="Sign In"
            onClick={() => closeAndNavigateTo(`/login?return_url=${window.location.pathname + window.location.search}`, true)}
            role="link"
            tabIndex={0}
          >
            <img src="/plus_frontend/assets/svg/ico-login.svg" alt="Sign In" className="nav-icon" />
            <span className="nav-text">Sign In</span>
          </a>
        </li>
      )}

      {!currentUser && (
        <li>
          <a
            title="Register"
            onClick={() => closeAndNavigateTo(`/signup?return_url=${window.location.pathname + window.location.search}`, true)}
            role="link"
            tabIndex={0}
          >
            <img src="/plus_frontend/assets/svg/ico-register.svg" alt="Register" className="nav-icon" />
            <span className="nav-text">Register</span>
          </a>
        </li>
      )}

      <li className="delin">
        <CategoryListing closeAndNavigateTo={closeAndNavigateTo} />
      </li>

      <li className="delin">
        <a
          role="link"
          tabIndex={0}
          title="Help and Support"
          onClick={() => closeAndNavigateTo('//support.niteflirt.com/hc/en-us', true)}
        >
          <img src="/plus_frontend/assets/svg/ico-help1.svg" alt="Help and Support" className="nav-icon" />
          <span className="nav-text">Help &amp; Support</span>
        </a>
      </li>

      <li>
        <a
          role="link"
          tabIndex={0}
          title="About"
          onClick={() => closeAndNavigateTo('/help/about', false)}
        >
          <img src="/plus_frontend/assets/svg/ico-nf.svg" alt="About" className="nav-icon" />
          <span className="nav-text">About</span>
        </a>
      </li>

      {currentUser && (
        <li className="delin">
          <a
            title="Sign Out"
            onClick={() => closeAndNavigateTo('/logout', true)}
            role="link"
            tabIndex={0}
          >
            <img src="/plus_frontend/assets/svg/ico-logout.svg" alt="Sign Out" className="nav-icon" />
            <span className="nav-text">Sign Out</span>
          </a>
        </li>
      )}
      <div className="more-menu-bottom-padding" />
    </>
  );
};

SharedCenterNav.defaultProps = {
  currentUser: null,
};

SharedCenterNav.propTypes = {
  closeAndNavigateTo: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  addMoneyUrl: PropTypes.string.isRequired,
};

export default SharedCenterNav;
