import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import mainApi from 'app/api/mainApi';
import feedsApi from 'app/api/feedsApi';
import profileImageApi from 'app/api/profileImageApi';

const TagInvalidationEventListener = () => {
  const allApis = [mainApi, feedsApi, profileImageApi];
  const dispatch = useDispatch();

  const handleTagInvalidationEvent = (evt) => {
    const { tags } = evt.detail;

    console.log('*** TagInvalidationEventListener! invalidating tags:', tags);

    allApis.forEach((api) => {
      dispatch(api.util.invalidateTags(tags));
    });
  };

  useEffect(() => {
    window.addEventListener('invalidateTags', handleTagInvalidationEvent);

    return () => {
      window.removeEventListener('invalidateTags', handleTagInvalidationEvent);
    };
  });

  return null;
};

export default TagInvalidationEventListener;
