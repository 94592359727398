import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const ChangePackageTribute = ({
  closeDialog,
  onChangeTributeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Change Package?
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              We&apos;ll charge you for the new Package today and cancel your original Package.
            </Typography>
            <Typography variant="body1">
              Are you sure you want to change Packages?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeDialog}
        >
          Keep Package
        </Button>
        <Button
          variant="contained"
          onClick={onChangeTributeClick}
        >
          Change Package
        </Button>
      </DialogActions>
    </>
  );
};

ChangePackageTribute.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onChangeTributeClick: PropTypes.func.isRequired,
};

export default ChangePackageTribute;
