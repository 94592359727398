import React from 'react';
import MuiCircularProgress,
{ circularProgressClasses } from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const CircularProgress = (props) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <MuiCircularProgress
      variant="determinate"
      sx={{
        color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      }}
      size={50}
      thickness={5}
      {...props}
      value={100}
    />
    <MuiCircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '#63177a' : '#63177a'),
        animationDuration: '1000ms',
        position: 'absolute',
        top: 0,
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'square',
        },
      }}
      size={50}
      thickness={5}
      {...props}
    />
  </Box>
);

export default CircularProgress;
