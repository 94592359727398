import React from 'react';
import { Link } from 'react-router-dom';

const SuccessfulPostCreationBanner = () => (
  <>
    Your post has been scheduled. See all your scheduled posts in the&nbsp;
    <Link to="/feed/queue/scheduled">
      Posts Queue
    </Link>
  </>
);

export default SuccessfulPostCreationBanner;
