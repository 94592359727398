import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import Comment from 'app/components/posts/Comment';

const CommentsPageContainer = ({ advisor, setOldestCursor, commentsQuery, setMoreComments }) => {
  const { data } = commentsQuery();
  const comments = data?.records;
  const moreComments = data?.moreRecords;

  useEffect(() => {
    setMoreComments(moreComments);
  }, [moreComments]);

  useEffect(() => {
    if (comments?.length > 0) {
      const oldestPost = comments[comments.length - 1];
      setOldestCursor(oldestPost.ordering_date);
    }
  }, [comments]);

  if (!comments) { return null; }

  return (
    <div className="all-comments">
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} advisor={advisor} />
      ))}
    </div>
  );
};

CommentsPageContainer.defaultProps = {
  advisor: null,
};

CommentsPageContainer.propTypes = {
  advisor: PropTypes.object,
  setOldestCursor: PropTypes.func.isRequired,
  commentsQuery: PropTypes.func.isRequired,
  setMoreComments: PropTypes.func.isRequired,
};

export default CommentsPageContainer;
