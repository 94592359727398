import React from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const OneTimeTributeForm = ({
  amountError,
  amountInvalid,
  closeClick,
  isLoading,
  sendTributeClick,
  setAmount,
  setAmountError,
  setMessage,
  tributeAmount,
  tributeMessage,
}) => {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          value={tributeAmount}
          type="number"
          inputMode="decimal"
          label="Tribute Amount"
          error={!!amountError}
          helperText={amountError}
          data-test-id="tribute-amount"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(e) => {
            setAmountError('');
            setAmount(e.currentTarget.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={tributeMessage}
          data-test-id="tribute-message"
          label="Personal Message (optional)"
          onChange={(e) => { setMessage(e.currentTarget.value); }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            onClick={closeClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={amountInvalid(tributeAmount) || isLoading}
            onClick={sendTributeClick}
          >
            Send Tribute
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

OneTimeTributeForm.propTypes = {
  amountError: PropTypes.string.isRequired,
  amountInvalid: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendTributeClick: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setAmountError: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  tributeAmount: PropTypes.string.isRequired,
  tributeMessage: PropTypes.string.isRequired,
};

export default OneTimeTributeForm;
