import React from 'react';
import { PropTypes } from 'prop-types';
import sanitizeHtml from 'sanitize-html';

// pick the preset that fits your usecase.  The 'base' preset will be used in addition to the one you pick
const OPTION_SETS = {
  broad: {
    allowedTags: ['ul', 'li', 'b', 'i', 'em', 'strong', 'a', 'div', 'img', 'br'],
    allowedAttributes: {
      '*': ['href', 'name', 'target', 'class', 'role', 'style', 'src'],
    },
  },

  linksAndText: {
    allowedTags: ['b', 'span', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      '*': ['style'],
    },
  },

  base: {
    allowedIframeHostnames: [],
    parser: { // there appears to be a bug in sanitize, it crashes when encountering an &entity; unless decodeEntities is false
      decodeEntities: false,
    },
  },
};

const sanitize = ({ html, preset, options }) => {
  const sanitized = sanitizeHtml(
    html,
    { ...OPTION_SETS[preset], ...OPTION_SETS.base, ...options },
  );

  return { __html: sanitized };
};

const SanitizedHTML = ({ html, preset, options }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={sanitize({ html, preset, options })} />
);

SanitizedHTML.defaultProps = {
  html: '',
  options: {},
  preset: 'linksAndText',
};

SanitizedHTML.propTypes = {
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.object,
  preset: PropTypes.string,
};

export default SanitizedHTML;
