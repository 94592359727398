import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookie from 'app/util/cookie';

import DialogsContext from 'app/contexts/DialogsContext';
import SecondaryAuthDialogContainer from 'app/containers/modals/SecondaryAuthDialogContainer';

// Only to be used in plus_frontend, not in the regular frontend code, since it relies
// on being inside a Router context for slicker navigation.
const BehindSecondaryAuth = ({ children }) => {
  const { openDialog } = useContext(DialogsContext);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const showDialog = () => {
      openDialog({
        component: SecondaryAuthDialogContainer,
        zIndex: 1000000, // gotta go pretty high to get on top of some of our other dialogs/modals

        props: {
          destination: '',
          navMethod: (_to) => setValidated(true),
          hideCancel: true, // this dialog should not be closeable
        },
      });
    };

    const checkCookie = () => {
      // If the cookie is set attempt to render children otherwise ask for secondary auth.
      if (Cookie.hasCookie('valid_secondary_auth')) {
        setValidated(true);
      } else {
        setValidated(false);
        showDialog();
      }
    };

    checkCookie(); // Check cookie immediately on mount

    const intervalId = setInterval(checkCookie, 2500); // Set interval to check cookie every 2.5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  return validated ? children : null;
};

BehindSecondaryAuth.propTypes = {
  children: PropTypes.object.isRequired,
};

export default BehindSecondaryAuth;
