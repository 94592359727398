import React from 'react';
import { PropTypes } from 'prop-types';

const RejectionReasonModal = ({ closeModal, post }) => {
  const postModerationReason = (
    post.moderation_detail && (
      <div>
        { post.moderation_detail }
      </div>
    )
  );

  const postItemRejected = (postItem) => (
    postItem.encoding_status === 'failed' ||
      postItem.rating === 'prohibited' ||
      (postItem.rating === 'paywall' && post.post_type === 'free')
  );

  const postItemModerationReason = (postItem) => {
    if (postItem.encoding_status === 'failed') {
      return 'This file could not be processed due to technical issues.';
    }

    switch (postItem.rating) {
    case 'prohibited': return 'This content is prohibited.';
    case 'paywall': return 'This content is not allowed on this post type.';
    default: return null;
    }
  };

  const postItemModerationReasons = (
    post.post_items.filter(postItemRejected).map((postItem) => (
      <li key={postItem.fm_item_id}>
        { postItem.filename}
        :&nbsp;
        { postItemModerationReason(postItem) }
      </li>
    ))
  );

  return (
    <>
      <div className="modal-header">
        <h4>Your post was removed for the following reason(s)</h4>
        <hr />
      </div>
      <div className="modal-body">
        { postModerationReason }
        { postItemModerationReasons && <ul>{ postItemModerationReasons }</ul> }
      </div>
      <div className="modal-footer">
        <a role="link" tabIndex={0} className="btn secondary" onClick={closeModal}>
          Close
        </a>
      </div>
    </>
  );
};

RejectionReasonModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

export default RejectionReasonModal;
