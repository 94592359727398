/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accordionState: [0, 0],
  categoryListingType: 1,
  categoryListingSort: 0,
};

const miscUIStateSlice = createSlice({
  name: 'miscUIState',
  initialState,
  reducers: {
    updateNavCategoryAccordionStateAction: (state, action) => {
      const { accordionState } = state;
      const { depth, index } = action.payload;

      // if this specific accordion at this depth is already open, close it by setting to an invalid index
      accordionState[depth] = accordionState[depth] === index ? -1 : index;
      // close all second levels if first level changes
      if (depth === 0) accordionState[1] = -1;

      state.accordionState = accordionState;
    },
    setCategoryListingType: (state, action) => {
      state.categoryListingType = action.payload.categoryListingType;
    },
    setCategoryListingSort: (state, action) => {
      state.categoryListingSort = action.payload.categoryListingSort;
    },
  },
});

const selectors = {
  navCategoryAccordionStateSelector: (state) => state.miscUIState.accordionState,
  categoryListingTypeSelector: (state) => state.miscUIState.categoryListingType,
  categoryListingSortSelector: (state) => state.miscUIState.categoryListingSort,
};

export const {
  updateNavCategoryAccordionStateAction,
  setCategoryListingType,
  setCategoryListingSort,
} = miscUIStateSlice.actions;

export const {
  navCategoryAccordionStateSelector,
  categoryListingTypeSelector,
  categoryListingSortSelector,
} = selectors;

export default miscUIStateSlice;
