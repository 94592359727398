import React from 'react';

import {
  Box,
  Button,
  Typography,
  styled,
} from '@mui/material';

const OverrideNFPSkeletonStyles = styled(Button)({
  '&.MuiButtonBase-root': {
    color: 'white',
  },
  '&.MuiButtonBase-root:hover': {
    color: 'white',
  },
});

const CallSystemDownError = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 350, margin: 'auto', mt: 3 }}>
        <Typography variant="error">
          We cannot complete your call at this time. We&apos;re running a system update. Please try again later.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 350, margin: 'auto', mt: 3 }}>
        <OverrideNFPSkeletonStyles variant="contained" color="primary" href="/account">
          Back to Account
        </OverrideNFPSkeletonStyles>
      </Box>
    </>
  );
};

export default CallSystemDownError;
