import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const MissingProfile = () => {
  return (
    <div className="container-fluid margin20">
      <div className="row">
        <div className="col-sm-6 first-sm col-xs-12 center-xs last-xs">
          <img alt="Hot girl with head in dryer" src="/niteflirt/images/landing/missing/not-found.jpg" style={{ maxWidth: '240px', marginTop: '20px', borderRadius: '4px' }} />
        </div>
        <div className="col-sm-6 last-sm col-xs-12 first-xs">
          <h2>Oops...</h2>
          <p>The Flirt you’re looking for is no longer available or has changed their name.</p>
          <p>
            Search above to continue looking for this Flirt or to find a new favorite.
          </p>
          <NavLink title="Feed" to="/feed/home">
            <button type="button" className="btn btn-primary marginlft0">
              Go to Feed
            </button>
          </NavLink>
          <p>
            Need help? Contact&nbsp;
            <Link to="//support.niteflirt.com/hc/en-us/requests/new" target="_blank">
              Customer Support
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissingProfile;
