import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Alert as MuiAlert, Portal, Snackbar } from '@mui/material';
import SanitizedHTML from 'app/helpers/SanitizedHTML';

const StyledSnackbar = styled(Snackbar)(() => {
  return {
    '&.MuiSnackbar-root': {
      zIndex: '1400', // We can bump this up but we'll remove important
      marginLeft: '0px',
      bottom: '80px',
      fontSize: '0.988em',
      '@media (min-width:900px)': {
        bottom: '40px',
        // NOTE: This component is specially styled with the left hand nav in mind.
        marginLeft: '120px',
      },
    },
  };
});

// valid values for severity === ["success", "info", "warning", "error"]
const SnackbarMessage = ({ position, message, severity, isOpen, handleClose }) => {
  if (!message) return null;

  const { vertical, horizontal } = position;

  return (
    <Portal>
      <StyledSnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          onClose={handleClose}
          severity={severity}
        >
          <SanitizedHTML html={message} />
        </MuiAlert>
      </StyledSnackbar>
    </Portal>
  );
};

SnackbarMessage.defaultProps = {
  isOpen: false,
  position: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  handleClose: () => {},
};

SnackbarMessage.propTypes = {
  isOpen: PropTypes.bool,
  position: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  }),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  handleClose: PropTypes.func,
};

export default SnackbarMessage;
