import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagCard from 'app/components/GoodyBagCard';
import DeprecatedGoodyBagCard from 'app/redesign/components/DeprecatedGoodyBagCard';

const GoodyBagCardContainer = ({
  goodyBag,
  viewingSelf,
  availableBalance,
  currentUser,
  advisorLoading,
  selected,
  advisor,
  viewingSingleAdvisor,
}) => {
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  if (newCardsEnabled) {
    return (
      <GoodyBagCard
        goodyBag={goodyBag}
        selected={selected}
        viewingSelf={viewingSelf}
        availableBalance={availableBalance}
        currentUser={currentUser}
        advisorLoading={advisorLoading}
        advisor={advisor}
        viewingSingleAdvisor={viewingSingleAdvisor}
      />
    );
  }

  return (
    <DeprecatedGoodyBagCard
      goodyBag={goodyBag}
      selected={selected}
      viewingSelf={viewingSelf}
      availableBalance={availableBalance}
      currentUser={currentUser}
      advisorLoading={advisorLoading}
      advisor={advisor}
      viewingSingleAdvisor={viewingSingleAdvisor}
    />
  );
};

GoodyBagCardContainer.defaultProps = {
  availableBalance: 0,
  currentUser: {},
  advisor: {},
  viewingSingleAdvisor: true,
};

GoodyBagCardContainer.propTypes = {
  goodyBag: GoodyBagShape.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  availableBalance: PropTypes.number,
  currentUser: PropTypes.object,
  advisorLoading: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  viewingSingleAdvisor: PropTypes.bool,
};

export default GoodyBagCardContainer;
