import React from 'react';
import { PropTypes } from 'prop-types';
import { DefaultPlayer as Video } from 'react-html5video';
import { isMobileSafari } from 'react-device-detect';
import { openVideoSafari } from 'app/helpers/iosSafariHelpers';

const hideFullScreen = isMobileSafari ? 'hide-fullscreen' : '';
const showSafariFullScreen = isMobileSafari ? 'safari-show-full' : 'safari-show-full displayNone';

const VideoMedia = ({ item: { poster, src, contentType } }) => {
  return (
    <div className="slick-item-bg">
      <div className="slick-black">
        <div className="video-container">
          <Video
            poster={poster}
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
            className={hideFullScreen}
            webkit-playsinline="true"
            playsInline
            allowFullScreen
          >
            <source src={src} type={contentType} />
          </Video>
        </div>
        <div className={showSafariFullScreen}>
          <a href={src} onClick={openVideoSafari}>
            <img src="/plus_frontend/assets/svg/ico-fullscreen.svg" alt="Fullscreen" />
          </a>
        </div>
      </div>
    </div>
  );
};

VideoMedia.propTypes = {
  item: PropTypes.object.isRequired,
};

export default VideoMedia;
