import { useEffect, useContext } from 'react';

import DialogsContext from 'app/contexts/DialogsContext';
import ForgotPasswordDialog from 'app/components/modals/ForgotPasswordDialog';

const ForgotPasswordLauncher = () => {
  const { openDialog } = useContext(DialogsContext);

  const onClose = () => {
    history.back();
  }

  useEffect(() => {
    openDialog({
      component: ForgotPasswordDialog,
      props: { closeDialog: onClose, hideCancel: true },
    });
  }, []);

  return null;
};

export default ForgotPasswordLauncher;
