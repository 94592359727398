import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import DialogsContext from 'app/contexts/DialogsContext';
import SignupContainer from 'app/containers/account_info/SignupContainer';

// TODO: make this a generic modal launcher (and use for signin)
const SignUpLauncher = () => {
  const { openDialog } = useContext(DialogsContext);

  useEffect(() => {
    openDialog({
      component: SignupContainer,
      props: {
        hideCancel: true,
        zIndex: 100,
      },
    });
  }, []);

  return null;
};

SignUpLauncher.propTypes = {
  type: PropTypes.string,
};

export default SignUpLauncher;
