import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';
import 'react-datepicker/dist/react-datepicker.css';

const PostScheduleModal = ({
  closeModal,
  currentDate,
  onSave,
  timezone,
}) => {
  const localTimezone = DateTime.local().setZone(timezone).offsetNameLong;

  const [ scheduledDate, setScheduledDate ] = useState(new Date());
  const [ internalError, setInternalError ] = useState(null);

  const validateDate = (date) => {
    if (date === null) {
      return;
    }
    setScheduledDate(date);
  };

  useEffect(() => {
    let newScheduledDateLuxon;

    if (currentDate) {
      newScheduledDateLuxon = DateTime.fromISO(currentDate, { zone: timezone });
    } else {
      newScheduledDateLuxon = DateTime.local().setZone(timezone).plus({ days: 1, hours: 1 });
    }

    let newMinutes = newScheduledDateLuxon.minute;
    let hourIncrement = 0;
    // Round to nearest 5 minutes
    if (newMinutes % 5 !== 0) {
      newMinutes = Math.round(newMinutes / 5) * 5;
      if (newMinutes === 60) {
        newMinutes = 0;
        hourIncrement = 1;
      }
    }

    const newScheduledDate = new Date(
      newScheduledDateLuxon.year,
      newScheduledDateLuxon.month - 1,
      newScheduledDateLuxon.day,
      newScheduledDateLuxon.hour + hourIncrement,
      newMinutes,
      0,
    );

    setScheduledDate(newScheduledDate);
  }, []);

  useEffect(() => {
    const newDate = DateTime.fromObject({
      year: scheduledDate.getFullYear(),
      month: scheduledDate.getMonth() + 1,
      day: scheduledDate.getDate(),
      hour: scheduledDate.getHours(),
      minute: scheduledDate.getMinutes(),
      second: 0,
    }, { zone: timezone });

    if (newDate < DateTime.local().setZone(timezone)) {
      setInternalError('Please select a time in the future.');
    } else {
      setInternalError(null);
    }
  }, [ scheduledDate ]);

  const saveTime = () => {
    const newDate = DateTime.fromObject({
      year: scheduledDate.getFullYear(),
      month: scheduledDate.getMonth() + 1,
      day: scheduledDate.getDate(),
      hour: scheduledDate.getHours(),
      minute: scheduledDate.getMinutes(),
      second: 0,
    }, { zone: timezone });

    onSave(newDate.toISO());
    closeModal();
  };

  const now = DateTime.local().setZone(timezone);
  const minDate = new Date(
    now.year,
    now.month - 1,
    now.day,
    now.hour,
    0,
    0,
  );

  return (
    <div data-test-id="post-schedule-modal" className="nfp-modal">
      <div className="modal-content" style={{ maxWidth: '280px' }}>
        <div className="modal-close-container">
          <a href="#" onClick={closeModal}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <div className="modal-header">
          <h4>Schedule Post</h4>
        </div>
        <div className="modal-body">
          {internalError ? <span className="error-txt margintop20">{internalError}</span> : null}
          <div className="container-fluid margintop20 marginbot10">
            <div className="row">
              <div className="col-xs-12">
                <div className="label-regular">Select Date</div>
                <DatePicker
                  selected={scheduledDate}
                  onChange={validateDate}
                  showTimeSelect
                  timeFormat="p"
                  timeIntervals={5}
                  dateFormat="Pp"
                  minDate={minDate}
                  onFocus={(e) => e.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="row margintop15">
              <div className="col-xs-12" style={{ fontSize: '0.875em', lineHeight: '1.375em', color: '#999' }}>
                In your time zone (
                {localTimezone}
                )
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
          <button
            type="button"
            className="btn"
            disabled={internalError}
            onClick={internalError ? () => null : saveTime}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

PostScheduleModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentDate: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

PostScheduleModal.defaultProps = {
  currentDate: null,
};

export default PostScheduleModal;
