import React from 'react';
import PropTypes from 'prop-types';

import PendingTooltip from 'app/components/tooltips/PendingTooltip';
import RejectedTooltip from 'app/components/tooltips/RejectedTooltip';

import useProfileImages from 'app/hooks/useProfileImages';

const FlirtAvatarImage = ({
  viewingSelf,
  openSelectFilesModal,
  advisor,
}) => {
  const { avatarImage } = useProfileImages(advisor);
  const imageStatus = advisor ? advisor.avatar_image_moderation_status : '';
  const pending = imageStatus === 'pending';
  const rejected = imageStatus === 'rejected';
  const imageIconColumns = (pending || rejected) ? 'col-xs-6' : 'col-xs-12';

  const bgStyles = {
    backgroundImage: `url('${avatarImage}')`,
  };

  return (
    <>
      <div className="nfp-avatar-hero" style={bgStyles}>&nbsp;</div>
      {viewingSelf && (
        <div className="edit-profimage-container1">
          <div className="row bottom-xs marginrgt0" style={{ width: '100%' }}>
            {(pending || rejected) && (
              <div className="col-xs-6 start-xs padding0">
                {pending && (
                  <div className="mockupPendingIcon1">
                    <PendingTooltip imageType="Avatar" />
                  </div>
                )}
                {rejected && !pending && (
                  <div className="mockupRejectIcon1">
                    <RejectedTooltip imageType="Avatar" />
                  </div>
                )}
              </div>
            )}
            <div className={`${imageIconColumns} end-xs padding0`}>
              <a href="#" onClick={() => openSelectFilesModal('avatar')}>
                <img src="/plus_frontend/assets/svg/btn-edit-photo.svg" alt="Icon" className="" />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

FlirtAvatarImage.propTypes = {
  openSelectFilesModal: PropTypes.func.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
};

export default FlirtAvatarImage;
