import React from 'react';
import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

import CallIconNF from 'app/components/customSvgs/CallIconNF';

// Inspired by the former Facebook spinners.
const Connecting = (props) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={80}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#63177a' : '#63177a'),
          animationDuration: '1000ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'square',
          },
        }}
        size={80}
        thickness={3}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CallIconNF sx={{ color: '#63177a', fontSize: '2em' }} />
      </Box>
    </Box>
  );
};

export default Connecting;
