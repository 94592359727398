import React, { useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

import SearchContext from 'app/contexts/SearchContext';
import SearchControls from 'app/components/SearchControls';
import { useSearchAdvisorsQuery } from 'app/api/mainApi';

const SearchControlsContainer = () => {
  const searchInpRef = useRef();
  const searchContainerRef = useRef();
  const navigate = useNavigate();

  const {
    searchString,
    setSearchString,
    searchHasFocus,
    setSearchHasFocus,
  } = useContext(SearchContext);

  const { data: flirts } = useSearchAdvisorsQuery({ searchString }, { skip: !searchHasFocus });

  const clearSearchString = () => {
    searchInpRef.current.value = '';
    setSearchString('');
  };

  const updateSearchString = (e) => {
    setSearchString(e.currentTarget.value);
  };

  const onWindowClick = (e) => {
    // these refs may not be set yet
    if (!searchContainerRef.current) return;

    // we only care about clicks outside of the search area
    if (!searchContainerRef.current.contains(e.target)) {
      clearSearchString();
      setSearchHasFocus(false);
    }
  };

  const onSearchInputFocus = () => {
    window.addEventListener('click', onWindowClick);
    setSearchHasFocus(true);
  };

  const removeClickHandlers = () => {
    window.removeEventListener('click', onWindowClick);
  };

  const navigateToFlirt = (flirtUrl) => {
    removeClickHandlers();
    navigate(flirtUrl);
  };

  useEffect(() => {
    return () => {
      setSearchHasFocus(false);
      removeClickHandlers();
    };
  }, []);

  const getFlirts = () => {
    return searchHasFocus ? flirts : null;
  };

  return (
    <SearchControls
      flirts={getFlirts()}
      searchInpRef={searchInpRef}
      searchContainerRef={searchContainerRef}
      clearSearchField={clearSearchString}
      onSearchInputFocus={onSearchInputFocus}
      updateSearchField={updateSearchString}
      navigateToFlirt={navigateToFlirt}
    />
  );
};

export default SearchControlsContainer;
