import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';

import EmojiPicker from 'app/components/shared/EmojiPicker';
import TextareaAutosize from 'react-autosize-textarea';

const PostComposeBox = ({
  body,
  setBody,
  isPaidBody,
  errors,
  label,
  openPaymentButtonsModal,
  hasPaymentButtons,
  currentUser,
  hasGoodyBags,
  openGoodyBagsModal,
}) => {
  const MaximumPostLength = isPaidBody ? 5000 : 1500; // if you change this, change Post::MAX_BODY_LENGTH or Post::MAX_PAID_BODY_LENGTH in feeds to match
  const toggleEmojiRef = useRef();
  const textAreaRef = useRef();
  const [cursor, setCursor] = useState({
    start: (body?.length) || 0,
    end: (body?.length) || 0,
  });

  const trueBodyLength = (newBody) => {
    if (newBody) {
      // Search for new lines in body and add to length since we want new lines to count for 2 characters.
      const newLines = newBody.match(/\n/g);
      return newBody.length + (newLines ? newLines.length : 0);
    }
    return 0;
  };

  const [bodyLength, setBodyLength] = useState(trueBodyLength(body));

  const updateBodyLength = (newBody) => {
    setBodyLength(trueBodyLength(newBody));
  };

  const handleChange = (e) => {
    updateBodyLength(e.target.value);
    setBody(e.target.value);
  };

  const updateCursor = () => {
    setCursor({
      start: textAreaRef.current.selectionStart,
      end: textAreaRef.current.selectionEnd,
    });
  };

  const insertTextAtCursor = (button) => {
    let before = body.substring(0, cursor.start);
    if (before.length > 0 && before[before.length - 1] !== '\n') {
      before = `${before}\n`;
    }

    let after = body.substring(cursor.end);
    if (after.length > 0 && after[0] !== '\n') {
      after = `\n${after}`;
    }

    const newBody = `${before}${button}${after}`;
    updateBodyLength(newBody);
    setBody(newBody);

    const start = cursor.start + button.length + 1;
    const end = start;

    textAreaRef.current.setSelectionRange(start, end);
    setCursor({ start, end });
  };

  const afterSelectPaymentBtnCallback = (paymentButtonId) => {
    insertTextAtCursor(
      `https://${window.location.hostname}/messages/click_payment_button?id=${paymentButtonId}`,
    );
  };

  const afterSelectGoodyBagCallback = (goodyBagId) => {
    insertTextAtCursor(
      `https://${window.location.hostname}/goodies/click/${currentUser.id}-${goodyBagId}`,
    );
  };

  const paymentButtonIcon = (
    <a href="#" onClick={() => openPaymentButtonsModal(afterSelectPaymentBtnCallback)}>
      <img src="/plus_frontend/assets/svg/ico-paidmail-blue.svg" alt="Payment" className="marginlft10" />
    </a>
  );

  const goodyBagIcon = (
    <a href="#" onClick={() => openGoodyBagsModal(afterSelectGoodyBagCallback)}>
      <img src="/plus_frontend/assets/svg/ico-goodies.svg" alt="Goody Bag" className="marginlft10" />
    </a>
  );

  const addToBody = (emojiCode) => {
    const newBody = `${body.substring(0, cursor.start)}${emojiCode}${body.substring(cursor.end)}`;
    setCursor({
      start: cursor.start + emojiCode.length,
      end: cursor.start + emojiCode.length,
    });
    textAreaRef.current.setSelectionRange(cursor.start + emojiCode.length, cursor.start + emojiCode.length);
    setBody(newBody);
  };

  const errorMessage = errors && errors.length > 0 && (
    <div className="text-error">
      {errors.join(' ')}
    </div>
  );

  return (
    <div className="container-textarea emo-mart-pos-create-post">
      <EmojiPicker
        setText={addToBody}
        toggleEmojiRef={toggleEmojiRef}
      />
      <div className={`label-float ${(errors && errors.length > 0) ? 'has-error' : ''}`}>
        <TextareaAutosize
          value={body}
          onChange={handleChange}
          maxLength={MaximumPostLength}
          data-lpignore="true"
          onKeyUp={updateCursor}
          onClick={updateCursor}
          ref={textAreaRef}
          className="textarea-grow"
        />
        {label && (
          <label className={body ? 'filled' : ''}>
            {label}
          </label>
        )}
        {errorMessage}
      </div>
      <div className="container-fluid padding-0-8 margintopminus10">
        <div className="row middle-xs">
          <div className="col-xs-5 start-xs">
            <a href="#" ref={toggleEmojiRef}>
              <img src="/plus_frontend/assets/svg/ico-smiley.svg" alt="Add Emoji" className="ico-emoji" />
            </a>
            { hasPaymentButtons && paymentButtonIcon}
            { hasGoodyBags && goodyBagIcon}
          </div>
          <div className="col-xs-7 end-xs">
            <span className="char-remaining" style={{ color: (bodyLength >= MaximumPostLength ? 'red' : '#999') }}>
              {bodyLength}
              &nbsp;/&nbsp;
              {MaximumPostLength}
              &nbsp;characters
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

PostComposeBox.propTypes = {
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
  isPaidBody: PropTypes.bool,
  errors: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  openPaymentButtonsModal: PropTypes.func.isRequired,
  hasPaymentButtons: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  hasGoodyBags: PropTypes.bool.isRequired,
  openGoodyBagsModal: PropTypes.func.isRequired,
};

PostComposeBox.defaultProps = {
  errors: null,
  label: false,
};

export default PostComposeBox;
