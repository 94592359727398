import React from 'react';
import IdVerification from 'app/components/account_info/IdVerification';
import { useGetUserQuery } from 'app/api/mainApi';
import { Navigate } from 'react-router';

const IdVerificationContainer = () => {
  const {
    data: {
      user: {
        idv_status,
        idv_date
      } = {}
    } = {}
  } = useGetUserQuery();

  if (idv_status === undefined) {
    return <p>Loading Information ...</p>
  }

  if (idv_status === 'not_required' || idv_status === 'exempt') {
    return <Navigate to="/account/account_info" />;
  }

  return (
    <IdVerification idv_status={idv_status} idv_date={idv_date} />
  );
}

export default IdVerificationContainer;
