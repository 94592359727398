import { useEffect, useContext } from 'react';

import DialogsContext from 'app/contexts/DialogsContext';
import ResetPasswordCompleteDialog from 'app/components/modals/ResetPasswordCompleteDialog';

const ResetPasswordCompleteLauncher = () => {
  const { openDialog } = useContext(DialogsContext);

  useEffect(() => {
    openDialog({
      component: ResetPasswordCompleteDialog,
      props: { hideCancel: true },
    });
  }, []);

  return null;
};

export default ResetPasswordCompleteLauncher;
