import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

const CallQualitySection = ({ options, callQualityFeedback, onChange }) => {
  const OTHER = 17;

  const [errorType, setErrorType] = useState(callQualityFeedback?.error_type ?? '');
  const [comment, setComment] = useState(callQualityFeedback?.comment ?? '');
  const [initialValues] = useState({
    errorType: callQualityFeedback?.error_type ?? '',
    comment: callQualityFeedback?.comment ?? '',
  });

  const isValid = () => {
    return (errorType !== OTHER || comment.trim() !== '');
  };

  const hasChanged = () => {
    return errorType !== initialValues.errorType || comment !== initialValues.comment;
  };

  useEffect(() => {
    onChange({ ...callQualityFeedback, valid: isValid(), changed: hasChanged() });
  }, [errorType, comment]);

  const updateCallQualityFeedback = (newErrorType, newComment) => {
    const updatedComment = newErrorType === OTHER ? newComment : '';
    setErrorType(newErrorType);
    setComment(updatedComment);
    onChange({
      ...callQualityFeedback,
      error_type: newErrorType,
      comment: updatedComment,
      valid: isValid(),
      changed: hasChanged(),
    });
  };

  const handleErrorTypeChange = (event) => {
    updateCallQualityFeedback(event.target.value, comment);
  };

  const handleCommentChange = (event) => {
    updateCallQualityFeedback(errorType, event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>Phone Connection (optional)</InputLabel>
        <Select value={errorType} label="Phone Connection (optional)" onChange={handleErrorTypeChange}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option[1]} value={option[1]}>
              {option[0]}
            </MenuItem>
          ))}
        </Select>
        { errorType === OTHER && (
          <TextField
            label="Tell us about the call quality."
            variant="outlined"
            fullWidth
            value={comment}
            onChange={handleCommentChange}
            inputProps={{ maxLength: 255 }}
          />
        )}
      </FormControl>
    </Box>
  );
};

CallQualitySection.propTypes = {
  options: PropTypes.array.isRequired,
  callQualityFeedback: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CallQualitySection;
