import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAvailabilityQuery } from 'app/api/mainApi';
import CallStatusSelector from 'app/components/CallStatusSelector';
import { PropTypes } from 'prop-types';
import { purge } from 'app/redux/store';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialog-paperWidthSm': {
    width: '100%',
    maxWidth: '700px',
    margin: '0px',
    borderRadius: '0px',
    [theme.breakpoints.up('md')]: {
      minHeight: '500px',
    },
  },
}));

const SignOutModal = ({ type }) => {
  const fullScreen = false;
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  let defaultPhone = null;

  const { data } = useGetAvailabilityQuery();

  if (data) {
    defaultPhone = data.phones?.find((phone) => phone.default);
  }

  const changeAvailabilityAndSignOut = () => {
    const defaultPhoneType = defaultPhone?.phone_type || 1;
    fetch('/change_availability', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        default_phone: defaultPhoneType,
        current_advisor_status: selectedIndex === 0 ? 1 : 4,
      }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 302) {
          window.location.href = '/';
          purge();
        }
      });
  };

  const handleClose = () => {
    if (type === 'full_page') {
      window.history.back();
      return;
    }
    setOpen(false);
  };

  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" />
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box mx={{ xs: 0, sm: 12, md: 17 }}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <img src="/plus_frontend/assets/svg/niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            <Typography variant="h3">
              Sign Out - Edit Availability
            </Typography>
            <Typography variant="body1">
              Thanks for visiting NiteFlirt. Before you sign out, would you like to change your availability status?
            </Typography>
          </Stack>
          <Stack mb={3}>
            <CallStatusSelector selectedIndex={selectedIndex} onIndexChange={setSelectedIndex} phone={defaultPhone} />
          </Stack>
          <Stack direction="row" justifyContent="center" mb={3}>
            <Button
              onClick={changeAvailabilityAndSignOut}
              variant="contained"
              size="large"
              fullWidth
            >
              Sign Out
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

SignOutModal.propTypes = {
  type: PropTypes.string,
};

SignOutModal.defaultProps = {
  type: 'modal',
};

export default SignOutModal;
