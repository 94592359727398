import React, { useState, useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ModalsContext from 'app/contexts/ModalsContext';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import MediaModalContainer from 'app/containers/MediaModalContainer';
import CreatePost from 'app/components/posts/CreatePost';
import SortableMediaBox from 'app/components/posts/SortableMediaBox';
import { HAS_OFFERING } from 'app/helpers/onboardingHelpers';
import FirstPostSuccessModal from 'app/components/modals/FirstPostSuccessModal';
import { resetFlirtFeed } from 'app/slices/resetRecordsSlice';
import PostScheduleModal from 'app/components/modals/PostScheduleModal';
import { showFlash, simpleErrorFlash } from 'app/slices/flashSlice';
import SuccessfulPostCreationBanner from 'app/components/posts/SuccessfulPostCreationBanner';
import { usePerformCreatePostMutation } from 'app/api/feedsApi';
import { useGetAppConfigQuery } from 'app/api/mainApi';

import usePostComposeActions from 'app/hooks/usePostComposeActions';

const CreatePostContainer = () => {
  // Cannot create a post if you haven't set a subscription price yet
  const appConfig = useGetAppConfigQuery();

  // if user has NOT completed onboarding, and they've not yet set a subscription price,
  // redirect to onboarding
  useEffect(() => {
    if (appConfig.isSuccess &&
      !appConfig.data.current_user.onboarding_complete &&
      appConfig.data.current_user.onboarding_step !== HAS_OFFERING) {
      return <Navigate replace to={`/feed/${appConfig.data.current_user.login}/onboarding`} />;
    }

    return null;
  }, [appConfig]);

  const currentUser = appConfig?.data?.current_user;
  const { openModal } = useContext(ModalsContext);
  const [freePost, setFreePost] = useState(true);
  const [subscriberPost, setSubscriberPost] = useState(false);
  const [body, setBody] = useState('');
  const [paidBody, setPaidBody] = useState('');
  const [errors, setErrors] = useState({});
  const [postType, setPostType] = useState('free'); // the actual post_type submitted to the controller
  const [selectedItems, setSelectedItems] = useState([]);
  const dispatch = useDispatch();
  const [multiPostGuard, setMultiPostGuard] = useState(false);
  const [scheduledAt, setScheduledAt] = useState(null);
  const [showPaidCaption, setShowPaidCaption] = useState(false);

  const [performCreatePost, {
    data: createPostData,
    status: createPostStatus,
    error: createPostErrors,
    isFetching: fetchingCreatePost,
  }] = usePerformCreatePostMutation();

  const {
    paymentButtons,
    goodyBags,
    openGoodyBagsModal,
    openPaymentButtonsModal
  } = usePostComposeActions();

  useEffect(() => {
    setErrors(createPostErrors?.data?.errors);
    if (!freePost) {
      // if they had an error, on a subscriber post, it could have been because they didn't have any paid content.
      //  Since that error is attached to the paid_body field, we need to make sure to display it.
      setShowPaidCaption(true);
    }

    if (createPostErrors?.data?.errors?.scheduled_at) {
      const error = createPostErrors.data.errors.scheduled_at[0];
      dispatch(simpleErrorFlash(error));
    }
  }, [createPostErrors]);

  const navigate = useNavigate();

  const freePostChanged = (event) => {
    // not letting the advisor change the post type if they're in the onboarding flow
    if (!currentUser?.onboarding_complete) return;

    // default subscriberPost to false when we switch radio; this unchecks the checkbox
    setSubscriberPost(false);

    if (event.target.value === 'free') {
      setFreePost(true); // radio state mgmt
      setPostType('free');
    } else {
      setFreePost(false);
      setPostType('welcome_kit');
    }
  };

  const onboardingComplete = (appConfig) => {
    return appConfig?.data?.current_user?.onboarding_complete;
  };

  const openSelectFilesModal = () => {
    openModal({
      component: MediaModalContainer,
      props: {
        selectedItems,
        setSelectedItems: setSelectedItemsAndClearTempState,
        freePost,
      },
      willNotCloseLowerModals: true,
    });
  };

  const openSchedulingModal = (currentDate = null) => {
    openModal({
      component: PostScheduleModal,
      props: {
        freePost,
        onSave: setScheduledAt,
        currentDate,
        timezone: currentUser.canonical_iana_timezone,
      },
      noDefaultBody: true,
    });
  };

  const removeSchedule = () => {
    setScheduledAt(null);
  };

  const subscriberPostChanged = () => {
    if (!currentUser?.onboarding_complete) return;

    if (subscriberPost) {
      setPostType('welcome_kit');
    } else {
      // if unchecking, revert to previous value
      setPostType(freePost ? 'free' : 'subscriber');
    }

    setSubscriberPost(!subscriberPost);
  };

  const setBodyAndClearTempState = (body) => {
    setBody(body);
    setErrors({});
    setMultiPostGuard(false);
  };

  const setPaidBodyAndClearTempState = (body) => {
    setPaidBody(body);
    setErrors({});
    setMultiPostGuard(false);
  };

  const setSelectedItemsAndClearTempState = (items) => {
    setSelectedItems(items);
    setErrors({});
    setMultiPostGuard(false);
  };

  const onSubmit = () => {
    if (multiPostGuard) return;

    setMultiPostGuard(true);

    // right before submit, encode current array order as a 1-indexed integer
    const orderedPostItems = selectedItems.map((el, idx) => {
      return {
        fm_item_id: el.fm_item_id,
        sort_order: idx + 1,
      };
    });

    performCreatePost({
      post: {
        body,
        paid_body: paidBody,
        post_type: postType,
        post_items: orderedPostItems,
        scheduled_at: scheduledAt,
      },
      onboarding: !currentUser.onboarding_complete,
      advisorId: currentUser.id,
    });
  };

  const SortableMediaUI = (
    <SortableMediaBox
      items={selectedItems}
      setItems={setSelectedItemsAndClearTempState}
    />
  );

  useEffect(() => {
    if (!onboardingComplete(appConfig)) {
      setFreePost(false);
      setSubscriberPost(false);
      setPostType('welcome_kit');
    }
  }, [appConfig]);

  useEffect(() => {
    if (createPostStatus !== 'pending') {
      setMultiPostGuard(false);
    }

    if (createPostStatus === 'fulfilled') {
      setMultiPostGuard(false);
      dispatch(resetFlirtFeed({ advisorId: appConfig.data.current_user.id }));
      navigate(`/feed/${createPostData.account.login}/${createPostData.id}?fromCreate=true`);

      if (createPostData.scheduled_at) {
        dispatch(showFlash({
          flashType: 'success',
          autoCloseDur: 10000,
          props: {
            message: SuccessfulPostCreationBanner,
          },
        }));
      }

      // the following conditional is a tad misleading,
      // technically speaking the advisor IS done with onboarding
      // now that they've created their first post
      // but the appConfig won't reflect this in this instant
      if (!onboardingComplete(appConfig)) {
        openModal({ component: FirstPostSuccessModal });
      }
    }
  }, [createPostStatus]);

  return (
    <AppBodyWithSidebar>
      <CreatePost
        body={body}
        setBody={setBodyAndClearTempState}
        paidBody={paidBody}
        setPaidBody={setPaidBodyAndClearTempState}
        freePost={freePost}
        freePostChanged={freePostChanged}
        showPaidCaption={showPaidCaption}
        setShowPaidCaption={setShowPaidCaption}
        openSelectFilesModal={openSelectFilesModal}
        subscriberPost={subscriberPost}
        subscriberPostChanged={subscriberPostChanged}
        errors={errors}
        onSubmit={onSubmit}
        hasSelectedItems={selectedItems.length > 0}
        SortableMediaUI={SortableMediaUI}
        onboardingComplete={currentUser?.onboarding_complete}
        fetchingCreatePost={fetchingCreatePost}
        scheduledAt={scheduledAt}
        openSchedulingModal={openSchedulingModal}
        removeSchedule={removeSchedule}
        timezone={currentUser?.canonical_iana_timezone}
        hasPaymentButtons={paymentButtons?.data?.length > 0}
        hasGoodyBags={goodyBags?.data?.length > 0}
        openPaymentButtonsModal={openPaymentButtonsModal}
        openGoodyBagsModal={openGoodyBagsModal}
        currentUser={currentUser}
      />
    </AppBodyWithSidebar>
  );
};

export default CreatePostContainer;
