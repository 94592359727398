/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';
import Lightbox from 'lightbox-react';
import Slider from 'react-slick';
import 'react-html5video/dist/styles.css';

import ImageMedia from 'app/components/media/ImageMedia';
import VideoMedia from 'app/components/media/VideoMedia';
import AudioMedia from 'app/components/media/AudioMedia';
import useFlirtMedia from 'app/hooks/useFlirtMedia';
import { postItemToMediaParams } from 'app/helpers/flirtMediaHelpers';

const MediaCountWidget = ({ currentItem, mediaItems }) => {
  const validMediaItems = mediaItems.filter(e => e && e.type);
  const itemsByType = validMediaItems.filter(e => e.type === currentItem?.type);
  const imageItems = validMediaItems.filter(e => e.type === 'image');
  const videoItems = validMediaItems.filter(e => e.type === 'video');
  const audioItems = validMediaItems.filter(e => e.type === 'audio');
  const hasImages = imageItems.length > 0;
  const hasVideos = videoItems.length > 0;
  const hasAudios = audioItems.length > 0;
  const itemIndex = itemsByType.indexOf(currentItem);
  const showingImage = currentItem?.type === 'image';
  const showingVideo = currentItem?.type === 'video';
  const showingAudio = currentItem?.type === 'audio';

  const currentTypeCounter = (
    <>
      <span className="current">
        {itemIndex + 1}
      </span>
      /
      <span className="total">
        {itemsByType.length}
      </span>
    </>
  );

  const itemTypeCounter = (itemType) => (
    <span className="current">
      {itemType.length}
    </span>
  );

  return (
    <>
      <div id="comments" />
      <div className="row center-xs">
        <div className="slick-counter">
          {hasImages && (
            <div data-test-id="image-counter">
              <img src="/plus_frontend/assets/svg/ico-image.svg" alt="Images" />
              {showingImage && currentTypeCounter}
              {!showingImage && itemTypeCounter(imageItems)}
            </div>
          )}
          {hasVideos && (
            <div data-test-id="video-counter">
              {hasImages && <span className="padding5" />}
              <img src="/plus_frontend/assets/svg/ico-video.svg" alt="Images" />
              {showingVideo && currentTypeCounter}
              {!showingVideo && itemTypeCounter(videoItems)}
            </div>
          )}
          {hasAudios && (
            <div data-test-id="audio-counter">
              {(hasImages || hasVideos) && <span className="padding5" />}
              <img src="/plus_frontend/assets/svg/ico-audio.svg" alt="Images" />
              {showingAudio && currentTypeCounter}
              {!showingAudio && itemTypeCounter(audioItems)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

MediaCountWidget.propTypes = {
  currentItem: PropTypes.object,
  mediaItems: PropTypes.array,
};

MediaCountWidget.defaultProps = {
  currentItem: null,
  mediaItems: [],
};

const PostMedia = ({ post }) => {
  if (!post.post_items || post.post_items.length === 0) { return null; }

  const mediaItems = post.post_items.map(postItemToMediaParams);

  const {
    nextItem,
    prevItem,
    currentItem,
    onPrevClick,
    onNextClick,
    onCloseClick,
    setMediaIndex,
    lightboxIsOpen,
    setLightboxIsOpen,
    pauseAllVideoPlayers,
    pauseAllAudioPlayers,
    lightboxMediaDecorator,
  } = useFlirtMedia({ mediaItems });

  const onImageClick = () => setLightboxIsOpen(true);

  const onSlideChange = (index) => {
    setMediaIndex(index);
    pauseAllVideoPlayers();
    pauseAllAudioPlayers();
  };

  // massaging the data for the slider component
  const getSliderComponent = (item) => {
    switch (item && item.type) {
      case 'image':
        return (
          <ImageMedia
            key={item.id}
            item={item}
            onImageClick={onImageClick}
          />
        );
      case 'audio':
        return <AudioMedia key={item.id} item={item} />;
      case 'video':
        return <VideoMedia key={item.id} item={item} />;
      default:
        console.warn('unrecognized media item type:', item && item.type);
        return null;
    }
  };

  return (
    <>
      <Slider
        dots={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        // draggable={false}
        afterChange={onSlideChange}
      >
        {mediaItems.map(getSliderComponent)}
      </Slider>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={lightboxMediaDecorator(currentItem)}
          nextSrc={lightboxMediaDecorator(nextItem)}
          prevSrc={lightboxMediaDecorator(prevItem)}
          onCloseRequest={onCloseClick}
          onMovePrevRequest={onPrevClick}
          onMoveNextRequest={onNextClick}
        />
      )}

      <MediaCountWidget currentItem={currentItem} mediaItems={mediaItems} />
    </>
  );
};

PostMedia.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostMedia;
