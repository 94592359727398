import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { isMobile } from 'react-device-detect';

import EmojiPicker from 'app/components/shared/EmojiPicker';
import { adjustCommentsAnchor } from 'app/helpers/postCommentsHelpers';

const CommentsComposeBox = ({ body, setBody, onSubmit, errors }) => {
  const errorMessage = errors?.body[0];
  const wrapperClass = isMobile ? 'nfp-chat-footer-mobile' : 'nfp-chat-footer-desktop';
  const toggleEmojiRef = useRef();
  const textAreaRef = useRef();

  const [cursor, setCursor] = useState({ start: 0, end: 0 });

  const updateCursor = () => {
    setCursor({
      start: textAreaRef.current.selectionStart,
      end: textAreaRef.current.selectionEnd,
    });
  };

  const addToBody = (emojiCode) => {
    const newBody = `${body.substring(0, cursor.start)}${emojiCode}${body.substring(cursor.end)}`;
    setCursor({
      start: cursor.start + emojiCode.length,
      end: cursor.start + emojiCode.length,
    });
    textAreaRef.current.setSelectionRange(cursor.start + emojiCode.length, cursor.start + emojiCode.length);
    setBody(newBody);
  };

  return (
    <div className={wrapperClass}>
      <div className="container-compose">
        <form>
          <div className="container-textarea">
            <div className="label-float">
              <textarea
                value={body}
                maxLength={200}
                onChange={(e) => setBody(e.target.value)}
                data-lpignore="true"
                onKeyUp={updateCursor}
                onClick={updateCursor}
                ref={textAreaRef}
              />
              <label className={body && 'filled'}>
                Add a comment
              </label>
              { errorMessage && (
                <div className="text-error">{errorMessage}</div>
              )}
            </div>
            <div className="container-fluid padding-0-8 margintopminus10 emo-mart-pos-comments">
              <EmojiPicker
                setText={addToBody}
                toggleEmojiRef={toggleEmojiRef}
              />
              <div className="row middle-xs">
                <div className="col-xs-6 start-xs">
                  <a href="#" ref={toggleEmojiRef}>
                    <img src="/plus_frontend/assets/svg/ico-smiley.svg" alt="Add Emoji" className="ico-emoji" />
                  </a>
                </div>
                <div className="col-xs-6 end-xs">
                  <a
                    href="#comments"
                    onClick={() => {
                      onSubmit();
                      adjustCommentsAnchor();
                    }}
                    style={{
                      display: 'inline-block',
                      border: '1px transparent white',
                      padding: '1px 1px 1px 10px',
                    }}
                  >
                    <img src="/plus_frontend/assets/svg/ico-send.svg" alt="Send" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

CommentsComposeBox.defaultProps = {
  errors: null,
};

CommentsComposeBox.propTypes = {
  body: PropTypes.string.isRequired,
  setBody: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default CommentsComposeBox;
