import React, { useState } from 'react';

import MainHome from 'app/components/MainHome';
import { useGetHomepageListingsQuery } from 'app/api/mainApi';

const MainHomeContainer = () => {
  const [page, setPage] = useState(1);
  const { data: listingsData } = useGetHomepageListingsQuery({ page, perPage: 12 });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <MainHome
      currentPage={page}
      listingsData={listingsData}
      handlePageChange={handlePageChange}
    />
  );
};

export default MainHomeContainer;
