import React from 'react';
import { PropTypes } from 'prop-types';
import { DateTime } from 'luxon';

const UnsubscribeModal = ({ closeModal, subscriptionEndsAt, clickAction }) => {
  const formattedSubscriptionEndsAt = DateTime.fromISO(subscriptionEndsAt).toLocaleString();

  return (
    <>
      <div className="modal-header">
        <h4>Unsubscribe</h4>
      </div>
      <div className="modal-body">
        <p>
          You will no longer be charged for this subscription and the current paid period will expire on&nbsp;
          { formattedSubscriptionEndsAt }
          . Are you sure you want to unsubscribe?
        </p>
      </div>
      <div className="modal-footer">
        <a role="link" tabIndex={0} className="btn secondary" onClick={closeModal}>
          Stay Subscribed
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            clickAction();
            closeModal();
          }}
        >
          Unsubscribe
        </button>
      </div>
    </>
  );
};

UnsubscribeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  subscriptionEndsAt: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
};

export default UnsubscribeModal;
