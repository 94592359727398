import React, { useState, useEffect } from 'react';

import { Stack, Typography, Button, DialogContent, IconButton } from '@mui/material';
import AutoFillableTextField from 'app/components/shared/AutoFillableTextField';
import { useVerifyAccountMutation } from 'app/api/mainApi';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const VerifyAccountDialog = ({ type, closeDialog }) => {
  const [data, setData] = useState('');

  const [verifyAccount, { data: verifyAccountData, error: verifyAccountError }  ] = useVerifyAccountMutation();

  // these params will be passed along unchanged with any form submission
  const queryParameters = new URLSearchParams(window.location.search);

  const navigate = useNavigate()
  const verificationType = queryParameters.get('type');

  const wording = verificationType === 'card' ?
                    'To verify your account, please provide us with the last 8 digits of the Credit Card you have on file.' :
                    'To verify your account, please provide us with the last 5 digits of your Social Security or Employer ID number.';

  const label = verificationType === 'card' ? 'Last 8 of Credit Card' : 'Social Security or Employer ID Number';

  useEffect(() => {
    if (verifyAccountData) {
      navigate('reset_password_complete');
    }
  }, [verifyAccountData]);

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    <DialogContent>
      <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
        <a href="/">
          <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
        </a>
        <Typography variant="h3">
          Verify Your Account
        </Typography>
        <Typography variant="body1" align="center">
          { wording }
        </Typography>
      </Stack>
      {
        verifyAccountError && (
          <Stack mb={3}>
            <Typography variant="body3" style={{color: '#d32f2f'}}>
              <div dangerouslySetInnerHTML={{__html: verifyAccountError.data.error}} />
            </Typography>
          </Stack>
        )
      }

      <Stack direction="column" spacing={3}>
        <AutoFillableTextField
          name="password"
          label={label}
          type="password"
          value={data}
          setValue={setData}
          onKeyPress={(e) => { if (e.key === 'Enter') verifyAccount({ data, type: verificationType}) }}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" mb={3} mt={2}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={() => verifyAccount({ data, type: verificationType})}
        >
          Verify Account
        </Button>
      </Stack>
    </DialogContent>
      </>
  );
};

export default VerifyAccountDialog;
