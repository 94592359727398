import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';

const NoPerksNoProblemDialog = ({ closeDialog }) => {
  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              No problem! You can create new Packages later from Packages Page.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeDialog}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

NoPerksNoProblemDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default NoPerksNoProblemDialog;
