import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const StyledCheckboxChip = styled(Chip)(({ variant }) => ({
  marginRight: '8px',
  '&:active': {
    backgroundColor: '#8461a4',
  },
  ...(variant === 'filled' && {
    backgroundColor: '#8461a4',
    color: 'white',
    '&:hover': {
      backgroundColor: '#8461a4',
    },
  }),
}));

// eslint-disable-next-line react/prop-types
const CheckboxChip = ({ label, value, state, setState }) => {
  const selected = (Array.isArray(state)) ? state.includes(value) : state === value;

  return (
    <StyledCheckboxChip
      label={label}
      variant={selected ? 'filled' : 'outlined'}
      onClick={() => setState(value)}
    />
  );
};

CheckboxChip.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  setState: PropTypes.func.isRequired,
};

export default CheckboxChip;
