import { useContext } from 'react';

import ModalsContext from 'app/contexts/ModalsContext';
import PaymentButtonsModal from 'app/components/modals/PaymentButtonsModal';
import GoodyBagsPickerModal from 'app/components/modals/GoodyBagsPickerModal';

import {
  useGetPaymentButtonsQuery,
  useGetGoodyBagsForCurrentUserQuery,
} from 'app/api/mainApi';

const usePostComposeActions = () => {
  const paymentButtons = useGetPaymentButtonsQuery();
  const goodyBags = useGetGoodyBagsForCurrentUserQuery();

  const { openModal } = useContext(ModalsContext);

  return ({
    openPaymentButtonsModal: (afterSelectPaymentBtnCallback) => {
      openModal({
        component: PaymentButtonsModal,
        props: {
          paymentButtons: paymentButtons.data,
          onSave: afterSelectPaymentBtnCallback,
        },
        willNotCloseLowerModals: true,
      });
    },

    openGoodyBagsModal: (afterSelectGoodyBagCallback) => {
      openModal({
        component: GoodyBagsPickerModal,
        props: {
          goodyBags: goodyBags.data,
          onSave: afterSelectGoodyBagCallback,
        },
        willNotCloseLowerModals: true,
      });
    },
    paymentButtons,
    goodyBags,
  });
};

export default usePostComposeActions;
