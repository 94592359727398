import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';
import withTooltip from 'app/hoc/withTooltip';
import { isSubscriptionPriceSet } from 'app/helpers/onboardingHelpers';

const FeedHeroTooltip = ({
  advisor,
  currentUser,
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  const advisorIsVisitor = advisor?.id === currentUser?.id;
  const [copyLinkText, setCopyLinkText] = useState('Copy link');

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyLinkText('Copied!');

    setTimeout(() => setCopyLinkText('Copy link'), 1000);
  };

  const handleShareClick = () => navigator.share({
    url: window.location.href,
    text: 'Check out this Flirt on NF+: ',
  });

  const createPostEnabled = currentUser?.is_advisor &&
    isSubscriptionPriceSet(currentUser?.onboarding_step);

  const subSettingsOrPackagesLink =
    featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') ? (
      <Link title="Packages" to="/feed/packages">
        Packages
      </Link>
    ) : (
      <Link title="Subscription Settings" to="/feed/subscription_settings">
        Subscription Settings
      </Link>
    );

  return (
    <Tooltip
      content={(
        <div
          ref={(el) => setTipContentRef(el)}
          className="nfp-tooltip"
        >
          <div className="row nowrap-xs">
            <div className="col-xs-11">
              <div className="menu-links">
                {advisorIsVisitor && (
                  <>
                    <Link
                      title="New Post"
                      to="/feed/create_post"
                      style={{ pointerEvents: createPostEnabled ? 'auto' : 'none' }}
                      disabled={createPostEnabled}
                    >
                      New Post
                    </Link>
                    <br />
                    <Link
                      title="Posts Queue"
                      to="/feed/queue/scheduled"
                    >
                      Posts Queue
                    </Link>
                    <br />
                    <Link
                      title="Following"
                      to="/feed/home"
                    >
                      Following
                    </Link>
                    <br />
                    {subSettingsOrPackagesLink}
                    <br />
                    <Link
                      title="Subscriptions"
                      to="/feed/subscriptions"
                    >
                      Subscriptions
                    </Link>
                    <br />
                  </>
                )}
                <a role="link" onClick={handleCopyLinkClick} tabIndex={0}>
                  {copyLinkText}
                </a>
                {navigator.share && (
                  <>
                    <br />
                    <a role="link" onClick={handleShareClick} tabIndex={0}>
                      Share
                    </a>
                  </>
                )}
                <br />
                <a href={`/profile/${advisor?.login}`}>
                  Flirt Profile
                </a>
                {featureFlag.enabled('redesign_users') && (
                  <>
                    <br />
                    <a href={`/${advisor?.login}`}>
                      View Storefront
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a
                role="link"
                tabIndex={0}
                onClick={toggleTip}
              >
                <img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" />
              </a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
      >
        <img src="/plus_frontend/assets/svg/ico-more-menu.svg" alt="More" className="icon-more-hero" />
      </a>
    </Tooltip>
  );
};

FeedHeroTooltip.defaultProps = {
  advisor: null,
  currentUser: null,
};

FeedHeroTooltip.propTypes = {
  advisor: PropTypes.object,
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default withTooltip(FeedHeroTooltip);
