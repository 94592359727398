// Cribbed directly from lib/search/query/goody_search/facet.rb in File Manger
// but converted to JSON.
export default [
  {
    id: 'price',
    label: 'PRICE',
    type: 'checkbox',
    values: [
      {
        id: '5',
        label: 'Less than $5',
      },
      {
        id: '5to10',
        label: '$5 to < $10',
      },
      {
        id: '10to15',
        label: '$10 to < $15',
      },
      {
        id: '15to20',
        label: '$15 to < $20',
      },
      {
        id: '20to25',
        label: '$20 to < $25',
      },
      {
        id: '25',
        label: '$25 or more',
      },
    ],
  },
  {
    id: 'media',
    label: 'MEDIA TYPES',
    type: 'checkbox',
    values: [
      {
        id: 'image',
        label: 'Photos',
      },
      {
        id: 'video',
        label: 'Videos',
      },
      {
        id: 'audio',
        label: 'Audio files',
      },
    ],
  },
  {
    id: 'rating',
    label: 'RATING',
    type: 'checkbox',
    values: [
      {
        id: 'five',
        label: 'Excellent',
      },
      {
        id: 'four',
        label: 'Good',
      },
      {
        id: 'three',
        label: 'Okay',
      },
      {
        id: 'two',
        label: 'Needs Loving',
      },
      {
        id: 'one',
        label: 'Unrated',
      },
    ],
  },
  {
    id: 'dominance_tags',
    label: 'DOMINANCE',
    type: 'checkbox',
    values: [
      {
        id: 'Woman dominant',
        label: 'Woman dominant',
      },
      {
        id: 'Woman submissive',
        label: 'Woman submissive',
      },
      {
        id: 'Woman neutral',
        label: 'Woman neutral',
      },
      {
        id: 'Man dominant',
        label: 'Man dominant',
      },
      {
        id: 'Man submissive',
        label: 'Man submissive',
      },
      {
        id: 'Man neutral',
        label: 'Man neutral',
      },
      {
        id: 'Transgender dominant',
        label: 'Transgender dominant',
      },
      {
        id: 'Transgender submissive',
        label: 'Transgender submissive',
      },
      {
        id: 'Transgender neutral',
        label: 'Transgender neutral',
      },
    ],
  },
  {
    id: 'age_tags',
    label: 'AGE',
    type: 'checkbox',
    values: [
      {
        id: 'teen',
        label: '18-19',
      },
      {
        id: 'twenties',
        label: '20-29',
      },
      {
        id: 'thirties',
        label: '30-39',
      },
      {
        id: 'forty_plus',
        label: '40+',
      },
    ],
  },
  {
    id: 'race_tags',
    label: 'RACE/ETHNICITY',
    type: 'checkbox',
    values: [
      {
        id: 'African descent',
        label: 'African descent',
      },
      {
        id: 'Asian',
        label: 'Asian',
      },
      {
        id: 'Caucasian',
        label: 'Caucasian',
      },
      {
        id: 'Latin American/Hispanic',
        label: 'Latin American/Hispanic',
      },
      {
        id: 'Mixed race',
        label: 'Mixed race',
      },
      {
        id: 'Indian subcontinent descent',
        label: 'Indian subcontinent descent',
      },
      {
        id: 'Middle Eastern',
        label: 'Middle Eastern',
      },
      {
        id: 'Native American',
        label: 'Native American',
      },
      {
        id: 'Pacific Islander',
        label: 'Pacific Islander',
      },
    ],
  },
  {
    id: 'content_rating',
    label: 'CONTENT RATING',
    type: 'checkbox',
    values: [
      {
        id: 'Tame',
        label: 'Tame',
      },
      {
        id: 'Provocative/Sexy',
        label: 'Provocative/Sexy',
      },
      {
        id: 'Censored nudity',
        label: 'Censored nudity',
      },
      {
        id: 'Female topless nudity',
        label: 'Female topless nudity',
      },
      {
        id: 'Buttocks nudity',
        label: 'Buttocks nudity',
      },
      {
        id: 'Below waist/full frontal nudity',
        label: 'Below waist/full frontal nudity',
      },
      {
        id: 'Below waist insertion/oral sex/masturbation',
        label: 'Below waist insertion/oral sex/masturbation',
      },
    ],
  },
  {
    id: 'publication_date',
    label: 'DATE ADDED',
    type: 'radio',
    values: [
      {
        id: 'anytime',
        label: 'Anytime',
      },
      {
        id: 'day',
        label: 'Last Day',
      },
      {
        id: 'week',
        label: 'Last 7 Days',
      },
      {
        id: 'month',
        label: 'Last 30 Days',
      },
    ],
  },
];
