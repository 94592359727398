import React from 'react';

import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStepper = styled(Stepper)(() => ({
  '&.MuiStepper-root': {
    '& .MuiSvgIcon-root': {
      fontSize: '1.375em',
    },
  },
}));

const steps = [
  {
    label: 'Your Phone Number',
    description: 'We’ll call you at the number you select. Your phone number is never revealed.',
  },
  {
    label: 'Select Payment Method',
    description:
      'Choose your payment method or add funds to your account. You’ll pay the per-minute rate on the Flirts listing for as long as you wish to speak.',
  },
  {
    label: 'Click Call Now',
    description: 'After your phone rings and you answer, press 1 and we’ll connect you with your chosen Flirt.',
  },
  {
    label: 'Enjoy Your Call',
    description: 'During your call will be prompted when the remaining talk-time available is 1 minute. You can add more money during your call',
  },
];

const HowItWorks = () => (
  <Box
    sx={{ p: 2 }}
    className="call-flow-aside-box"
  >
    <Paper
      sx={{ p: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ mb: 1.5 }}
      >
        How It Works
      </Typography>
      <CustomStepper
        orientation="vertical"
      >
        {steps.map((step) => (
          <Step
            key={step.label}
            active
            expanded
          >
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </CustomStepper>
    </Paper>
  </Box>
);

export default HowItWorks;
