import React from 'react';
import { PropTypes } from 'prop-types';

import MonthlyTributes from 'app/components/modals/tributes/MonthlyTributes';

import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const MonthlyTributesContainer = ({ advisor }) => {
  const {
    activeTributes,
    publicPackages,
  } = useRecurringTributeManagement({ advisor });

  return (
    <MonthlyTributes
      advisor={advisor}
      activeTributes={activeTributes}
      packages={publicPackages}
    />
  );
};

MonthlyTributesContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default MonthlyTributesContainer;
