import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconVerifyId = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="40px" height="32px" viewBox="0 0 40 32">
    {/* Your SVG Path */}
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-id-verify" transform="translate(0.903226, 1.000000)">
        <rect id="Rectangle" fill="#682b7f" x="0" y="4" width="36" height="27" rx="2" />
        <path d="M4.09677419,26 C4.21454299,25.6087283 4.30906525,25.232847 4.43661397,24.8819752 C4.95372139,23.4594718 5.73401646,22.4050804 6.68574193,21.6050008 C7.78385913,20.681872 8.97716424,20.1996636 10.2409258,20.1327005 C11.0153837,20.0917088 11.7963956,20.0817938 12.5673203,20.1777619 C14.1671326,20.3769494 15.6122581,21.1808765 16.8251231,22.7426336 C17.4785351,23.5840009 17.9807934,24.5844519 18.2626705,25.796225 C18.2734745,25.8428402 18.2836128,25.8899733 18.2919078,25.9376984 C18.2948265,25.9544947 18.2911398,25.9737327 18.2901669,26 L4.09677419,26 Z" id="Fill-1" fill="#FFFFFF" />
        <path d="M15.3076076,13.9326278 C15.3084064,16.6409707 13.4705421,18.8552666 11.2220101,18.8549706 C8.96001854,18.8546746 7.12700952,16.6486659 7.1262103,13.9258205 C7.12541159,11.2011992 8.95473308,8.99977802 11.2194288,9 C13.4824651,9.00029597 15.3068084,11.2014952 15.3076076,13.9326278" id="Fill-3" fill="#FFFFFF" />
        <rect id="Rectangle" fill="#FFFFFF" x="21.0967742" y="20" width="10" height="2" />
        <rect id="Rectangle" fill="#FFFFFF" x="21.0967742" y="24" width="10" height="2" />
        <circle id="Oval" stroke="#682b7f" strokeWidth="2" fill="#FFFFFF" cx="29.5967742" cy="8.5" r="8.5" />
        <path d="M24.4967742,8.80343899 C24.7978921,8.50493705 25.1012768,8.2088478 25.3993434,7.90721832 C25.631546,7.67229494 25.8571229,7.43053563 26.1070229,7.16951957 C26.8795639,7.91865621 27.6403357,8.65644432 28.4203742,9.41286368 C30.0594793,7.69132829 31.6894306,5.97944361 33.3361203,4.25 C33.8804255,4.80339694 34.4193691,5.35134301 34.9583127,5.89928907 L34.9583127,5.9439683 C34.8983768,5.99445583 34.8332537,6.03976057 34.7792024,6.09614576 C32.7133101,8.25133775 30.6483332,10.407468 28.5843152,12.5645365 C28.5343614,12.6167219 28.4965691,12.681194 28.4456562,12.75 C28.3529844,12.6630989 28.2939639,12.6105561 28.2379075,12.5548411 C27.0339152,11.358108 25.8303152,10.1609727 24.6262793,8.96428421 C24.5851306,8.92340272 24.5400152,8.88676575 24.4967742,8.84811822 C24.4967742,8.83324003 24.4967742,8.81831717 24.4967742,8.80343899" id="Fill-1" fill="#682b7f" />
      </g>
    </g>
  </SvgIcon>
);

export default IconVerifyId;
