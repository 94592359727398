import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

// lame workaround for autofill conflicting with the floating label,
// adapted with some small changes from
// https://stackoverflow.com/questions/76830737/chrome-autofill-causes-textbox-collision-for-textfield-label-and-value
const AutofillableTextField = ({ name, value, setValue, ...props }) => {
  const [fieldHasValue, setFieldHasValue] = useState(false);

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') stateSetter(autofilled);
    if (e.animationName === 'mui-auto-fill-cancel') stateSetter(autofilled);
  };

  const defaultProps = {
    size: 'small',
    variant: 'outlined',
    id: `outlined-basic-${name}`,
    onChange: (e) => { setValue(e.target.value); },
    inputProps: { onAnimationStart: makeAnimationStartHandler(setFieldHasValue) },
    InputLabelProps: { shrink: !!(fieldHasValue || value) },
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField {...defaultProps} {...props} />;
};

AutofillableTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func,
  props: PropTypes.object,
};

AutofillableTextField.defaultProps = {
  value: '',
  setValue: () => {},
  props: {},
};

export default AutofillableTextField;
