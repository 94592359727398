import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const PackageConfirmationsDialog = ({
  packageId,
  dialogTitle,
  dialogContent,
  closeDialog,
  submitButtonLabel,
  submitAction,
  cancelButtonLabel,
  cancelAction,
}) => {
  return (
    <>
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {dialogContent}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={cancelAction}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          variant="outlined"
          onClick={() => submitAction(packageId)}
        >
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
};

PackageConfirmationsDialog.propTypes = {
  packageId: PropTypes.number.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  dialogContent: PropTypes.string.isRequired,
};

export default PackageConfirmationsDialog;
