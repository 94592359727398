import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PackageTooltip from 'app/components/tooltips/PackageTooltip';
import PerkList from 'app/components/tributes/PerkList';
import { timestampInTimezone } from 'app/helpers/timestampHelpers';

const PackageManagementCard = ({ packageData, currentUser }) => {
  return (
    <Box className="panel2" my={2} py={2}>
      <Grid container alignItems="center">
        <Grid data-test-id="package-rate" item xs={6}>
          <Box sx={{ width: '150px' }}>
            <Box sx={{ display: 'inline', fontSize: '1.313em' }}>
              {packageData.rate}
            </Box>
            {' '}
            a month
          </Box>
        </Grid>
        <Grid data-test-id="deleted-date" item xs={6} sx={{ textAlign: 'end' }}>
          {packageData.status === 'pending_onboarding' && (
            <Link to={`/feed/${currentUser?.login}/onboarding`}>
              Set Up Feed
            </Link>
          )}
          {packageData.status === 'retired_package' && (
            `Deleted ${timestampInTimezone(
              packageData.retiredAt,
              currentUser?.canonical_iana_timezone,
              'L/d/yyyy',
            )}`
          )}
          {(packageData.status === 'public_package' ||
            packageData.status === 'private_package') && (
              <PackageTooltip packageData={packageData} />
            )}
        </Grid>
        <PerkList packageData={packageData} />
        <Grid item xs={12}>
          <div className="package-id-text help-text">
            Package ID:
            {' '}
            {packageData.id}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

PackageManagementCard.defaultProps = {
  currentUser: null,
};

PackageManagementCard.propTypes = {
  packageData: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default PackageManagementCard;
