import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const PackagePerksPromptDialog = ({
  closeDialog,
  createOnboardingPackage,
  currentUser,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <DialogTitle>
        Would you like to add Perks?
      </DialogTitle>
      <CloseDialogButton
        closeDialog={() => {
          closeDialog();
          navigate(`/feed/${currentUser.login}/onboarding?no_perks=true`);
        }}
      />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              You can add Perks, such as free call minutes, to your Feed subscription. We call this a Package. Would you like to try it out?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            closeDialog();
            createOnboardingPackage().then(navigate(`/feed/${currentUser.login}/onboarding?no_perks=true`));
          }}
        >
          No Thanks
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            closeDialog();
            navigate('/feed/packages/new?onboarding=true');
          }}
        >
          Add Perks
        </Button>
      </DialogActions>
    </>
  );
};

PackagePerksPromptDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  createOnboardingPackage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default PackagePerksPromptDialog;
