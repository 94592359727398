import React, { useContext } from 'react';

import { useGetItemsQuery } from 'app/api/assetApi';

import usePaginatedMediaItems from 'app/hooks/usePaginatedMediaItems';

import FileManagerAlbumSelect from './FileManagerAlbumSelect';
import ItemsPageContainer from 'app/containers/posts/postMedia/ItemsPageContainer';
import AddMediaContext from 'app/contexts/AddMediaContext';

const FileManagerTab = () => {
  const {
    albumsQuery,
    selectedAlbum,
    setSelectedAlbum,
    profileImageMode,
  } = useContext(AddMediaContext);

  const {
    cursors,
    setOldestCursor,
  } = usePaginatedMediaItems({
    albumId: selectedAlbum?.value,
  });

  const itemPages = cursors.map((cursor) => {
    return (
      <ItemsPageContainer
        key={cursor}
        setOldestCursor={setOldestCursor}
        itemsQuery={() => {
          return useGetItemsQuery(
            {
              albumId: selectedAlbum?.value,
              cursor,
              imagesOnly: profileImageMode,
            },
            { skip: !selectedAlbum },
          );
        }}
      />
    );
  });

  return (
    <>
      <div className="container-fm-folders">
        <div className="row middle-xs">
          <div className="col-sm-2 start-sm col-xs-4 center-xs">Folders:</div>
          <div className="col-sm-5 col-xs-8">
            <FileManagerAlbumSelect
              albumsQuery={albumsQuery}
              value={selectedAlbum}
              onChange={setSelectedAlbum}
            />
          </div>
        </div>
      </div>
      <div id="media-items" className="container-fluid fm-scrolling1">
        <div>
          {itemPages && (
            <>
              <div className="row nowrap-xs middle-xs fm-row-sticky1">
                <div className="col-xs-2" style={{ maxWidth: '45px' }} />
                <div className="col-xs-4 start-xs">
                  Preview
                </div>
                <div className="col-xs-6 start-xs">
                  Name
                </div>
              </div>
              {itemPages}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileManagerTab;
