import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showFlash } from 'app/slices/flashSlice';
import { resetFlirtFeed } from 'app/slices/resetRecordsSlice';
import { useTogglePostPinMutation } from 'app/api/feedsApi';

const usePinPost = (post) => {
  const dispatch = useDispatch();

  const [
    performPinPost,
    {
      isSuccess: isPinSuccess,
      isError: isPinError,
    },
  ] = useTogglePostPinMutation();

  useEffect(() => {
    if (isPinSuccess) {
      const message = post.pinned ?
        'This post is no longer pinned.' :
        'This post is now pinned to the top of your feed.';

      dispatch(showFlash({
        flashType: 'success',
        props: { message },
      }));

      dispatch(resetFlirtFeed({ advisorId: post.main_user_id }));
    } else if (isPinError) {
      const message = post.pinned ?
        'Error while trying to unpin the post. Try again.' :
        'Error trying to pin the post. Try again.';

      dispatch(showFlash({
        flashType: 'error',
        props: { message },
      }));
    }
  }, [isPinSuccess, isPinError]);

  return {
    pinPost: performPinPost,
  };
};

export default usePinPost;
