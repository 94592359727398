import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const bang = () => savedCallback.current();
    const id = setInterval(bang, delay);

    return () => clearInterval(id);
  }, []);
};

export default useInterval;
