import React from 'react';
import { PropTypes } from 'prop-types';

import AdvisorCenterNav from './AdvisorCenterNav';
import CategoryListing from './CategoryListing';
import { useGetAdvisorQuery } from 'app/api/mainApi';
import useProfileImages from 'app/hooks/useProfileImages';
import { featureFlag } from 'app/util/featureFlag';
import AdvisorAvailabilityMenuContainer from 'app/containers/AdvisorAvailabilityMenuContainer';
import UnreadMailBadge from 'app/components/shared/UnreadMailBadge';
import useSignOut from 'app/hooks/useSignOut';

const TransientAdvisorNav = ({
  currentUser,
  navigateTo,
  addMoneyUrl,
  balance,
}) => {
  const { data: advisor } = useGetAdvisorQuery({ login: currentUser.login });
  const { acceptedAvatarImage: avatarImage } = useProfileImages(advisor);
  const display = { display: 'block' };
  const isAvailabilityEnabled = featureFlag.enabled('AVAILABILITY_19613');
  const { signOut } = useSignOut();

  return (
    <div className="more-menu more-menu-in-nav" style={{ display }}>
      <ul className="nav-top paddingtop0">
        <li>
          <div
            className="menu-initials menu-avatar"
            style={{ backgroundImage: `url('${avatarImage}')` }}
          >
            &nbsp;
          </div>
        </li>
        <li className="margintopminus10">
          <a
            data-test-id="menu-user-link"
            title={currentUser?.login}
            role="link"
            tabIndex={0}
            className="bluelink"
            onClick={() => {
              navigateTo(`/${currentUser?.login}`, true);
            }}
          >
            {currentUser?.login}
          </a>
        </li>
        {isAvailabilityEnabled && (
          <li>
            <AdvisorAvailabilityMenuContainer />
          </li>
        )}
        <li className="delin subhead marginbot0" />
        <li>
          <div
            className="small-text"
            style={{ fontColor: '#999', fontSize: '0.875em' }}
          >
            Balance:&nbsp;
            <span>
              $
              {balance}
            </span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              title="Transactions"
              role="link"
              tabIndex={0}
              className="bluelink"
              onClick={() => {
                navigateTo('/transactions/details', false);
              }}
            >
              Transactions
            </a>
          </div>
        </li>
        <li className="delin subhead marginbot0" />
        <li>
          <UnreadMailBadge />
        </li>
        <li>
          <a
            title="Featured Listings"
            role="link"
            tabIndex={0}
            onClick={() => {
              navigateTo('/account/featured_listing_results/listings', true);
            }}
          >
            <img src="/plus_frontend/assets/svg/ico-featuredlistings.svg" alt="Featured Listings" className="nav-icon" />
            <span className="nav-text">Featured Listings</span>
          </a>
        </li>
        <li>
          <a
            title="Goodies"
            role="link"
            tabIndex={0}
            onClick={() => {
              navigateTo('/account/filemanager#tab-goody', true);
            }}
          >
            <img src="/plus_frontend/assets/svg/ico-goodies2.svg" alt="Goodies" className="nav-icon" />
            <span className="nav-text">Goodies</span>
          </a>
        </li>
        <li>
          <a
            title="Customers"
            role="link"
            tabIndex={0}
            onClick={() => {
              navigateTo('/my_customers', true);
            }}
          >
            <img src="/plus_frontend/assets/svg/ico-customers.svg" alt="Customers" className="nav-icon" />
            <span className="nav-text">Customers</span>
          </a>
        </li>
        <li>
          <a
            title="File Manager"
            role="link"
            tabIndex={0}
            onClick={() => {
              navigateTo('/account/filemanager#tab-library', true);
            }}
          >
            <img src="/plus_frontend/assets/svg/ico-filemanager.svg" alt="File Manger" className="nav-icon" />
            <span className="nav-text">File Manager</span>
          </a>
        </li>
        <li className="delin subhead marginbot0" />
        <AdvisorCenterNav
          balance={balance}
          closeAndNavigateTo={navigateTo}
          currentUser={currentUser}
          addMoneyUrl={addMoneyUrl}
        />
        <li className="delin">
          <CategoryListing closeAndNavigateTo={navigateTo} />
        </li>
        <li className="delin">
          <a
            tabIndex={0}
            title="Help & Support"
            href="//support.niteflirt.com/hc/en-us"
          >
            <img
              src="/plus_frontend/assets/svg/ico-help1.svg"
              alt="Help & Support"
              className="nav-icon"
            />
            <span className="nav-text">Help & Support</span>
          </a>
        </li>

        <li>
          <a
            tabIndex={0}
            title="About"
            href="/help/about"
          >
            <img
              src="/plus_frontend/assets/svg/ico-nf.svg"
              alt="About"
              className="nav-icon"
            />
            <span className="nav-text">About</span>
          </a>
        </li>

        {currentUser && (
          <>
            <li className="delin subhead marginbot0" />
            <li>
              <a
                title="Sign Out"
                onClick={signOut}
                role="link"
                tabIndex={0}
              >
                <img src="/plus_frontend/assets/svg/ico-logout.svg" alt="Sign Out" className="nav-icon" />
                <span className="nav-text">Sign Out</span>
              </a>
            </li>
          </>
        )}
      </ul>
      <div className="more-menu-bottom-padding" />
    </div>
  );
};

TransientAdvisorNav.defaultProps = {
  currentUser: null,
  balance: null,
};

TransientAdvisorNav.propTypes = {
  currentUser: PropTypes.object,
  navigateTo: PropTypes.func.isRequired,
  addMoneyUrl: PropTypes.string.isRequired,
  balance: PropTypes.string,
};

export default TransientAdvisorNav;
