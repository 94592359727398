import React from 'react';
import PropTypes from 'prop-types';

import PhoneShape from 'app/shapes/PhoneShape';

import {
  Box,
  Typography,
} from '@mui/material';

const NeedsPhoneError = ({
  phones,
  isLoading,
}) => {
  if (isLoading) {
    return null;
  }

  const hasNoPhones = phones && phones.length === 0;

  if (hasNoPhones) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 350, margin: 'auto', mt: 3 }}>
        <Typography variant="error">
          Please add a phone number to your account.
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

NeedsPhoneError.defaultProps = {
  phones: null,
};

NeedsPhoneError.propTypes = {
  phones: PropTypes.arrayOf(PhoneShape),
  isLoading: PropTypes.bool.isRequired,
};

export default NeedsPhoneError;
