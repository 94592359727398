import React from 'react';
import Grid from '@mui/material/Grid';

const ImageNotPublicPreviewable = ({ itemCount }) => {
  return (
    <div>
      <div className="slick-blur">&nbsp;</div>
      <div className="slick-blur2" />
      <div className="slick-item-bg">
        <Grid
          container
          rowSpacing={0}
          columnSpacing={0}
          direction="column"
          style={{ color: 'white' }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            xs={12}
            item
          >
            Too hot to display publicly! Log in to see this preview.
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ImageNotPublicPreviewable;
