export const followButtonSrc = (follow) => {
  if (follow && follow.active) {
    return '/plus_frontend/assets/svg/btn-following.svg';
  }

  return '/plus_frontend/assets/svg/btn-follow.svg';
};

export const profileFollowButtonSrc = (follow) => {
  if (follow && follow.active) {
    return '/plus_frontend/assets/svg/btn-following2.svg';
  }

  return '/plus_frontend/assets/svg/btn-follow2.svg';
};
