import React from 'react';
import { PropTypes } from 'prop-types';

import { useGetPostLikesQuery } from 'app/api/feedsApi';

const PostLikesModalContainer = ({ post }) => {
  const { data: likes, isFetching } = useGetPostLikesQuery(
    { postId: post?.id },
    { skip: !post },
  );

  return (
    <>
      <div className="modal-header">
        <div className="container-fluid padding-0-8">
          <div className="row middle-xs">
            <div className="marginrgt10">
              <img src="/plus_frontend/assets/svg/ico-favorite.svg" className="modal-icon" alt="Likes" />
            </div>
            <div>
              <h4>
                {likes?.likes.length}
                &nbsp;
                {likes?.likes.length > 1 ? 'Likes' : 'Like'}
              </h4>
            </div>
          </div>
        </div>
      </div>
      {isFetching && <p>Loading...</p>}
      {!isFetching && likes?.likes.length === 0 && <p>No likes yet</p>}
      {!isFetching && likes?.likes.length > 0 && (
        <ul
          className="modal-scrollable"
        >
          {likes?.likes.map((like) => (
            <li style={{ listStyle: 'none' }} key={like[0]}>
              {like[2]}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

PostLikesModalContainer.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostLikesModalContainer;
