import React from 'react';
import { PropTypes } from 'prop-types';
import SubscribeModal from 'app/components/modals/subscriptions/SubscribeModal';
import { useGetSubscriptionQuery } from 'app/api/mainApi';
import { useSelector } from 'react-redux';

const SubscribeModalContainer = ({
  closeModal,
  setDenyCloseModalRequests,
  openManageCardsModal,
  clickAction,
  advisor,
}) => {
  const subData = useGetSubscriptionQuery({ advisorId: advisor?.id }, { skip: !advisor })?.data;
  const offering = subData?.available_offerings?.[0];
  const { loading } = useSelector((state) => state.subscription);

  // intercept the click action, and call it with
  // data we're confident is fresh, and don't use
  // a function closure anymore
  const localClickAction = () => {
    setDenyCloseModalRequests(true);
    clickAction({ advisorId: advisor.id, offering });
    setDenyCloseModalRequests(false);
  };

  return (
    <SubscribeModal
      offering={offering}
      clickAction={localClickAction}
      openManageCardsModal={openManageCardsModal}
      closeModal={closeModal}
      pendingSubRequest={loading}
    />
  );
};

SubscribeModalContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
  setDenyCloseModalRequests: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openManageCardsModal: PropTypes.func.isRequired,
  clickAction: PropTypes.func.isRequired,
};

export default SubscribeModalContainer;
