import { useEffect } from 'react';

import useSignOut from 'app/hooks/useSignOut';

const SignOutLauncher = ({ type }) => {
  const { signOut } = useSignOut();

  useEffect(() => {
    signOut(type);
  }, []);

  return null;
};

export default SignOutLauncher;
