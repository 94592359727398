import React from 'react';
import { PropTypes } from 'prop-types';

const UnfollowConfirmModal = ({ closeModal, doUnfollow }) => {
  return (
    <>
      <div className="modal-header">
        <h4>Stop Following This Flirt?</h4>
      </div>
      <div className="modal-body">
        <p>
          Are you sure? You won&apos;t be able to comment or like their posts without following them.
        </p>
      </div>
      <div className="modal-footer">
        <a role="link" tabIndex={0} className="btn secondary" onClick={closeModal}>
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            doUnfollow();
            closeModal();
          }}
        >
          Unfollow
        </button>
      </div>
    </>
  );
};

UnfollowConfirmModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  doUnfollow: PropTypes.func.isRequired,
};

export default UnfollowConfirmModal;
