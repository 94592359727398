import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import PhoneListingCard from 'app/components/PhoneListingCard';
import DeprecatedPhoneListingCard from 'app/redesign/components/DeprecatedPhoneListingCard';

const PhoneListingContainer = ({ listing, headingSource }) => {
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  if (newCardsEnabled) {
    return <PhoneListingCard listing={listing} headingSource={headingSource} />;
  }

  return (
    <DeprecatedPhoneListingCard
      listing={listing}
      headingSource={headingSource}
    />
  );
};

PhoneListingContainer.defaultProps = {
  headingSource: '',
};

PhoneListingContainer.propTypes = {
  headingSource: PropTypes.string,
  listing: PropTypes.object.isRequired,
};

export default PhoneListingContainer;
