import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';

const FileUploadProgress = ({
  file,
  onRemoveClick,
  updateIndividualFileData,
  uploadInProgress,
}) => {
  const [imageURI, setImageURI] = useState();
  const fileReader = new FileReader();
  const [percentUploaded, setPercentUploaded] = useState(0);

  fileReader.onload = (e) => {
    if (e.target.result.indexOf('data:video') !== -1) {
      setImageURI('/plus_frontend/assets/ico-fm-video.png');
    } else if (e.target.result.indexOf('data:audio') !== -1) {
      setImageURI('/plus_frontend/assets/ico-fm-audio.png');
    } else {
      setImageURI(e.target.result);
    }
  };

  useEffect(() => {
    const percUploaded = (file.bytesLoaded / file.bytesTotal) * 100;
    setPercentUploaded(percUploaded);

    if (!file.previewRendered) {
      fileReader.readAsDataURL(file.file);

      updateIndividualFileData({
        fileName: file.file.name,
        key: 'previewRendered',
        value: true,
      });
    }
  }, [file]);

  return (
    <div key={file.file.name} className="row nowrap-xs middle-xs fm-row-item">
      <div className="col-xs-1 max10" />
      <div className="col-xs-4 center-xs">
        <div
          className="fm-thumb"
          style={{ backgroundImage: `url('${imageURI}')` }}
          tabIndex={0}
          role="button"
          onClick={onRemoveClick}
          data-filename={file.file.name}
        >
          {!uploadInProgress && (
            <img src="/plus_frontend/assets/svg/ico-remove.svg" alt="Remove" className="ico-remove" />
          )}
        </div>
      </div>
      <div className="col-xs-7 fm-filename">
        {file.file.name}
        <div className="row margintop10">
          <div className="col-xs-12 start-xs" style={{ maxWidth: '250px' }}>
            <ProgressBar
              completed={percentUploaded}
              isLabelVisible={false}
              bgColor="#62c462"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FileUploadProgress.propTypes = {
  file: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  updateIndividualFileData: PropTypes.func.isRequired,
  uploadInProgress: PropTypes.bool.isRequired,
};

export default FileUploadProgress;
