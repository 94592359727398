import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import PackageManagement from 'app/components/tributes/PackageManagement';
import AdvisorPackagesContext from 'app/contexts/AdvisorPackagesContext';

import {
  useGetMyPackagesQuery,
  useUpdatePackageMutation,
  useDestroyPackageMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import DialogsContext from 'app/contexts/DialogsContext';
import PackageConfirmationsDialog from 'app/components/packages/PackageConfirmationsDialog';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const PackageManagementContainer = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const { data: packages, isLoading } = useGetMyPackagesQuery();
  const { openDialog, closeDialog } = useContext(DialogsContext);
  const dispatch = useDispatch();

  const [updatePackage, {
    status: updatePackageStatus,
    error: updatePackageError,
    data: updatePackageData,
  }] = useUpdatePackageMutation();

  const [destroyPackage, {
    status: destroyPackageStatus,
    error: destroyPackageError,
  }] = useDestroyPackageMutation();

  const makePackagePublic = (id) => {
    closeDialog();
    updatePackage({ id, status: 'public_package' });
  };

  const makePackagePrivate = (id) => {
    closeDialog();
    updatePackage({ id, status: 'private_package' });
  };

  const executeDestroyPackage = (id) => {
    closeDialog();
    destroyPackage({ id });
  };

  const confirmChangeToPublic = (id) => {
    openDialog({
      component: PackageConfirmationsDialog,
      props: {
        packageId: id,
        dialogTitle: 'Are you sure you want to make this Package public?',
        dialogContent: 'Public packages are shown on your profile and can accept new subscribers.',
        closeDialog,
        submitButtonLabel: 'Make Public',
        submitAction: makePackagePublic,
        cancelButtonLabel: 'Keep Private',
        cancelAction: closeDialog,
      },
    });
  };

  const confirmChangeToPrivate = (id) => {
    openDialog({
      component: PackageConfirmationsDialog,
      props: {
        packageId: id,
        dialogTitle: 'Are you sure you want to make this Package private?',
        dialogContent: 'Private Packages are hidden on your profile. You can make it public anytime. Feel free to share the private Package link with specific customers.\n\nCurrent subscribers of the private Package will continue to receive the perks and be billed.',
        closeDialog,
        submitButtonLabel: 'Make Private',
        submitAction: makePackagePrivate,
        cancelButtonLabel: 'Keep Public',
        cancelAction: closeDialog,
      },
    });
  };

  const packageStatusHandler = (packageData) => {
    if (packageData.status === 'public_package') {
      confirmChangeToPrivate(packageData.id);
    } else {
      confirmChangeToPublic(packageData.id);
    }
  };

  const seeCustomerListHandler = () => {
    console.log('seeCustomerListHandler');
  };

  const copyPackageLinkHandler = (packageId, login) => {
    if (!login) return false;

    navigator.clipboard.writeText(`https://${window.location.host}/${login}/packages/${packageId}`);

    return dispatch(showSnackbarMessage({
      message: 'Package link copied.',
      severity: 'info',
    }));
  };

  const deletePackageHandler = (id) => {
    openDialog({
      component: PackageConfirmationsDialog,
      props: {
        packageId: id,
        dialogTitle: 'Are you sure you want to delete this Package?',
        dialogContent: 'All your subscribers will be cancelled at the end of their billing cycle.\n\nYou can\'t undo this.\n\nConsider making your package private. Private packages are hidden on your profile and maintain their current subscribers and perks.',
        closeDialog,
        submitButtonLabel: 'Delete Package',
        submitAction: executeDestroyPackage,
        cancelButtonLabel: 'Keep Package',
        cancelAction: closeDialog,
      },
    });
  };

  useEffect(() => {
    if (updatePackageError) {
      dispatch(showSnackbarMessage({
        message: updatePackageError.data.error,
        severity: 'error',
      }));
    }
  }, [updatePackageError]);

  useEffect(() => {
    const newStatus = updatePackageData?.status === 'public_package' ? 'public' : 'private';

    if (updatePackageStatus === 'fulfilled') {
      dispatch(showSnackbarMessage({
        message: `Your package is now ${newStatus}.`,
        severity: 'success',
      }));
    }
  }, [updatePackageData, updatePackageStatus]);

  useEffect(() => {
    if (destroyPackageError) {
      dispatch(showSnackbarMessage({
        message: destroyPackageError.data.error,
        severity: 'error',
      }));
    }
  }, [destroyPackageError]);

  useEffect(() => {
    if (destroyPackageStatus === 'success') {
      dispatch(showSnackbarMessage({
        message: 'Your package has been deleted.',
        severity: 'success',
      }));
    }
  }, [destroyPackageStatus]);

  return (
    <AdvisorPackagesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        seeCustomerListHandler,
        copyPackageLinkHandler,
        packageStatusHandler,
        deletePackageHandler,
      }}
    >
      <AppBodyWithSidebar>
        <PackageManagement
          packages={packages}
          isLoading={isLoading}
          currentUser={currentUser}
        />
      </AppBodyWithSidebar>
    </AdvisorPackagesContext.Provider>
  );
};

export default PackageManagementContainer;
