import { DateTime } from 'luxon';

// lets just use functions and not access the timezones directly
const timeZones =
  [
    ['International Date Line West', 'Etc/GMT+12'],
    ['American Samoa', 'Pacific/Pago_Pago'],
    ['Midway Island', 'Pacific/Midway'],
    ['Hawaii', 'Pacific/Honolulu'],
    ['Alaska', 'America/Juneau'],
    ['Pacific Time (US & Canada)', 'America/Los_Angeles'],
    ['Tijuana', 'America/Tijuana'],
    ['Arizona', 'America/Phoenix'],
    ['Mazatlan', 'America/Mazatlan'],
    ['Mountain Time (US & Canada)', 'America/Denver'],
    ['Central America', 'America/Guatemala'],
    ['Central Time (US & Canada)', 'America/Chicago'],
    ['Chihuahua', 'America/Chihuahua'],
    ['Guadalajara', 'America/Mexico_City'],
    ['Monterrey', 'America/Monterrey'],
    ['Saskatchewan', 'America/Regina'],
    ['Bogota', 'America/Bogota'],
    ['Eastern Time (US & Canada)', 'America/New_York'],
    ['Indiana (East)', 'America/Indiana/Indianapolis'],
    ['Lima', 'America/Lima'],
    ['Atlantic Time (Canada)', 'America/Halifax'],
    ['Caracas', 'America/Caracas'],
    ['Georgetown', 'America/Guyana'],
    ['La Paz', 'America/La_Paz'],
    ['Puerto Rico', 'America/Puerto_Rico'],
    ['Santiago', 'America/Santiago'],
    ['Newfoundland', 'America/St_Johns'],
    ['Brasilia', 'America/Sao_Paulo'],
    ['Buenos Aires', 'America/Argentina/Buenos_Aires'],
    ['Montevideo', 'America/Montevideo'],
    ['Greenland', 'America/Godthab'],
    ['Mid-Atlantic', 'Atlantic/South_Georgia'],
    ['Azores', 'Atlantic/Azores'],
    ['Cape Verde Is.', 'Atlantic/Cape_Verde'],
    ['Casablanca', 'Africa/Casablanca'],
    ['Dublin', 'Europe/Dublin'],
    ['Edinburgh', 'Europe/London'],
    ['Lisbon', 'Europe/Lisbon'],
    ['Monrovia', 'Africa/Monrovia'],
    ['UTC', 'Etc/UTC'],
    ['Amsterdam', 'Europe/Amsterdam'],
    ['Belgrade', 'Europe/Belgrade'],
    ['Berlin', 'Europe/Berlin'],
    ['Bern', 'Europe/Zurich'],
    ['Bratislava', 'Europe/Bratislava'],
    ['Brussels', 'Europe/Brussels'],
    ['Budapest', 'Europe/Budapest'],
    ['Copenhagen', 'Europe/Copenhagen'],
    ['Ljubljana', 'Europe/Ljubljana'],
    ['Madrid', 'Europe/Madrid'],
    ['Paris', 'Europe/Paris'],
    ['Prague', 'Europe/Prague'],
    ['Rome', 'Europe/Rome'],
    ['Sarajevo', 'Europe/Sarajevo'],
    ['Skopje', 'Europe/Skopje'],
    ['Stockholm', 'Europe/Stockholm'],
    ['Vienna', 'Europe/Vienna'],
    ['Warsaw', 'Europe/Warsaw'],
    ['West Central Africa', 'Africa/Algiers'],
    ['Zagreb', 'Europe/Zagreb'],
    ['Athens', 'Europe/Athens'],
    ['Bucharest', 'Europe/Bucharest'],
    ['Cairo', 'Africa/Cairo'],
    ['Harare', 'Africa/Harare'],
    ['Helsinki', 'Europe/Helsinki'],
    ['Jerusalem', 'Asia/Jerusalem'],
    ['Kaliningrad', 'Europe/Kaliningrad'],
    ['Kyiv', 'Europe/Kiev'],
    ['Pretoria', 'Africa/Johannesburg'],
    ['Riga', 'Europe/Riga'],
    ['Sofia', 'Europe/Sofia'],
    ['Tallinn', 'Europe/Tallinn'],
    ['Vilnius', 'Europe/Vilnius'],
    ['Baghdad', 'Asia/Baghdad'],
    ['Istanbul', 'Europe/Istanbul'],
    ['Kuwait', 'Asia/Kuwait'],
    ['Minsk', 'Europe/Minsk'],
    ['Moscow', 'Europe/Moscow'],
    ['Nairobi', 'Africa/Nairobi'],
    ['Riyadh', 'Asia/Riyadh'],
    ['Volgograd', 'Europe/Volgograd'],
    ['Tehran', 'Asia/Tehran'],
    ['Abu Dhabi', 'Asia/Muscat'],
    ['Baku', 'Asia/Baku'],
    ['Samara', 'Europe/Samara'],
    ['Tbilisi', 'Asia/Tbilisi'],
    ['Yerevan', 'Asia/Yerevan'],
    ['Kabul', 'Asia/Kabul'],
    ['Almaty', 'Asia/Almaty'],
    ['Ekaterinburg', 'Asia/Yekaterinburg'],
    ['Islamabad', 'Asia/Karachi'],
    ['Tashkent', 'Asia/Tashkent'],
    ['Chennai', 'Asia/Kolkata'],
    ['Sri Jayawardenepura', 'Asia/Colombo'],
    ['Kathmandu', 'Asia/Kathmandu'],
    ['Astana', 'Asia/Dhaka'],
    ['Urumqi', 'Asia/Urumqi'],
    ['Rangoon', 'Asia/Rangoon'],
    ['Bangkok', 'Asia/Bangkok'],
    ['Jakarta', 'Asia/Jakarta'],
    ['Krasnoyarsk', 'Asia/Krasnoyarsk'],
    ['Novosibirsk', 'Asia/Novosibirsk'],
    ['Beijing', 'Asia/Shanghai'],
    ['Chongqing', 'Asia/Chongqing'],
    ['Hong Kong', 'Asia/Hong_Kong'],
    ['Irkutsk', 'Asia/Irkutsk'],
    ['Kuala Lumpur', 'Asia/Kuala_Lumpur'],
    ['Perth', 'Australia/Perth'],
    ['Singapore', 'Asia/Singapore'],
    ['Taipei', 'Asia/Taipei'],
    ['Ulaanbaatar', 'Asia/Ulaanbaatar'],
    ['Osaka', 'Asia/Tokyo'],
    ['Seoul', 'Asia/Seoul'],
    ['Yakutsk', 'Asia/Yakutsk'],
    ['Adelaide', 'Australia/Adelaide'],
    ['Darwin', 'Australia/Darwin'],
    ['Brisbane', 'Australia/Brisbane'],
    ['Canberra', 'Australia/Melbourne'],
    ['Guam', 'Pacific/Guam'],
    ['Hobart', 'Australia/Hobart'],
    ['Port Moresby', 'Pacific/Port_Moresby'],
    ['Sydney', 'Australia/Sydney'],
    ['Vladivostok', 'Asia/Vladivostok'],
    ['Magadan', 'Asia/Magadan'],
    ['New Caledonia', 'Pacific/Noumea'],
    ['Solomon Is.', 'Pacific/Guadalcanal'],
    ['Srednekolymsk', 'Asia/Srednekolymsk'],
    ['Auckland', 'Pacific/Auckland'],
    ['Fiji', 'Pacific/Fiji'],
    ['Kamchatka', 'Asia/Kamchatka'],
    ['Marshall Is.', 'Pacific/Majuro'],
    ['Chatham Is.', 'Pacific/Chatham'],
    ['Nuku\'alofa', 'Pacific/Tongatapu'],
    ['Samoa', 'Pacific/Apia'],
    ['Tokelau Is.', 'Pacific/Fakaofo'],
  ];

// Function to find timezone value by label
export const findTimeZoneValueByLabel = (label) => {
  return timeZones.find(([currentLabel]) => currentLabel === label)?.[1];
};

// Function to find timezone label by value
export const findTimeZoneLabelByValue = (value) => {
  return timeZones.find(([, currentValue]) => currentValue === value)?.[0];
};

// Function to get time zones with formatted offsets
export const getTimeZonesWithFormattedOffsets = () => {
  return timeZones.map(([label, canonicalName]) => {
    const { offset } = DateTime.now().setZone(canonicalName); // Get offset in minutes
    const hours = Math.floor(Math.abs(offset) / 60).toString().padStart(2, '0');
    const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
    const sign = offset >= 0 ? '+' : '-';
    const formattedOffset = `${sign}${hours}:${minutes}`;
    return {
      label: `(GMT${formattedOffset}) ${label}`,
      value: canonicalName,
    };
  });
};
