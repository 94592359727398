import React from 'react';
import { PropTypes } from 'prop-types';
import { useMatch } from 'react-router';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import QueuedPost from 'app/components/QueuedPost';
import usePostMenuActions from 'app/hooks/usePostMenuActions';

const QueuedPostContainer = ({ post, displayScheduledDate }) => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  const matchInProcessing = useMatch('/feed/queue/*');

  const {
    postActions,
    isScheduled,
  } = usePostMenuActions({
    post,
    currentUser,
    advisor: currentUser,
    showViewPost: matchInProcessing,
  });

  return (
    <QueuedPost
      key={post.id}
      post={post}
      user={currentUser}
      actions={postActions}
      isScheduled={isScheduled}
      ianaTimezone={currentUser.canonical_iana_timezone}
      displayScheduledDate={displayScheduledDate}
    />
  );
};

QueuedPostContainer.propTypes = {
  post: PropTypes.object.isRequired,
  displayScheduledDate: PropTypes.bool,
};

QueuedPostContainer.defaultProps = {
  displayScheduledDate: false,
};

export default QueuedPostContainer;
