import React from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
} from '@mui/material';

import { green } from '@mui/material/colors';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';
import MenuAvailabilityList from 'app/components/availabilityMenu/MenuAvailabilityList';
import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';

const SeekerChatPhoneStatusMenu = ({ phone, chatStatuses }) => {
  const [ updateCurrentChatPhoneStatus ] = useUpdateAvailabilityMutation();

  const handleOnClick = (chatStatus) => {
    if (phone.chat_status === chatStatus) { return; }

    updateCurrentChatPhoneStatus({ availability: { chat_status: chatStatus } });
  };

  return (
    <Paper sx={{ p: 0 }} variant="outlined">
      <MenuAvailabilityList aria-label="chat phone statuses">
        {chatStatuses.map((status, index) => (
          <React.Fragment key={status.value}>
            <ListItemButton
              data-test-id={`${status.value}`}
              onClick={() => { handleOnClick(status.value); }}
            >
              <Stack style={{ width: '100%' }}>
                <ListItemText primary={status.title} />
                {status.title === 'Available - Chat Alerts On' && <ListItemText secondary={phone.secure_number} sx={{ mt: -0.5 }} />}
              </Stack>
              {phone.chat_status === status.value && (
                <CheckMarkNF sx={{ color: green[ 500 ] }} />
              )}
            </ListItemButton>
            {index !== (chatStatuses.length - 1) ? <Divider /> : null}
          </React.Fragment>
        ))}
      </MenuAvailabilityList>
    </Paper>
  );
};

SeekerChatPhoneStatusMenu.defaultProps = {
  phone: {},
  chatStatuses: [],
};

SeekerChatPhoneStatusMenu.propTypes = {
  phone: PhoneShape,
  chatStatuses: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default SeekerChatPhoneStatusMenu;
