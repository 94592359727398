import RoundedPagination from 'app/redesign/components/shared/RoundedPagination';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const BoxPagination = styled(Box)(() => ({
  '&.MuiBox-root': {
    margin: '0 12px', // adjust this as needed
    display: 'flex',
    justifyContent: 'flex-end',
    '& nav.MuiPagination-root': {
      height: 'auto',
      maxWidth: 'calc(100% - 12px)',
      display: 'flex',
      alignSelf: 'flex-end',
      border: '1px solid transparent',
    },
  },
}));
const BoxedPagination = ({ count, onPageChange }) => {
  if (count <= 1) {
    return null;
  }

  return (
    <BoxPagination>
      <RoundedPagination count={count} onPageChange={onPageChange} />
    </BoxPagination>
  );
};

BoxedPagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
export default BoxedPagination;
