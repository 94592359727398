import React from 'react';
import { PropTypes } from 'prop-types';

import { ALL_MEDIA, PHOTO_MEDIA, VIDEO_MEDIA, AUDIO_MEDIA } from 'app/constants/mediaFilterTypes';

const MediaFilters = ({ mediaFilter, setMediaFilterAndResetCursors }) => {
  return (
    <div className="container-summary-tags">
      <div className="row middle-xs start-xs">
        <span
          role="button"
          tabIndex={0}
          onClick={() => { setMediaFilterAndResetCursors(ALL_MEDIA); }}
          className={mediaFilter === ALL_MEDIA ? 'active' : ''}
        >
          All
        </span>
        <span
          role="button"
          tabIndex={0}
          onClick={() => { setMediaFilterAndResetCursors(PHOTO_MEDIA); }}
          className={mediaFilter === PHOTO_MEDIA ? 'active' : ''}
        >
          Photo
        </span>
        <span
          role="button"
          tabIndex={0}
          onClick={() => { setMediaFilterAndResetCursors(VIDEO_MEDIA); }}
          className={mediaFilter === VIDEO_MEDIA ? 'active' : ''}
        >
          Video
        </span>
        <span
          role="button"
          tabIndex={0}
          onClick={() => { setMediaFilterAndResetCursors(AUDIO_MEDIA); }}
          className={mediaFilter === AUDIO_MEDIA ? 'active' : ''}
        >
          Audio
        </span>
      </div>
    </div>
  )
};

MediaFilters.propTypes = {
  mediaFilter: PropTypes.string.isRequired,
  setMediaFilterAndResetCursors: PropTypes.func.isRequired,
};

export default MediaFilters;
