/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';
import { isMobile } from 'react-device-detect';
import Tooltip from 'app/components/shared/Tooltip';

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  TouchSensor,
  closestCenter,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';

import {
  POSTER_BLANK,
  IMAGE_BLANK,
} from 'app/constants/assetPaths';

import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ item, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const defaultImage = item.item_type === 'video' ? POSTER_BLANK : IMAGE_BLANK;

  return (
    <div
      data-id={item.id}
      className="prev-outer"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="row middle-xs">
        <div className="col-xs-9 start-xs padding0">
          <div className="row middle-xs">
            <div className="ico-draggable">
              <img src="/plus_frontend/assets/svg/ico-draggable.svg" alt="Drag to reorder" />
            </div>
            <div className="padding5">
              {index + 1}
            </div>
            <div className="padding1">
              <Tooltip>
                <p><b>Filename:</b></p>
                <p>{item.file_name}</p>
                <p>Media may be subject to further review.</p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="col-xs-3 end-xs padding0">
          <img
            src="/plus_frontend/assets/svg/ico-remove.svg"
            className="ico-remove"
            alt="Remove"
            cursor="pointer"
          />
        </div>
      </div>
      <div
        className="prev-inner"
        style={{ backgroundImage: `url('${item.url}')` }}
      >
        <img
          style={{ display: 'none' }}
          src={item.url}
          alt={item.description}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
            e.currentTarget.style.display = 'block';
          }}
        />
      </div>
    </div>
  );
};

SortableItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const SortableMediaBox = ({ items, setItems }) => {
  const onRemoveClick = (item) => {
    const itemsRev = items.filter((e) => e.id !== item.id);
    setItems(itemsRev);
  };

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (
      event.activatorEvent.target.className.indexOf('ico-remove') !== -1
    ) {
      onRemoveClick(active);
    }

    if (active.id !== over.id) {
      setItems(() => {
        const oldIndex = items.findIndex((e) => e.id === active.id);
        const newIndex = items.findIndex((e) => e.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  if (items?.length === 0) { return null; }

  let previewsWrapperWidth = 0;
  if (items) {
    previewsWrapperWidth = items.length * 220;
  }

  const sensor = isMobile ? TouchSensor : PointerSensor;
  const sensors = useSensors(useSensor(sensor));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={items}
        strategy={horizontalListSortingStrategy}
      >
        <div className="container-previews-wrapper">
          <div className="container-previews" style={{ width: `${previewsWrapperWidth}px` }}>
            <div className="row start-xs">
              {items && items.map((item, index) => (
                <SortableItem
                  key={`item-${item.id}`}
                  item={item}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

SortableMediaBox.defaultProps = {
  items: [],
};

SortableMediaBox.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func.isRequired,
};

export default SortableMediaBox;
