import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import IconVerifyId from 'app/components/customSvgs/IconVerifyId';
import { DateTime } from 'luxon';
import PropTypes from "prop-types";

const IdVerification = ({ idv_status, idv_date }) => {

  let extraText = false;
  let textColor = 'green';
  let showDate = true;
  let showVerifyButton = false;
  let showRetryButton = false;

  const formattedStatus = (idv_status.replace('_', ' ')).charAt(0).toUpperCase() + idv_status.slice(1);
  let formattedDate = 'N/A';
  if (idv_date) {
    formattedDate = DateTime.fromISO(idv_date).toFormat('MMM dd, yyyy');
  }

  switch (idv_status) {
    case 'verified':
      showVerifyButton = false;
      break;
    case 'failed':
      extraText = true;
      textColor = '#d32f2f';
      showDate = true;
      break;
    case 'unverified':
      extraText = true;
      textColor = '#d32f2f';
      showDate = false;
      break;
    case 'error':
      textColor = '#d32f2f';
      showRetryButton = true;
      break;
    default:
      showVerifyButton = true;
  }

  const startVerification = () => {
    window.location.href = '/identity_verification/consent';
  }

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '550px' }}>

      {
        showVerifyButton ? (
          <Stack spacing={3} sx={{ textAlign: 'center' }}>
            <IconVerifyId style={{ fontSize: '5em', margin: 'auto' }} />
            <Typography variant="h2">Get Verified!</Typography>
            <Typography variant="body1">
              To ensure the safety of the NiteFlirt community and its members, we require identity verification. Completing ID verification is easy and only takes a few minutes.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" size="large" sx={{ maxWidth: '300px', width: '100%' }} onClick={startVerification}>
                Verify My Identity
              </Button>
            </Box>
          </Stack>
        ) : (
          <>
          <Stack spacing={3} sx={{ textAlign: 'center' }}>
            <IconVerifyId style={{ fontSize: '5em', margin: 'auto' }} />
          </Stack>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1" fontWeight="bold">ID Verification Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: textColor }}>{ formattedStatus }</Typography>
              {extraText && (
                <Typography variant="body1">
                  We were unable to verify your ID. Please contact
                  {' '}
                  <a href="mailto:support@niteflirt.com">support@niteflirt.com</a>
                </Typography>
              )}
            </Grid>
            {
              !showRetryButton && (
                <>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body1" fontWeight="bold">Last Verified</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{ showDate ? formattedDate : 'N/A'}</Typography>
                  </Grid>
                </>
              )
            }
          </Grid>
            {
              showRetryButton && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                  <Button variant="outlined" size="large" sx={{ maxWidth: '300px', width: '100%' }} onClick={startVerification}>
                    Retry Verification
                  </Button>
                </Box>
              )
            }
          </>
        )
      }
    </Box>
  )

}

IdVerification.propTypes = {
  idv_status: PropTypes.string.isRequired,
  idv_date: PropTypes.string.isRequired,
};

export default IdVerification;