import React from 'react';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

import FlirtContentContainer from 'app/containers/FlirtContentContainer';
import FlirtHeaderContainer from 'app/containers/FlirtHeaderContainer';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import Subscription from 'app/components/subscriptions/Subscription';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { Navigate } from 'react-router-dom';

const FlirtContainer = () => {
  const { login: advisorLogin } = useParams();
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  if (currentUser?.login === advisorLogin && !currentUser.onboarding_complete) {
    return <Navigate to={`/feed/${currentUser.login}/onboarding`} replace />;
  }

  if (featureFlag.enabled('FEED_TO_STOREFRONT_20571')) {
    return <Navigate to={`/${advisorLogin}/feed`} replace />;
  }

  return (
    <AppBodyWithSidebar
      sidebarContent={<Subscription fetchStateFromApi advisorLogin={advisorLogin} />}
    >
      <FlirtHeaderContainer currentUser={currentUser} />
      <FlirtContentContainer />
    </AppBodyWithSidebar>
  );
};

export default FlirtContainer;
