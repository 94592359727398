import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';

const CustomStepper = styled(Stepper)(() => ({
  '&.MuiStepper-root': {
    '& .MuiSvgIcon-root': {
      fontSize: '1.125em',
    },
  },
}));

const steps = [
  'Register',
  'Add Money',
  'Add Phone',
];

const SignupStepper = ({ activeStep }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <CustomStepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </Box>
  );
};

SignupStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default SignupStepper;
