import React from 'react';
import PropTypes from 'prop-types';

import ListingShape from 'app/shapes/ListingShape';

import {
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import {
  toNumber,
  toCurrency,
} from 'app/helpers/currencyHelpers';
import InsuffientFundsError from 'app/components/call/errors/InsufficientFundsError';

const CurrentBalance = ({
  balance,
  listing,
  isBalanceLoading,
  isListingLoading,
}) => {
  const availableBalance = !isBalanceLoading && toNumber(balance.available_balance);
  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: 550,
        margin: 'auto',
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          Balance
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          { isBalanceLoading || isListingLoading ? '-' : (
            <>
              <Typography variant="body1">
                {toCurrency(availableBalance)}
              </Typography>
              <InsuffientFundsError
                balance={balance}
                listing={listing}
                isLoading={isListingLoading}
              />
              <Typography variant="body2">
                <a href="#">Add</a>
              </Typography>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

CurrentBalance.defaultProps = {
  balance: null,
  listing: null,
};

CurrentBalance.propTypes = {
  balance: PropTypes.object,
  listing: ListingShape,
  isBalanceLoading: PropTypes.bool.isRequired,
  isListingLoading: PropTypes.bool.isRequired,
};

export default CurrentBalance;
