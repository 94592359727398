import React, { useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import FileManagerAlbumItem from 'app/components/posts/FileManagerAlbumItem';

import AddMediaContext from 'app/contexts/AddMediaContext';

const ItemsPageContainer = ({ setOldestCursor, itemsQuery }) => {
  const {
    isChecked,
    toggleSelectedItem,
    freePost,
    profileImageMode,
  } = useContext(AddMediaContext);

  const { data } = itemsQuery();
  const items = data?.records;

  useEffect(() => {
    if (items?.length > 0) {
      const oldestItem = items[items.length - 1];
      setOldestCursor(oldestItem.created_at);
    }
  }, [items]);

  if (!items) { return null; }

  return (
    <>
      {items.map((item) => (
        <FileManagerAlbumItem
          key={item.fm_item_id}
          item={item}
          checked={isChecked(item)}
          onChange={toggleSelectedItem}
          freePost={freePost}
          selectOrRadio={profileImageMode ? 'radio' : 'select'}
        />
      ))}
    </>
  );
};

ItemsPageContainer.propTypes = {
  setOldestCursor: PropTypes.func.isRequired,
  itemsQuery: PropTypes.func.isRequired,
};

export default ItemsPageContainer;
