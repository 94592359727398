import React from 'react';
import { PropTypes } from 'prop-types';

const ImageMedia = ({ item: { id, thumb, contentType }, onImageClick }) => {
  return (
    <a key={id} role="link" tabIndex={0} onClick={onImageClick}>
      <div
        className="slick-blur"
        style={{ backgroundImage: `url('${thumb}')` }}
        type={contentType}
      >
        &nbsp;
      </div>
      <div className="slick-blur2" style={{ width: '100%' }} />
      <div
        className="slick-item-bg"
      >
        <div className="slick-size" style={{ backgroundImage: `url('${thumb}')` }}>&nbsp;</div>
      </div>
    </a>
  );
};

ImageMedia.propTypes = {
  item: PropTypes.object.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default ImageMedia;
