export const toCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

export const toNumber = (amount) => {
  const parsed = parseFloat(amount);

  if (Number.isNaN(parsed)) {
    return 0;
  } else {
    return parsed;
  }
};

export const amountOutOfRange = (newAmountNumber, min = 1, max = 1000) => {
  return newAmountNumber < min || newAmountNumber > max;
};

export const invalidAmountFormat = (newAmountString) => {
  return newAmountString.match(/^[0-9]*(\.[0-9]{1,2})?$/) === null;
};

export const amountInvalid = (newAmountString, min = 1, max = 1000) => {
  return amountOutOfRange(toNumber(newAmountString), min, max) ||
    invalidAmountFormat(newAmountString);
};
