import React from 'react';
import { PropTypes } from 'prop-types';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

const listItemIconStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '35px',
  minWidth: '35px',
};

const listItemTextStyle = {
  marginTop: 0,
  color: '#63177A',
};

const PerkListItem = ({ perkDescription, perkType }) => (
  <ListItem data-test-id={`${perkType}`} sx={{ px: 0.5 }}>
    <ListItemIcon style={listItemIconStyle}>
      <CheckIcon style={{ color: '#63177A' }} />
    </ListItemIcon>
    <ListItemText
      primary={perkDescription}
      primaryTypographyProps={{ style: listItemTextStyle }}
    />
  </ListItem>
);

PerkListItem.propTypes = {
  perkDescription: PropTypes.string.isRequired,
};

export default PerkListItem;
