import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { showSnackbarMessage, closeSnackBarMessage } from 'app/slices/snackbarMessageSlice';

import SnackbarMessage from 'app/components/shared/SnackbarMessage';

const SnackbarMessageContainer = () => {
  const dispatch = useDispatch();

  // NOTE: This is for demo purposes only
  window.demoSnackbarMessage = (message, severity) => {
    dispatch(showSnackbarMessage({ message, severity }));
  };

  const handleClose = () => {
    dispatch(closeSnackBarMessage());
  };
  const {
    isOpen,
    position,
    message,
    severity,
  } = useSelector((state) => state.snackbarMessages);

  return (
    <SnackbarMessage
      isOpen={isOpen}
      message={message}
      severity={severity}
      handleClose={handleClose}
      position={position}
    />
  );
};

export default SnackbarMessageContainer;
