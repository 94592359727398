import React from 'react';
import { useNavigate } from 'react-router';
import { PropTypes } from 'prop-types';

const LockedMediaItem = ({ postId, advisor, mediaItem }) => {
  const navigate = useNavigate();

  let iconSrc;
  switch (true) {
  case /audio/.test(mediaItem.content_type):
    iconSrc = '/plus_frontend/assets/svg/ico-audio.svg';
    break;
  case /image/.test(mediaItem.content_type):
    iconSrc = '/plus_frontend/assets/svg/ico-image.svg';
    break;
  case /gif/.test(mediaItem.content_type):
  case /video/.test(mediaItem.content_type):
    iconSrc = '/plus_frontend/assets/svg/ico-video.svg';
    break;
  default:
    iconSrc = null;
  }

  return (
    <div
      className="media locked"
      role="link"
      tabIndex={0}
      onClick={() => navigate(`/feed/${advisor.login}/${postId}`)}
    >
      <div className="inner">
        <img src="/plus_frontend/assets/svg/ico-lock.svg" alt="Locked" />
        <div className="media-type">
          <img src={iconSrc} alt="Lock" />
        </div>
      </div>
    </div>
  );
};

LockedMediaItem.propTypes = {
  postId: PropTypes.number.isRequired,
  advisor: PropTypes.object.isRequired,
  mediaItem: PropTypes.object.isRequired,
};

const MediaItem = ({ mediaItem, onMediaItemClick, advisor }) => {
  if (!mediaItem.unlocked) {
    return (
      <LockedMediaItem
        postId={mediaItem.post_id}
        advisor={advisor}
        mediaItem={mediaItem}
      />
    );
  }

  const isGif = mediaItem.content_type.match(/gif/);
  const isVid = mediaItem.content_type.match(/video/);
  const isAud = mediaItem.content_type.match(/audio/);

  return (
    <div
      style={{ backgroundImage: `url(${mediaItem.thumbnail_url})` }}
      className="media"
      role="link"
      tabIndex={0}
      onClick={() => onMediaItemClick(mediaItem)}
    >
      <div className="inner">
        {isGif && <img src="/plus_frontend/assets/svg/ico-play-gif.svg" alt="GIF" />}

        {isVid && (
          <img
            src="/plus_frontend/assets/svg/ico-video2.svg"
            alt="Video"
            style={{ width: '50px' }}
          />
        )}

        {isAud && (
          <img
            src="/plus_frontend/assets/svg/ico-audio.svg"
            alt="Audio"
            style={{ opacity: '0.75', width: '50px' }}
          />
        )}
      </div>
    </div>
  );
};

MediaItem.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  onMediaItemClick: PropTypes.func.isRequired,
  advisor: PropTypes.object.isRequired,
};

export default MediaItem;
