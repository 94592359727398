import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CollapseArrowBox = styled(Box)(({ open }) => ({
  position: open ? 'absolute' : 'relative',
  top: open ? '110px' : '0px',
  right: open ? '10px' : 'auto',
  paddingRight: open ? '0px' : '10px',
  zIndex: open ? '1' : 'auto',
  transition: 'top 0.3s, position 0.3s',
  marginBottom: open ? '0px' : '-20px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

const HeroPane = styled(Box)(() => ({
  width: '100%',
  maxWidth: '758px',
  height: '150px',
  textAlign: 'center',
  fontSize: '1.125em',
  color: 'white',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledList = styled('ul')(({ theme }) => ({
  maxWidth: '350px',
  textAlign: 'left',
  margin: '10px auto 0px auto',
  fontSize: '0.813em',
  lineHeight: '1.5em',
  paddingLeft: 0,
  listStylePosition: 'inside',
  '& li': {
    paddingLeft: '10px',
    marginLeft: '-10px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.750em',
  },
}));

const CarouselWrapperBox = styled(Box)({
  maxWidth: '758px',
  margin: '0 auto',
});

const Hero = () => {
  const [open, setOpen] = useState(true); // State to toggle Collapse

  // Function to handle the toggle
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <CollapseArrowBox open={open}>
        <IconButton
          onClick={handleToggle}
          sx={{
            color: open ? 'white' : '#06c', // Conditional color based on 'open'
          }}
        >
          <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />
        </IconButton>
      </CollapseArrowBox>
      <Collapse in={open}>
        <CarouselWrapperBox>
          <Carousel
            fullHeightHover={false}
            navButtonsAlwaysVisible={false}
            navButtonsProps={{
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                borderRadius: '50%',
                fontSize: '24px',
              },
            }}
          >
            <HeroPane
              id="hero-pane-1"
              sx={{
                background: 'url(/niteflirt/images/redesign/hero3/bg-pane1.jpg) left top no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <Typography variant="h3" component="div" sx={{ fontSize: { xs: '32px', sm: '44px' }, color: '#fff', fontWeight: '300' }}>
                Speak to Your&nbsp;
                <Typography component="span" sx={{ color: '#bd62d8' }}>
                  Desire
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '9px',
                    position: 'absolute',
                    display: 'inline-block',
                    marginTop: '10px',
                  }}
                >
                  TM
                </Typography>
              </Typography>
              <Typography component="p">Phone, Goodies, Mail, and now Chat!</Typography>
            </HeroPane>

            <HeroPane
              id="hero-pane-2"
              sx={{
                background: 'url(/niteflirt/images/redesign/hero3/bg-pane2.jpg) center top no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <Typography component="p">New Customers get</Typography>
              <Typography variant="h3" component="div" sx={{ fontSize: { xs: '32px', sm: '40px' }, color: '#fff', fontWeight: '300' }}>
                <Typography component="span" sx={{ color: '#bd62d8' }}>
                  3
                </Typography>
                &nbsp;FREE Minutes
              </Typography>
            </HeroPane>

            <HeroPane
              id="hero-pane-3"
              sx={{
                background: 'url(/niteflirt/images/redesign/hero3/bg-pane3.jpg) center top no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <Typography variant="h3" component="div" sx={{ fontSize: { xs: '26px', sm: '30px' }, color: '#fff', fontWeight: '300' }}>
                Safe. Secure. Discreet.
              </Typography>
              <StyledList>
                <li>Your phone number is never revealed</li>
                <li>Discreetly billed as &quot;NF Services&quot;</li>
                <li>RapidSSL secured, encrypted servers</li>
                <li>Trusted for over 20 years</li>
              </StyledList>
            </HeroPane>

            <HeroPane
              id="hero-pane-4"
              sx={{
                background: 'url(/niteflirt/images/redesign/hero3/bg-pane4.jpg) center top no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <Typography variant="h3" component="div" sx={{ fontSize: { xs: '32px', sm: '40px' }, color: '#fff', fontWeight: '300' }}>
                <Typography component="span" sx={{ color: '#bd62d8' }}>
                  Chat
                </Typography>
                &nbsp;Now
              </Typography>
              <Typography component="p" sx={{ mt: 0.5 }}>Anytime from Anywhere</Typography>
            </HeroPane>
          </Carousel>
        </CarouselWrapperBox>
      </Collapse>
    </>
  );
};

export default Hero;
