import React from 'react';

import AppBody from 'app/components/layout/AppBody';

const Chat = () => {
  return (
    <AppBody>
      <div>Chat</div>
    </AppBody>
  );
};

export default Chat;
