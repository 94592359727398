import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

const EmojiPicker = ({ setText, toggleEmojiRef }) => {
  const [showingEmojiPicker, setShowingEmojiPicker] = useState(false);
  const emojiPickerRef = useRef();

  const onEmojiSelection = (emojiCode) => {
    setText(emojiCode.native);
  };

  useEffect(() => {
    const bodyClick = (e) => {
      const clickedOutsideEmojiPicker = emojiPickerRef.current && !emojiPickerRef.current.contains(e.target);
      const clickedToggleEmojiRef = toggleEmojiRef.current && toggleEmojiRef.current.contains(e.target);

      if (clickedToggleEmojiRef) {
        setShowingEmojiPicker(!showingEmojiPicker);
      } else if (clickedOutsideEmojiPicker) {
        setShowingEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', bodyClick);

    return () => {
      document.removeEventListener('mousedown', bodyClick);
    };
  }, [showingEmojiPicker]);

  if (!showingEmojiPicker) return null;

  return (
    <span ref={emojiPickerRef}>
      <Picker
        onSelect={onEmojiSelection}
      />
    </span>
  );
};

EmojiPicker.propTypes = {
  setText: PropTypes.func.isRequired,
  toggleEmojiRef: PropTypes.object.isRequired,
};

export default EmojiPicker;
