import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThumbDownOffAlt, ThumbUpOffAlt, ThumbUp, ThumbDown } from '@mui/icons-material';

const iconSize = '1.375em'; // Define a common size for both icons

// Styled components with adjusted sizes
const StyledThumbUpOffAlt = styled(ThumbUpOffAlt)(({ theme }) => ({
  fontSize: iconSize,
  color: theme.palette.grey[400],
  width: '32px',
  height: '32px',
}));

const StyledThumbDownOffAlt = styled(ThumbDownOffAlt)(({ theme }) => ({
  fontSize: iconSize,
  color: theme.palette.grey[400],
  width: '32px',
  height: '32px',
}));

const StyledThumbUp = styled(ThumbUp)(() => ({
  width: '30px',
  height: '30px',
}));

const StyledThumbDown = styled(ThumbDown)(() => ({
  width: '30px',
  height: '30px',
}));

const ThumbsUpDown = ({ rating, onRatingChange, readOnly }) => {
  const handleThumbChange = (newRating) => {
    if (readOnly) return;

    const event = { target: { value: newRating } };
    onRatingChange(event, newRating);
  };

  return (
    <Box>
      <IconButton onClick={() => handleThumbChange(rating === 10 ? 0 : 10)} disabled={readOnly} style={{ width: '48px', height: '48px' }}>
        {rating === 10 ? <StyledThumbUp /> : <StyledThumbUpOffAlt />}
      </IconButton>
      <IconButton onClick={() => handleThumbChange(rating === -10 ? 0 : -10)} disabled={readOnly} style={{ width: '48px', height: '48px' }}>
        {rating === -10 ? <StyledThumbDown /> : <StyledThumbDownOffAlt />}
      </IconButton>
    </Box>
  );
};

ThumbsUpDown.propTypes = {
  rating: PropTypes.number,
  onRatingChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ThumbsUpDown.defaultProps = {
  rating: null,
  readOnly: false, // Provide a default value for the readOnly prop
};

export default ThumbsUpDown;
