import React from 'react';
import { PropTypes } from 'prop-types';

const GenericFlash = ({ closeFlash, message }) => {
  return (
    <>
      <div className="col-xs-10">
        {(typeof message === 'function') ? message() : message}
      </div>
      <div className="col-xs-2 end-xs">
        <a role="link" tabIndex={0} onClick={closeFlash}>
          <img src="/plus_frontend/assets/svg/x-close.svg" alt="close" />
        </a>
      </div>
    </>
  );
};

GenericFlash.propTypes = {
  closeFlash: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export const GENERIC_FLASH = 'GENERIC_FLASH';
export default GenericFlash;
