/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasUnreadMessages: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setHasUnreadMessages: (state, action) => {
      state.hasUnreadMessages = action.payload.hasUnreadMessages;
    },
  },
});

export default chatSlice;

export const {
  setHasUnreadMessages,
} = chatSlice.actions;
