import React from 'react';
import { PropTypes } from 'prop-types';

import PackageForm from 'app/components/modals/tributes/PackageForm';

import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const PackageFormContainer = ({ advisor }) => {
  const {
    publicPackages,
    subscribeToPackageClick,
  } = useRecurringTributeManagement({ advisor });

  return (
    <PackageForm
      advisor={advisor}
      packages={publicPackages}
      subscribeToPackageClick={subscribeToPackageClick}
    />
  );
};

PackageFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default PackageFormContainer;
