import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { timestampInTimezone } from 'app/helpers/timestampHelpers';
import ModalsContext from 'app/contexts/ModalsContext';
import RejectionReasonModal from 'app/components/modals/RejectionReasonModal';

import PostActionMenu from './shared/PostActionMenu';

const POST_TYPE_MAPPING = {
  free: 'Free',
  subscriber: 'Preexisting Subscribers',
  welcome_kit: 'All Subscribers',
  archived: 'Removed', // This one should not come up given the queries in place but it's a possible value.
};

const QueuedPost = ({
  post,
  ianaTimezone,
  displayScheduledDate,
  actions,
  isScheduled,
}) => {
  const { openModal } = useContext(ModalsContext);

  const openRejectionReasonModal = () => {
    openModal({
      component: RejectionReasonModal,
      props: { post },
    });
  };

  const postTimeStamp = (post) => {
    if (displayScheduledDate) {
      return timestampInTimezone(post.scheduled_at, ianaTimezone);
    }
    return (post.published_at && timestampInTimezone(post.published_at, ianaTimezone)) || (post.created_at && timestampInTimezone(post.created_at, ianaTimezone));
  };

  const moderationStatusClassMapping = {
    pending: 'pending-txt',
    not_reviewed: 'pending-txt',
    not_accepted: 'error-txt',
  };

  const moderationStatusClass = () => {
    return moderationStatusClassMapping[post.moderation_status] || '';
  };

  const moderationStatusDisplayMapping = {
    pending: 'Pending Review',
    not_reviewed: 'Pending Review',
    not_accepted: 'Rejected',
  };

  const moderationStatusDisplay = () => {
    return moderationStatusDisplayMapping[post.moderation_status] || '';
  };

  const encodingStatusDisplay = () => {
    let encodingStatus = '';

    post.post_items.forEach((postItem) => {
      if (postItem.encoding_status === 'pending') {
        encodingStatus = 'Pending encoding';
      }
    });
    return encodingStatus;
  };

  return (
    <tr>
      <td>
        <span className="nowrap">{postTimeStamp(post)}</span>
      </td>
      <td>
        <div className="clamp2">
          {post.body
            ? post.body
            : <em>No public caption added</em>}
        </div>
        <div className="container-fluid padding-0-8 margintop10">
          <div className="row middle-xs">
            <div className="paddingrgt5"><img src="/plus_frontend/assets/svg/ico-image-20x20.svg" alt="Images" /></div>
            <div className="paddingrgt15">{post.image_count || 0}</div>
            <div className="paddingrgt5"><img src="/plus_frontend/assets/svg/ico-video-20x20.svg" alt="Video" /></div>
            <div className="paddingrgt15">{post.video_count || 0}</div>
            <div className="paddingrgt5"><img src="/plus_frontend/assets/svg/ico-audio-20x20.svg" alt="Audio" /></div>
            <div className="paddingrgt15">{post.audio_count || 0}</div>
            {!isScheduled && post.moderation_status && (
              <div className="margintop5">
                <span className={moderationStatusClass()}>
                  {encodingStatusDisplay() || moderationStatusDisplay()}
                </span>
                {post.moderation_reason && (
                  <span>
                    &nbsp;-&nbsp;
                    <a href="#" onClick={openRejectionReasonModal}>See details</a>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="row middle-xs margintop5">
            <div className="text-gray">{ POST_TYPE_MAPPING[post.post_type]}</div>
          </div>
        </div>
      </td>
      <td className="paddingtop10 padding0">
        <PostActionMenu actions={actions} />
      </td>
    </tr>
  );
};

QueuedPost.propTypes = {
  post: PropTypes.object.isRequired,
  ianaTimezone: PropTypes.string.isRequired,
  displayScheduledDate: PropTypes.bool,
  actions: PropTypes.array.isRequired,
  isScheduled: PropTypes.bool.isRequired,
};

QueuedPost.defaultProps = {
  displayScheduledDate: false,
};

export default QueuedPost;
