import React from 'react';
import Box from '@mui/material/Box';

const CategoryHeader = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgb(204, 204, 204)',
        height: '90px',
      }}
    >
      Header Stuff HERE
    </Box>
  );
};

export default CategoryHeader;
