import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useMatch } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

import { useGetAppConfigQuery, useGetAdvisorQuery } from 'app/api/mainApi';
import AppBody from 'app/components/layout/AppBody';
import ProfileTabsWithContent from 'app/redesign/components/ProfileTabsWithContent';
import Homepage from 'app/redesign/components/Homepage';
import HeaderContactButtons from 'app/redesign/components/HeaderContactButtons';
import StickyProfilePageControls from 'app/redesign/components/StickyProfilePageControls';
import ProfileContext from 'app/contexts/ProfileContext';
import ProfileMenu from 'app/redesign/components/ProfileMenu';

function AdvisorHomepageContainer(props) {
  const { login: advisorLogin } = useParams();
  const { data: advisor, isLoading: advisorLoading } = useGetAdvisorQuery({ login: advisorLogin });
  const encodedAdvisorLogin = encodeURIComponent(advisor?.login);
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = (currentUser?.id === advisor?.id);
  const viewingProfile = useMatch(`/profile/${encodedAdvisorLogin}`);
  const [stickyNavVisible, setStickyNavVisible] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      setStickyNavVisible(e.target.scrollTop > 180);
    };

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) { bodyContent.addEventListener('scroll', onScroll); }

    return () => {
      bodyContent.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <AppBody>
      <Grid container alignItems="center" className="container-hdr-sticky2">
        <Grid item p={1}>
          <Link
            role="link"
            to={`/${advisor?.login}`}
          >
            <ArrowBackIosSharpIcon
              htmlColor="white"
            />
          </Link>
        </Grid>
        <Grid item>
          {advisor?.login}
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Box className="margintop5">
            <ProfileMenu advisor={advisor} currentUser={currentUser} />
          </Box>
        </Grid>
      </Grid>
      <div className="container-fluid container-profile-info">
        <div className="row nowrap-sm">
          <div className="col-sm-9 col-xs-9 start-xs" />
          <div className="col-sm-3 hidden-xs">
            <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion={false} />
          </div>
        </div>
      </div>
      <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion />
      <StickyProfilePageControls
        advisor={advisor}
        currentUser={currentUser}
        stickyNavVisible={stickyNavVisible}
        viewingProfile={viewingProfile}
      />

      <Homepage advisorLogin={advisorLogin} />

      {/* we have a context provider with no value here. why? */}
      <ProfileContext.Provider value={props}>
        <ProfileTabsWithContent
          stickyNavVisible
          advisor={advisor}
          advisorLoading={advisorLoading}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
        />
      </ProfileContext.Provider>
    </AppBody>
  );
}

export default AdvisorHomepageContainer;
