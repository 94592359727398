import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import AdvisorCard from 'app/components/AdvisorCard';
import { useGetListingQuery, useGetChatProfileQuery } from 'app/api/mainApi';

const AdvisorInfoCardContainer = ({ listingId = null, chatProfileName = null }) => {
  if (!listingId && !chatProfileName) return null;

  const { data: listingData } = useGetListingQuery(
    { id: listingId },
    { skip: !listingId },
  );

  const { data: chatProfileData } = useGetChatProfileQuery(
    { login: chatProfileName },
    { skip: !chatProfileName },
  );

  if (!listingData && !chatProfileData) return null;

  return (
    <Stack mb={3} mt={1} data-testid="listing">
      <AdvisorCard
        avatarSrc={listingData?.photo_url || chatProfileData?.image_url}
        name={listingData?.login || chatProfileData?.user_login}
        description={listingData?.title || chatProfileData?.description}
        price={listingData?.price_per_minute || window.VOLLEY_PRICE}
        type={listingData ? 'call' : 'chat'}
      />
    </Stack>
  );
};

AdvisorInfoCardContainer.propTypes = {
  listingId: PropTypes.string,
  chatProfileName: PropTypes.string,
};

AdvisorInfoCardContainer.defaultProps = {
  listingId: null,
  chatProfileName: null,
};

export default AdvisorInfoCardContainer;
