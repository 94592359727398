import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Paper,
  Grid,
  Typography,
  Divider,
  Stack,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import { green } from '@mui/material/colors';

import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';
import AvailabilityList from 'app/redesign/components/availability/AvailabilityList';
import PhoneShape from 'app/shapes/PhoneShape';
import { useUpdateAvailabilityMutation } from 'app/api/mainApi';

const CurrentCallPhoneStatus = ({ phone, callStatuses, displaySecureNumber }) => {
  const [ updateCurrentCallPhoneStatus ] = useUpdateAvailabilityMutation();
  const handleChangeCallPhoneStatus = (callStatus) => {
    if (phone.call_phone_status === callStatus) { return; }

    updateCurrentCallPhoneStatus({ availability: { call_status: callStatus } });
  };
  const displayNumberMethod = displaySecureNumber ? 'secure_number' : 'unsecure_number';

  return (
    <Box data-test-id="current-call-phone-status">
      <Grid container sx={{ mb: 1, alignItems: 'center', px: { xs: 3, sm: 0 }, pt: { xs: 1.5, sm: 0 } }}>
        <Grid item xs={6}>
          <Typography variant="h6">
            Call Status
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ p: 0 }} variant="outlined">
        <AvailabilityList aria-label="current phone availability">
          {callStatuses.map((callStatus, index) => (
            <React.Fragment key={`call-status-${callStatus.value}`}>
              <ListItem data-test-id={`call-status-menu-item-${callStatus.title}`} onClick={() => { handleChangeCallPhoneStatus(callStatus.value); }}>
                <Stack direction="row" justifyContent="space-between" style={{ width: '100%' }}>
                  <Stack style={{ width: '100%' }}>
                    <ListItemText primary={callStatus.title} />
                    {callStatus.title === 'Yes - Taking Calls' && <ListItemText secondary={phone[ displayNumberMethod ]} sx={{ mt: -0.5 }} />}
                  </Stack>
                  {phone.call_phone_status === callStatus.value && (
                    <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', alignSelf: 'center' }}>
                      <CheckMarkNF sx={{ color: green[ 500 ] }} />
                    </ListItemIcon>
                  )}
                </Stack>
              </ListItem>
              {(index !== callStatuses.length - 1) && <Divider />}
            </React.Fragment>
          ))}
        </AvailabilityList>
      </Paper>
    </Box>
  );
};

CurrentCallPhoneStatus.defaultProps = {
  phone: null,
  callStatuses: [],
  displaySecureNumber: true,
};

CurrentCallPhoneStatus.propTypes = {
  phone: PhoneShape,
  callStatuses: PropTypes.array,
  displaySecureNumber: PropTypes.bool,
};

export default CurrentCallPhoneStatus;
