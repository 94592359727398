import React from 'react';
import { PropTypes } from 'prop-types';

import Post from 'app/components/posts/Post';
import DeprecatedPost from 'app/components/posts/DeprecatedPost';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import usePostMenuActions from '../hooks/usePostMenuActions';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router';
import { resetFlirtFeed } from 'app/slices/resetRecordsSlice';
import { featureFlag } from 'app/util/featureFlag';

const PostContainer = ({
  post,
  advisor,
  showComments,
  showPin,
  showLink,
}) => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = currentUser?.id === advisor?.id;
  const dispatch = useDispatch();
  const match = useMatch('/feed/:login/:postId');
  const navigate = useNavigate();

  // #19537 - Recurring Package Tribute - Feature Flag - Remove
  const isRecurringPackageTributeEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');
  const feedToStoreFrontEnabled = featureFlag.enabled('FEED_TO_STOREFRONT_20571');

  const afterDeleteCallback = () => {
    dispatch(resetFlirtFeed({ advisorId: advisor.id }));

    if (match?.params?.postId) {
      if (feedToStoreFrontEnabled) {
        navigate(`/${advisor.login}/feed`);
      } else {
        navigate(`/feed/${advisor.login}`);
      }
    }
  };

  const {
    postActions,
    isScheduled,
  } = usePostMenuActions({
    post,
    currentUser,
    advisor,
    afterDeleteCallback,
  });

  if (isRecurringPackageTributeEnabled) {
    return (
      <Post
        post={post}
        advisor={advisor}
        viewingSelf={viewingSelf}
        actions={postActions}
        showComments={showComments}
        showPin={showPin}
        showLink={showLink}
        isScheduled={isScheduled}
      />
    );
  } else {
    return (
      <DeprecatedPost
        post={post}
        advisor={advisor}
        viewingSelf={viewingSelf}
        actions={postActions}
        showComments={showComments}
        showPin={showPin}
        showLink={showLink}
        isScheduled={isScheduled}
      />
    );
  }
};

PostContainer.defaultProps = {
  post: null,
  advisor: null,
  showPin: false,
  showLink: true,
};

PostContainer.propTypes = {
  post: PropTypes.object,
  showPin: PropTypes.bool,
  showLink: PropTypes.bool,
  advisor: PropTypes.object,
  showComments: PropTypes.bool.isRequired,
};

export default PostContainer;
