import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { featureFlag } from 'app/util/featureFlag';

import ModalsContext from 'app/contexts/ModalsContext';
import FollowButton from 'app/components/profile/FollowButton';
import FollowButtonDeprecated from 'app/components/FollowButtonDeprecated';
import GenericModal from 'app/components/modals/GenericModal';
import UnfollowConfirmModal from 'app/components/modals/UnfollowConfirmModal';
import { showFlash } from 'app/slices/flashSlice';
import ManageCardsModalContainer from 'app/containers/modals/ManageCardsModalContainer';

import {
  useFollowAdvisorMutation,
  useGetFollowQuery,
  useUnfollowAdvisorMutation,
} from 'app/api/feedsApi';

import {
  useGetSubscriptionQuery,
  useGetAdvisorQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

const FollowButtonContainer = ({ subscriptionData, fetchStateFromApi, followData }) => {
  let advisor;
  let follow;
  let subscription;
  let subscriptionFetchSuccess;

  const { openModal } = useContext(ModalsContext);
  const dispatch = useDispatch();

  // get app config & currentUser
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  if (fetchStateFromApi) {
    // get advisor
    const { login: advisorLogin } = useParams();
    advisor = useGetAdvisorQuery({ login: advisorLogin })?.data;

    // get subscription information
    const subsQuery = useGetSubscriptionQuery(
      { advisorId: advisor?.id },
      { skip: !advisor || !currentUser },
    );

    subscription = subsQuery?.data?.subscription;
    subscriptionFetchSuccess = subsQuery?.isSuccess;

    // get follow
    follow = useGetFollowQuery(
      { advisorId: advisor?.id },
      { skip: !currentUser || !advisor },
    )?.data;
  } else {
    follow = followData;
    subscription = subscriptionData.subscription;
    subscriptionFetchSuccess = true;

    advisor = {
      id: subscriptionData.subscription.advisor_id,
      active: subscriptionData.subscription.advisor_active,
    };
  }

  if (follow?.active === true && advisor?.blocked) {
    follow = { active: false };
  }

  const [followAdvisor, { isLoading }] = useFollowAdvisorMutation();
  const [unfollowAdvisor] = useUnfollowAdvisorMutation();

  // TODO: I think this can be handled by the useCreditCardManagement custom hook now!
  const openManageCardsModal = () => {
    openModal({
      component: ManageCardsModalContainer,
      props: {
        afterCloseAction: () => {
          dispatch(followAdvisor({ advisorId: advisor.id }));
        },
      },
    });
  };

  const hasNoDefaultCard = (user) => {
    return !user.default_card.last_four;
  };

  // follow click handler
  const followClick = () => {
    if (isLoading) {
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (currentUser?.id == advisor.id) {
      return dispatch(showFlash({
        flashType: 'error',
        props: { message: 'You can\'t follow yourself' },
      }));
    }

    if (follow?.active !== true && advisor.blocked) {
      return dispatch(showFlash({
        flashType: 'error',
        props: { message: 'We\’re sorry, you are unable to follow this Flirt because this Flirt blocked you or you blocked this Flirt.' },
      }));
    }

    if (follow?.active === true && advisor.blocked) {
      return dispatch(showFlash({
        flashType: 'error',
        props: { message: 'We\’re sorry, you are unable to follow this Flirt because this Flirt blocked you or you blocked this Flirt.' },
      }));
    }

    if (subscriptionFetchSuccess && subscription && subscription.status !== 'cancelled') {
      return dispatch(showFlash({
        flashType: 'error',
        props: { message: 'You can\'t unfollow this Flirt because you\'re their subscriber.' },
      }));
    } else if (follow && follow.active) {
      return openModal({
        component: UnfollowConfirmModal,
        props: {
          doUnfollow: () => { unfollowAdvisor({ advisorId: advisor.id }); },
        },
      });
    } else if (hasNoDefaultCard(currentUser)) {
      return openModal({
        component: GenericModal,
        props: {
          callToAction: () => {
            dispatch(openManageCardsModal());
          },
          ctaButtonLabel: 'Add Card',
          header: 'Follow this Flirt?',
          body: 'You must have a credit card on file to follow this flirt.',
        },
      });
    } else {
      return followAdvisor({ advisorId: advisor.id });
    }
  };

  if (featureFlag.enabled('FEED_TO_STOREFRONT_20571')) {
    return (
      <FollowButton follow={follow} followButtonClick={followClick} />
    );
  }

  return (
    <FollowButtonDeprecated follow={follow} followButtonClick={followClick} />
  );
};

FollowButtonContainer.defaultProps = {
  subscriptionData: null,
  followData: null,
};

FollowButtonContainer.propTypes = {
  fetchStateFromApi: PropTypes.bool.isRequired,
  subscriptionData: PropTypes.object,
  followData: PropTypes.object,
};

export default FollowButtonContainer;
