import React from 'react';
import PropTypes from 'prop-types';

import ListingShape from 'app/shapes/ListingShape';

import {
  Grid,
  Stack,
} from '@mui/material';

import Typography from '@mui/material/Typography';

import { toNumber } from 'app/helpers/currencyHelpers';

const TalkTime = ({
  balance,
  listing,
  isLoading,
}) => {
  const talkTime = (balance, listing) => {
    return Math.floor(toNumber(balance.available_balance) / listing.price_per_minute);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: 550,
        margin: 'auto',
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          Talk Time
        </Typography>
      </Grid>
      <Grid
        item
        xs={9}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography variant="body1">
            {isLoading ? '-' : `${talkTime(balance, listing)} Minutes`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

TalkTime.defaultProps = {
  balance: null,
  listing: null,
};

TalkTime.propTypes = {
  balance: PropTypes.object,
  listing: ListingShape,
  isLoading: PropTypes.bool.isRequired,
};

export default TalkTime;
