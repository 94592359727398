import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import AddMediaContext from 'app/contexts/AddMediaContext';
import FileManagerTab from 'app/components/posts/FileManagerTab';
import UploadFilesTab from 'app/components/posts/UploadFilesTab';
import { FILE_MANAGER_TAB, UPLOAD_TAB } from 'app/constants/tabNames';

const CancelButton = ({ uploadInProgress, cancelClick }) => {
  return (
    <a
      role="button"
      tabIndex={0}
      className={`btn secondary btn-cancel ${uploadInProgress ? 'disabled' : ''}`}
      onClick={() => {
        if (uploadInProgress) { return; }
        cancelClick();
      }}
    >
      Cancel
    </a>
  );
};

CancelButton.propTypes = {
  uploadInProgress: PropTypes.bool.isRequired,
  cancelClick: PropTypes.func.isRequired,
};

const AddToPostButton = ({ uploadInProgress, addToPostClick }) => {
  return (
    <button
      type="button"
      disabled={uploadInProgress}
      className={`btn ${uploadInProgress ? 'disabled' : ''}`}
      onClick={() => {
        if (uploadInProgress) { return; }
        addToPostClick();
      }}
    >
      Add to Post
    </button>
  );
};

AddToPostButton.propTypes = {
  uploadInProgress: PropTypes.bool.isRequired,
  addToPostClick: PropTypes.func.isRequired,
};

const CloseButton = ({ uploadInProgress, closeModal }) => {
  return (
    <button
      type="button"
      disabled={uploadInProgress}
      className={`btn ${uploadInProgress ? 'disabled' : ''}`}
      onClick={() => {
        if (uploadInProgress) { return; }
        closeModal();
      }}
    >
      Close
    </button>
  );
};

CloseButton.propTypes = {
  uploadInProgress: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const AddMediaModal = () => {
  const {
    closeModal,
    currentTab,
    setCurrentTab,
    cancelClick,
    addToPostClick,
    uploadInProgress,
    anyUploadErrors,
    profileImageMode,
  } = useContext(AddMediaContext);

  return (
    <div data-test-id="add-media-modal" className="nfp-modal">
      <div className="modal-content">
        <div className="modal-close-container">
          <a href="#" onClick={closeModal}>
            <img src="/plus_frontend/assets/svg/x-close.svg" className="modal-close" alt="close" />
          </a>
        </div>
        <div className="modal-header">
          <h4>Select Files</h4>
        </div>
        <div className="modal-body" style={{ margin: '0 -20px' }}>
          <div className="container-summary">
            <div className="row middle-xs">
              <div
                className={`col-sm-4 col-sm-offset-2 col-xs-5 col-xs-offset-1 center-xs summary-tab ${currentTab === FILE_MANAGER_TAB ? 'active' : ''}`}
                role="link"
                tabIndex={0}
                onClick={() => {
                  if (uploadInProgress) { return; }
                  setCurrentTab(FILE_MANAGER_TAB);
                }}
              >
                File Manager
              </div>
              <div
                className={`col-sm-4 col-xs-5 center-xs summary-tab ${currentTab === UPLOAD_TAB ? 'active' : ''}`}
                role="link"
                tabIndex={0}
                onClick={() => {
                  if (uploadInProgress) { return; }
                  setCurrentTab(UPLOAD_TAB);
                }}
              >
                Upload
              </div>
            </div>
          </div>
          {currentTab === FILE_MANAGER_TAB && <FileManagerTab />}
          {currentTab === UPLOAD_TAB && <UploadFilesTab />}
        </div>
        <div className="modal-footer">
          {!anyUploadErrors && (
            <>
              <CancelButton
                uploadInProgress={uploadInProgress}
                cancelClick={cancelClick}
              />
              {!profileImageMode && (
                <AddToPostButton
                  addToPostClick={addToPostClick}
                  uploadInProgress={uploadInProgress}
                />
              )}
            </>
          )}
          {anyUploadErrors && (
            <CloseButton
              uploadInProgress={uploadInProgress}
              closeModal={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMediaModal;
