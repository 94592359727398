import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const BlurBox = styled(Box)({
  zIndex: '-1',
  filter: 'blur(10px) opacity(80%)',
  transform: 'translate3d(0, 0, 0)',
  overflow: 'hidden',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '200%',
  width: '150px',
  height: '180px',
  clipPath: 'inset(0)',
});

export default BlurBox;
