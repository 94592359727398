import React from 'react';
import PropTypes from 'prop-types';

import ListingShape from 'app/shapes/ListingShape';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

const CannotCallSelfError = ({
  isLoading,
  listing,
  viewer,
}) => {
  if (isLoading || listing.advisor_id !== viewer.id) {
    return null;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{ textAlign: 'center' }}
      mt={3}
    >
      <Typography variant="error">
        You cannot call yourself
      </Typography>
      <Box mt={1}>
        <a href="/account">
          <Button
            variant="contained"
            size="small"
          >
            Back to Account
          </Button>
        </a>
      </Box>
    </Stack>
  );
};

CannotCallSelfError.defaultProps = {
  viewer: null,
  listing: null,
};

CannotCallSelfError.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  viewer: PropTypes.object,
  listing: ListingShape,
};

export default CannotCallSelfError;
