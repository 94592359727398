import ManageCardsModalContainer from 'app/containers/modals/ManageCardsModalContainer';
import RetrySubscribeModalContainer from 'app/containers/modals/RetrySubscribeModalContainer';
import PickANewCardModal from 'app/components/modals/subscriptions/PickANewCardModal';
import AddToBalanceModalContainer from 'app/containers/modals/AddToBalanceModalContainer';

const useCreditCardManagement = ({
  guard,
  setGuard,
  openModal,
  currentUser,
  onTryAgainSuccess,
  onManageCardsSuccess,
  onAddToBalanceSuccess,
}) => {
  const openAddToBalanceModal = (deficit, context, assetName, customCloseFunc) => {
    openModal({
      component: AddToBalanceModalContainer,
      props: {
        deficit,
        context,
        assetName,
        afterCloseAction: onAddToBalanceSuccess,
      },
      customCloseFunc: () => {
        if (setGuard) setGuard(false);
        if (customCloseFunc) customCloseFunc();
      },
    });
  };

  const openManageCardsModal = (forceOpen = false, onCloseClickCallback = null) => {
    if (guard && !forceOpen) return;
    if (setGuard) setGuard(true);

    openModal({
      props: {
        afterSuccessAction: onManageCardsSuccess,
      },
      component: ManageCardsModalContainer,
      customCloseFunc: () => {
        if (setGuard) setGuard(false);
        if (onCloseClickCallback) onCloseClickCallback();
      },
    });
  };

  const openRetrySubscribeModal = () => {
    openModal({
      component: RetrySubscribeModalContainer,
      props: {
        currentUser,
        clickAction: onTryAgainSuccess,
        useDifferentCardAction: openManageCardsModal,
      },
    });
  };

  const openPickANewCardModal = () => {
    if (guard) return;

    openModal({
      component: PickANewCardModal,
      props: {
        currentUser,
        clickAction: openManageCardsModal,
      },
      customCloseFunc: () => { if (setGuard) setGuard(false); },
    });
  };

  return {
    openManageCardsModal,
    openRetrySubscribeModal,
    openPickANewCardModal,
    openAddToBalanceModal,
  };
};

export default useCreditCardManagement;
