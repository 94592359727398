/* eslint-disable react/no-danger */
import React from 'react';
import { PropTypes } from 'prop-types';

const PaymentButtonPreview = ({ loggedIn, url, isOwner }) => {
  const id = url.split('=')[1];

  if (!loggedIn) {
    return (
      <a href={`/messages/preview_payment_button?id=${id}`} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  return (
    <iframe title={`Link to payment button ${id}`} src={`/messages/preview_payment_button?id=${id}`} className={isOwner ? "owner-pb-iframe" : "payment-button-iframe"} />
  );
};

PaymentButtonPreview.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default PaymentButtonPreview;
