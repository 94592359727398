import React from 'react';
import PhoneShape from 'app/shapes/PhoneShape';

import {
  Box,
  Typography,
} from '@mui/material';

import Connecting from 'app/components/call/callInProgress/Icons/Connecting';

const CreatedStep = ({ selectedPhone }) => {
  return (
    <Box
      textAlign="center"
      mt={3}
    >
      <Typography variant="h3">
        We are calling your number&nbsp;
        {selectedPhone.secure_number}
      </Typography>
      <Box mt={3} mb={3}>
        <Connecting />
      </Box>
      <Typography variant="body1" gutterBottom>
        Answer the phone, follow the prompts.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Press &apos;1&apos; to start the call.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You won&apos;t be charged until the conversation begins.
      </Typography>
    </Box>
  );
};

CreatedStep.propTypes = {
  selectedPhone: PhoneShape.isRequired,
};

export default CreatedStep;
