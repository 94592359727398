import React from 'react';
import { PropTypes } from 'prop-types';

const PickANewCardModal = ({ closeModal, currentUser, clickAction }) => {
  let title;
  let ctaLabel;
  let body;

  if (currentUser?.default_card?.card_type) {
    title = 'Please Use A Different Card';
    ctaLabel = 'Use Different Card';
    body = `We're sorry, we're unable to charge your ${currentUser.default_card.card_type} ending in ${currentUser.default_card.last_four}. Please use a different one.`;
  } else {
    title = 'Please Add a Card';
    ctaLabel = 'Add Card';
    body = 'We tried to charge you for your subscription but you do not have a card on file. Please add a card.';
  }

  return (
    <>
      <div className="modal-header">
        <h4>{title}</h4>
      </div>
      <div className="modal-body">
        <p>{body}</p>
      </div>
      <div className="modal-footer">
        <a role="link" tabIndex={0} className="btn secondary" onClick={closeModal}>
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            // need to close modal before calling click action,
            // b/c click action is to open another modal!
            // it will not show otherwise
            closeModal();
            clickAction();
          }}
        >
          {ctaLabel}
        </button>
      </div>
    </>
  );
};

PickANewCardModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  clickAction: PropTypes.func.isRequired,
};

export default PickANewCardModal;
