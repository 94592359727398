import React from 'react';
import { PropTypes } from 'prop-types';

const RetrySubscribeModal = ({
  closeModal,
  currentUser,
  useDifferentCardAction,
  clickAction,
  pendingSubRequest,
}) => {
  // TODO: distinguish between a decline and a platphorm-internal error, and conditionally
  // replace first sentence with "We're sorry, we encountered a system error."

  return (
    <>
      <div className="modal-header">
        <h4>Something Went Wrong</h4>
      </div>
      <div className="modal-body">
        <p>
          We&apos;re sorry, your bank has declined the transaction. Please try again or try a different card.
        </p>
        <p>
          { currentUser.default_card.card_type }
          : XXXX-XXXX-XXXX-
          { currentUser.default_card.last_four }
        </p>
        <p><a role="link" tabIndex={0} onClick={useDifferentCardAction}>Use a different card</a></p>
      </div>
      <div className="modal-footer">
        <a role="link" tabIndex={0} className="btn secondary" onClick={closeModal}>
          Cancel
        </a>
        <button
          type="button"
          className="btn btn-submit"
          onClick={() => {
            clickAction();
          }}
          disabled={pendingSubRequest}
        >
          Retry
        </button>
      </div>
    </>
  );
};

RetrySubscribeModal.defaultProps = {
  pendingSubRequest: false,
};

RetrySubscribeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  clickAction: PropTypes.func.isRequired,
  useDifferentCardAction: PropTypes.func.isRequired,
  pendingSubRequest: PropTypes.bool,
};

export default RetrySubscribeModal;
