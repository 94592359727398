import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import withTooltip from 'app/hoc/withTooltip';

const PendingTooltip = ({
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
  imageType,
}) => {
  return (
    <Tooltip
      content={(
        <div ref={(el) => setTipContentRef(el)} className="nfp-tooltip">
          <div className="row">
            <div className="col-xs-11">
              <div className="menu-text">
                Your NF+
                {' '}
                {imageType}
                {' '}
                is pending review and will be automatically published if approved.
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a role="link" tabIndex={0} onClick={toggleTip}><img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" /></a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
      >
        <img src="/plus_frontend/assets/svg/ico-pending.svg" style={{ margin: '1px 10px 2px 0' }} alt="Pending" />
      </a>
    </Tooltip>
  );
};

PendingTooltip.propTypes = {
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
};

export default withTooltip(PendingTooltip);
