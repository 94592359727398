import React from 'react';
import { PropTypes } from 'prop-types';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled(({ expand, ...other }) => {
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MonetaryForm = ({
  amountError,
  amountInvalid,
  messageOpen,
  sendTributeClick,
  setAmount,
  setAmountError,
  setMessage,
  setMessageOpen,
  tributeAmount,
  tributeMessage,
}) => {
  return (
    <>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>Recurring Tribute:</Typography>
      <Grid item xs={12}>
        <Paper elevation={0} variant="outlined" style={{ padding: '30px 20px 10px 20px', border: '1px solid #ddd', borderRadius: '1px' }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <TextField
              value={tributeAmount}
              type="number"
              inputMode="decimal"
              error={!!amountError}
              helperText={amountError}
              data-test-id="tribute-amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              size="small"
              onChange={(e) => {
                setAmountError('');
                setAmount(e.currentTarget.value);
              }}
            />
            <Typography variant="body1" sx={{ mt: 1 }}>
              per month
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              disabled={amountInvalid(tributeAmount)}
              onClick={sendTributeClick}
            >
              Tribute
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Enter the amount you want to send monthly
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
            <Typography
              variant="body1"
              sx={{
                color: '#06c',
                marginTop: '0px',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => setMessageOpen(!messageOpen)}
            >
              Add a personal message (Optional)
            </Typography>
            <ExpandMore
              aria-label="expand"
              // Ternary is necessary to prevent warning
              expand={messageOpen ? 1 : 0}
              size="small"
              style={{ color: '#06c' }}
              onClick={() => setMessageOpen(!messageOpen)}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Stack>
          <Collapse in={messageOpen}>
            <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={tributeMessage}
                data-test-id="tribute-message"
                label="Personal Message (optional)"
                onChange={(e) => { setMessage(e.currentTarget.value); }}
              />
            </Stack>
          </Collapse>
        </Paper>
      </Grid>
    </>
  );
};

MonetaryForm.propTypes = {
  amountError: PropTypes.string.isRequired,
  amountInvalid: PropTypes.func.isRequired,
  messageOpen: PropTypes.bool.isRequired,
  sendTributeClick: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setAmountError: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setMessageOpen: PropTypes.func.isRequired,
  tributeAmount: PropTypes.string.isRequired,
  tributeMessage: PropTypes.string.isRequired,
};

export default MonetaryForm;
