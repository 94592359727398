import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const withTooltip = (Component) => {
  const hoc = (props) => {
    const [tipOpen, setTipOpen] = useState(false);
    const [buttonRef, setButtonRef] = useState(null);
    const [tipContentRef, setTipContentRef] = useState(null);

    useEffect(() => {
      const bodyClick = (e) => {
        if ((tipContentRef && tipContentRef.contains(e.target)) || buttonRef.contains(e.target)) {
          return;
        }

        setTipOpen(false);
      };

      document.addEventListener('mousedown', bodyClick);

      return () => {
        document.removeEventListener('mousedown', bodyClick);
      };
    });

    const toggleTip = () => {
      setTipOpen(!tipOpen);
    };

    return (
      <Component
        toggleTip={toggleTip}
        tipOpen={tipOpen}
        setButtonRef={setButtonRef}
        setTipContentRef={setTipContentRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };

  return hoc;
};

withTooltip.propTypes = {
  component: PropTypes.object.isRequired,
};

export default withTooltip;
