import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import { yellow } from '@mui/material/colors';

const SecondaryAuthDialog = ({ checkPassword, closeDialog, createSecondaryAuthResult, hideCancel }) => {
  const [password, setPassword] = useState('');

  // Focus on the password input field when the dialog opens
  useEffect(() => {
    document.getElementById('secondary-auth-password').focus();
  }, []);

  return (
    <>
      <DialogTitle id="customized-dialog-title">
        Enter Password
      </DialogTitle>
      {!hideCancel && (
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        <Box mt={-3}>
          <Grid container mb={1}>
            <Grid item xs={12} className="pull-right">
              <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <LockIcon sx={{ color: yellow[800] }} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    This page is secure
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ border: '1px solid #ddd', p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography sx={{ color: '#666', fontSize: '1.188em', mb: 2, mt: -1 }}>
                Enter your password to continue
              </Typography>
              <Typography sx={{ lineHeight: '1.5em' }}>
                To protect the privacy of your account and your personal information, please sign into our secure server.&nbsp;
                <a href="#">Why do I need to enter my password again?</a>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: { xs: 0, sm: 2 } }}>
              <Grid container spacing={2}>
                <Grid item sm={3} xs={12} sx={{ textAlign: { xs: 'left', sm: 'right' }, marginBottom: { xs: -1, sm: 0 } }}>
                  <Typography sx={{ mt: 1 }}>
                    Password:
                  </Typography>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <TextField
                    id="secondary-auth-password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') { checkPassword(password); } }}
                    error={createSecondaryAuthResult?.length > 0}
                    helperText={createSecondaryAuthResult}
                    sx={{ maxWidth: '200px' }}
                  />
                  <Typography mt={1} sx={{ fontSize: '0.875em' }}>
                    <a href="/forgot_password">Forgot Password?</a>
                  </Typography>
                  <Grid container spacing={2} justifyContent="flex-start" mt={0.5}>
                    <Grid item>
                      <Button variant="contained" size="medium" onClick={() => { checkPassword(password); }}>
                        Continue &gt;
                      </Button>
                    </Grid>
                    { !hideCancel && (
                      <Grid item>
                        <Button variant="contained" size="medium" onClick={closeDialog}>
                          Cancel
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
};

SecondaryAuthDialog.defaultProps = {
  createSecondaryAuthResult: '',
  hideCancel: false,
};

SecondaryAuthDialog.propTypes = {
  checkPassword: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  createSecondaryAuthResult: PropTypes.string,
  hideCancel: PropTypes.bool,
};

export default SecondaryAuthDialog;
