import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { featureFlag } from 'app/util/featureFlag';

const SubscribedModal = ({ closeModal, advisorLogin }) => {
  if (featureFlag.enabled('FEED_TO_STOREFRONT_20571')) {
    window.location.href = `/${advisorLogin}/feed`;
    return null;
  }

  return (
    <>
      <div className="modal-header">
        <h4>Success</h4>
      </div>
      <div className="modal-body">
        <p>
          Your subscription has been charged.
        </p>
      </div>
      <div className="modal-footer">
        <Link to={`/feed/${advisorLogin}`} onClick={closeModal}>
          <button type="button" className="btn">
            Go to&nbsp;
            {advisorLogin}
            's feed
          </button>
        </Link>
      </div>
    </>
  );
};

SubscribedModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  advisorLogin: PropTypes.string.isRequired,
};

export default SubscribedModal;
