import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import { resetHomeFeed } from 'app/slices/resetRecordsSlice';

const NewPostsFlash = ({ closeFlash }) => {
  const dispatch = useDispatch();

  const closeWithSideEffect = () => {
    dispatch(resetHomeFeed());
    closeFlash();
  };

  return (
    <>
      <div className="col-xs-10">
        There are new posts.&nbsp;
        <button className="btn btn-sm" type="button" onClick={closeWithSideEffect}>Load Posts</button>
      </div>
      <div className="col-xs-2 end-xs">
        <a role="link" tabIndex={0} onClick={closeFlash}>
          <img src="/plus_frontend/assets/svg/x-close.svg" alt="close" />
        </a>
      </div>
    </>
  );
};

NewPostsFlash.propTypes = {
  closeFlash: PropTypes.func.isRequired,
};

export const NEW_POSTS_FLASH = 'NEW_POSTS_FLASH';
export default NewPostsFlash;
