import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Unstable_Grid2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingContainer from 'app/containers/ChatListingContainer';

const CategoryListings = ({
  currentTab,
  currentSort,
  handleTabChange,
  handleSortChange,
  listingsData,
  sorts,
  tabs,
}) => {
  return (
    <>
      {/* tabs and sort by selector */}
      <Grid container>
        <Grid item xs={12} sm={9}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={3} container justifyContent="flex-end">
          {currentTab !== 3 && (
            <FormControl sx={{ zIndex: 10 }}>
              <InputLabel id="sort-by-label">Sort by</InputLabel>
              <Select
                labelId="sort-by-label"
                value={currentSort}
                label="Sort by"
                onChange={handleSortChange}
                sx={{ marginRight: '10px' }}
              >
                {sorts.map((sort) => (
                  <MenuItem key={sort.value} value={sort.value}>
                    {sort.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Box
        px={{ xs: 0, sm: 2 }}
        pt={{ xs: 2.5, sm: 3.5 }}
        pb={{ xs: 10, sm: 10 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: { xs: '3px', sm: '3px' },
            justifyContent: 'center',
          }}
        >
          {listingsData?.listings.map((listing) => {
            return (
              <Box
                key={`listing_${listing.id}`}
                sx={{
                  width: { xs: '330px', sm: '330px' },
                  flex: { xs: '0 0 330px', sm: '0 0 330px' },
                }}
              >
                {listingsData.chat ? (
                  <ChatListingContainer advisor={listing} />
                ) : (
                  <PhoneListingContainer listing={listing} />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

CategoryListings.defaultProps = {
  listingsData: null,
  currentSort: 0,
};

CategoryListings.propTypes = {
  currentTab: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  listingsData: PropTypes.object,
  currentSort: PropTypes.number,
  sorts: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
};

export default CategoryListings;
