import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';

import { useGetAppConfigQuery } from 'app/api/mainApi';

const StackNavMail = styled(Stack)(() => ({
  '&.MuiStack-root': {
    '& .MuiBadge-root': {
      '& .MuiBadge-badge': {
        marginRight: '18px',
        backgroundColor: '#ff8700',
      },
    },
  },
}));

const UnreadMailBadge = ({ linkPath }) => {
  const { data: appConfig } = useGetAppConfigQuery();

  return (
    <StackNavMail direction="row">
      <a href={linkPath} title="Mail">
        <Badge
          color="success"
          badgeContent={appConfig?.unread_mail_count}
          max={99}
        >
          <MailIcon className="nav-icon" />
        </Badge>
        <span className="nav-text">Mail</span>
      </a>
    </StackNavMail>
  );
};

UnreadMailBadge.defaultProps = {
  linkPath: '/messages/inbox',
};

UnreadMailBadge.propTypes = {
  linkPath: PropTypes.string,
};

export default UnreadMailBadge;
