import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeFlash } from 'app/slices/flashSlice';
import GenericFlash from 'app/components/flashes/GenericFlash';
import NewPostsFlash, { NEW_POSTS_FLASH } from 'app/components/flashes/NewPostsFlash';

const ensureProperFlashType = (type) => {
  if (!['global', 'feed', 'error', 'success'].includes(type)) {
    throw new Error('flashType must be one of [global, feed, error, success]');
  }
};

const getComponent = (comp) => {
  switch (comp) {
  case NEW_POSTS_FLASH:
    return NewPostsFlash;
  default:
    return GenericFlash;
  }
};

const FlashContainer = () => {
  const dispatch = useDispatch();

  const {
    component,
    props,
    closeFunc,
    autoCloseDur,
    flashType,
  } = useSelector(state => state.flashes);

  if (!flashType) { return null; }

  ensureProperFlashType(flashType);

  const FlashComponent = getComponent(component);
  const autoClose = autoCloseDur || 3000;

  const enhancedCloseFunc = () => {
    if (closeFunc) {
      closeFunc();
    }

    dispatch(closeFlash());
  };

  setTimeout(() => { enhancedCloseFunc(); }, autoClose);

  return (
    <div
      className={`container-alert ${flashType} ${flashType}-alert`}
      style={{ zIndex: 999 }}
    >
      <div className="container-max">
        <div className="container-fluid padding0">
          <div className="row middle-xs">
            { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
            <FlashComponent {...props} closeFlash={enhancedCloseFunc} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashContainer;
