import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

export const FormWrapper = ({ form, children }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <form>
        {children}
      </form>
    </FormProvider>
  );
};

FormWrapper.propTypes = {
  form: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormWrapper;
