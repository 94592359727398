import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import ModalsContext from 'app/contexts/ModalsContext';
import MediaModalContainer from 'app/containers/MediaModalContainer';
import FlirtAvatarImage from 'app/components/profile/FlirtAvatarImage';
import FlirtCoverImage from 'app/components/profile/FlirtCoverImage';
import FollowButtonContainer from 'app/containers/FollowButtonContainer';
import { timestampInTimezone, DATE_FORMAT } from 'app/helpers/timestampHelpers';
import ProfilePageControls from 'app/redesign/components/ProfilePageControls';
import StickyProfilePageControls from 'app/redesign/components/StickyProfilePageControls';

import { featureFlag } from 'app/util/featureFlag';
import { feedsUrlForUser } from 'app/helpers/userHelper';
import HeaderContactButtons from 'app/redesign/components/HeaderContactButtons';

function ProfilePageHeader({ stickyNavVisible, advisor, currentUser, viewingSelf }) {
  const { openModal } = useContext(ModalsContext);
  const [selectedItems, setSelectedItems] = useState([]);

  const feedsUrl = feedsUrlForUser(advisor);

  const openSelectFilesModal = (mode) => {
    openModal({
      component: MediaModalContainer,
      props: {
        selectedItems,
        setSelectedItems,
        advisor,
        profileImageMode: mode,
      },
    });
  };

  return (
    <>
      <div className="nfp-hero">
        <div className="container-max">
          <ProfilePageControls advisor={advisor} currentUser={currentUser} />
          <div className="container-fluid container-profile-info">
            <div className="row nowrap-sm">
              <div className="col-sm-3 col-xs-4 center-xs first-part">
                {/* new element/class */}
                <div className="profile-avatar-block">
                  <FlirtAvatarImage
                    viewingSelf={viewingSelf}
                    openSelectFilesModal={openSelectFilesModal}
                    advisor={advisor}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-xs-8 start-xs second-part">
                <div className="profile-margin1">
                  <div className="row middle-xs">
                    <div className="profile-name-block">
                      <h2>{advisor?.login}</h2>
                    </div>
                    <div>
                      {!featureFlag.enabled('FEED_TO_STOREFRONT_20571') && advisor?.onboarding_complete && (
                        <Link
                          title="Feed"
                          to={feedsUrl}
                          role="link"
                        >
                          <img
                            src="/plus_frontend/assets/svg/ico-feed.svg"
                            alt="Feed"
                            className="ico-hero-feed"
                          />
                        </Link>
                      )}
                    </div>

                  </div>
                  <div className="row middle-xs">
                    {featureFlag.enabled('FEED_TO_STOREFRONT_20571') && advisor?.onboarding_complete && (
                      <div className="marginrgt15 margintop7">
                        <FollowButtonContainer fetchStateFromApi />
                      </div>
                    )}
                    {advisor?.show_member_since && (
                      <div className="member-since">
                        Member Since&nbsp;
                        {timestampInTimezone(advisor?.member_since, currentUser?.canonical_iana_timezone, DATE_FORMAT)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-3 hidden-xs">
                <div className="profile-margin2">
                  <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion={false} />
                </div>
              </div>
            </div>
            {/* new element/class */}
            <div className="row row-white" />
          </div>
        </div>
      </div>

      <div className="profile-edit-cover-pos">
        <FlirtCoverImage
          viewingSelf={viewingSelf}
          openSelectFilesModal={openSelectFilesModal}
          advisor={advisor}
        />
      </div>
      <HeaderContactButtons advisor={advisor} currentUser={currentUser} mobileVersion />

      <StickyProfilePageControls advisor={advisor} currentUser={currentUser} stickyNavVisible={stickyNavVisible} />
    </>
  );
}

ProfilePageHeader.defaultProps = {
  stickyNavVisible: false,
  currentUser: {},
  advisor: {},
  viewingSelf: false,
};

ProfilePageHeader.propTypes = {
  stickyNavVisible: PropTypes.bool,
  currentUser: PropTypes.object,
  advisor: PropTypes.object,
  viewingSelf: PropTypes.bool,
};

export default ProfilePageHeader;
