import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const NameTypography = styled(Typography)({
  fontWeight: '300',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  position: 'relative',
});

export default NameTypography;
