import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useGetAvailabilityQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import ChatStatusMenu from 'app/components/availabilityMenu/ChatStatusMenu';
import CallMonitorToggle from 'app/redesign/components/availability/CallMonitorToggle';
import QuietHoursToggle from 'app/redesign/components/availability/QuietHoursToggle';
import AdvisorCallStatusMenu from 'app/components/availabilityMenu/AdvisorCallStatusMenu';

const AccordionNav = styled(Accordion)(() => ({
  '&.MuiPaper-root': {
    boxShadow: 'none',
    padding: '0px 0px',
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      color: 'var(--link_color)',
      fontSize: '0.850em',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      minHeight: 'auto',
      margin: '12px 0px',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
    },
  },
}));

const AdvisorAvailabilityMenuContainer = ({ onClick }) => {
  const { data: availability } = useGetAvailabilityQuery();
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;

  if (availability && availability.phones.length === 0) {
    return (
      <Box data-test-id="advisor-availability-menu-container" sx={{ mr: 2 }}>
        <a className="bluelink" href="/availability" onClick={onClick}>
          Add Phone Number
        </a>
      </Box>
    );
  }

  const currentCallPhone = availability?.phones?.find((phone) => phone.is_current_call_phone);
  const currentChatPhone = availability?.phones?.find((phone) => phone.is_current_chat_phone);

  return (
    <Box data-test-id="advisor-availability-menu-container" sx={{ mr: 2 }}>
      <AdvisorCallStatusMenu phone={currentCallPhone} callStatuses={availability?.call_statuses} onClick={onClick} />
      <AccordionNav>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: '0px' }}
        >
          More Options
        </AccordionSummary>
        <AccordionDetails>
          <ChatStatusMenu
            availability={availability}
            enrolledInChat={availability?.enrolled_in_chat}
            explicitlyUnenrolledInChat={!!availability?.explicitly_unenrolled_in_chat}
            phone={currentChatPhone}
            chatStatuses={availability?.chat_statuses}
            onClick={onClick}
            chatProfileId={availability?.chat_profile_id}
          />
          <Divider />
          <CallMonitorToggle availability={availability} />
          {!currentUser?.should_hide_availability_options && (
            <QuietHoursToggle availability={availability} />
          )}
        </AccordionDetails>
      </AccordionNav>
    </Box>
  );
};

AdvisorAvailabilityMenuContainer.defaultProps = {
  onClick: () => { },
};

AdvisorAvailabilityMenuContainer.propTypes = {
  onClick: PropTypes.func,
};

export default AdvisorAvailabilityMenuContainer;
