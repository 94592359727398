import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  useGetAppConfigQuery,
  useUpdateSubscriptionOfferingMutation,
  useCreatePackageMutation,
} from 'app/api/mainApi';

import SubscriptionSettings from 'app/components/SubscriptionSettings';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import GenericModal from 'app/components/modals/GenericModal';
import ModalsContext from 'app/contexts/ModalsContext';
import { showFlash } from 'app/slices/flashSlice';
import { featureFlag } from 'app/util/featureFlag';
import { setPackageRate } from 'app/slices/packageSlice';
import DialogsContext from 'app/contexts/DialogsContext';
import PackagePerksPromptDialog from 'app/components/onboarding/PackagePerksPromptDialog';

const SubscriptionSettingsContainer = () => {
  const recurringPackageTribsEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');

  // FF
  const MAX_PRICE = recurringPackageTribsEnabled ? 999.0 : 50.0;

  const MIN_PRICE = 3.99;
  const DEFAULT_PRICE = 14.99;
  const BASE_SUCCESS_MSG = 'Your changes were saved.';
  const CREATE_POST_MSG = 'You can now create your first post.';

  const params = new URLSearchParams(window.location.search);
  const inOnboardingFlow = params.get('onboarding') === 'true';
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const [newRate, setNewRate] = useState();
  const [initialRate, setInitialRate] = useState();
  const [rangeError, setRangeError] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const { openModal } = useContext(ModalsContext);
  const { openDialog, closeDialog } = useContext(DialogsContext);
  const { rate: packageOnboardingRate } = useSelector(state => state.packageOnboarding);
  const { feedAccess: packageOnboardingFeedAccess } = useSelector(state => state.packageOnboarding);

  const [
    updateSubscriptionOffering, {
      status: updateSubscriptionStatus,
      data: rateData,
    },
  ] = useUpdateSubscriptionOfferingMutation();

  const [
    createPackage, {
      isSuccess: createPackageSuccess,
      error: createPackageError,
    },
  ] = useCreatePackageMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateAndSetRate = (val) => {
    const inputRate = parseFloat(val);

    if (inputRate < MIN_PRICE || inputRate > MAX_PRICE) {
      const errorCode = inputRate > MAX_PRICE ? 'hi' : 'lo';
      setRangeError(errorCode);
    } else {
      setRangeError(null);
    }

    if (recurringPackageTribsEnabled) {
      dispatch(setPackageRate({ rate: val }));
    }

    // TODO:
    // when we move away from subscriptions altogether, we can remove the local
    // state and just use the redux store (as above)
    setNewRate(val);
  };

  useEffect(() => {
    if (rateData?.rate) {
      validateAndSetRate(rateData.rate);
    }
  }, [rateData]);

  // this useEffect will only fire when we're not recurringPackageTribsEnabled
  // otherwise, we'll prompt the user if they want to add perks to the package
  // we're currently creating as part of the onboarding flow
  useEffect(() => {
    if (updateSubscriptionStatus === 'fulfilled') {
      let message = BASE_SUCCESS_MSG;
      if (currentUser.offerings.length === 0) {
        message = `${message} ${CREATE_POST_MSG}`;
      }

      dispatch(showFlash({ flashType: 'success', props: { message } }));

      if (inOnboardingFlow) {
        navigate(`/feed/${currentUser.login}/onboarding`);
      }
    }
  }, [updateSubscriptionStatus]);

  useEffect(() => {
    let userRate = DEFAULT_PRICE;

    if (currentUser?.offerings?.length > 0) {
      userRate = currentUser.offerings[0].rate.toFixed(2).toString();
      setInitialRate(userRate);
    }

    validateAndSetRate(userRate);
  }, [currentUser]);

  const createOnboardingPackage = () => {
    return createPackage({
      rate: packageOnboardingRate,
      status: 'public_package',
      feedAccess: packageOnboardingFeedAccess,
    });
  };

  const updateSubscriptionOrContinuePackageOnboarding = ({ newRate }) => {
    if (recurringPackageTribsEnabled) {
      dispatch(setPackageRate({ rate: newRate }));

      // show the new dialog asking to add perks
      openDialog({
        component: PackagePerksPromptDialog,
        props: {
          closeDialog,
          createOnboardingPackage,
          currentUser,
        },
      });
    } else {
      updateSubscriptionOffering({ newRate });
    }
  };

  const onSubmitClick = () => {
    if (rangeError || saveDisabled) { return; }

    if (Number(newRate) > Number(initialRate)) {
      openModal({
        component: GenericModal,
        props: {
          header: 'Confirm Price Increase',
          body: 'Increasing the price will turn off auto-renewal for current subscribers and require them to accept the increased price to continue their subscriptions. Are you sure you want to increase the price?',
          ctaButtonLabel: 'Confirm',
          callToAction: () => {
            updateSubscriptionOrContinuePackageOnboarding({ newRate });
          },
        },
        customCloseFunc: () => { validateAndSetRate(initialRate); },
      });
    } else {
      updateSubscriptionOrContinuePackageOnboarding({ newRate });
    }
  };

  const onCancelClick = () => {
    if (cancelDisabled) { return; }
    validateAndSetRate(initialRate || DEFAULT_PRICE);

    if (inOnboardingFlow) {
      navigate(`/feed/${currentUser.login}/onboarding`);
    }
  };

  useEffect(() => {
    if (!initialRate) {
      setSaveDisabled(false);

      // FF
      if (recurringPackageTribsEnabled && !inOnboardingFlow) {
        setCancelDisabled(true);
      }
    } else if (initialRate === newRate) {
      setSaveDisabled(true);

      // FF
      if (recurringPackageTribsEnabled && !inOnboardingFlow) {
        setCancelDisabled(true);
      }
    } else {
      setSaveDisabled(false);
      setCancelDisabled(false);
    }
  }, [initialRate, newRate]);

  return (
    <AppBodyWithSidebar>
      <SubscriptionSettings
        onSubmitClick={onSubmitClick}
        onCancelClick={onCancelClick}
        rate={newRate}
        setRate={validateAndSetRate}
        rangeError={rangeError}
        saveDisabled={saveDisabled}
        cancelDisabled={cancelDisabled}
      />
    </AppBodyWithSidebar>
  );
};

export default SubscriptionSettingsContainer;
