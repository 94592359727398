import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

const CreatePackageTooltip = ({ atMaxPublicPackages }) => {
  const [tipOpen, setTipOpen] = useState(false);
  const tipContentRef = useRef(null);
  const buttonRef = useRef(null);

  const toolTipCopy = atMaxPublicPackages ?
    'You already have 4 public Packages. To create another public Package, you\'ll need to switch one of your existing public Packages to private.' :
    'Private Packages are hidden on your profile.';

  const toggleTip = () => {
    setTipOpen(prevTipOpen => !prevTipOpen);
  };

  const bodyClick = (e) => {
    if ((tipContentRef.current && tipContentRef.current.contains(e.target)) || buttonRef.current.contains(e.target)) {
      return;
    }

    setTipOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', bodyClick);

    return () => {
      document.removeEventListener('mousedown', bodyClick);
    };
  }, []);

  return (
    <Tooltip
      content={(
        <div ref={tipContentRef} className="nfp-tooltip">
          <div className="row">
            <div className="col-xs-11">
              <div className="menu-text">
                {toolTipCopy}
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a role="link" tabIndex={0} onClick={toggleTip}><img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" /></a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={buttonRef}
        onClick={toggleTip}
      >
        <img src="/plus_frontend/assets/svg/ico-info.svg" className="margintop4" alt="info" />
      </a>
    </Tooltip>
  );
};

CreatePackageTooltip.defaultProps = {
  atMaxPublicPackages: false,
};

CreatePackageTooltip.propTypes = {
  atMaxPublicPackages: PropTypes.bool,
};

export default CreatePackageTooltip;
