import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import NumberInput from 'app/components/favorite_listings/NumberInput';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialog-paperWidthSm': {
    width: '100%',
    maxWidth: '400px',
    margin: '0px',
    borderRadius: '0px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '500px',
    },
  },
}));

const PositionDialog = ({ open, onClose, moveFavorite, defaultPosition }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [position, setPosition] = useState(defaultPosition);
  const { favoriteListingsData } = useContext(FavoriteListingsContext);
  const { total_entries: maxValue } = favoriteListingsData.pagination;

  const handleClose = () => {
    onClose();
  };

  const handleUpdate = () => {
    moveFavorite(position);
    handleClose();
  };

  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        Move to ...
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Select a new position for this Flirt.
        </Typography>
        <Box mt={3}>
          <NumberInput
            initialValue={defaultPosition}
            maxValue={maxValue}
            onChange={setPosition}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="outlined" size="medium">
          Cancel
        </Button>
        <Button autoFocus onClick={handleUpdate} variant="contained" size="medium">
          Update
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

PositionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PositionDialog;
