import React from 'react';
import { PropTypes } from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';

const AudioMedia = ({ item: { src } }) => {
  return (
    <div className="slick-item-bg">
      <div className="slick-black">
        <div className="video-container">
          <div className="container-fluid">
            <div className="row middle-xs center-xs" style={{ height: '98%' }}>
              <ReactAudioPlayer
                src={src}
                controls
                title={src}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AudioMedia.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AudioMedia;
