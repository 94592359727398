import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, InputLabel } from '@mui/material';
import { setFilter } from 'app/slices/goodyBagFilterSlice';
import CheckboxChip from 'app/components/CheckboxChip';

const ChipRadioFilter = ({ facet, filters, resultCounts }) => {
  const { id, label, values } = facet;
  const filterValue = filters[id];
  const dispatch = useDispatch();

  const setFilterValue = (value) => {
    // if the value is ALREADY selected, then clear the filter, otherwise set it
    const newValue = filterValue === value ? '' : value;

    dispatch(setFilter({ filter: id, value: newValue }));
  };

  const counts = resultCounts[id] || {};

  return (
    <Box>
      <InputLabel id={`${id}_label`} sx={{ mt: 2, mb: 1 }}>{label}</InputLabel>
      {values
        // zip the values with the counts
        .map((value, index) => [value, counts[index]])
        .filter((value) => (
          // if there are no counts, just return true
          // if there are counts, only return values that have a positive count
          (Object.keys(counts).length === 0) || (value[1] > 0)
        ))
        // transform the zipped values into MenuItem components
        .map((item) => {
          const [value, count] = item;

          return (
            <CheckboxChip
              key={`${id}_${value.id}`}
              label={`${value.label}${count ? ` (${count})` : ''}`}
              value={value.id}
              state={filterValue}
              setState={setFilterValue}
            />
          );
        })}
    </Box>
  );
};

ChipRadioFilter.propTypes = {
  facet: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
};

export default ChipRadioFilter;
