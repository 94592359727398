import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const MaxPublicPackagesDialog = ({ closeDialog }) => {
  const navigate = useNavigate();

  return (
    <>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              You currently have 4 public packages, and your first Feed Package must also be public. To proceed with creating a Feed, please change one of your existing public Packages to private.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            closeDialog();
            navigate('/feed/packages');
          }}
        >
          Go To Packages
        </Button>
      </DialogActions>
    </>
  );
};

MaxPublicPackagesDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default MaxPublicPackagesDialog;
