import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { doneResettingPostComments } from 'app/slices/resetCommentsSlice';

const usePaginatedComments = ({ resetPostCommentsRequest, postId }) => {
  const dispatch = useDispatch();

  // used as placeholder from which we'll add to our cursors
  // collection. adding to cursors will make a new CommentsPageContainer
  // and each CommentsPageContainer will load a page of posts based on
  // this cursor
  const oldestCursor = useRef(null);

  // each cursor in this collection represents a page
  // we can remove pages by removing the cursors, allowing
  // the component to rerender itself. this should also
  // give RTK the opportunity to let go of cached data
  const [cursors, setCursors] = useState(['']);

  // after a page of data loads, we store the cursor from the oldest post in
  // the oldestCursor Ref. when the user scrolls to the bottom of the page,
  // concat the oldestCursor to the cursors collection to add a new page and
  // trigger the fetching of an older page of comments
  const setOldestCursor = (cursor) => {
    oldestCursor.current = cursor;
  };

  // adding to our cursors collection is what triggers
  // rerendering, with an additional CommentsPageContainer for
  // the cursor stored in the oldestCursor Ref
  const loadMoreRecords = () => {
    const uniqCursors = new Set(cursors.concat(oldestCursor.current));
    setCursors([...uniqCursors]);
  };

  // this resets the whole comment collection, and allows RTK to throw away cached data.
  useEffect(() => {
    if (resetPostCommentsRequest && (resetPostCommentsRequest === postId)) {
      setCursors([]);
      dispatch(doneResettingPostComments());

      // a timeout is necessary for react to throw away the cache,
      // so we can fetch new records
      setTimeout(() => { setCursors(['']); }, 500);
    }
  }, [resetPostCommentsRequest]);

  return {
    setOldestCursor,
    cursors,
    setCursors,
    loadMoreRecords,
  };
};

export default usePaginatedComments;
