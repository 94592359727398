/* eslint-disable no-lonely-if */
import React from 'react';
import { DateTime } from 'luxon';

import SubscriptionButton from '../components/subscriptions/buttons/SubscriptionButton';
import RenewalFailedSubscriptionButton from 'app/components/subscriptions/buttons/RenewalFailedSubscriptionButton';
import GenericSubscriptionButton from 'app/components/subscriptions/buttons/GenericSubscriptionButton';
import { toCurrency } from 'app/helpers/currencyHelpers';

const getSubscriptionRate = (offerings) => {
  // here is where we'll need to be smarter about multiple subscription offers in the future
  if (offerings?.length > 0) return toCurrency(offerings[0].rate);
  return null;
};

const getRenewalRate = (offerings) => {
  return `Renews ${getSubscriptionRate(offerings)} per month`;
};

// TODO: this requires reformatted date (Jun 20, 2023)
const getSubscriptionExpiration = (subscription) => {
  if (!subscription?.subscription_ends_at) { return null; }

  return DateTime.fromISO(subscription?.subscription_ends_at).toLocaleString();
};

const useSubscriptionButtonState = ({
  subscriptionData,
  currentUser,
  advisor,
  callsToAction,
  displayOnHiddenPost,
  isFetching,
}) => {
  if (!subscriptionData || !advisor) { return null; }

  const { subscription, available_offerings: subscriptionOfferings } = subscriptionData;
  const currentUserIsAdvisor = currentUser?.id === advisor.id;
  const advisorActive = advisor.active;
  const advisorOnboardingComplete = advisor.onboarding_complete;
  const neverSubscribed = !subscription || subscription.status === 'incomplete';
  const subscriptionIsCurrent = subscription && subscription.status === 'active';
  const subscriptionExpired = subscription && subscription.status === 'cancelled';
  const pendingCancellation = subscription && subscription.status === 'pending_cancel';
  const chargeFailed = subscription && subscription.status === 'charge_failed';
  const subscriptionPriceChanged = subscription && subscription.needs_approval;
  const subscriptionRate = getSubscriptionRate(subscriptionOfferings);

  if (displayOnHiddenPost) {
    return (
      <GenericSubscriptionButton
        callToAction={callsToAction.subscribe}
        isFetching={isFetching}
      />
    );
  } else if (!currentUser) {
    // unauthenticated user
    return (
      <SubscriptionButton
        buttonClass="inactive"
        label="Subscribe"
        price={subscriptionRate}
        period="per month"
        callToAction={() => {}}
        isFetching={isFetching}
      />
    );
  } else if (currentUserIsAdvisor) {
    if (advisorActive && advisorOnboardingComplete) {
      // advisor viewing her own active feed
      return (
        <SubscriptionButton
          buttonClass="inactive"
          label="Subscribe"
          price={subscriptionRate}
          period="per month"
          callToAction={callsToAction.cantFollowSelf}
          isFetching={isFetching}
        />
      );
    } else {
      // advisor viewing her own suspended feed
      // or feed with no welcome kit (not started yet)
      return (
        <SubscriptionButton
          buttonClass="disabled"
          label="Subscribe"
          price={subscriptionRate}
          period="per month"
        />
      );
      //
    }
  } else {
    if (advisorActive) {
      if (!advisorOnboardingComplete) {
        // seeker looking at advisor who's not completed onboarding
        return (
          <SubscriptionButton
            buttonClass="disabled"
            label="Subscribe"
            price={subscriptionRate}
            period="per month"
          />
        );
      } else if (neverSubscribed) {
        return (
          <SubscriptionButton
            buttonClass="inactive"
            label="Subscribe"
            price={subscriptionRate}
            period="per month"
            callToAction={callsToAction.subscribe}
            isFetching={isFetching}
          />
        );
      } else if (subscriptionExpired) {
        // seeker viewing active feed, subscription has expired
        return (
          <SubscriptionButton
            buttonClass="inactive"
            label="Subscribe"
            price={subscriptionRate}
            period="per month"
            secondaryLabel={subscription?.subscription_ends_at ? 'Expired' : ''}
            secondaryDate={getSubscriptionExpiration(subscription)}
            callToAction={callsToAction.subscribe}
            isFetching={isFetching}
          />
        );
      } else if (subscriptionPriceChanged) {
        // seeker viewing active feed with price increase
        return (
          <SubscriptionButton
            buttonClass="inactive"
            label="Renew (Price Change)"
            price={subscriptionRate}
            period="per month"
            secondaryLabel="Expires"
            secondaryDate={getSubscriptionExpiration(subscription)}
            callToAction={callsToAction.approveSubscription}
            isFetching={isFetching}
          />
        );
      } else if (pendingCancellation) {
        // seeker viewing active feed with subscription that is set to expire
        // (without price increase -- we checked for that above with subscriptionPriceChanged)
        return (
          <SubscriptionButton
            buttonClass="inactive"
            label="Renew"
            price={subscriptionRate}
            period="per month"
            secondaryLabel="Expires"
            secondaryDate={getSubscriptionExpiration(subscription)}
            callToAction={callsToAction.subscribeWithoutConfirmation}
            isFetching={isFetching}
          />
        );
      } else if (subscriptionIsCurrent) {
        // seeker viewing active feed with recurring subscription (no expiration)
        return (
          <SubscriptionButton
            buttonClass="active"
            label="Subscribed"
            price={subscriptionRate}
            period="per month"
            secondaryLabel={getRenewalRate(subscriptionOfferings)}
            secondaryDate={getSubscriptionExpiration(subscription)}
            callToAction={callsToAction.unsubscribe}
            isFetching={isFetching}
          />
        );
      } else if (chargeFailed) {
        // charge failed
        return (
          <RenewalFailedSubscriptionButton
            buttonClass="active"
            callToAction={callsToAction.unsubscribe}
            label="Subscribed"
            price={subscriptionRate}
            period="per month"
            chooseCardFunc={callsToAction.openPickANewCardModal}
            expirationDate={getSubscriptionExpiration(subscription)}
            isFetching={isFetching}
          />
        );
      }
    } else if (!advisorActive) {
      if (subscriptionExpired) {
        // seeker viewing suspended feed to which she's no longer subscribed
        return (
          <SubscriptionButton
            buttonClass="disabled"
            label="Subscribe"
            price={subscriptionRate}
            period="per month"
            secondaryLabel={subscription?.subscription_ends_at ? 'Expired' : ''}
            secondaryDate={getSubscriptionExpiration(subscription)}
          />
        );
      } else if (subscriptionIsCurrent) {
        // seekeer viewing a suspended feed to which she is subscribed
        return (
          <SubscriptionButton
            buttonClass="disabled"
            label="Subscribed"
            price={subscriptionRate}
            period="per month"
            secondaryLabel="Expires"
            secondaryDate={getSubscriptionExpiration(subscription)}
          />
        );
      } else if (neverSubscribed) {
        // seeker viewing a suspended feed to which she's never subscribed
        return (
          <SubscriptionButton
            buttonClass="disabled"
            label="Subscribe"
            price={subscriptionRate}
            period="per month"
          />
        );
      }
    }
  }

  console.warn(
    'could not determine subscription button configuration for (advisor, currentUser, subscription)',
    advisor,
    currentUser,
    subscription,
  );

  return null;
};

export default useSubscriptionButtonState;
