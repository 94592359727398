import { showFlash } from 'app/slices/flashSlice';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import ModalsContext from 'app/contexts/ModalsContext';
import DialogsContext from 'app/contexts/DialogsContext';
import TributeWorkflowContainer from 'app/containers/modals/tributes/TributeWorkflowContainer';
import FavoriteAdvisorModalContainer from 'app/containers/modals/FavoriteAdvisorModalContainer';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { resetTributeSliceState } from 'app/slices/tributesSlice';
import { featureFlag } from 'app/util/featureFlag';

const useFlirtActions = ({ advisor, listing }) => {
  const dispatch = useDispatch();
  const { openModal } = useContext(ModalsContext);
  const { openDialog } = useContext(DialogsContext);
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const myOwnPost = currentUser?.id === advisor?.id;

  const onTributeButtonClick = () => {
    if (myOwnPost) {
      dispatch(showFlash({
        flashType: 'error',
        props: { message: 'We\'re sorry, you cannot send a tribute to yourself.' },
      }));
    } else {
      // Ensure that any state from a previous incomplete tribute is reset, most
      // commonly due to closing out of the `useCreditCardManagement` modal
      dispatch(resetTributeSliceState());

      openDialog({
        component: TributeWorkflowContainer,
        props: { advisorLogin: advisor.login },
      });
    }
  };

  const onFavoriteButtonClick = () => {
    if (myOwnPost) {
      dispatch(showFlash({
        flashType: 'error',
        props: { message: 'You cannot favorite yourself.' },
      }));
    } else {
      openModal({
        component: FavoriteAdvisorModalContainer,
        props: { listing },
      });
    }
  };

  return { onTributeButtonClick, onFavoriteButtonClick };
};

export default useFlirtActions;
