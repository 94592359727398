import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import {
  useGetSocialMediaConnectionsQuery,
  useGetSocialMediaEventTemplatesQuery,
  useUpdateSocialMediaEventTemplatesMutation,
  useCreateSocialMediaManualShoutMutation,
} from 'app/api/mainApi';

import {
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import AppBodyContent from 'app/components/layout/AppBodyContent';
import TitleWithBackNavigation from 'app/components/shared/TitleWithBackNavigation';
import TwitterSetupCard from 'app/components/social_shouts/TwitterSetupCard';
import TumblrSetupCard from 'app/components/social_shouts/TumblrSetupCard';
import NewSocialMediaManualShoutForm from 'app/components/social_shouts/NewSocialMediaManualShoutForm';
import SocialMediaEventTemplates from 'app/components/social_shouts/SocialMediaEventTemplates';

const SocialShoutsContainer = () => {
  const { data: socialMediaConnections } = useGetSocialMediaConnectionsQuery();
  const {
    data: socialMediaEventTemplates,
    isSuccess: getSocialMediaEventTemplatesIsSuccess,
  } = useGetSocialMediaEventTemplatesQuery();
  const [createSocialMediaManualShout, {
    error: createSocialMediaManualShoutError,
  }] = useCreateSocialMediaManualShoutMutation();
  const [updateSocialMediaEventTemplates, {
    status: updateSocialMediaEventTemplatesStatus,
    error: updateSocialMediaEventTemplatesErrors,
  }] = useUpdateSocialMediaEventTemplatesMutation();
  const [socialMediaTemplateFormData, setSocialMediaTemplateFormData] = useState(socialMediaEventTemplates);
  const dispatch = useDispatch();

  const twitterConnection = socialMediaConnections?.find((connection) => connection.provider === 'twitter');
  const tumblrConnection = socialMediaConnections?.find((connection) => connection.provider === 'tumblr');

  const updateSocialMediaEventTemplate = (updatedEventTemplate) => {
    const updatedEventTemplates = socialMediaTemplateFormData.map((originalEventTemplate) => {
      if (updatedEventTemplate.id === originalEventTemplate.id) {
        return updatedEventTemplate;
      }

      return originalEventTemplate;
    });

    setSocialMediaTemplateFormData(updatedEventTemplates);
  };

  const handleCancelUpdateSocialMediaEventTemplates = () => {
    setSocialMediaTemplateFormData(socialMediaEventTemplates);
  };

  const handleUpdateSocialMediaEventTemplates = () => {
    const attributes = socialMediaTemplateFormData.map((eventTemplate) => ({
      id: eventTemplate.id,
      send_to_twitter: eventTemplate.send_to_twitter,
      send_to_tumblr: eventTemplate.send_to_tumblr,
      content: eventTemplate.content,
    }));

    updateSocialMediaEventTemplates({
      user: {
        social_media_event_templates_attributes: attributes,
      },
    });
  };

  const displaySnackbarFromUrlParams = () => {
    const url = new URL(window.location.toString());
    const params = new URLSearchParams(url.search);
    const severityParamName = 'snackbar[severity]';
    const messageParamName = 'snackbar[message]';
    const severity = params.get(severityParamName);
    const message = params.get(messageParamName);

    if (severity && message) {
      dispatch(showSnackbarMessage({
        message,
        severity,
      }));

      params.delete(severityParamName);
      params.delete(messageParamName);

      url.search = params.toString();
      window.history.replaceState({}, '', url.toString());
    }
  };

  useEffect(() => {
    if (updateSocialMediaEventTemplatesStatus === 'rejected' && updateSocialMediaEventTemplatesErrors.status === 422) {
      setSocialMediaTemplateFormData(updateSocialMediaEventTemplatesErrors.data);
    }
  }, [updateSocialMediaEventTemplatesErrors, updateSocialMediaEventTemplatesStatus]);

  useEffect(() => {
    if (getSocialMediaEventTemplatesIsSuccess) {
      setSocialMediaTemplateFormData(socialMediaEventTemplates);
    }
  }, [getSocialMediaEventTemplatesIsSuccess]);

  useEffect(() => {
    displaySnackbarFromUrlParams();
  }, [window.location]);

  return (
    <AppBodyWithSidebar>
      <TitleWithBackNavigation title="Social Shouts" />
      <AppBodyContent>
        <Grid container sx={{ alignItems: 'center', px: { xs: 2.5, sm: 1 }, py: { xs: 1, sm: 1 } }}>
          <Grid item xs={12}>
            <Typography>
              Link your social media accounts to NiteFlirt and set up automatic event updates.
            </Typography>
          </Grid>
        </Grid>
        { twitterConnection && <TwitterSetupCard connection={twitterConnection} /> }
        { tumblrConnection && <TumblrSetupCard connection={tumblrConnection} /> }

        <SocialMediaEventTemplates
          socialMediaEventTemplates={socialMediaTemplateFormData}
          updateSocialMediaEventTemplate={updateSocialMediaEventTemplate}
          handleCancelUpdateSocialMediaEventTemplates={handleCancelUpdateSocialMediaEventTemplates}
          handleUpdateSocialMediaEventTemplates={handleUpdateSocialMediaEventTemplates}
          twitterConnection={twitterConnection}
          tumblrConnection={tumblrConnection}
          socialMediaTemplateFormData={socialMediaTemplateFormData}
        />

        <Divider sx={{ backgroundColor: '#eee', my: 1 }} />

        <NewSocialMediaManualShoutForm
          createSocialMediaManualShout={createSocialMediaManualShout}
          tumblrConnection={tumblrConnection}
          twitterConnection={twitterConnection}
          submissionError={createSocialMediaManualShoutError}
        />
      </AppBodyContent>
    </AppBodyWithSidebar>
  );
};

export default SocialShoutsContainer;
