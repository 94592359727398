import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import ModalsContext from 'app/contexts/ModalsContext';
import LegalLiabilityModalContainer from './LegalLiabilityModalContainer';
import Onboarding from 'app/components/onboarding/Onboarding';

import {
  useGetAppConfigQuery,
  useGetMyPackagesQuery,
} from 'app/api/mainApi';

import AppBody from 'app/components/layout/AppBody';
import DialogsContext from 'app/contexts/DialogsContext';
import MaxPublicPackagesDialog from 'app/components/onboarding/MaxPublicPackagesDialog';
import { ACCEPTED_2257 } from 'app/helpers/onboardingHelpers';
import NoPerksNoProblemDialog from 'app/components/onboarding/NoPerksNoProblemDialog';
import { featureFlag } from 'app/util/featureFlag';

const OnboardingContainer = () => {
  const { login: advisorLogin } = useParams();
  const { openModal } = useContext(ModalsContext);
  const { data: appConfig, isSuccess } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const onboardingStep = appConfig?.current_user?.onboarding_step;
  const onboardingComplete = appConfig?.current_user?.onboarding_complete;
  const { data: packages } = useGetMyPackagesQuery(currentUser, { skip: !currentUser });
  const [atMaxPublicPackages, setAtMaxPublicPackages] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogsContext);
  const navigate = useNavigate();
  const recurringPackageTribsEnabled = featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537');

  const openLegalLiabilityModal = () => {
    openModal({ component: LegalLiabilityModalContainer });
  };

  const onSetPriceClick = () => {
    if (onboardingStep !== ACCEPTED_2257) return;

    if (recurringPackageTribsEnabled && atMaxPublicPackages) {
      openDialog({
        component: MaxPublicPackagesDialog,
        props: { closeDialog },
      });
    } else {
      // when we remove this feature flag, "subscription settings" may not make a whole lot of sense anymore in our new world of package tributes. we should consider renaming these subscription routes at that time
      navigate('/feed/subscription_settings?onboarding=true');
    }
  };

  // redirect if onboarding is complete OR if currentUser is not the advisor
  useEffect(() => {
    if (!isSuccess) return null;

    if (
      appConfig.current_user.login.toLowerCase() !== advisorLogin.toLowerCase() ||
      onboardingComplete
    ) {
      return navigate(`/feed/${advisorLogin}`);
    }

    return null;
  }, [isSuccess]);

  useEffect(() => {
    if (packages && packages.publicAndPendingOnboardingPackages.length >= 4) {
      setAtMaxPublicPackages(true);
    }
  }, [packages]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('no_perks') === 'true') {
      window.history.replaceState({}, document.title, window.location.pathname);

      openDialog({
        component: NoPerksNoProblemDialog,
        props: { closeDialog },
      });
    }
  }, []);

  return (
    <AppBody>
      <Onboarding
        onboardingStep={onboardingStep}
        openLegalLiabilityModal={openLegalLiabilityModal}
        onSetPriceClick={onSetPriceClick}
      />
    </AppBody>
  );
};

export default OnboardingContainer;
