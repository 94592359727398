import React from 'react';

import { styled } from '@mui/material/styles';

import AvailabilityShape from 'app/shapes/AvailabilityShape';
import {
  useUpdateAvailabilityMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import {
  FormControlLabel,
  Grid,
  Switch,
  Box,
  Typography,
} from '@mui/material';

const FormControlFloats = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    '& .MuiTypography-root': {
      width: 'auto',
      [ theme.breakpoints.up('xs') ]: {
        width: '100%',
      },
    },
    [ theme.breakpoints.up('xs') ]: {
      width: '100%',
      // minWidth: '240px',
    },
    [ theme.breakpoints.up('sm') ]: {
      width: 'auto',
    },
  },
}));

const QuietHoursToggle = ({ availability }) => {
  const [ updateAvailability ] = useUpdateAvailabilityMutation();
  const handleToggleAvailability = () => {
    updateAvailability({ availability: { chat_quiet_hours_enabled: !availability?.chat_quiet_hours_enabled } });
  };
  const appConfig = useGetAppConfigQuery().data;
  const currentUser = appConfig?.current_user;

  const content = (
    <Grid
      data-test-id="quiet-hours-toggle"
      container
      alignItems="center"
      sx={{ padding: '5px 0px' }}
    >
      <Grid item xs={12}>
        <FormControlFloats
          value="start"
          control={(
            <Switch
              checked={!!availability?.chat_quiet_hours_enabled}
              name="chat-alerts"
              onChange={handleToggleAvailability}
              sx={{ ml: 2 }}
            />
          )}
          label="Chat Alert Quiet Hours"
          labelPlacement="start"
          sx={{ ml: 0 }}
        />
      </Grid>
    </Grid>
  );

  if (availability?.explicitly_unenrolled_in_chat) {
    return null;
  } else if (currentUser?.should_hide_availability_options) {
    return (
      <Box data-test-id="quiet-hours-notice" sx={{ mx: 0, my: 1 }}>
        <Typography variant="body3">
          Quiet Hours are only available when Chat Status is on.
        </Typography>
      </Box>
    );
  } else {
    return content;
  }
};

QuietHoursToggle.defaultProps = {
  availability: {},
};

QuietHoursToggle.propTypes = {
  availability: AvailabilityShape,
};

export default QuietHoursToggle;
