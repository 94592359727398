import React from 'react';
import { PropTypes } from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PackageCtaCard from 'app/components/tributes/PackageCtaCard';

const PackageForm = ({
  advisor,
  packages,
  subscribeToPackageClick,
}) => {
  return (
    <Grid item xs={12} sx={{ mt: 1 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>Packages:</Typography>
      {packages?.map((advisorPackage) => (
        <React.Fragment key={advisorPackage.id}>
          <PackageCtaCard
            advisor={advisor}
            advisorPackage={advisorPackage}
            subscribeToPackageClick={subscribeToPackageClick}
          />
        </React.Fragment>
      ))}
    </Grid>
  );
};

PackageForm.defaultProps = {
  packages: [],
};

PackageForm.propTypes = {
  advisor: PropTypes.object.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    rate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    feed_perk: PropTypes.shape({
      id: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    call_perk: PropTypes.shape({
      id: PropTypes.number,
      perSessionAmount: PropTypes.number,
      limitByPeriod: PropTypes.bool,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    volley_perk: PropTypes.shape({
      id: PropTypes.number,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
  })),
  subscribeToPackageClick: PropTypes.func.isRequired,
};

export default PackageForm;
