import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import usePaginatedComments from 'app/hooks/usePaginatedComments';
import CommentsPageContainer from './CommentsPageContainer';
import { useGetCommentsQuery } from 'app/api/feedsApi';
import { createMoreCommentsAnchor } from 'app/helpers/postCommentsHelpers';

const CommentsContainer = ({ post, advisor }) => {
  const { resetPostCommentsRequest } = useSelector(state => state.resetComments);
  const [moreComments, setMoreComments] = useState(false);

  const {
    cursors,
    setOldestCursor,
    loadMoreRecords,
  } = usePaginatedComments({
    resetPostCommentsRequest,
    postId: post.id,
  });

  const commentPages = cursors.map((cursor) => {
    return (
      <CommentsPageContainer
        key={cursor}
        advisor={advisor}
        setOldestCursor={setOldestCursor}
        setMoreComments={setMoreComments}
        commentsQuery={() => {
          return useGetCommentsQuery({ cursor, postId: post.id });
        }}
      />
    );
  });

  if (!post) { return null; }

  return (
    <>
      {commentPages}
      <div id="more-comments" />
      {moreComments && (
        <div className="row marginbot60">
          <div className="col-xs-12 center-xs">
            <a
              href="#more-comments"
              onClick={() => {
                loadMoreRecords();
                createMoreCommentsAnchor();
              }}
            >
              View More Comments
            </a>
          </div>
        </div>
      )}
    </>
  );
};

CommentsContainer.defaultProps = {
  advisor: null,
  post: null,
};

CommentsContainer.propTypes = {
  advisor: PropTypes.object,
  post: PropTypes.object,
};

export default CommentsContainer;
