import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import {
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
} from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import PhoneShape from 'app/shapes/PhoneShape';

import { useCreatePhoneMutation } from 'app/api/mainApi';

import SanitizedHTML from 'app/helpers/SanitizedHTML';
import { capitalize } from 'app/helpers/stringHelpers';

const CustomFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    minHeight: 'auto',
    marginTop: '6px',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend': {
    display: 'none',
  },
});

const ButtonBox = styled(Box)(() => ({
  '&.MuiBox-root': {
    padding: '5px 10px',
    '@media (max-width: 900px)': {
      padding: '10px 0px 0px 0px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .MuiButton-root': {
      borderRadius: '6px',
    },
  },
}));

const AddPhone = ({ currentUser, phones, setShowAddPhoneNumber, countries }) => {
  const [digits, setDigits] = useState('');
  const [phoneNumberHasError, setPhoneNumberHasError] = useState(false);
  const [createPhoneNumberError, setCreatePhoneNumberError] = useState(null);

  const availablePhoneTypes = (() => {
    const phoneTypes = phones.map((phone) => phone.type_name);
    const possiblePhoneTypes = ['home', 'work', 'cell'];

    return possiblePhoneTypes.filter((phoneType) => !phoneTypes.includes(phoneType));
  })();

  const [phoneType, setPhoneType] = useState(availablePhoneTypes.length > 0 ? availablePhoneTypes[0] : '');
  const [
    createPhone,
    createPhoneResult,
  ] = useCreatePhoneMutation();

  const hideAddPhoneNumber = () => {
    setShowAddPhoneNumber(false);
    setPhoneNumberHasError(false);
    setDigits('');
  };

  const fakePhoneNumber = () => {
    return digits.startsWith('+1 300');
  };

  const permittedToHaveSpecialAreaCode = () => {
    return currentUser?.email?.endsWith('@a.com') || currentUser?.email?.endsWith('@platphormcorp.com');
  };

  const handleCreatePhone = async () => {
    if (!matchIsValidTel(digits) && !(permittedToHaveSpecialAreaCode() && fakePhoneNumber())) {
      setPhoneNumberHasError(true);
      return false;
    }

    await createPhone({ digits, phoneType });
    return true;
  };

  const setDigitsWhileError = (newDigits) => {
    setDigits(newDigits);
    setPhoneNumberHasError(!matchIsValidTel(newDigits));
  };

  const handleChangePhoneInput = phoneNumberHasError ? setDigitsWhileError : setDigits;

  useEffect(() => {
    if (createPhoneResult.isSuccess) {
      setCreatePhoneNumberError(null);
      hideAddPhoneNumber();
    }

    if (
      createPhoneResult.isError &&
      createPhoneResult.error.status === 422
    ) {
      const errorMessage = createPhoneResult.error?.data?.inline_error || 'There was a problem adding the phone number.';
      setCreatePhoneNumberError(errorMessage);
    }
  }, [createPhoneResult]);

  return (
    <Box data-test-id="add-phone" sx={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'no-wrap', mb: 1 }}>
        <CustomFormControl>
          <Select
            id="phoneType"
            value={phoneType}
            label="Phone Type"
            onChange={(e) => setPhoneType(e.target.value)}
            sx={{ mr: 1 }}
            size="small"
          >
            {availablePhoneTypes.map((phoneType) => (
              <MenuItem key={phoneType} value={phoneType}>{capitalize(phoneType)}</MenuItem>
            ))}
          </Select>
        </CustomFormControl>
        <Box style={{ maxWidth: '245px' }}>
          <MuiTelInput
            id="digits"
            inputRef={input => input?.focus()}
            defaultCountry="US"
            forceCallingCode
            focusOnSelectCountry
            onlyCountries={countries}
            preferredCountries={['US', 'CA']}
            onChange={handleChangePhoneInput}
            error={phoneNumberHasError}
            value={digits}
            size="small"
          />
          {phoneNumberHasError && (
            <Box id="errorMsg" sx={{ color: '#8a1f11', mt: 1 }}>
              <Typography variant="body2">
                Enter a valid phone number.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {createPhoneNumberError && (
        <Box id="errorMsg" sx={{ color: '#8a1f11', my: 1 }}>
          <Typography variant="body2">
            <SanitizedHTML html={createPhoneNumberError} />
          </Typography>
        </Box>
      )}
      <ButtonBox sx={createPhoneNumberError ? { width: '100%', display: 'flex', justifyContent: 'flex-end' } : {}}>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" size="small" onClick={hideAddPhoneNumber}>
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleCreatePhone}>
            Save
          </Button>
        </Stack>
      </ButtonBox>
    </Box>
  );
};

AddPhone.defaultProps = {
  currentUser: {},
  phones: [],
  countries: [],
};

AddPhone.propTypes = {
  currentUser: PropTypes.object,
  phones: PropTypes.arrayOf(PhoneShape),
  setShowAddPhoneNumber: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string),
};

export default AddPhone;
