import React, { useEffect, useState } from 'react';

import MySubscriptions from 'app/components/subscriptions/MySubscriptions';
import AppBody from 'app/components/layout/AppBody';
import { useGetFollowsQuery } from 'app/api/feedsApi';
import { useGetSubscriptionsQuery } from 'app/api/mainApi';

const TABS = ['active', 'needs_attention', 'expired'];

const MySubscriptionsContainer = () => {
  const [currentFilter, setCurrentFilter] = useState('active');
  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery({ filter: currentFilter });

  // This allows us to set the current filter based on the URL
  // ?t=needs_attention
  // If we add a new tab, we need to add it to the TABS array
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (TABS.includes(params.get('t'))) {
      setCurrentFilter(params.get('t'));
    }
  }, []);

  const { data: follows } = useGetFollowsQuery(
    { advisorIds: subscriptions?.map((subData) => subData.subscription.advisor_id) },
    { skip: !subscriptions || subscriptions.length === 0 },
  );

  const filterButtonClick = (newFilter) => {
    setCurrentFilter(newFilter);
  };

  return (
    <AppBody>
      <MySubscriptions
        subscriptions={subscriptions}
        follows={follows}
        isLoading={isLoading}
        currentFilter={currentFilter}
        filterButtonClick={filterButtonClick}
      />
    </AppBody>
  );
};

export default MySubscriptionsContainer;
