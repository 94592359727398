import React from 'react';
import PropTypes from 'prop-types';

import { Box, FormControl, Select, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

const SORTS = [
  ['1', 'Relevance'],
  ['2', 'Price: Low to High'],
  ['3', 'Price: High to Low'],
  ['6', 'Rating'],
  ['4', 'Newest Arrivals'],
  ['11', 'Most Popular'],
];

const Sortcontrol = ({ sort, setSort }) => {
  return (
    <Box display="flex" style={{ marginLeft: 'auto' }}>
      <FormControl variant="standard">
        <Select
          id="goodySearchSort"
          value={sort}
          label="Sort"
          onChange={(e) => setSort(e.target.value)}
          sx={{ mr: 1 }}
          size="small"
          IconComponent={SortIcon}
        >
          {SORTS.map(([key, value]) => (
            // the nbsp; is to add space between the value and the sort icon;
            // we can't use padding because it turns the icon into a non-clickable area
            <MenuItem key={key} value={key}>
              {value}
              &nbsp;&nbsp;
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

Sortcontrol.defaultProps = {
  sort: '4', // Newest Arrivals
};

Sortcontrol.propTypes = {
  sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
};

export default Sortcontrol;
