import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import ChatTabs from 'app/components/chat/ChatTabs';
import ChatBrowseHeader from 'app/components/chat/ChatBrowseHeader';

const ChatTabsPos = styled(Box)(() => ({
  '&.MuiBox-root': {
    marginTop: '18px',
    borderTop: '1px solid #e0e0e0',
  },
}));

const ChatBrowseContainer = () => {
  return (
    <AppBody>
      <TemplateShell2 header="Chat" goBackPath="/">
        <ChatBrowseHeader />
        <ChatTabsPos>
          <ChatTabs activeTab="browse" />
        </ChatTabsPos>
      </TemplateShell2>
    </AppBody>
  );
};

export default ChatBrowseContainer;
