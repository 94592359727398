import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import CircularProgress from 'app/components/shared/CircularProgress';
import PhoneListingCard from 'app/components/PhoneListingCard';
import ChatListingCard from 'app/components/ChatListingCard';

const LISTING_COMPONENT_MAP = {
  live: PhoneListingCard,
  chat: ChatListingCard,
};

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25vh',
  backgroundColor: theme.palette.background.default,
}));

const ListingSearchResults = ({
  isLoading,
  cardType,
  records,
  page,
  lastPage,
  pageBackClick,
  pageForwardClick,
}) => {
  const ListingComponent = LISTING_COMPONENT_MAP[cardType];

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingContainer>
            <CircularProgress />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Finding Flirts
            </Typography>
          </LoadingContainer>
        </Box>
      </Grid>
    );
  }

  if (records && records.length !== 0) {
    return (
      <Grid item xs={12}>
        <Box
          px={{ xs: 0, sm: 2 }}
          pt={{ xs: 2.5, sm: 3.5 }}
          pb={{ xs: 10, sm: 10 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { xs: '3px', sm: '3px' },
              justifyContent: 'center',
            }}
          >
            {records?.map((listing) => {
              return (
                <Box
                  key={`listing_${listing.id}`}
                  sx={{
                    width: { xs: '330px', sm: '330px' },
                    flex: { xs: '0 0 330px', sm: '0 0 330px' },
                  }}
                >
                  <ListingComponent
                    key={listing.id}
                    listing={listing}
                    advisor={cardType === 'chat' && listing?.advisor}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>

        {lastPage > 1 && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                variant="contained"
                disabled={page === 1}
                onClick={pageBackClick}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                variant="contained"
                disabled={lastPage === page}
                onClick={pageForwardClick}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        No results found.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        We can&apos;t find any results based on your search. Try adjusting or resetting your filters to display better results.
      </Typography>
    </Grid>
  );
};

ListingSearchResults.defaultProps = {
  records: [],
  lastPage: 1,
};

ListingSearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cardType: PropTypes.string.isRequired,
  records: PropTypes.array,
  page: PropTypes.number.isRequired,
  lastPage: PropTypes.number,
  pageBackClick: PropTypes.func.isRequired,
  pageForwardClick: PropTypes.func.isRequired,
};

export default ListingSearchResults;
