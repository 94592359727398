import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import PackageManagementCard from 'app/components/tributes/PackageManagementCard';
import FlirtCardPlaceholder from 'app/components/FlirtCardPlaceholder';
import BackArrow from 'app/components/shared/BackArrow';

const PackageSection = ({ packages, isLoading, currentUser, title }) => {
  if (packages?.length === 0) { return null; }

  return (
    <Grid container>
      <Grid item xs={6}>
        <strong>{title}</strong>
      </Grid>
      {title === 'Public Packages' && (
        <Grid
          data-test-id="package-count"
          item
          xs={6}
          sx={{
            textAlign: {
              xs: 'center',
              sm: 'end',
            },
          }}
        >
          {`${packages?.length} of 4 max`}
        </Grid>
      )}
      <Grid data-test-id={`${title}`} item xs={12}>
        <PackagesList
          packages={packages}
          isLoading={isLoading}
          currentUser={currentUser}
        />
      </Grid>
    </Grid>
  );
};

PackageSection.defaultProps = {
  packages: [],
  currentUser: null,
};

PackageSection.propTypes = {
  packages: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  title: PropTypes.string.isRequired,
};

const PackagesList = ({ packages, isLoading, currentUser }) => {
  if (isLoading) {
    return (
      <>
        <FlirtCardPlaceholder />
        <FlirtCardPlaceholder />
        <FlirtCardPlaceholder />
      </>
    );
  } else {
    return packages.map((packageData) => {
      return (
        <PackageManagementCard
          key={packageData.id}
          packageData={packageData}
          currentUser={currentUser}
        />
      );
    });
  }
};

PackagesList.defaultProps = {
  packages: [],
  currentUser: null,
};

PackagesList.propTypes = {
  packages: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

const PackageManagement = ({
  packages,
  isLoading,
  currentUser,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-hdr-sticky2">
        <div className="row middle-xs start-xs nowrap-xs">
          <div className="col-xs-12">
            <BackArrow />
            <h2>Manage Packages</h2>
          </div>
        </div>
      </div>

      <Container>
        <Grid container pt={3} rowSpacing={2} justifyContent="center" alignItems="center">
          <Grid data-test-id="intro-message" item xs={12}>
            Customers can subscribe to your Packages on a monthly basis. Add a Package to customize your perks.
          </Grid>
          <Box textAlign="center" sx={{ marginBottom: '20px', marginTop: '20px' }}>
            <Button
              data-test-id="add-package-button"
              variant="contained"
              size="medium"
              onClick={() => navigate('/feed/packages/new')}
            >
              Add Package
            </Button>
          </Box>
        </Grid>

        {/* public */}
        <PackageSection
          packages={packages?.publicAndPendingOnboardingPackages}
          title="Public Packages"
          isLoading={isLoading}
          currentUser={currentUser}
        />

        {/* private */}
        <PackageSection
          packages={packages?.privatePackages}
          title="Private Packages"
          isLoading={isLoading}
          currentUser={currentUser}
        />

        {/* deleted */}
        <PackageSection
          packages={packages?.retiredPackages}
          title="Deleted Packages"
          isLoading={isLoading}
          currentUser={currentUser}
        />
      </Container>
    </>
  );
};

PackageManagement.defaultProps = {
  packages: {},
  isLoading: false,
  currentUser: null,
};

PackageManagement.propTypes = {
  packages: PropTypes.object,
  isLoading: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default PackageManagement;
