import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import LegalLiabilityModal from '../components/onboarding/LegalLiabilityModal';
import { useAcceptLegalLiabilityMutation } from 'app/api/mainApi';

const LegalLiabilityModalContainer = ({ closeModal }) => {
  const [acceptLegalLiability, {
    isLoading, isSuccess,
  }] = useAcceptLegalLiabilityMutation();

  const agreeToLegalLiability = () => {
    acceptLegalLiability();
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return (
    <LegalLiabilityModal
      isLoading={isLoading}
      closeModal={closeModal}
      agreeToLegalLiability={agreeToLegalLiability}
    />
  );
};

LegalLiabilityModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default LegalLiabilityModalContainer;
