import React from 'react';
import { PropTypes } from 'prop-types';

const FavoriteAdvisorModal = ({ onClickAddFavorite }) => {
  return (
    <>
      <div className="modal-header">
        <h4>
          Add to Favorites
        </h4>
      </div>
      <div className="modal-body">
        <br />
        <div className="container-fluid padding-0-8">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 col-xs-12">
              Favorite Flirts is a feature that lets you reach your favorite Flirts easily from&nbsp;
              <a href="/account">your account</a>
              &nbsp;or on 1-800-TO-FLIRT.
              <br />
              <br />
              <div className="row end-sm center-xs" style={{ margin: '0px -20px' }}>
                <a
                  className="btn secondary"
                  href="/account/favorite"
                  style={{ padding: '0.5em 1em' }}
                >
                  Manage Favorites
                </a>
                <button
                  type="button"
                  className="btn"
                  onClick={onClickAddFavorite}
                  style={{ padding: '0.5em 1em' }}
                >
                  Add to Favorites
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FavoriteAdvisorModal.propTypes = {
  onClickAddFavorite: PropTypes.func.isRequired,
};

export default FavoriteAdvisorModal;
