import React from 'react';
import { useMatch } from 'react-router-dom';

import { featureFlag } from 'app/util/featureFlag';

import TransientMobileNav from 'app/components/nav/TransientMobileNav';
import { useGetAppConfigQuery } from 'app/api/mainApi';

const NavContainerMobile = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const match = useMatch('/feed/:login/:postId');
  const storefrontMatch = useMatch('/:login/:tab/:path');

  const hideNav = () => {
    if (featureFlag.enabled('FEED_TO_STOREFRONT_20571')) {
      return storefrontMatch?.pattern?.path === '/:login/:tab/:path'
        && storefrontMatch.params.path !== 'onboarding';
    } else {
      return match?.pattern?.path === '/feed/:login/:postId' &&
        match.params.postId !== 'onboarding';
    }
  };

  if (hideNav() && currentUser) {
    return null; // hide nav on post detail for authenticated users
  } else {
    return (
      <TransientMobileNav />
    );
  }
};

export default NavContainerMobile;
