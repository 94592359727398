import React from 'react';
import { PropTypes } from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import CloseDialogButton from '../CloseDialogButton';

import PackageCtaCard from 'app/components/tributes/PackageCtaCard';

const IndividualPackage = ({
  advisor,
  advisorPackage,
  subscribeToPackageClick,
  closeDialog,
}) => {
  return (
    <>
      <DialogTitle>
        {advisor.login}
        &apos;s Package
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <PackageCtaCard
              advisor={advisor}
              advisorPackage={advisorPackage}
              subscribeToPackageClick={subscribeToPackageClick}
              headingSource="category"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

IndividualPackage.propTypes = {
  advisor: PropTypes.object.isRequired,
  advisorPackage: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  subscribeToPackageClick: PropTypes.func.isRequired,
};

export default IndividualPackage;
