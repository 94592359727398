/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resetPostCommentsRequest: null,
};

const resetCommentsSlice = createSlice({
  name: 'resetComments',
  initialState,
  reducers: {
    resetPostComments: (state, action) => {
      state.resetPostCommentsRequest = action.payload.postId;
    },
    doneResettingPostComments: (state) => {
      state.resetPostCommentsRequest = null;
    },
  },
});

export default resetCommentsSlice;

export const {
  resetPostComments,
  doneResettingPostComments,
} = resetCommentsSlice.actions;
