import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showFlash } from 'app/slices/flashSlice';
import SuccessfulPostCreationBanner from 'app/components/posts/SuccessfulPostCreationBanner';
import { errorMessageFromObject } from 'app/helpers/apiMessageHelpers';
import { usePerformUpdatePostMutation } from 'app/api/feedsApi';

const useUpdatePost = (post) => {
  const dispatch = useDispatch();
  const [scheduledAtChanged, setScheduledAtChanged] = useState(false);

  const [
    performUpdatePost,
    {
      isSuccess: isUpdatePostSuccess,
      isError: isUpdatePostError,
      error: postUpdateError,
      data: postUpdateData,
    },
  ] = usePerformUpdatePostMutation();

  useEffect(() => {
    if (isUpdatePostSuccess) {
      const removedMsg = 'This post is no longer visible to those who subscribed after the post date.';
      const addedMsg = 'This post is now visible to current and future subscribers.';
      let autoCloseDur = 3000;

      let message = {
        post_type: (post.post_type === 'welcome_kit' ? removedMsg : addedMsg),
        body: 'Post updated.',
      }[postUpdateData.updateType];

      if (scheduledAtChanged) {
        autoCloseDur = 10000;
        message = SuccessfulPostCreationBanner;
      }

      if (message) {
        dispatch(showFlash({
          flashType: 'success',
          autoCloseDur,
          props: { message },
        }));
      }
    } else if (isUpdatePostError) {
      const message = errorMessageFromObject(postUpdateError, 'Error while trying to update the post.');

      dispatch(showFlash({
        flashType: 'error',
        props: { message },
      }));
    }
    setScheduledAtChanged(false);
  }, [isUpdatePostSuccess, isUpdatePostError]);

  return {
    updatePost: performUpdatePost,
    scheduledAtChanged,
    setScheduledAtChanged,
  };
};

export default useUpdatePost;
