import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

const MenuSelect = ({ onSelect, menuItems, defaultValue = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultValue || Object.keys(menuItems)[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (key) => {
    setSelectedMenuItem(key);
    handleClose();
    onSelect(key);
  };

  return (
    <div>
      <a
        href="#"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SortIcon sx={{ color: 'var(--color_gray_nine)' }} />
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.entries(menuItems).map(([key, label]) => (
          <MenuItem
            key={key}
            onClick={() => handleMenuItemClick(key)}
            style={{ backgroundColor: selectedMenuItem === key ? '#e0ecf9' : 'inherit' }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MenuSelect.defaultProps = {
  defaultValue: null,
};

MenuSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  menuItems: PropTypes.objectOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.string,
};

export default MenuSelect;
