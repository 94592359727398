import React from 'react';
import { PropTypes } from 'prop-types';

import EditMonetaryTribute from 'app/components/modals/tributes/EditMonetaryTribute';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const EditMonetaryTributeContainer = ({ advisor }) => {
  const {
    activeMonetaryTribute,
    amountError,
    amountInvalid,
    closeDialogAndResetTributeSlice,
    dispatch,
    setAmount,
    setAmountError,
    setCurrentStep,
    setMessage,
    tributeAmount,
    tributeMessage,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const onCancelTributeClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CANCEL_MONETARY_TRIBUTE,
    }));
  };

  const onUpdateTributeClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_EDIT_TRIBUTE,
    }));
  };

  return (
    <EditMonetaryTribute
      activeMonetaryTribute={activeMonetaryTribute}
      advisor={advisor}
      amount={tributeAmount}
      amountError={amountError}
      amountInvalid={amountInvalid}
      closeDialog={closeDialogAndResetTributeSlice}
      message={tributeMessage}
      onCancelTributeClick={onCancelTributeClick}
      onUpdateTributeClick={onUpdateTributeClick}
      setAmount={setAmount}
      setAmountError={setAmountError}
      setMessage={setMessage}
      useDifferentCardClick={useDifferentCardClick}
    />
  );
};

EditMonetaryTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default EditMonetaryTributeContainer;
