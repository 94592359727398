import React from 'react';
import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import MonthlyTributesContainer from 'app/containers/modals/tributes/MonthlyTributesContainer';
import OneTimeFormContainer from 'app/containers/modals/tributes/OneTimeFormContainer';

import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup } from '@mui/material';

const NewTributeForm = ({
  advisor,
  recurring,
  setRecurringClick,
  closeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Send a Tribute to&nbsp;
        {advisor.login}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box sx={{ marginLeft: { xs: 0, sm: 4 }, marginRight: { xs: 0, sm: 4 } }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonGroup
                variant="contained"
                aria-label="Basic button group"
                size="small"
              >
                <Button
                  className={!recurring ? 'active' : ''}
                  onClick={() => setRecurringClick(false)}
                >
                  One-time
                </Button>
                <Button
                  className={recurring ? 'active' : ''}
                  onClick={() => setRecurringClick(true)}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              { recurring ?
                <MonthlyTributesContainer advisor={advisor} /> :
                <OneTimeFormContainer advisor={advisor} /> }
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );
};

NewTributeForm.propTypes = {
  advisor: PropTypes.object.isRequired,
  recurring: PropTypes.bool.isRequired,
  setRecurringClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
};

export default NewTributeForm;
