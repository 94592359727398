import { styled } from '@mui/material/styles';
import List from '@mui/material/List';

const ListAvailability = styled(List)(() => ({
  '&.MuiList-root': {
    padding: '3px 0px',
    '& .MuiListItemIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      '.MuiListItemIcon-root': {
        display: 'block',
      },
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: '#99c',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
    },
    '& .MuiDivider-root': {
      margin: '3px 0px',
    },
  },
}));

export default ListAvailability;