import React from 'react';
import { PropTypes } from 'prop-types';

const QueuedPostPlaceholder = ({ placeholderText }) => {
  return (
    <div className="container-fluid padding10">
      <div className="row">
        <div className="col-xs-10">
          <p>{placeholderText}</p>
        </div>
      </div>
    </div>
  );
};

QueuedPostPlaceholder.propTypes = {
  placeholderText: PropTypes.string,
};

QueuedPostPlaceholder.defaultProps = {
  placeholderText: 'No posts',
};

export default QueuedPostPlaceholder;
