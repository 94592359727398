import React from 'react';
import { PropTypes } from 'prop-types';
import usePostUtilities from 'app/hooks/usePostUtilities';

const CommentLink = ({
  children,
  showComments,
  post,
  currentUser,
}) => {
  const {
    redirectToLogin,
    navigateToPostComments,
  } = usePostUtilities({ post });

  const clickAction = (
    (!currentUser && redirectToLogin) ||
    (!showComments && navigateToPostComments) ||
    null
  );

  return (
    <a
      onClick={clickAction}
      className="show-comments"
      role="button"
      tabIndex="0"
    >
      {children}
    </a>
  );
};

CommentLink.defaultProps = {
  currentUser: null,
};

CommentLink.propTypes = {
  children: PropTypes.object.isRequired,
  showComments: PropTypes.bool.isRequired,
  post: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default CommentLink;
