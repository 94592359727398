import { useEffect, useRef, useState } from 'react';

const usePaginatedMediaItems = ({ albumId }) => {
  // used as placeholder from which we'll add to our cursors
  // collection. adding to cursors will make a new MediaItemsPageContainer
  // and each MediaItemsPageContainer will load a page of media items based on
  // this cursor
  const oldestCursor = useRef(null);

  // each cursor in this collection represents a page
  // we can remove pages by removing the cursors, allowing
  // the component to rerender itself. this should also
  // give RTK the opportunity to let go of cached data
  const [cursors, setCursors] = useState(['']);

  // after a page of data loads, we store the cursor from the oldest media item
  // in the oldestCursor Ref. when the user scrolls to the bottom of the page,
  // concat the oldestCursor to the cursors collection to add a new page and
  // trigger the fetching of an older page of comments
  const setOldestCursor = (cursor) => {
    oldestCursor.current = cursor;
  };

  // adding to our cursors collection is what triggers
  // rerendering, with an additional MediaItemsPageContainer for
  // the cursor stored in the oldestCursor Ref
  const loadMoreRecords = () => {
    const uniqCursors = new Set(cursors.concat(oldestCursor.current));
    setCursors([...uniqCursors]);
  };

  const onBodyScroll = (e) => {
    const element = e.target;
    const diff = element.scrollHeight - Math.ceil(element.scrollTop) - element.clientHeight;

    if (diff <= 2 && element.scrollTop > 0) {
      loadMoreRecords();
    }
  };

  const initScrollHandler = () => {
    document.getElementById('media-items').onscroll = onBodyScroll;
  };

  const removeScrollHandler = () => {
    document.getElementById('media-items')?.removeEventListener('scroll', onBodyScroll);
  };

  useEffect(() => {
    setCursors(['']);
  }, [albumId]);

  // listen for scroll events
  useEffect(() => {
    initScrollHandler();

    return () => {
      removeScrollHandler();
    };
  });

  return {
    setOldestCursor,
    cursors,
    setCursors,
    loadMoreRecords,
    initScrollHandler,
    removeScrollHandler,
  };
};

export default usePaginatedMediaItems;
