import React from 'react';
import { PropTypes } from 'prop-types';

import PostMedia from './PostMedia';
import HiddenSubscriptionPost from './HiddenSubscriptionPost';
import PlaceholderPost from './PlaceholderPost';
import RejectedPost from './RejectedPost';
import PendingModerationPost from './PendingModerationPost';
import TranscodingPost from './TranscodingPost';

const PostItemArea = ({ post, advisorLogin }) => {
  if (post === undefined) return <PlaceholderPost />;

  if (!post.unlocked) return <HiddenSubscriptionPost advisorLogin={advisorLogin} post={post} />;

  switch (post.post_item_encoding_status) {
  case 'pending': return <TranscodingPost />;
  case 'failed': return <RejectedPost post={post} />;
  default:
    if (post.published_at) return <PostMedia post={post} />;

    switch (post.moderation_status) {
    case 'not_accepted':
    case 'not_accepted_edited': return <RejectedPost post={post} />;
    case 'not_reviewed': return <PendingModerationPost />;
    default:
      // uh oh, unrecognized moderation status?!?
      return <PostMedia post={post} />;
    }
  }
};

PostItemArea.defaultProps = {
  advisorLogin: null,
};

PostItemArea.propTypes = {
  post: PropTypes.object.isRequired,
  advisorLogin: PropTypes.string,
};

export default PostItemArea;
