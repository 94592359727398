/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';

export const FormButton = ({ text, onClick, variant, order, disabled, sx, ...buttonProps }) => {
  return (
    <Grid item xs="auto" sm="auto" sx={{ order }}>
      <Button
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        fullWidth={false}
        sx={sx}
        {...buttonProps}
      >
        {text}
      </Button>
    </Grid>
  );
};

FormButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  order: PropTypes.object,
  sx: PropTypes.object,
};

FormButton.defaultProps = {
  order: {},
  sx: { width: { xs: 'auto', sm: 'auto' } },
};

export default FormButton;
