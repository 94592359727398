import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { getTimeZonesWithFormattedOffsets } from 'app/constants/timezones';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormTimeZone = ({ name = 'timeZone' }) => {
  const { control } = useFormContext();
  const timeZones = getTimeZonesWithFormattedOffsets();

  return (
    <Box sx={{ my: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="timezone-label">Time Zone</InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              labelId="timezone-label"
              id="timezone-select"
              {...field}
              input={<OutlinedInput label="Time Zone" />}
              MenuProps={MenuProps}
            >
              {timeZones.map(({ label, value }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
};

FormTimeZone.propTypes = {
  name: PropTypes.string
};

FormTimeZone.defaultProps = {
  name: 'timeZone'
};
export default FormTimeZone;
