// Largely inspired by plus_frontend/src/javascripts/app/helpers/listingHelpers.js which is inspired by
// application_helper.rb#call_button_link. We diverge from this routine by passing forward a way to hijack clicks and
// forwarding a disabed class. What could go wrong?
import React from 'react';
import PropTypes from 'prop-types';

import ListingShape from 'app/shapes/ListingShape';

const CallNowButton = ({ onClick, disabled, listing }) => {
  // const messageUrl = `/messages/compose?message%5Brecipients_list%5D=${listing.login}`;
  // const chatUrl = `/chat/${listing.advisor_slug}`;
  const className = disabled ? 'call-button call-now--disabled' : 'call-button call-now';

  return (
    <div>
      <a
        href="#"
        onClick={onClick}
      >
        <div className={className}>
          <span>Call Now</span>
        </div>
      </a>
    </div>
  );
};

CallNowButton.defaultProps = {
  disabled: false,
};

CallNowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  listing: ListingShape.isRequired,
};

export default CallNowButton;
