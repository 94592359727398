import React from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import { setHasUnreadMessages } from 'app/slices/chatSlice';

import TransientSeekerNav from 'app/components/nav/TransientSeekerNav';
import TransientAdvisorNav from 'app/components/nav/TransientAdvisorNav';
import TransientUnauthorizedNav from 'app/components/nav/TransientUnauthorizedNav';

import {
  userIsSeeker,
  userIsAdvisor,
} from 'app/helpers/whitelistHelpers';

const TransientNavContainer = ({ isWithinRouterContext }) => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const dispatch = useDispatch();
  const txnUrl = appConfig?.txn_url;
  const addMoneyUrl = `${txnUrl}/purchase/new`;

  // if we're in nf main there is no router context, so we can't do
  // react-router navigation. in that case we'll just use window.location.href
  let navigate;
  if (isWithinRouterContext) {
    navigate = useNavigate();
  } else {
    navigate = (path) => { window.location.href = path; };
  }

  if (currentUser) {
    dispatch(setHasUnreadMessages({ hasUnreadMessages: currentUser.has_unread_chat_messages }));
  }

  const navigateTo = (path, pageRefresh = false, newTab = false) => {
    if (pageRefresh) {
      window.location.href = path;
    } else if (newTab) {
      window.open(path, '_blank');
    } else {
      // note that this call to navigate might actually replace window.location.href
      // if this container has been rendered outside of a router context (nf main). see the
      // usage of isWithinRouterContext in the initialization of the navigate function above
      navigate(path);
    }
  };

  let navComponent;
  if (userIsSeeker(currentUser)) {
    navComponent = (
      <TransientSeekerNav
        currentUser={currentUser}
        navigateTo={navigateTo}
        addMoneyUrl={addMoneyUrl}
        balance={appConfig?.available_balance}
      />
    );
  } else if (userIsAdvisor(currentUser)) {
    navComponent = (
      <TransientAdvisorNav
        currentUser={currentUser}
        navigateTo={navigateTo}
        addMoneyUrl={addMoneyUrl}
        balance={appConfig?.available_balance}
      />
    );
  } else {
    navComponent = (
      <TransientUnauthorizedNav
        navigateTo={navigateTo}
        addMoneyUrl={addMoneyUrl}
      />
    );
  }

  return (
    <nav style={{ paddingTop: '20px', flexShrink: 0 }}>
      {navComponent}
    </nav>
  );
};

TransientNavContainer.defaultProps = {
  isWithinRouterContext: true,
};

TransientNavContainer.propTypes = {
  isWithinRouterContext: PropTypes.bool,
};

export default TransientNavContainer;
