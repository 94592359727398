/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  component: null,
  props: null,
  closeFunc: null,
  autoCloseDur: null,
  flashType: null,
};

const flashSlice = createSlice({
  name: 'flashes',
  initialState,
  reducers: {
    showFlash: (state, action) => {
      state.component = action.payload.component;
      state.props = action.payload.props;
      state.closeFunc = action.payload.closeFunc;
      state.autoCloseDur = action.payload.autoCloseDur;
      state.flashType = action.payload.flashType;
    },
    simpleErrorFlash: (state, action) => {
      state.component = null;
      state.props = { message: action.payload };
      state.flashType = 'error';
    },
    closeFlash: (state) => {
      state.component = null;
      state.props = null;
      state.closeFunc = null;
      state.autoCloseDur = null;
      state.flashType = null;
    },
  },
});

export default flashSlice;

export const {
  showFlash,
  closeFlash,
  simpleErrorFlash,
} = flashSlice.actions;
