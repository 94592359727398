import React from 'react';
import PropTypes from 'prop-types';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';
import AvailabilityShape from 'app/shapes/AvailabilityShape';

import {
  Box,
  Grid,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';

import CheckMarkNF from 'app/components/customSvgs/CheckMarkNF';
import AvailabilityList from 'app/redesign/components/availability/AvailabilityList';
import EnrollInChatCTA from './EnrollInChatCTA';

const ChatStatus = ({
  phone,
  title,
  displaySecureNumber,
  zeroStateCopy,
  currentUser,
  availability,
}) => {
  const [ updateCurrentChatPhoneStatus ] = useUpdateAvailabilityMutation();
  const handleOnClick = (chatStatus) => {
    if (phone.chat_status === chatStatus) { return; }

    updateCurrentChatPhoneStatus({ availability: { chatStatus, phoneId: phone.id } });
  };
  const displayNumberMethod = displaySecureNumber ? 'secure_number' : 'unsecure_number';
  const {
    enrolled_in_chat: enrolledInChat,
    chat_statuses: chatStatuses,
    explicitly_unenrolled_in_chat: explicitlyUnenrolledInChat,
    chat_profile_id: chatProfileId,
  } = availability;
  const advisorNeedsToEnrollInChat = explicitlyUnenrolledInChat || (currentUser?.is_advisor && !enrolledInChat);
  const onlyHasInternationalPhones = availability?.phones?.every(phone => phone.domestic !== true) && availability?.phones?.length;

  let content = '';
  if (advisorNeedsToEnrollInChat) {
    content = (<EnrollInChatCTA chatProfileId={chatProfileId} explicitlyUnenrolledInChat={explicitlyUnenrolledInChat} />);
  } else if (!phone && onlyHasInternationalPhones && !currentUser?.is_advisor) {
    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <AvailabilityList aria-label="section1">
          <ListItem data-test-id="Off">
            <Stack style={{ width: '100%' }}>
              <ListItemText primary="Off - No Chat Alerts" />
            </Stack>
            <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', alignSelf: 'center' }}>
              <CheckMarkNF sx={{ color: green[500] }} />
            </ListItemIcon>
          </ListItem>
        </AvailabilityList>
      </Paper>
    );
  } else if (!phone && !onlyHasInternationalPhones) {
    content = (
      <Paper data-test-id="no-verified-phone" sx={{ p: 2 }} variant="outlined">
        {zeroStateCopy}
      </Paper>
    );
  // NOTE:
  // If the user has only international phones but is enrolled in chat, we should let them change their chat status.
  // This case comes up for a user who only has an international phone.
  } else if (onlyHasInternationalPhones) {
    const handleStatusChangeWithNoPhone = (chatStatus) => {
      if (availability.chatStatus === chatStatus) { return; }
      updateCurrentChatPhoneStatus({ availability: { chatStatus, phoneId: null } });
    };

    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <AvailabilityList aria-label="section1">
          {chatStatuses.map((status, index) => (
            <React.Fragment key={status.value}>
              <ListItem
                data-test-id={`${status.value}`}
                onClick={() => { handleStatusChangeWithNoPhone(status.value); }}
              >
                <Stack style={{ width: '100%' }}>
                  <ListItemText primary={status.title} />
                  {status.title === 'Available - Chat Alerts On' && (
                    <ListItemText sx={{ mt: -0.5 }} />
                  )}
                </Stack>
                {availability.chat_status === status.value && (
                  <ListItemIcon
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignContent: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    <CheckMarkNF sx={{ color: green[500] }} />
                  </ListItemIcon>
                )}
              </ListItem>
              {index !== (chatStatuses.length - 1) ? <Divider /> : null}
            </React.Fragment>
          ))}
        </AvailabilityList>
      </Paper>
    );
  } else {
    content = (
      <Paper sx={{ p: 0 }} variant="outlined">
        <AvailabilityList aria-label="section1">
          {chatStatuses.map((status, index) => (
            <React.Fragment key={status.value}>
              <ListItem
                data-test-id={`${status.value}`}
                onClick={() => { handleOnClick(status.value); }}
              >
                <Stack style={{ width: '100%' }}>
                  <ListItemText primary={status.title} />
                  {status.title === 'Available - Chat Alerts On' && (
                    <ListItemText secondary={phone[ displayNumberMethod ]} sx={{ mt: -0.5 }} />
                  )}
                </Stack>
                {(phone.chat_status === status.value ||
                  (status.value === 'online_only' && !phone?.is_current_chat_phone)) && (
                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignContent: 'center',
                        alignSelf: 'center',
                      }}
                    >
                      <CheckMarkNF sx={{ color: green[ 500 ] }} />
                    </ListItemIcon>
                  )}
              </ListItem>
              {index !== (chatStatuses.length - 1) ? <Divider /> : null}
            </React.Fragment>
          ))}
        </AvailabilityList>
      </Paper>
    );
  }

  return (
    <Box data-test-id="current-chat-phone-status">
      <Grid container sx={{ mb: 1, alignItems: 'center', px: { xs: 3, sm: 0 }, pt: { xs: 1.5, sm: 0 } }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {title}
          </Typography>
        </Grid>
      </Grid>
      {content}
    </Box>
  );
};

ChatStatus.defaultProps = {
  title: 'Chat Status',
  phone: {},
  displaySecureNumber: true,
  zeroStateCopy: 'No verified phone numbers on file. Click the Chat icon above to verify a phone number.',
  currentUser: {},
  availability: {},
};

ChatStatus.propTypes = {
  title: PropTypes.string,
  phone: PhoneShape,
  displaySecureNumber: PropTypes.bool,
  zeroStateCopy: PropTypes.string,
  currentUser: PropTypes.object,
  availability: AvailabilityShape,
};

export default ChatStatus;
