import {
  List,
  styled,
} from '@mui/material';

const MenuAvailabilityList = styled(List)(() => ({
  '&.MuiList-root': {
    fontSize: '0.850em',
    padding: '0 0px',
    '& .MuiListItemIcon-root': {
      display: 'none',
    },
    '& .Mui-selected': {
      '.MuiListItemIcon-root': {
        display: 'block',
      },
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: '#99c',
    },
    '& .MuiButtonBase-root': {
      borderRadius: '0px',
      padding: '3px 15px',
    },
    '& .MuiDivider-root': {
      margin: '3px 0px',
    },
    '& .MuiSvgIcon-root': {
      height: '16px',
    },
  },
}));

export default MenuAvailabilityList;
