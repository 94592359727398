import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Box,
  Stack,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';

const NetworkStatus = ({
  dataUrl,
}) => {
  return (
    <>
      <DialogTitle>
        Network Status
      </DialogTitle>
      <DialogContent>
        <Box mx={{ xs: 0, sm: 12, md: 17 }}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            {/* /plus_frontend/assets/svg/niteflirt-logo.svg */}
            <img
              src={dataUrl}
              alt="NiteFlirt"
              style={{ width: '180px' }}
            />
            <Typography variant="h3">
              You&apos;re Offline!
            </Typography>
            <img src="/plus_frontend/assets/svg/pwa-graphic.svg" alt="" style={{ width: '200px' }} />
          </Stack>
        </Box>
      </DialogContent>
    </>
  );
};

NetworkStatus.propTypes = {
  dataUrl: PropTypes.string.isRequired,
};

export default NetworkStatus;
