import React from 'react';
import { Link } from 'react-router-dom';

const Statement2257 = () => (
  <>
    <b>NiteFlirt&apos;s 18 U.S.C. 2257 statement</b>
    <br />
    NiteFlirt is not the primary nor secondary producer (as defined in 18 U.S.C. § 2257) of any of the content found on the Site.
    <br />
    <br />
    Niteflirt requires all Members, Flirts, and users who access or use any area of the Niteflirt Site to be eighteen (18) years old or older. Further NiteFlirt requires all members who upload content to certify that all models were 18 years or older on the date the content was created and that they keep records if and as required by Sections 2257/2257A of Title 18 of the US Code.
    <br />
    <br />
    NiteFlirt acts as a technology service to allow Members to share information, entertainment services, or other products or services among themselves. In respect to content, NiteFlirt&apos;s activities are limited to the transmission, storage, retrieval, hosting, and/or formatting of depictions posted by third party users, on areas of the website under the user&apos;s control. NiteFlirt does not evaluate, provide, produce, or control the Flirt Services, information, or exchanges between Members, in any manner. NiteFlirt does not itself provide any content, information, entertainment services, or other products or services, nor verify, guarantee, or make any representations regarding the identity or qualifications of any Flirt or Member. NiteFlirt does not make editorial or managerial decisions concerning Member or Flirt content, sexually explicit or otherwise.
    <br />
    <br />
    By using the NiteFlirt service, Members and Flirts are bound by the&nbsp;
    <Link to="/help/tos">Terms of Service</Link>
    &nbsp;to follow all applicable federal, state and local laws, regulations, and ordinances relating to obscene and indecent content, communications, and record-keeping obligations.
    <br />
    <br />
    Flirts are solely responsible for the content provided under their NiteFlirt Member accounts and for ensuring that they are in compliance with all applicable laws and regulations, including but not limited to providing certain record location information under 18 USC 2257 and corresponding regulations where applicable.
    Please direct 2257 records requests directly to the respective Flirt who has uploaded the content. For assistance communicating with any Flirt please contact&nbsp;
    <a href="mailto:support@niteflirt.com">support@niteflirt.com</a>
    .
    <br />
    <br />

  </>
);

export default Statement2257;
