import React, { useState, useEffect } from 'react';

import { PropTypes } from 'prop-types';
import PostComposeBox from 'app/components/posts/PostComposeBox';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { usePerformUpdatePostMutation } from 'app/api/feedsApi';

import usePostComposeActions from 'app/hooks/usePostComposeActions';

const EditCaptionModal = ({
  closeModal,
  setUnsavedChanges,
  post,
}) => {
  const [body, setBody] = useState(post.body);
  const [paidBody, setPaidBody] = useState(post.paid_body);
  const [errors, setErrors] = useState({});
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;

  const {
    paymentButtons,
    goodyBags,
    openGoodyBagsModal,
    openPaymentButtonsModal
  } = usePostComposeActions();

  const setBodyAndClearTempState = (body) => {
    setBody(body);
    setErrors({});
    setUnsavedChanges(post.body !== body); // to trigger the "unsaved changes" warning
  };

  const setPaidBodyAndClearTempState = (body) => {
    setPaidBody(body);
    setErrors({});
    setUnsavedChanges(post.body !== body); // to trigger the "unsaved changes" warning
  };

  const [
    performUpdatePost,
    {
      isSuccess: isUpdatePostSuccess,
      error: updatePostError,
    },
  ] = usePerformUpdatePostMutation();

  useEffect(() => {
    setErrors(updatePostError?.data?.errors);
  }, [updatePostError]);

  // we close the modal on success, but if there's an error, we'll let the PostComposeBox handle that flow
  useEffect(
    () => { if (isUpdatePostSuccess) closeModal(); },
    [isUpdatePostSuccess],
  );

  return (
    <>
      <div className="modal-header">
        <h4>Edit Caption</h4>
      </div>
      <div className="modal-body">
        <div className="row margintop20">
          <div className="col-xs-12">
            <div className="subhead">Public Caption</div>
            <PostComposeBox
              body={body}
              setBody={setBodyAndClearTempState}
              errors={errors?.body}
              label={false}
              currentUser={currentUser}
              hasPaymentButtons={paymentButtons?.data?.length > 0}
              hasGoodyBags={goodyBags?.data?.length > 0}
              openPaymentButtonsModal={openPaymentButtonsModal}
              openGoodyBagsModal={openGoodyBagsModal}
            />
          </div>
        </div>

        {post.post_type !== 'free' && (
          <div className="row margintop20">
            <div className="col-xs-12">
              <div className="subhead">Subscriber Caption</div>
              <PostComposeBox
                body={paidBody}
                setBody={setPaidBodyAndClearTempState}
                isPaidBody
                errors={[errors?.paid_body, errors?.base].flatMap(i => i).filter(i => i)}
                label={false}
                currentUser={currentUser}
                hasPaymentButtons={paymentButtons?.data?.length > 0}
                hasGoodyBags={goodyBags?.data?.length > 0}
                openPaymentButtonsModal={openPaymentButtonsModal}
                openGoodyBagsModal={openGoodyBagsModal}
              />
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            setUnsavedChanges(false);
            performUpdatePost({
              postId: post.id,
              advisorId: post.main_user_id,
              cursorPage: post.cursorPage,
              post: { body, paid_body: paidBody },
            });
          }}
        >
          Save
        </button>
      </div>
    </>
  );
};

EditCaptionModal.propTypes = {
  post: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,

};

export default EditCaptionModal;
