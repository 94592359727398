import React from 'react';
import PropTypes from 'prop-types';

import PendingTooltip from 'app/components/tooltips/PendingTooltip';
import RejectedTooltip from 'app/components/tooltips/RejectedTooltip';

import useProfileImages from 'app/hooks/useProfileImages';

const FlirtCoverImage = ({
  viewingSelf,
  openSelectFilesModal,
  advisor,
}) => {
  const { coverImage } = useProfileImages(advisor);
  const imageStatus = advisor ? advisor.cover_image_moderation_status : '';
  const pending = imageStatus === 'pending';
  const rejected = imageStatus === 'rejected';

  const bgStyles = {
    backgroundImage: `url('${coverImage}')`,
  };

  return (
    <>
      <div className="nfp-hero-bg" style={bgStyles}><div className="nfp-hero-opac" /></div>
      {viewingSelf && (
        <div className="row end-xs edit-cover-container1">
          <div className="row edit-cover-container2">
            {pending && (
              <div className="mockupPendingIcon2">
                <PendingTooltip imageType="Cover" />
              </div>
            )}
            {rejected && !pending && (
              <div className="mockupRejectIcon2">
                <RejectedTooltip imageType="Cover" />
              </div>
            )}
            <a href="#" onClick={() => openSelectFilesModal('cover')}>
              <img src="/plus_frontend/assets/svg/btn-edit-photo.svg" alt="Icon" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

FlirtCoverImage.propTypes = {
  openSelectFilesModal: PropTypes.func.isRequired,
  viewingSelf: PropTypes.bool.isRequired,
  advisor: PropTypes.object,
};

export default FlirtCoverImage;
