import React from 'react';

import {
  Grid,
  Stack,
} from '@mui/material';

import Typography from '@mui/material/Typography';

const CallInfo = () => {
  return (
    <Grid
      item
      xs={12}
      mt={2}
      mb={1}
    >
      <Stack
        direction="column"
        justifyContent="center"
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="h3">
          We’ll call you at the number below.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            mt: 0.5
          }}
        >
          Your number is never revealed.
        </Typography>
      </Stack>
    </Grid>
  );
};

export default CallInfo;
