import React from 'react';

const NotPreviewableMedia = () => {
  return (
    <div className="slick-item-bg">
      <div className="slick-black">
        <div className="video-container">
          <div className="container-fluid">
            <div className="row middle-xs center-xs" style={{ height: '100%', color: 'white' }}>
              Too hot to display publicly! Log in to see this preview.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotPreviewableMedia;
