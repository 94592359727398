import { AVAILABILITY_SETTINGS_CHANGE } from 'app/constants/pubnub/types';
import mainApi from 'app/api/mainApi';

export const AvailabilityRefresher = (store) => (next) => (action) => {
  if (action.type !== AVAILABILITY_SETTINGS_CHANGE) {
    return next(action);
  }

  console.log('refreshing availability');

  store.dispatch(mainApi.util.invalidateTags([{ type: 'Availability' }]));

  return next(action);
};
