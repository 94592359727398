import React from 'react';
import { PropTypes } from 'prop-types';

import Subscription from './Subscription';

const MobileSubscriptions = ({ advisorLogin }) => {
  return (
    <div className="mobile-bundles">
      <div>
        <Subscription
          fetchStateFromApi
          advisorLogin={advisorLogin}
        />
      </div>
    </div>
  );
};

MobileSubscriptions.defaultProps = {
  advisorLogin: null,
};

MobileSubscriptions.propTypes = {
  advisorLogin: PropTypes.string,
};

export default MobileSubscriptions;
