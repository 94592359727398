import PropTypes from 'prop-types';

const PhoneShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  secure_number: PropTypes.string,
  unsecure_number: PropTypes.string,

  phone_type: PropTypes.number,
  is_current_call_phone: PropTypes.bool,
  call_phone_status: PropTypes.number,

  is_current_chat_phone: PropTypes.bool,
  chat_phone_status: PropTypes.string,
  can_chat: PropTypes.bool,
  default: PropTypes.bool,
});

export default PhoneShape;
