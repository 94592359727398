import React from 'react';
import { PropTypes } from 'prop-types';
import AuthenticatedLink from '../../shared/AuthenticatedLink';

const GenericSubscriptionButton = ({ callToAction, isFetching }) => {
  return (
    <AuthenticatedLink
      type="button"
      className="btn margintop20 btn-submit"
      onClick={() => {
        if (isFetching) { return; }
        callToAction();
      }}
      disabled={isFetching}
    >
      Subscribe to Unlock
    </AuthenticatedLink>
  );
};

GenericSubscriptionButton.defaultProps = {
  isFetching: false,
};

GenericSubscriptionButton.propTypes = {
  callToAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export default GenericSubscriptionButton;
