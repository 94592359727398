import React, { useState } from 'react';
import { Grid, Container, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import GenderSelectChips from 'app/components/GenderSelectChips';

const ChatBrowseHeader = () => {
  const validateChatAlignment = () => {
    const validAlignments = ['839', '840', '770'];
    const chatAlignment = localStorage.getItem('chatAlignment');
    if (!validAlignments.includes(chatAlignment)) {
      localStorage.setItem('chatAlignment', '839');
      return '839';
    }
    return chatAlignment;
  };

  const [chatAlignment, setChatAlignment] = useState(() => {
    return validateChatAlignment();
  });
  const [searchText, setSearchText] = useState('');

  const handleChatAlignment = (newChatAlignment) => {
    setChatAlignment(newChatAlignment);
    localStorage.setItem('chatAlignment', newChatAlignment); // Save the alignment to localStorage
  };
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText('');
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Find flirts to chat with"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <GenderSelectChips state={chatAlignment} setState={handleChatAlignment} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatBrowseHeader;
