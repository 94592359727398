import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import GoodyBagItemShape from 'app/shapes/GoodyBagItemShape';
import numberToHumanSize from 'app/helpers/fileSizeHelper';

import Grid from '@mui/material/Grid';

import ItemDetail from './ItemDetail';

const ImageGoodyBagItem = ({ goodyBagItem, onGoodyBagItemClick }) => {
  return (
    <Fragment>
      <Grid item xs={8}>
        <div className="ellipsis">
          <ItemDetail>
            {goodyBagItem.previewable ? (
              // TODO: Make this open a preview thing
              <a href="#" onClick={() => { onGoodyBagItemClick(goodyBagItem); }}>{goodyBagItem.file_name}</a>
            ) : (
              <span>{goodyBagItem.file_name}</span>
            )}
          </ItemDetail>
        </div>
      </Grid>
      <Grid item xs={4}>
        <ItemDetail className="pull-right">
          <span>
            {goodyBagItem.content_type.split('/').slice(-1)}
            &nbsp;
            &#40;
            {numberToHumanSize(goodyBagItem.file_size)}
            &#41;
          </span>
        </ItemDetail>
      </Grid>
    </Fragment>
  );
};

ImageGoodyBagItem.propTypes = {
  goodyBagItem: GoodyBagItemShape.isRequired,
  onGoodyBagItemClick: PropTypes.func.isRequired,
};

export default ImageGoodyBagItem;
