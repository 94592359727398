import React from 'react';

const PlaceholderPost = () => {
  return (
    <div className="panel">
      <div className="container-fluid container-post-placeholder">
        <div className="row">
          <div className="loading shape5" />
        </div>
        <div className="row">
          <div className="loading shape6" />
        </div>
      </div>
    </div>
  );
};

export default PlaceholderPost;
