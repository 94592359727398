import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckMarkNF = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="18px" height="16px" viewBox="0 0 18 16">
    {/* Your SVG Path */}
    <path
      d="M16.066,1.696 L15.208875,0.82354333 C16.1454,1.77571159 17.0727,2.71850115 18,3.66129071 L18,3.73816527 C17.896875,3.82503351 17.784825,3.90298431 17.691825,4 C14.137275,7.70819798 10.5843,11.4180103 7.032975,15.1294371 C6.947025,15.2192266 6.882,15.3301565 6.7944,15.4485433 C6.63495,15.2990223 6.5334,15.2086178 6.43695,15.1127553 L0.222825,8.93488528 C0.152025,8.86454506 0.0744,8.80150793 0,8.73501144 L0,8.65813688 C0.5181,8.14453797 1.0401,7.63509028 1.55295,7.11611015 C1.69476857,6.97262983 1.83515072,6.82766749 1.97647813,6.68026728 L6.43695,11.1127553 C6.5334,11.2086178 6.63495,11.2990223 6.7944,11.4485433 C6.882,11.3301565 6.947025,11.2192266 7.032975,11.1294371 C10.5843,7.41801033 14.137275,3.70819798 17.691825,0 L16.066,1.696 Z"
    />
  </SvgIcon>
);

export default CheckMarkNF;
