import React from 'react';
import Box from '@mui/material/Box';

const DecisionScreen = () => {
  return (
    <Box
      sx={{
        padding: '20px',
      }}
    >
      <h2>What&apos;s Next?</h2>
      <Box sx={{ padding: '10px' }}>
        <strong>
          A.&nbsp;
          <a href={window.location.href}>
            Browse
          </a>
          &nbsp;
          or&nbsp;
          <a href="/search">
            search
          </a>
          &nbsp;
          for more listings&nbsp;
          <em>OR</em>
        </strong>
      </Box>

      <Box sx={{ padding: '10px' }}>
        <strong>
          B. Learn more about NiteFlirt in the video below&nbsp;
          <em>OR</em>
        </strong>
        <Box sx={{ marginTop: '10px' }}>
          <iframe
            allowFullScreen
            allowTransparency="true"
            className="vzaar-video-player"
            frameBorder="0"
            height="306"
            id="vzvd-966101"
            name="vzvd-966101"
            src={`${window.location.protocol}//view.vzaar.com/966101/player`}
            title="vzaar video player"
            type="text/html"
            width="544"
          />
        </Box>
      </Box>
      <Box sx={{ padding: '10px' }}>
        <strong>
          C. Register to receive a special offer&nbsp;
          <a href="/signup">
            HERE!
          </a>
        </strong>
      </Box>
    </Box>
  );
};

export default DecisionScreen;
