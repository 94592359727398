import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from 'react-tooltip-lite';

import withTooltip from 'app/hoc/withTooltip';

const CommenterNameTooltip = ({
  children,
  posterLogin,
  toggleTip,
  tipOpen,
  setButtonRef,
  setTipContentRef,
}) => {
  return (
    <Tooltip
      content={(
        <div
          ref={(el) => setTipContentRef(el)}
          className="nfp-tooltip"
        >
          <div className="row">
            <div className="col-xs-11">
              <div className="menu-text">
                { posterLogin }
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a
                role="link"
                tabIndex={0}
                onClick={toggleTip}
              >
                <img
                  src="/plus_frontend/assets/svg/x-close.svg"
                  className="marginlftminus5"
                  alt="close"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="up"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={(el) => setButtonRef(el)}
        onClick={toggleTip}
      >
        {children}
      </a>
    </Tooltip>
  );
};

CommenterNameTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ]),
  toggleTip: PropTypes.func.isRequired,
  tipOpen: PropTypes.bool.isRequired,
  setButtonRef: PropTypes.func.isRequired,
  setTipContentRef: PropTypes.func.isRequired,
};

export default withTooltip(CommenterNameTooltip);
