import React from 'react';
import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

import CallIconNF from 'app/components/customSvgs/CallIconNF';

// Inspired by the former Facebook spinners.
const Connected = (props) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: 'green',
          [`& .${circularProgressClasses.circle}`]: {
            strokeWidth: 3, // Ensuring consistent stroke width
          },
        }}
        size={80}
        thickness={3} // Adjusting the thickness
        {...props}
        value={100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CallIconNF sx={{ color: 'green', fontSize: '2em' }} />
      </Box>
    </Box>
  );
};

export default Connected;
