import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import UseDifferentCard from 'app/components/modals/tributes/UseDifferentCard';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const UseDifferentCardContainer = ({ advisor }) => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const cardType = currentUser?.default_card?.card_type;
  const lastFour = currentUser?.default_card?.last_four;
  const {
    closeDialogAndResetTributeSlice,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  return (
    <UseDifferentCard
      cardType={cardType}
      lastFour={lastFour}
      useDifferentCardClick={useDifferentCardClick}
      closeClick={closeDialogAndResetTributeSlice}
    />
  );
};

UseDifferentCardContainer.defaultProps = {
  advisor: null,
};

UseDifferentCardContainer.propTypes = {
  advisor: PropTypes.object,
};

export default UseDifferentCardContainer;
