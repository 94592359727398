import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  styled,
} from '@mui/material';

const CustomStyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(3, 0, 8, 0), // top, right, bottom, left
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 5, 8, 5), // top, right, bottom, left
  },
}));

const AppBodyContent = ({ children }) => {
  return (
    <CustomStyledBox>
      {children}
    </CustomStyledBox>
  );
};

AppBodyContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppBodyContent;
