import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Stack } from '@mui/material';

const StayConnectedDiv = styled('div')({
  margin: '10px',
  background: 'none',
  padding: '15px',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  textAlign: 'center',
  maxWidth: '326px',
});

const StayConnected = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <StayConnectedDiv>
          <Box sx={{ background: 'none' }} id="awaybody">
            <h3 style={{ fontSize: '18px', color: '#555' }}>
              Stay Connected With Us
            </h3>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <a href="https://phonesexblog.niteflirt.com/" rel="noreferrer" target="_blank">
                <img src="/plus_frontend/assets/svg/ico-soc-blog.svg" alt="NF Blog" />
              </a>
              <a href="https://www.facebook.com/NiteFlirt" rel="noreferrer" target="_blank">
                <img src="/plus_frontend/assets/svg/ico-soc-facebook.svg" alt="Facebook" />
              </a>
              <a href="https://x.com/niteflirt" rel="noreferrer" target="_blank">
                <img src="/plus_frontend/assets/svg/ico-soc-twitter.svg" alt="X" />
              </a>
              <a href="https://www.instagram.com/niteflirt_/" rel="noreferrer" target="_blank">
                <img src="/plus_frontend/assets/svg/ico-soc-instagram.svg" alt="Instagram" />
              </a>
            </Stack>
          </Box>
        </StayConnectedDiv>
      </Grid>
    </Grid>
  );
};

export default StayConnected;
