import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

const paragraphStyles = {
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
};

const GoodyBagDescription = ({ description }) => {
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false);
  const [showReadMoreLink, setShowReadMoreLink] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setShowReadMoreLink(
        ref.current.scrollHeight !== ref.current.clientHeight,
      );
    }
  }, []);

  // TODO: Can we move these classes into an override? or Component
  return (
    <Grid item xs={12} sx={{ p: 2, pt: 3, pb: 3 }}>
      <div className="description" style={readMoreIsOpen ? null : paragraphStyles} ref={ref}>
        {description}
      </div>
      {showReadMoreLink && (
        <div className="div-read-more">
          <a href="#" onClick={() => setReadMoreIsOpen(!readMoreIsOpen)}>{readMoreIsOpen ? 'See less' : 'See more' }</a>
        </div>
      )}
    </Grid>
  );
};

GoodyBagDescription.defaultProps = {
  description: '',
};

GoodyBagDescription.propTypes = {
  description: PropTypes.string,
};

export default GoodyBagDescription;
