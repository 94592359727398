/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';

import { FILE_MANAGER_TAB } from 'app/constants/tabNames';
import { useGetAlbumsQuery } from 'app/api/assetApi';
import ModalsContext from 'app/contexts/ModalsContext';
import ProfileImageModal from 'app/components/modals/ProfileImageModal';
import AddMediaModal from 'app/components/modals/AddMediaModal';
import AddMediaContext from 'app/contexts/AddMediaContext';
import { getWatermarkText, getSignedWatermarkText } from 'app/helpers/watermarkText';

import {
  useGetFileManagerTokenQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

const MediaModalContainer = ({
  closeModal,
  closeAllModals,
  setDenyCloseModalRequests,
  selectedItems,
  setSelectedItems,
  freePost,
  advisor,
  profileImageMode,
}) => {
  const [uploadsComplete, setUploadsComplete] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [anyUploadErrors, setAnyUploadErrors] = useState(false);
  const [currentTab, setCurrentTab] = useState(FILE_MANAGER_TAB);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [innerSelectedItems, setInnerSelectedItems] = useState(selectedItems);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const appConfig = useGetAppConfigQuery();
  const fileManagerTokenQuery = useGetFileManagerTokenQuery();
  const fmToken = fileManagerTokenQuery?.data?.fm_token;
  const currentUser = appConfig?.data?.current_user;
  const filesUploaderUrl = appConfig?.data?.file_uploader_url;
  const albumsQuery = useGetAlbumsQuery();
  const albums = albumsQuery?.data;
  const watermarkCheckbox = useRef();
  const { openModal } = useContext(ModalsContext);

  const isChecked = (item) => (!!innerSelectedItems.find((el) => (el.fm_item_id === item.id)));

  // massage the item into the format we're going to be POSTing
  const decoratedItem = (item) => {
    return {
      id: item.id,
      fm_item_id: item.id,
      url: item.thumbnail_url,
      item_type: item.item_type,
      file_name: item.file_name || item.filename,
    };
  };

  const openProfileImageModal = (image) => {
    openModal({
      component: ProfileImageModal,
      props: {
        closeModal,
        closeAllModals,
        advisor,
        currentUser,
        mode: profileImageMode,
        image,
        selectedAlbum,
        fmToken,
        filesUploaderUrl,
      },
      willNotCloseLowerModals: true,
    });
  };

  const cancelClick = () => {
    if (uploadInProgress) return;

    // undo anything we've done since opening this modal
    setSelectedItems(selectedItems);
    closeModal();
  };

  const addFileManagerItemsToPost = () => {
    setSelectedItems(innerSelectedItems);
    closeModal();
  };

  const updateIndividualFileData = ({ fileName, key, value }) => {
    const index = filesToUpload.findIndex((file) => (file.file.name === fileName));
    const fileToUpdate = filesToUpload[index];

    fileToUpdate[key] = value;
    filesToUpload.splice(index, 1, { ...fileToUpdate });
    setFilesToUpload([...filesToUpload]);
  };

  const allUploadsSucceeded = () => {
    return !filesToUpload.some(f => f.uploadFailed);
  };

  useEffect(() => {
    if (uploadsComplete) {
      const reformattedFiles = filesToUpload.map((f) => {
        if (f.uploadFailed) { return null; }

        return {
          item_type: f.uploadResponse.item_type,
          url: f.uploadResponse.thumbnail_url,
          fm_item_id: f.uploadResponse.id,
          id: f.uploadResponse.id,
          file_name: f.uploadResponse.file_name,
        };
      });

      // Strip null elemets from the reformattedFiles array
      const filteredFiles = reformattedFiles.filter(n => n);
      const combinedFiles = [...filteredFiles, ...innerSelectedItems];

      setSelectedItems(combinedFiles);

      if (allUploadsSucceeded()) {
        closeModal();
      }
    }
  }, [uploadsComplete]);

  const startAllFileUploads = () => {
    if (uploadInProgress) return;

    setUploadInProgress(true);
    setDenyCloseModalRequests(true);

    const allUploads = filesToUpload.map((f) => {
      // keep a record of the original filename
      // FM will alter filename with incrementing int vals if there are dupes
      const origFileName = f.file.name;

      const formData = new FormData();
      formData.append('item[seller_album_id]', selectedAlbum.value);
      formData.append('item[file]', f.file);
      formData.append('create_or_return_duplicate_file', true);
      formData.append('token', fmToken);
      formData.append('upload_from', 'nf_plus');

      if (watermarkCheckbox.current.checked && !profileImageMode) {
        formData.append('watermark_text', getWatermarkText(currentUser));
        formData.append('signed_watermark_text', getSignedWatermarkText(currentUser));
      }

      return axios({
        method: 'POST',
        url: filesUploaderUrl,
        data: formData,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (prog) => {
          updateIndividualFileData({
            fileName: origFileName,
            key: 'bytesLoaded',
            value: prog.loaded,
          });
        },
      }).then((response) => {
        updateIndividualFileData({ fileName: origFileName, key: 'uploadResponse', value: response.data });

        if (response.data.error_messages.length > 0) {
          updateIndividualFileData({ fileName: origFileName, key: 'uploadResponse', value: response.data.error_messages.join(',') });
          updateIndividualFileData({ fileName: origFileName, key: 'uploadFailed', value: true });
        }
      }).catch((error) => {
        updateIndividualFileData({ fileName: origFileName, key: 'uploadResponse', value: error?.response?.data?.error_messages?.join(',') });
        updateIndividualFileData({ fileName: origFileName, key: 'uploadFailed', value: true });
      });
    });

    Promise.all(allUploads).then(() => {
      setUploadInProgress(false);
      setDenyCloseModalRequests(false);
      setUploadsComplete(true);

      if (!allUploadsSucceeded()) {
        setAnyUploadErrors(true);
      }
    });
  };

  const addToPostClick = () => {
    if (uploadInProgress) return;

    if (currentTab === FILE_MANAGER_TAB) {
      addFileManagerItemsToPost();
    } else {
      startAllFileUploads();
    }
  };

  const toggleSelectedItem = (rawItem, checkboxState) => {
    const item = decoratedItem(rawItem);

    if (checkboxState) {
      if (isChecked(item)) return; // don't add again it if it's already here
      if (profileImageMode) {
        showPreview(item);
      } else {
        setInnerSelectedItems(innerSelectedItems.concat(item));
      }
    } else if (profileImageMode) showPreview(item);
  };

  const showPreview = (image) => {
    openProfileImageModal(image);
  };

  const onFilesSelected = (e) => {
    const newFilesWithMeta = Array.from(e.target.files).map((f) => ({
      file: f,
      bytesLoaded: 0,
      bytesTotal: f.size,
      previewRendered: false,
      uploadResponse: null,
    }));

    if (profileImageMode) {
      showPreview(e.target.files[0]);
    } else {
      setFilesToUpload([...newFilesWithMeta, ...filesToUpload]);
    }
  };

  const openNativeFileDialog = (e) => {
    if (uploadInProgress) return;

    e.preventDefault();
    document.getElementById('fileInput').click();
  };

  const onRemoveUploadClick = (e) => {
    if (uploadInProgress) return;

    const newArray = filesToUpload.filter((f) => e.currentTarget.dataset.filename !== f.file.name);
    setFilesToUpload(newArray);
  };

  useEffect(() => {
    if (!albums) return;

    if (!selectedAlbum) {
      const album = albums.find(a => /Default/.test(a.title)) || albums[0];
      setSelectedAlbum({ value: album.id, label: album.title });
    }
  }, [albums]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context = {
    openNativeFileDialog,
    closeModal,
    currentTab,
    setCurrentTab,
    albumsQuery,
    selectedAlbum,
    setSelectedAlbum,
    toggleSelectedItem,
    onFilesSelected,
    cancelClick,
    addToPostClick,
    filesToUpload,
    setFilesToUpload,
    isChecked,
    onRemoveUploadClick,
    filesUploaderUrl,
    fmToken,
    uploadInProgress,
    updateIndividualFileData,
    currentUser,
    watermarkCheckbox,
    anyUploadErrors,
    freePost,
    profileImageMode,
  };

  return (
    <AddMediaContext.Provider value={context}>
      <AddMediaModal />
    </AddMediaContext.Provider>
  );
};

MediaModalContainer.defaultProps = {
  advisor: null,
  freePost: false,
  profileImageMode: '',
};

MediaModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  advisor: PropTypes.object,
  freePost: PropTypes.bool,
  setDenyCloseModalRequests: PropTypes.func.isRequired,
  profileImageMode: PropTypes.string,
};

export default MediaModalContainer;
