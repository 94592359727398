import React from 'react';
import { useNavigate } from 'react-router';
import { PropTypes } from 'prop-types';

const FirstPostSuccessModal = ({ closeModal }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="modal-header">
        <h4>Congrats, your feed is now live!</h4>
      </div>
      <div className="modal-body">
        <p>
          On NF+, you control the content that is visible to current &amp; future subscribers so they find value
          in their subscriptions right away. We recommend creating 9 more Subscriber Posts that are visible to
          current &amp; future subscribers. Be sure to frequently create Free Posts to entice new subscribers.
        </p>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          Cancel
        </a>
        <button
          type="button"
          className="btn"
          onClick={() => {
            navigate('/feed/create_post');
            closeModal();
          }}
        >
          Create Another Post
        </button>
      </div>
    </>
  );
};

FirstPostSuccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default FirstPostSuccessModal;
