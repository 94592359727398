import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import ExistingTributeForm from 'app/components/modals/tributes/ExistingTributeForm';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import { showFlash } from 'app/slices/flashSlice';
import { useCreateBonusMutation } from 'app/api/mainApi';
import { setRecurring } from 'app/slices/tributesSlice';

import NewTributeFormContainer from 'app/containers/modals/tributes/NewTributeFormContainer';
import { featureFlag } from 'app/util/featureFlag';

const ExistingTributeFormContainer = ({ advisor }) => {
  if (featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537')) {
    return <NewTributeFormContainer advisor={advisor} />;
  }

  const dispatch = useDispatch();
  const [createBonus, createBonusResult] = useCreateBonusMutation();

  const {
    closeDialogAndResetTributeSlice,
    currentUserHasCard,
    setTributeDeficit,
    setAmountError,
    activeTributes,
    setCurrentStep,
    tributeMessage,
    tributeAmount,
    amountInvalid,
    closeDialog,
    amountError,
    setMessage,
    setAmount,
  } = useRecurringTributeManagement({ advisor });

  const closeClick = () => {
    closeDialogAndResetTributeSlice();
  };

  const sendTributeClick = () => {
    const fromNfChat = window.location.pathname.includes('/chat');

    createBonus({
      listingId: advisor.top_listing_id,
      message: tributeMessage,
      amount: parseFloat(tributeAmount),
      fromNfChat,
    });
  };

  const handleCreateBonusSuccess = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS,
    }));
  };

  const handleCreateBonusError = (data) => {
    const { errors, deficit } = data;

    if (errors.amount && errors.amount[0] === 'You don\'t have enough money in your account.') {
      if (currentUserHasCard) {
        setTributeDeficit(deficit);

        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.ADD_TO_BALANCE,
        }));
      } else {
        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
        }));
      }
    }

    const recipientError = errors.not_send_to_denied_recipients || errors.not_send_to_blocked_recipients;

    if (recipientError) {
      closeDialog();

      dispatch(showFlash({
        flashType: 'error',
        props: { message: recipientError[0] },
      }));
    }
  };

  const onEditTributeClick = () => {
    dispatch(setRecurring({ recurring: true }));

    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_MONETARY_TRIBUTE,
    }));
  };

  const onUpdateDefaultCardClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
    }));
  };

  useEffect(() => {
    if (createBonusResult.isSuccess) {
      handleCreateBonusSuccess();
    }

    if (
      createBonusResult.isError &&
      createBonusResult.error.status === 422
    ) {
      handleCreateBonusError(createBonusResult.error.data);
    }
  }, [createBonusResult]);

  if (!advisor || !activeTributes) return null;

  return (
    <ExistingTributeForm
      advisor={advisor}
      setAmount={setAmount}
      amount={tributeAmount}
      setMessage={setMessage}
      closeClick={closeClick}
      message={tributeMessage}
      amountError={amountError}
      closeDialog={closeDialog}
      amountInvalid={amountInvalid}
      activeTributes={activeTributes}
      setAmountError={setAmountError}
      sendTributeClick={sendTributeClick}
      onEditTributeClick={onEditTributeClick}
      onUpdateDefaultCardClick={onUpdateDefaultCardClick}
    />
  );
};

ExistingTributeFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ExistingTributeFormContainer;
