import React from 'react';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useGetProcessingPostsQuery } from 'app/api/feedsApi';
import QueuedPostContainer from 'app/containers/posts_queue/QueuedPostContainer';
import QueuedPostPlaceholder from 'app/components/QueuedPostPlaceholder';

const ProcessingPostsContainer = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  const { data: posts } = useGetProcessingPostsQuery(
    { userId: currentUser?.id },
    { refetchOnMountOrArgChange: true },
  );

  if (!posts || posts.length === 0) {
    return (
      <QueuedPostPlaceholder placeholderText="All of your posts have been processed!" />
    );
  }

  return (
    <div className="container-fluid padding10">
      <div className="row">
        <div className="col-xs-12 start-xs">
          <div className="container-table-queue">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="3">Submission Date</th>
                </tr>
              </thead>
              <tbody>
                {posts.map((post) => <QueuedPostContainer key={post.id} post={post} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessingPostsContainer;
