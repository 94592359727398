import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { PropTypes } from 'prop-types';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import { showFlash } from 'app/slices/flashSlice';
import { userIsSeeker } from 'app/helpers/whitelistHelpers';

const RestrictedRoute = ({ children }) => {
  const HOME = '/feed/home';

  const dispatch = useDispatch();
  const { data } = useGetAppConfigQuery();
  const currentUser = data?.current_user;

  if (!currentUser) { return null; }

  if (userIsSeeker(currentUser)) {
    dispatch(showFlash({
      flashType: 'error',
      props: { message: `You're not allowed to see this content! Redirected to ${HOME}` },
    }));

    return <Navigate to={HOME} replace />;
  }

  return children;
};

RestrictedRoute.propTypes = {
  children: PropTypes.object.isRequired,
};

export default RestrictedRoute;
