import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
} from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';

const TitleWithBackNavigation = ({ title }) => {
  return (
    <Grid container alignItems="center" className="container-hdr-sticky2">
      <Grid item p={1}>
        <span role="button" onClick={() => window.history.back()} tabIndex={0}>
          <ArrowBackIosSharpIcon
            htmlColor="white"
          />
        </span>
      </Grid>
      <Grid item>
        { title }
      </Grid>
    </Grid>
  );
};

TitleWithBackNavigation.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleWithBackNavigation;
