import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import MediaItem from 'app/components/media/MediaItem';

const MediaItemsPageContainer = ({
  setOldestCursor,
  feedQuery,
  setMoreRecords,
  onMediaItemClick,
  advisor,
}) => {
  const { data, isFetching } = feedQuery();
  const mediaItems = data?.records;
  const moreRecords = data?.moreRecords;

  useEffect(() => { setMoreRecords(moreRecords); }, [moreRecords]);

  useEffect(() => {
    if (mediaItems?.length > 0) {
      const oldestItem = mediaItems[mediaItems.length - 1];
      const compositeCursor = `${oldestItem.post_published_at}|${oldestItem.sort_order}`;
      setOldestCursor(compositeCursor);
    }
  }, [mediaItems]);

  if (isFetching) {
    return (
      <div className="container-fluid margintop30">
        <div className="row">
          <div className="col-xs-12 center-xs">
            <img src="/plus_frontend/assets/svg/nfp-loading.svg" alt="Loading" className="spinner" />
          </div>
        </div>
      </div>
    );
  }

  return (
    mediaItems.map((mediaItem) => {
      return (
        <MediaItem
          key={mediaItem.fm_item_id}
          onMediaItemClick={onMediaItemClick}
          mediaItem={mediaItem}
          advisor={advisor}
        />
      );
    })
  );
};

MediaItemsPageContainer.propTypes = {
  setOldestCursor: PropTypes.func.isRequired,
  feedQuery: PropTypes.func.isRequired,
  setMoreRecords: PropTypes.func.isRequired,
  onMediaItemClick: PropTypes.func.isRequired,
  advisor: PropTypes.object.isRequired,
};

export default MediaItemsPageContainer;
