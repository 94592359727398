import { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import mainApi, {
  usePerformUnsubscribeMutation,
  usePerformApprovePriceChangeMutation,
  useGetAppConfigQuery,
  useGetAdvisorQuery,
  useGetSubscriptionQuery,
  usePerformSubscribeMutation,
  usePerformResubscribeMutation,
} from 'app/api/mainApi';

import useSubscriptionButtonState from 'app/hooks/useSubscriptionButtonState';
import ModalsContext from 'app/contexts/ModalsContext';

// modals
import UnsubscribeModal from 'app/components/modals/subscriptions/UnsubscribeModal';
import SubscribedModal from 'app/components/modals/subscriptions/SubscribedModal';
import { showFlash, simpleErrorFlash } from 'app/slices/flashSlice';
import { resetFlirtFeed } from 'app/slices/resetRecordsSlice';
import SubscribeModalContainer from './modals/SubscribeModalContainer';
import { startLoading, endLoading } from 'app/slices/subscriptionSlice';
import useCreditCardManagement from 'app/hooks/useCreditCardManagement';

const SubscriptionButtonContainer = ({
  subscriptionData,
  fetchStateFromApi,
  advisorLogin,
  displayOnHiddenPost,
}) => {
  let subData;
  let advisor;

  if (fetchStateFromApi) {
    advisor = useGetAdvisorQuery({ login: advisorLogin }, { skip: !advisorLogin })?.data;
    subData = useGetSubscriptionQuery({ advisorId: advisor?.id }, { skip: !advisor })?.data;
  } else {
    subData = subscriptionData;
    advisor = {
      id: subData.subscription.advisor_id,
      active: subData.subscription.advisor_active,
      login: subData.subscription.advisor_login,
      onboarding_complete: subData.subscription.advisor_onboarding_complete,
      blocked: subData.subscription.advisor_blocked,
    };
  }

  const { data: config } = useGetAppConfigQuery();
  const { openModal, closeAllModals } = useContext(ModalsContext);
  const currentUser = config?.current_user;
  const currentUserHasCard = Object.keys(currentUser?.default_card || {}).length > 0;
  const [errorCount, setErrorCount] = useState(0);
  const dispatch = useDispatch();
  const [multiRequestGuard, setMultiRequestGuard] = useState(false);

  /* eslint-disable no-unused-vars */
  const [performUnsubscribe, {
    status: unsubscribeStat,
    data: unsubscribedData,
    isFetching: isFetchingUnsubscribe,
  }] = usePerformUnsubscribeMutation();
  /* eslint-enable no-unused-vars */

  /* eslint-disable no-unused-vars */
  const [performSubscribe, {
    status: subscribeStat,
    data: subscribedData,
    isFetching: isFetchingSubscribe,
    error: subscribeError,
    isError: isSubscribeError,
  }] = usePerformSubscribeMutation();
  /* eslint-enable no-unused-vars */

  /* eslint-disable no-unused-vars */
  const [performResubscribe, {
    status: resubscribeStat,
    data: resubscribedData,
    isFetching: isFetchingResubscribe,
  }] = usePerformResubscribeMutation();
  /* eslint-enable no-unused-vars */

  /* eslint-disable no-unused-vars */
  const [performApprovePriceChange, {
    status: approvePriceChangeStat,
    data: approvePriceChangeData,
    isFetching: isFetchingApprovePriceChange,
  }] = usePerformApprovePriceChangeMutation();
  /* eslint-enable no-unused-vars */

  // Auto-pick the first offering, because that's all we only support one avaiable offering for MVP
  const offering = subData?.available_offerings?.[0];
  const subscriptionEndsAt = subData?.subscription?.subscription_ends_at;

  // this alias function will be set to the openManageCardsModal function
  // as returned by the useCreditCardManagement hook. This is necessary because of the
  // cyclical dependency structures between openSubscribeModal, requestToOpenSubscribeModal,
  // and the useCreditCardManagement hook.
  let openManageCardsModalAlias;

  const openSubscribeModal = (forceOpen = false) => {
    if (multiRequestGuard && !forceOpen) return;

    setMultiRequestGuard(true);

    openModal({
      component: SubscribeModalContainer,
      props: {
        advisor,
        currentUser,
        openManageCardsModal: openManageCardsModalAlias,
        stats: subscribeStat,
        clickAction: performSubscribe,
      },
      customCloseFunc: () => {
        setMultiRequestGuard(false);
      },
    });
  };

  // this is necessary to reload the offer data BEFORE launching the modal.
  // for {reasons} if the modal launches before the data is refreshed, the modal
  // shows the old subscription offering (price) and doesn't update like it should
  // (like a normal react comp).
  const requestToOpenSubscribeModal = async (forceOpen = false) => {
    if (advisor?.blocked) {
      return dispatch(showFlash({
        flashType: 'error',
        props: {
          message: 'We\'re sorry, you are unable to subscribe this Flirt because this Flirt blocked you or you blocked this Flirt.',
        },
      }));
    }

    // invalidate the tag so RTK fetches fresh subscription data
    await dispatch(mainApi.util.invalidateTags([
      { type: 'Subscription', id: `advisor-${advisor.id}` },
    ]));

    return openSubscribeModal(forceOpen);
  };

  const creditCardTryAgainSuccess = () => {
    performSubscribe({ advisorId: advisor.id, offering });
  };

  const {
    openManageCardsModal,
    openRetrySubscribeModal,
    openPickANewCardModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: multiRequestGuard,
    setGuard: setMultiRequestGuard,
    onTryAgainSuccess: creditCardTryAgainSuccess,
    onManageCardsSuccess: requestToOpenSubscribeModal,
  });

  openManageCardsModalAlias = openManageCardsModal;

  useEffect(() => {
    if (
      subscribeStat === 'fulfilled' ||
      resubscribeStat === 'fulfilled' ||
      unsubscribeStat === 'fulfilled' ||
      approvePriceChangeStat === 'fulfilled'
    ) {
      setTimeout(() => {
        setMultiRequestGuard(false);
      }, 1000);
    }
  }, [subscribeStat, resubscribeStat, approvePriceChangeStat, unsubscribeStat]);

  const subscribeWithoutConfirmation = () => {
    if (multiRequestGuard) return;

    setMultiRequestGuard(true);

    if (advisor?.blocked) {
      dispatch(showFlash({
        flashType: 'error',
        props: {
          message: 'We\'re sorry, you are unable to subscribe this Flirt because this Flirt blocked you or you blocked this Flirt.',
        },
      }));
    } else {
      performResubscribe({
        advisorId: advisor.id,
        subscriptionId: subData.subscription.id,
      });
    }
  };

  const approveSubscription = () => {
    if (multiRequestGuard) return;

    setMultiRequestGuard(true);

    performApprovePriceChange({
      advisorId: advisor.id,
      subscriptionId: subData.subscription.id,
    });
  };

  const openUnsubscribeModal = () => {
    if (multiRequestGuard) return;

    setMultiRequestGuard(true);

    openModal({
      component: UnsubscribeModal,
      props: {
        subscriptionEndsAt,
        clickAction: () => { performUnsubscribe({ advisorId: advisor.id }); },
      },
      customCloseFunc: () => {
        setMultiRequestGuard(false);
      },
    });
  };

  useEffect(() => {
    if (isSubscribeError) {
      closeAllModals();

      // this happens when the advisor changes their price
      // between the time the user clicks subscribe and the time
      // the user confirms the subscription
      if (subscribeError?.status === 404) {
        dispatch(simpleErrorFlash('Sorry something went wrong. Please try again.'));
      }

      // we will get a 400 when the user is out of funds
      if (subscribeError?.status === 400) {
        openRetrySubscribeModal();
      }
    }
  }, [isSubscribeError]);

  useEffect(() => {
    if (subscribeStat === 'pending') {
      dispatch(startLoading());
    } else {
      dispatch(endLoading());
    }

    // skip effect unless we've just received a 200 response
    if (subscribeStat !== 'fulfilled' || !subscribedData) return;

    closeAllModals();

    // note that subscribedData.error is not the same as isSubscribeError
    // 404 and 400 errors are handled in the isSubscribeError effect above
    if (subscribedData.error) {
      setMultiRequestGuard(false);
      if (errorCount === 0) { // first error they encountered
        openRetrySubscribeModal();
      } else { // subsequent errors
        openPickANewCardModal();
      }
      setErrorCount(errorCount + 1);
    } else if (window.location.pathname.match(`^/feed/${encodeURIComponent(advisor.login)}`)) {
      setMultiRequestGuard(false);
      // If we got here, there was no error; if already on flirt feed,
      // just close modal and refresh flirt feed
      dispatch(resetFlirtFeed({ advisorId: advisor.id }));
    } else if (subscribedData.charged) {
      setMultiRequestGuard(false);
      openModal({
        component: SubscribedModal,
        props: { advisorLogin: advisor.login },
      });
    }
  }, [subscribeStat]);

  const cantFollowSelf = () => {
    dispatch(showFlash({
      flashType: 'error',
      props: { message: 'You can\'t subscribe to yourself' },
    }));
  };

  const callsToAction = {
    cantFollowSelf,
    openPickANewCardModal,
    subscribeWithoutConfirmation,
    unsubscribe: openUnsubscribeModal,
    subscribe: currentUserHasCard ? requestToOpenSubscribeModal : openManageCardsModal,
    approveSubscription,
  };

  const configuredButton = useSubscriptionButtonState({
    subscriptionData: subData,
    currentUser,
    advisor,
    callsToAction,
    displayOnHiddenPost,
    isFetching: multiRequestGuard,
  });

  if (!configuredButton) { return null; }

  return configuredButton;
};

SubscriptionButtonContainer.defaultProps = {
  subscriptionData: null,
  advisorLogin: null,
};

SubscriptionButtonContainer.propTypes = {
  subscriptionData: PropTypes.object,
  fetchStateFromApi: PropTypes.bool.isRequired,
  displayOnHiddenPost: PropTypes.bool.isRequired,
  advisorLogin: PropTypes.string,
};

export default SubscriptionButtonContainer;
