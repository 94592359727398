import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetNewFeedbackQuery, useUpsertFeedbackMutation } from 'app/api/mainApi';
import LeaveFeedbackModal from 'app/components/modals/LeaveFeedbackModal';
import ModalsContext from 'app/contexts/ModalsContext';

const LeaveFeedbackModalContainer = ({ objectType, objectId }) => {
  const queryResult = useGetNewFeedbackQuery({ objectType, objectId });
  const { data } = queryResult;
  const [upsertFeedback, { status: upsertFeedbackStatus, error: upsertFeedbackError }] = useUpsertFeedbackMutation();
  const [feedbackData, setFeedbackData] = useState(null);
  const { closeModal } = useContext(ModalsContext);

  useEffect(() => {
    setFeedbackData(data);
  }, [data]);

  const handleSubmit = (formData) => {
    const id = formData.feedback.id || '';
    const feedbackAndCallQualityData = {
      feedback: formData.feedback,
      call_quality_feedback: formData.callQualityFeedback,
    };
    upsertFeedback({ id, feedbackAndCallQualityData });
  };

  useEffect(() => {
    if (upsertFeedbackStatus === 'fulfilled' || upsertFeedbackStatus === 'rejected') {
      const updatedFeedbackData = {
        ...feedbackData,
        feedback: {
          ...feedbackData.feedback,
          ...upsertFeedbackError?.data.feedback,
        },
      };
      setFeedbackData(updatedFeedbackData);
    }

    if (upsertFeedbackStatus === 'fulfilled') {
      closeModal();
    }
  }, [upsertFeedbackStatus, upsertFeedbackError]);

  if (!feedbackData) {
    return null;
  }

  return (
    <LeaveFeedbackModal data={feedbackData} onSubmit={handleSubmit} />
  );
};

LeaveFeedbackModalContainer.propTypes = {
  objectType: PropTypes.string.isRequired,
  objectId: PropTypes.number.isRequired,
};

export default LeaveFeedbackModalContainer;
