import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';
import useFlirtActions from 'app/hooks/useFlirtActions';
import { contextAwareChatUrl } from 'app/helpers/userHelper';

function HeaderContactButtons({ advisor, currentUser, mobileVersion }) {
  const { onTributeButtonClick } = useFlirtActions({ advisor });
  const chatUrl = contextAwareChatUrl(currentUser, advisor);

  if (mobileVersion) {
    return (
      <div className="container-fluid padding-0-8 profile-mobile-buttons">
        <div className="row center-xs">
          { advisor?.enrolled_chat && !advisor?.explicitly_unenrolled_in_chat && (
            <div className="margin5">
              <Link
                title="Chat"
                reloadDocument
                to={chatUrl}
                role="link"
              >
                <img src="/plus_frontend/assets/svg/btn-chat.svg" alt="Chat" />
              </Link>
            </div>
          )}
          <div className="margin5" />
          <div className="margin5">
            <AuthenticatedLink
              role="link"
              tabIndex={0}
              title="Pay a Tribute"
              onClick={onTributeButtonClick}
            >
              <img
                src="/plus_frontend/assets/svg/btn-tribute.svg"
                alt="Pay a Tribute"
              />
            </AuthenticatedLink>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row nowrap-sm end-sm paddingrgt15">
        { advisor?.enrolled_chat && !advisor?.explicitly_unenrolled_in_chat && (
          <div className="padding5">
            <Link
              title="Chat"
              reloadDocument
              to={chatUrl}
              role="link"
            >
              <img src="/plus_frontend/assets/svg/btn-chat-profile.svg" alt="Chat" />
            </Link>
          </div>
        )}
        <div className="padding5">
          <AuthenticatedLink
            role="link"
            tabIndex={0}
            title="Pay a Tribute"
            onClick={onTributeButtonClick}
          >
            <img
              src="/plus_frontend/assets/svg/btn-tribute-profile.svg"
              alt="Pay a Tribute"
              className="nav-hero-icon"
            />
          </AuthenticatedLink>
        </div>
      </div>
    );
  }
}

HeaderContactButtons.defaultProps = {
  advisor: {},
  currentUser: null,
};

HeaderContactButtons.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
  mobileVersion: PropTypes.bool.isRequired,
};

export default HeaderContactButtons;
