import React from 'react';
import FeedbackShape from 'app/shapes/FeedbackShape';

import {
  Box,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { DateTime } from 'luxon';

import LeaveFeedbackButton from 'app/components/feedback/LeaveFeedbackButton';

const objectTypeDisplayName = {
  Conference: 'Niteflirt Call',
  PurchasedGoodyBag: 'Goody Bag',
  Message: 'Niteflirt Mail Message',
};

const FeedbackRow = ({ feedback }) => {
  return (
    <Grid data-test-id="feedback-row" container sx={{ borderBottom: '1px solid #ddd', padding: { xs: '20px', sm: '20px 0px' } }}>
      <Grid item xs={8}>
        <Typography>
          <a href={`/profile/${feedback.advisor_login}`} data-test-id="advisor-login">
            {feedback.advisor_login}
          </a>
        </Typography>
        <Typography data-test-id="object-type" variant="body3" sx={{ color: grey[ 600 ] }}>
          {objectTypeDisplayName[ feedback.object_type ]}
        </Typography>
      </Grid>
      <Grid item xs={4} className="pull-right">
        <Typography data-test-id="created-at" variant="body3" sx={{ color: grey[ 600 ], alignSelf: 'flex-end' }}>
          {DateTime.fromISO(feedback.created_at).toFormat('MM/dd/yyyy')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <a
            href={`/transactions/details?object_type=${feedback.object_type}&object_id=${feedback.object_id}`}
            data-test-id="view-details-link"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="outlined" size="small">View Details</Button>
          </a>
          {feedback.can_leave_feedback && (
            <LeaveFeedbackButton feedback={feedback} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

FeedbackRow.propTypes = {
  feedback: FeedbackShape.isRequired,
};

export default FeedbackRow;
