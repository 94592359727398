import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/components/App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'app/redux/store';

window.global_store ||= store;
const rootElement = document.getElementById('nf-plus-app');
// NOTE: WOMP will strip out data attributes from the dom when it re-renders the app. This is a temporary fix to get the mode from the dom.
const wompHack = document.getElementById('use-modal-mode');
const mode = wompHack ? 'modal' : rootElement.dataset.mode;

ReactDOM.render(
  <Provider store={window.global_store}>
    <PersistGate loading={null} persistor={persistor}>
      <App mode={mode} />
    </PersistGate>
  </Provider>,
  rootElement,
);
