import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import ModalsContext from 'app/contexts/ModalsContext';
import RejectionReasonModal from 'app/components/modals/RejectionReasonModal';

const RejectedPost = ({ post }) => {
  const { openModal } = useContext(ModalsContext);

  const openRejectionReasonModal = () => {
    openModal({
      component: RejectionReasonModal,
      props: { post },
    });
  };

  return (
    <div>
      <div className="flux-state-overlay1">
        <h2>Post Removed</h2>
        <br />
        <br />
        <br />
        <div style={{ fontSize: '0.938em' }}>
          (Only you can see this.)
        </div>
        <br />
        <br />
        <br />
        <button type="button" className="btn" onClick={openRejectionReasonModal}>
          See Details
        </button>
      </div>
      <div className="overflow-hidden">
        <div className="slick-item-bg flux-state-item1" style={{ backgroundImage: 'url(/plus_frontend/assets/bg-blur1.png)' }}>&nbsp;</div>
      </div>
    </div>
  );
};

RejectedPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default RejectedPost;
