import React from 'react';
import { useParams } from 'react-router';

const TransactionDetails = () => {
  const { id, action } = useParams();
  const idPathElement = id ? `/${id}` : '';

  // It was a big mistake to rename the detail page "details" just because it shows more than one detail.
  // This string replace means that all paths are now named the same thing they were in the old design.
  const singularAction = action.replace('details', 'detail');

  const newPath = `/embedded/account/${singularAction}${idPathElement}${window.location.search}`;

  return (
    <iframe
      id="mainIframe"
      title="Transactions"
      className="iframe-responsive"
      style={{ width: '100%', border: 0 }}
      src={newPath}
    />
  );
};

export default TransactionDetails;
