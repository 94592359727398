import { DateTime } from 'luxon';

export const DATE_FORMAT = 'MM/dd/yy';

export const timestampInTimezone = (iso8601Timestamp, ianaTimezone, format = 'LLL d hh:mm a') => {
  if (!iso8601Timestamp) return '';

  const options = {};
  if (ianaTimezone) options.zone = ianaTimezone;

  const date = DateTime.fromISO(iso8601Timestamp, options);

  return date.toFormat(format);
};
