import React from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { styled } from '@mui/material/styles';

import { Link } from '@mui/material';

const DividerWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
}));

const ExistingTributeForm = ({
  advisor,
  activeTributes,
  amount,
  amountInvalid,
  setAmount,
  message,
  setMessage,
  amountError,
  setAmountError,
  sendTributeClick,
  closeClick,
  onEditTributeClick,
  onUpdateDefaultCardClick,
}) => {
  return (
    <>
      <DialogTitle>
        Send a Tribute to&nbsp;
        {advisor.login}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Choose a custom one-time tribute amount.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={amount}
              type="number"
              inputMode="decimal"
              label="Tribute Amount"
              error={!!amountError}
              helperText={amountError}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(e) => {
                setAmountError('');
                setAmount(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={message}
              label="Personal Message (optional)"
              onChange={(e) => { setMessage(e.currentTarget.value); }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                onClick={closeClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={amountInvalid(amount)}
                onClick={sendTributeClick}
              >
                Send Tribute
              </Button>
            </Stack>
          </Grid>
          <Grid container item xs={12} rowSpacing={2}>
            <DividerWrapper>
              <Divider />
            </DividerWrapper>
            <Grid item xs={12}>
              <Typography variant="body1">
                {`You also have the following recurring tribute to ${advisor.login}:`}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {`${activeTributes.monetaryTribute.rate} per month`}
                </Typography>
                <Typography variant="body1">
                  {`Renews ${activeTributes.monetaryTribute.renewalDate}`}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <EditOutlinedIcon onClick={onEditTributeClick} />
                </Grid>
              </Grid>
            </Grid>
            {activeTributes.monetaryTribute.status === 'charge_failed' && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Renewal attempt(s) failed. We&apos;ll try again.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Link role="button" tabIndex={0} onClick={onUpdateDefaultCardClick}>
                    Update Default Card
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

ExistingTributeForm.propTypes = {
  advisor: PropTypes.object.isRequired,
  activeTributes: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
  amountInvalid: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  amountError: PropTypes.string.isRequired,
  setAmountError: PropTypes.func.isRequired,
  sendTributeClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
  onEditTributeClick: PropTypes.func.isRequired,
  onUpdateDefaultCardClick: PropTypes.func.isRequired,
};

export default ExistingTributeForm;
