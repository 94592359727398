import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const PasswordHelpText = () => {
  return (
    <Box style={{ marginBottom: '20px' }}>
      <Typography variant="body3" sx={{ color: '#757575', lineHeight: 'normal' }}>
        Password must contain the following:
        <Box ml={1}>
          • 6-16 characters
          <br />
          • Include at least one letter
          <br />
          • Include at least one number
        </Box>
      </Typography>
    </Box>
  );
};
