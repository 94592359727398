import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, Select, MenuItem, InputLabel, FormControl, OutlinedInput, Chip } from '@mui/material';
import { setFilter } from 'app/slices/goodyBagFilterSlice';

const MultiSelectFilter = ({ facet, filters, resultCounts }) => {
  const { id, label, values } = facet;
  const filterValue = filters[id];
  const dispatch = useDispatch();

  const onChange = (event) => {
    const { value } = event.target;
    dispatch(setFilter({ filter: id, value }));
  };

  const selectedChips = (selected) => {
    if (!selected) return null;
    const selectedLabels = selected.map(
      // look up value in the facet data, and turn them into human-readable labels
      (value) => values.find(
        (v) => v.id === value,
      ).label,
    ).filter(x => x); // filter any unrecognized values

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selectedLabels.map((label) => (
          <Box key={label} onClick={(e) => e.stopPropagation()}>
            <Chip label={label} />
          </Box>
        ))}
      </Box>
    );
  };

  const counts = resultCounts[id] || {};

  return (
    <Box>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id={`${id}_label`}>{label}</InputLabel>
        <Select
          labelId={`${id}_label`}
          id={id}
          multiple
          value={filterValue}
          onChange={onChange}
          input={<OutlinedInput label={label} />}
          renderValue={selectedChips}
        >
          {
            values
              // zip the values with the counts
              .map((value, index) => [value, counts[index]])
              .filter((value) => (
                // if there are no counts, just return true
                // if there are counts, only return values that have a positive count
                (Object.keys(counts).length === 0) || (value[1] > 0)
              ))
              // transform the zipped values into MenuItem components
              .map((item) => {
                const [value, count] = item;

                return (
                  <MenuItem key={`${id}_${value.id}`} value={value.id}>
                    {value.label}
                    &nbsp;
                    {count && `(${count})`}
                  </MenuItem>
                );
              })
          }
        </Select>
      </FormControl>
    </Box>
  );
};

MultiSelectFilter.propTypes = {
  facet: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  resultCounts: PropTypes.object.isRequired,
};

export default MultiSelectFilter;
