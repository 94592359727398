import React from 'react';
import { PropTypes } from 'prop-types';

const RenewalFailedSubscriptionButton = ({
  buttonClass,
  callToAction,
  label,
  price,
  period,
  chooseCardFunc,
  expirationDate,
}) => {
  return (
    <>
      <div className={`container-bundle ${buttonClass}`}>
        <a role="link" tabIndex={0} onClick={callToAction}>
          <div className="row middle-xs">
            <div className="col-xs-6">
              <div>
                {label}
                &nbsp;
                <span className="small-text" />
              </div>
            </div>
            <div className="col-xs-6 end-xs">
              <div className="row middle-xs end-xs">
                {price}
                <span className="small-text">
                  &nbsp;
                  {period}
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="container-renew">
        <div className="row middle-xs">
          <div className="col-xs-7 start-xs">
            <span className="error">Renewal Failed</span>
            &nbsp;-&nbsp;
            <a
              role="link"
              tabIndex={0}
              onClick={chooseCardFunc}
            >
              Update Card
            </a>
            &nbsp;or Expires
          </div>
          <div className="col-xs-5 end-xs">
            {expirationDate}
          </div>
        </div>
      </div>
    </>
  );
};

RenewalFailedSubscriptionButton.defaultProps = {

};

RenewalFailedSubscriptionButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  callToAction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  chooseCardFunc: PropTypes.func.isRequired,
  expirationDate: PropTypes.string.isRequired,
};

export default RenewalFailedSubscriptionButton;
