import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import DeprecatedNewTributeForm from 'app/components/modals/tributes/DeprecatedNewTributeForm';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

import { useCreateBonusMutation } from 'app/api/mainApi';

const DeprecatedNewTributeFormContainer = ({ advisor }) => {
  const [createBonus, createBonusResult] = useCreateBonusMutation();

  const {
    dispatch,
    setAmount,
    recurring,
    setMessage,
    closeDialog,
    amountError,
    setRecurring,
    tributeAmount,
    amountInvalid,
    setAmountError,
    tributeMessage,
    setCurrentStep,
    setTributeDeficit,
    currentUserHasCard,
    closeDialogAndResetTributeSlice,
  } = useRecurringTributeManagement({ advisor });

  const label = recurring ? 'Monthly Tribute Amount' : 'One-time Tribute Amount';

  const setRecurringClick = (recurring) => {
    dispatch(setRecurring({ recurring }));
  };

  const closeClick = closeDialogAndResetTributeSlice;

  const sendTributeClick = () => {
    if (recurring && !currentUserHasCard) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
    }

    if (recurring) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_MONETARY_TRIBUTE,
      }));
    } else {
      const fromNfChat = window.location.pathname.includes('/chat');

      return createBonus({
        listingId: advisor.top_listing_id,
        message: tributeMessage,
        amount: parseFloat(tributeAmount),
        fromNfChat,
      });
    }
  };

  const handleCreateBonusSuccess = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS,
    }));
  };

  const handleCreateBonusError = (data) => {
    const { errors, deficit } = data;

    if (errors.amount && errors.amount[0] === 'You don\'t have enough money in your account.') {
      if (currentUserHasCard) {
        setTributeDeficit(deficit);

        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.ADD_TO_BALANCE,
        }));
      } else {
        dispatch(setCurrentStep({
          currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
        }));
      }
    }

    const recipientError = errors.not_send_to_denied_recipients || errors.not_send_to_blocked_recipients;

    if (recipientError) {
      closeDialog();

      dispatch(showSnackbarMessage({
        message: recipientError[0],
        severity: 'error',
      }));
    }
  };

  useEffect(() => {
    if (createBonusResult.isSuccess) {
      handleCreateBonusSuccess();
    }

    if (
      createBonusResult.isError &&
      createBonusResult.error.status === 422
    ) {
      handleCreateBonusError(createBonusResult.error.data);
    }
  }, [createBonusResult]);

  return (
    <DeprecatedNewTributeForm
      advisor={advisor}
      recurring={recurring}
      setRecurringClick={setRecurringClick}
      amount={tributeAmount}
      message={tributeMessage}
      setAmount={setAmount}
      setMessage={setMessage}
      amountInvalid={amountInvalid}
      amountError={amountError}
      setAmountError={setAmountError}
      label={label}
      sendTributeClick={sendTributeClick}
      closeClick={closeClick}
      isLoading={createBonusResult.isLoading}
    />
  );
};

DeprecatedNewTributeFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default DeprecatedNewTributeFormContainer;
