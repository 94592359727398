// get the first error message for standard rails response object
// given a serialized active record object the errors will show up as:
// { errors: { field: [ 'message' ] } }
export const errorMessageFromObject = (object, defaultMessage = null) => {
  try {
    const errorPairs = Object.entries(object.data.errors);
    // [ [ 'field', [ 'message' ] ] ]
    return errorPairs[0][1][0];
  } catch (_err) {
    return defaultMessage || 'We\'re sorry, that didn\'t work';
  }
};
