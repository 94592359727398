import React, { useState, useEffect } from 'react';

import {
  Drawer,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import HowItWorks from 'app/components/call/HowItWorks';

const HowItWorksToggle = () => {
  const anchor = 'right';
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(isOpen);
  };

  useEffect(() => {
    // Hide the drawer at the lg breakpoint, 1200px today.
    const mediaQuery = window.matchMedia('(min-width: 1200px)');

    const handleResize = () => {
      if (mediaQuery.matches) {
        setIsOpen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    mediaQuery.addEventListener('change', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  const drawerContents = (
    <Grid container className="drawer-max-width">
      <Grid container className="drawer-hdr">
        <Grid item xs={11}>
          <span className="subhead">
            How It Works
          </span>
        </Grid>
        <Grid item xs={1} className="pull-right">
          <a
            role="link"
            tabIndex={0}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <img
              src="/plus_frontend/assets/svg/x-close.svg"
              alt="close"
            />
          </a>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 10, lineHeight: '1.5em' }}>
        <Grid item xs={12} sx={{ mb: 2, mt: 0.25 }}>
          <Paper
            elevation={0}
            sx={{ mt: 1, p: 2 }}
          >
            <HowItWorks />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        textAlign: 'center',
        display: { lg: 'none' },
      }}
    >
      <Typography variant="body3">
        <a
          href="#"
          onClick={toggleDrawer(true)}
        >
          How It Works
        </a>
      </Typography>
      <Drawer
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        {drawerContents}
      </Drawer>
    </Stack>
  );
};

export default HowItWorksToggle;
