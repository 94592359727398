export default class Cookie {
  static getCookies() {
    const cookies = {};
    document.cookie.split('; ').forEach((cookie) => {
      const [name, value] = cookie.split('=');
      cookies[name] = decodeURIComponent(value);
    });
    return cookies;
  }

  static hasCookie(name) {
    return !!(Cookie.getCookies()[name]);
  }

  static setCookie(name, value, exdays, params = {}) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    let cValue = escape(value) + ((exdays == null) ? '' : `; expires=${exdate.toUTCString()}`);
    Object.keys(params).forEach((key) => {
      cValue += `; ${key}=${params[key]}`;
    });
    document.cookie = `${name}=${cValue}`;
  }

  static deleteCookie(name) {
    document.cookie = `${name}=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
