const camelize = (str) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelKey = camelize(key);
      result[camelKey] = camelizeKeys(obj[key]);

      // include the snake_case key for flexibility
      result[key] = obj[key];

      return result;
    }, {});
  }
  return obj;
};

export default camelizeKeys;
