import React from 'react';
import { PropTypes } from 'prop-types';
import { toCurrency } from 'app/helpers/currencyHelpers';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CloseDialogButton from '../CloseDialogButton';

const ConfirmUpdateTribute = ({
  cardType,
  closeDialog,
  confirmTributeClick,
  isLoading,
  lastFour,
  tributeAmount,
  useDifferentCardClick,
}) => {
  return (
    <>
      <DialogTitle>
        Confirm Update Recurring Tribute
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Starting today,&nbsp;
              {toCurrency(tributeAmount)}
              &nbsp;will be deducted from your NiteFlirt balance or
              charged to your default card every month. Cancel any time.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Current default card:&nbsp;
              {cardType}
              &nbsp;ending in&nbsp;
              {lastFour}
            </Typography>
            <Button
              variant="text"
              onClick={useDifferentCardClick}
            >
              Update your default card
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={confirmTributeClick}
        >
          Confirm Tribute
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmUpdateTribute.propTypes = {
  cardType: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  confirmTributeClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  lastFour: PropTypes.string.isRequired,
  tributeAmount: PropTypes.string.isRequired,
  useDifferentCardClick: PropTypes.func.isRequired,
};

export default ConfirmUpdateTribute;
