import React from 'react';
import { PropTypes } from 'prop-types';

import {
  POSTER_BLANK,
  IMAGE_BLANK,
} from 'app/constants/assetPaths';

const FileManagerAlbumItem = ({ item, checked, onChange, freePost, selectOrRadio }) => {
  const freePostEligible = freePost && ['everyone', null].includes(item.rating);
  const acceptableItemType = ['image', 'video', 'audio'].includes(item.item_type);
  const errorMessage = () => {
    if (!acceptableItemType) {
      return (
        <div className="text-error">
          <span style={{ textTransform: 'capitalize' }}>{item.item_type}</span>
          {' '}
          files not allowed in Posts
        </div>
      );
    } else if (freePost && !freePostEligible) {
      return (<div className="text-error">Not eligible for Free Posts</div>);
    }
    return '';
  };
  const disableCheckbox = (freePost && !freePostEligible) || !acceptableItemType;
  const checkboxClass = disableCheckbox ? 'checkbox-container is-disabled' : 'checkbox-container';
  const radioClass = disableCheckbox ? 'radio-container is-disabled' : 'radio-container';
  const defaultImage = item.item_type === 'video' ? POSTER_BLANK : IMAGE_BLANK;

  const renderSelect = () => {
    if (selectOrRadio == 'select') {
      return (
        <div className="col-xs-2 fm-checkbox">
          <label className={checkboxClass}>
            <input
              type="checkbox"
              onChange={() => (onChange(item, !checked))}
              checked={checked}
              disabled={disableCheckbox}
            />
            <span className="checkmark" />
          </label>
        </div>
      );
    } else {
      return (
        <div className="col-xs-2 fm-checkbox">
          <label className={radioClass}>
            <input
              type="radio"
              onChange={() => (onChange(item, !checked))}
              checked={checked}
              disabled={disableCheckbox}
            />
            <span className="checkmark" />
          </label>
        </div>
      );
    }
  };

  return (
    <div key={`item_${item.fm_item_id}`} className="row nowrap-xs middle-xs fm-row-item">
      {renderSelect()}
      <div className="col-xs-4 center-xs">
        <div
          className="fm-thumb"
          style={{ backgroundImage: `url('${item.thumbnail_url}')` }}
        >
          <img
            style={{ display: 'none' }}
            src={item.thumbnail_url}
            alt={item.description}
            onError={(e) => {
              e.currentTarget.src = defaultImage;
              e.currentTarget.style.width = '100px';
              e.currentTarget.style.display = 'block';
            }}
          />
        </div>
      </div>
      <div className="col-xs-6 fm-filename" title={item.description}>
        {item.title}
        {errorMessage()}
      </div>
    </div>
  );
};

FileManagerAlbumItem.propTypes = {
  item: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  freePost: PropTypes.bool,
  selectOrRadio: PropTypes.string.isRequired,
};

export default FileManagerAlbumItem;
