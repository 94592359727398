import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { useGetAdvisorListingsQuery } from 'app/api/mainApi';
import { useParams } from 'react-router';

import { featureFlag } from 'app/util/featureFlag';

import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import ChatListingContainer from 'app/containers/ChatListingContainer';
import ChatListingCard from 'app/components/ChatListingCard';

function ListingsContainer({ advisor, headingSource }) {
  const { login: advisorLogin } = useParams();
  const { data: callListings, isLoading: listingsLoading } =
    useGetAdvisorListingsQuery({ login: advisorLogin });
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  if (listingsLoading) return <div data-test-id="loading-text">Loading...</div>;

  // NOTE: We need to slice the array because the original array from RTK is immutable.
  const listings = callListings?.slice();

  if (
    !advisor.explicitly_unenrolled_in_chat &&
    advisor.enrolled_chat &&
    advisor.chat_eligible &&
    listings?.push
  ) {
    listings.push({
      type: 'chat',
    });
  }

  // Split listings into rows of 2, because our layout system can't do that automatically
  const rows = Array.from(
    { length: listings?.length ? Math.ceil(listings.length / 2) : 0 },
    (_, i) => listings?.slice(i * 2, i * 2 + 2),
  );

  if (newCardsEnabled) {
    return (
      <Box
        px={{ xs: 0, sm: 2 }}
        pt={{ xs: 2.5, sm: 3.5 }}
        pb={{ xs: 10, sm: 10 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: { xs: '3px', sm: '3px' },
            justifyContent: 'center',
          }}
        >
          {listings.map((listing) => (
            <Box
              key={`listing_${listing.id}`}
              sx={{
                width: { xs: '330px', sm: '330px' },
                flex: { xs: '0 0 330px', sm: '0 0 330px' },
              }}
            >
              {listing.type === 'chat' ? (
                <ChatListingContainer advisor={advisor} />
              ) : (
                <PhoneListingContainer listing={listing} headingSource={headingSource} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <div
      className="container-fluid padding-0-8"
      data-test-id="listings-container"
      style={{ maxWidth: '757px', marginLeft: '0px' }}
    >
      {rows.map((row) => (
        <div key={`row_${row[ 0 ].id}`} className="row">
          {row.map((listing) => (
            <div key={`listing_${listing.id}`} className="col-sm-6 col-xs-12">
              {listing.type === 'chat' ? (
                <ChatListingContainer advisor={advisor} />
              ) : (
                <PhoneListingContainer
                  listing={listing}
                  headingSource="category"
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

ListingsContainer.defaultProps = {
  advisor: {},
  headingSource: '',
};

ListingsContainer.propTypes = {
  advisor: PropTypes.object,
  headingSource: PropTypes.string,
};

export default ListingsContainer;
