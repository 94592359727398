import React from 'react';
import { useMatch, useNavigate } from 'react-router';

const HomeFeedTabs = () => {
  const navigate = useNavigate();
  const viewingExploreTab = useMatch('/feed/explore');

  return (
    <div className="container-summary">
      <div className="row middle-xs">
        <div
          className={`col-sm-4 col-sm-offset-2 col-xs-5 col-xs-offset-1 center-xs summary-tab ${!viewingExploreTab ? 'active' : ''}`}
          role="link"
          tabIndex={0}
          onClick={() => { navigate('/feed/home'); }}
        >
          Following
        </div>
        <div
          className={`col-sm-4 col-xs-5 center-xs summary-tab ${viewingExploreTab ? 'active' : ''}`}
          role="link"
          tabIndex={0}
          onClick={() => { navigate('/feed/explore'); }}
        >
          Explore
        </div>
      </div>
    </div>
  );
};

export default HomeFeedTabs;
