import React from 'react';
import { PropTypes } from 'prop-types';

import {
  IconButton,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const ActiveTributeDetail = ({
  advisor,
  activeMonetaryTribute,
}) => {
  const chargeFailed = activeMonetaryTribute?.status === 'charge_failed';

  const {
    dispatch,
    setCurrentStep,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const editTribute = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.EDIT_MONETARY_TRIBUTE,
    }));
  };

  return (
    <Grid container rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="body1">
          {`You also have the following Recurring Tribute with ${advisor.login}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} mt={1}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={10} container>
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {`${activeMonetaryTribute.rate} per month`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                There are no perks associated with this tribute.
              </Typography>
            </Grid>
            {chargeFailed && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    Renewal Failed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body3">
                    <Link
                      role="button"
                      tabIndex={0}
                      onClick={useDifferentCardClick}
                      style={{ cursor: 'pointer' }}
                    >
                      Update Card Now
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body3" style={{ color: '#666' }}>
                {chargeFailed ? 'Expires ' : 'Renews '}
                {activeMonetaryTribute.renewalDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <IconButton onClick={editTribute}>
              <EditOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActiveTributeDetail.propTypes = {
  advisor: PropTypes.object.isRequired,
  activeMonetaryTribute: PropTypes.object.isRequired,
};

export default ActiveTributeDetail;
