const useProfileImages = (advisor) => {
  let avatarImage = advisor ? advisor.photo : '';
  let acceptedAvatarImage = advisor ? advisor.photo : '';
  let coverImage = advisor ? advisor.header_photo : '';

  if (advisor?.avatar_image?.url) {
    avatarImage = advisor.avatar_image.url;
  }

  if (advisor?.latest_accepted_avatar_image?.url) {
    acceptedAvatarImage = advisor.latest_accepted_avatar_image.url;
  }

  if (advisor?.cover_image?.url) {
    coverImage = advisor.cover_image.url;
  }

  return {
    avatarImage,
    acceptedAvatarImage,
    coverImage,
  };
};

export default useProfileImages;
