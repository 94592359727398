import React from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const BillingError = ({
  errorText,
  cardType,
  lastFour,
  retryTributeClick,
  useDifferentCardClick,
  closeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Something Went Wrong
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              We&apos;re sorry, your bank has declined the transaction.&nbsp;
              Please try again or try a different card.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Current default card:&nbsp;
              {cardType}
              &nbsp;ending in&nbsp;
              {lastFour}
            </Typography>
            <Button
              variant="text"
              onClick={useDifferentCardClick}
            >
              Update your default card
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeClick}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={retryTributeClick}
        >
          Retry
        </Button>
      </DialogActions>
    </>
  );
};

BillingError.propTypes = {
  errorText: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  retryTributeClick: PropTypes.func.isRequired,
  useDifferentCardClick: PropTypes.func.isRequired,
  closeClick: PropTypes.func.isRequired,
};

export default BillingError;
