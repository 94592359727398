import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const ChatTabs = ({ activeTab }) => {
  let activeValue;
  if (activeTab === 'chat') {
    activeValue = '1';
  } else if (activeTab === 'browse') {
    activeValue = '2';
  } else {
    activeValue = '2';
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeValue}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Chats" value={activeValue} component={Link} to="/chat" reloadDocument />
        <Tab label="Browse" value={activeValue} component={Link} to="/chat/browse" reloadDocument />
      </Tabs>
    </Box>
  );
};

ChatTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default ChatTabs;
