import React from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router';

import FlirtHeader from 'app/components/FlirtHeader';
import { useGetAdvisorQuery } from 'app/api/mainApi';
import useFlirtActions from 'app/hooks/useFlirtActions';

const FlirtHeaderContainer = ({ includeSubscriptions, currentUser }) => {
  const { login: advisorLogin } = useParams();
  const { data: advisor } = useGetAdvisorQuery({ login: advisorLogin });
  const { onTributeButtonClick } = useFlirtActions({ advisor });

  return (
    <FlirtHeader
      advisor={advisor}
      currentUser={currentUser}
      includeSubscriptions={includeSubscriptions}
      onTributeButtonClick={onTributeButtonClick}
    />
  );
};

FlirtHeaderContainer.defaultProps = {
  includeSubscriptions: true,
  currentUser: null,
};

FlirtHeaderContainer.propTypes = {
  includeSubscriptions: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default FlirtHeaderContainer;
