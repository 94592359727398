import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import ConfirmCancelMonetaryTribute from 'app/components/modals/tributes/ConfirmCancelMonetaryTribute';
import { useDestroyRecurringMonetaryTributeMutation } from 'app/api/mainApi';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const ConfirmCancelMonetaryTributeContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const { closeDialogAndResetTributeSlice } = useRecurringTributeManagement({ advisor });

  const [
    destroyRecurringMonetaryTribute,
    destroyRecurringMonetaryTributeResult,
  ] = useDestroyRecurringMonetaryTributeMutation();

  const onCancelTributeClick = () => {
    destroyRecurringMonetaryTribute({ advisorId: advisor.id });
  };

  const onKeepTributeClick = () => {
    closeDialogAndResetTributeSlice();
  };

  useEffect(() => {
    if (destroyRecurringMonetaryTributeResult.isSuccess) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'Your recurring tribute has been cancelled.',
        severity: 'success',
      }));
    }

    if (destroyRecurringMonetaryTributeResult.isError) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'We\'re sorry, we encountered a system error.',
        severity: 'error',
      }));
    }
  }, [destroyRecurringMonetaryTributeResult]);

  const closeDialog = () => {
    closeDialogAndResetTributeSlice();
  };

  return (
    <ConfirmCancelMonetaryTribute
      advisor={advisor}
      closeDialog={closeDialog}
      onKeepTributeClick={onKeepTributeClick}
      onCancelTributeClick={onCancelTributeClick}
    />
  );
};

ConfirmCancelMonetaryTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmCancelMonetaryTributeContainer;
