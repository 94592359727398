import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ADVISOR_TAG } from './sharedApiTags';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

const profileImageApi = createApi({
  reducerPath: 'profileImage',

  baseQuery: fetchBaseQuery({ baseUrl: '/' }),

  endpoints: (builder) => ({
    performCreateProfileImage: builder.mutation({
      invalidatesTags: (_result, err, originalArgs) => {
        if (!err) {
          // cross-slice cache tag invalidation
          dispatchInvalidateCacheTagsEvent([
            { type: ADVISOR_TAG, id: originalArgs.advisorId },
          ]);
        }

        return [];
      },
      query: ({ fmId, userId, type }) => {
        return {
          method: 'POST',
          url: `users/${userId}/plus_profile_${type}_images`,
          body: {
            [`plus_profile_${type}_image`]: {
              fm_item_id: fmId,
            },
          },
        };
      },
    }),
  }),
});

export default profileImageApi;

export const { usePerformCreateProfileImageMutation } = profileImageApi;
