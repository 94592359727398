import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltipLite from 'react-tooltip-lite';

const Tooltip = ({ header, children }) => {
  const tipContentRef = useRef(null);
  const buttonRef = useRef(null);

  const [tipOpen, setTipOpen] = useState(false);

  const bodyClick = (e) => {
    if ((tipContentRef?.current?.contains(e.target)) || buttonRef?.current?.contains(e.target)) {
      return;
    }
    setTipOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', bodyClick);

    return () => { document.removeEventListener('mousedown', bodyClick); };
  }, []);

  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  return (
    <ReactTooltipLite
      content={(
        <div ref={tipContentRef} className="nfp-tooltip">
          <div className="row">
            <div className="col-xs-11">
              <div className="menu-text">
                {header && (
                  <div className="subhead">{header}</div>
                )}
                {children}
              </div>
            </div>
            <div className="col-xs-1 end-xs">
              <a role="link" tabIndex={0} onPointerDown={toggleTip}><img src="/plus_frontend/assets/svg/x-close.svg" className="marginlftminus5" alt="close" /></a>
            </div>
          </div>
        </div>
      )}
      isOpen={tipOpen}
      tagName="span"
      direction="down"
      forceDirection
    >
      <a
        role="link"
        tabIndex={0}
        ref={buttonRef}
        onPointerDown={toggleTip}
      >
        <img src="/plus_frontend/assets/svg/ico-info.svg" className="marginlft10 margintop4" alt="info" />
      </a>
    </ReactTooltipLite>
  );
};

Tooltip.defaultProps = {
  header: undefined,
};

Tooltip.propTypes = {
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Tooltip;
