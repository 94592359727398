import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SocialMediaEventTemplateShape from 'app/shapes/SocialMediaEventTemplateShape';
import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';

const eventTypeLabelDictionary = {
  1: 'Made yourself available:',
  2: 'Ended a call:',
  3: 'Received a tribute:',
  4: 'Was favorited:',
  5: 'Added a Goody Bag:',
  6: 'Sold a Goody Bag:',
  9: 'Started a new chat:',
};

const EventTemplate = ({
  eventTemplate,
  twitterConnection,
  updateSocialMediaEventTemplate,
  tumblrConnection,
}) => {
  const [hasValidTwitterConnection, setHasValidTwitterConnection] = useState(twitterConnection?.id && !twitterConnection?.authentication_error);
  const [hasValidTumblrConnection, setHasValidTumblrConnection] = useState(tumblrConnection?.id && !tumblrConnection?.authentication_error);
  const [latestPotentialSubstitute, setLatestPotentialSubstitute] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleUpdateTwitter = (event) => {
    updateSocialMediaEventTemplate({ ...eventTemplate, send_to_twitter: event.target.checked });
  };

  const handleUpdateTumblr = (event) => {
    updateSocialMediaEventTemplate({ ...eventTemplate, send_to_tumblr: event.target.checked });
  };

  // TODO: Refactor the autocomplete fields here and in NewSocialMediaManualShoutForm to share the same logic
  const hiddenTextFieldRef = useRef(null);
  const textFieldRef = useRef(null);
  const handleUpdateContent = (event) => {
    updateSocialMediaEventTemplate({ ...eventTemplate, content: event.target.value });

    const match = event.target.value.match(/\{[^\}]*$/);
    if (match) {
      const lastOpenBraceWithoutCloseOrSpace = match[0];
      setLatestPotentialSubstitute(lastOpenBraceWithoutCloseOrSpace);
    } else {
      setLatestPotentialSubstitute('');
    }
  };

  const handleSelectAutoComplete = (_event, value) => {
    const lastOpenBraceIndex = eventTemplate.content.lastIndexOf('{');

    if (lastOpenBraceIndex !== -1) {
      const newValue = eventTemplate.content.slice(0, lastOpenBraceIndex) + value;

      updateSocialMediaEventTemplate({ ...eventTemplate, content: newValue });
      setLatestPotentialSubstitute('');
      hiddenTextFieldRef.current.focus();
    }
  };

  useEffect(() => {
    setHasValidTwitterConnection(twitterConnection?.id && !twitterConnection?.authentication_error);

    setHasValidTumblrConnection(tumblrConnection?.id && !tumblrConnection?.authentication_error);
  }, [eventTemplate, twitterConnection, tumblrConnection]);

  return (
    <Paper
      sx={{ p: 2 }}
      elevation={0}
    >
      <Grid container>
        <Grid
          item
          sm={10}
          xs={9}
        >
          <Box display="block" sx={{ position: 'relative' }}>
            <Box
              sx={latestPotentialSubstitute.length > 0 ? { zIndex: 1, position: 'absolute', width: '100%' } : { position: 'absolute', width: '100%', visibility: 'hidden' }}
            >
              <Autocomplete
                options={eventTemplate.valid_substitutions}
                onChange={handleSelectAutoComplete}
                blurOnSelect
                disableClearable
                freeSolo
                open={latestPotentialSubstitute.length > 0}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    type="hidden"
                    inputRef={hiddenTextFieldRef}
                  />
                )}
              />
            </Box>
            <TextField
              error={!!eventTemplate.errors?.content}
              helperText={eventTemplate.errors?.content}
              multiline
              rows={isFocused ? 5 : 1}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 140, style: { fontSize: '0.875rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' } }}
              label={eventTypeLabelDictionary[eventTemplate.event_type]}
              size="small"
              value={eventTemplate.content}
              onChange={handleUpdateContent}
              inputRef={textFieldRef}
              onFocus={() => {
                textFieldRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                setIsFocused(true);
              }}
              onBlur={() => setIsFocused(false)}
            />
          </Box>
        </Grid>
        <Grid
          item
          sm={2}
          xs={3}
          alignItems="center"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            {
              // NOTE: We need to check for the existence of the connection before we we render the checkbox in order
              // to correctly render the defaultChecked state.  If we render without waiting for a connection object,
              // we will always get an unchecked box because the component only renders once with the defaultChecked
              // state.  Since we are waiting for an api return it will always be false at first until the api returns
              // the connection.
            }
            <Checkbox
              checked={hasValidTwitterConnection && eventTemplate.send_to_twitter}
              disabled={!hasValidTwitterConnection}
              onChange={handleUpdateTwitter}
            />

            <Checkbox
              checked={hasValidTumblrConnection && eventTemplate.send_to_tumblr}
              disabled={!hasValidTumblrConnection}
              onChange={handleUpdateTumblr}
            />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

EventTemplate.defaultProps = {
  twitterConnection: null,
  tumblrConnection: null,
};

EventTemplate.propTypes = {
  twitterConnection: SocialMediaConnectionShape,
  tumblrConnection: SocialMediaConnectionShape,
  eventTemplate: SocialMediaEventTemplateShape.isRequired,
  updateSocialMediaEventTemplate: PropTypes.func.isRequired,
};

export default EventTemplate;
