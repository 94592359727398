import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import ConfirmCancelPackageTribute from 'app/components/modals/tributes/ConfirmCancelPackageTribute';
import { useDestroyRecurringPackageTributeMutation } from 'app/api/mainApi';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const ConfirmCancelPackageTributeContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const {
    activePackageTribute,
    closeDialogAndResetTributeSlice,
  } = useRecurringTributeManagement({ advisor });

  const [
    destroyRecurringPackageTribute,
    destroyRecurringPackageTributeResult,
  ] = useDestroyRecurringPackageTributeMutation();

  const onCancelTributeClick = () => {
    destroyRecurringPackageTribute({ advisorId: advisor.id });
  };

  const onKeepTributeClick = () => {
    closeDialogAndResetTributeSlice();
  };

  useEffect(() => {
    if (destroyRecurringPackageTributeResult.isSuccess) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'Your Package has been cancelled.',
        severity: 'success',
      }));
    }

    if (destroyRecurringPackageTributeResult.isError) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'We\'re sorry, we encountered a system error.',
        severity: 'error',
      }));
    }
  }, [destroyRecurringPackageTributeResult]);

  const closeDialog = () => {
    closeDialogAndResetTributeSlice();
  };

  return (
    <ConfirmCancelPackageTribute
      activePackageTribute={activePackageTribute}
      closeDialog={closeDialog}
      onCancelTributeClick={onCancelTributeClick}
      onKeepTributeClick={onKeepTributeClick}
    />
  );
};

ConfirmCancelPackageTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmCancelPackageTributeContainer;
