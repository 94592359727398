/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rate: 14.99,
  feedAccess: true,
};

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setPackageRate: (state, action) => {
      state.rate = action.payload.rate;
    },
    setPackageFeedAccess: (state, action) => {
      state.feedAccess = action.payload.feedAccess;
    },
  },
});

export default packageSlice;

export const {
  setPackageRate,
  setPackageFeedAccess,
} = packageSlice.actions;
