import React, { useContext, useEffect, useRef } from 'react';

import SearchContext from 'app/contexts/SearchContext';

const SearchToggle = () => {
  const { setToggleRef, searchVisible } = useContext(SearchContext);
  const toggleRef = useRef();

  useEffect(() => {
    setToggleRef(toggleRef);
  }, [toggleRef]);

  return (
    <div ref={toggleRef} className="col-xs-2 end-xs icon-search-toggle">
      <img
        src="/plus_frontend/assets/svg/arrow-menu-up.svg"
        alt="Search"
        className={`icon-arrow-sticky2 ${searchVisible ? '' : 'displayNone'}`}
      />
      <img
        src="/plus_frontend/assets/svg/ico-search.svg"
        alt="Search"
        className={`icon-search-sticky2 ${searchVisible ? 'displayNone' : ''}`}
      />
    </div>
  );
};

export default SearchToggle;
