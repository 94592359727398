import React from 'react';

const SoundEffects = () => (
  <React.Fragment>
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <audio id="callAlertSound" style={{ display: 'none' }}>
      <source src="/niteflirt/audios/callalert.mp3" type="audio/mpeg" />
      <source src="/niteflirt/audios/callalert.ogg" type="audio/ogg" />
      <source src="/niteflirt/audios/callalert.wav" type="audio/wav" />
    </audio>

    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <audio id="callFailSound" style={{ display: 'none' }}>
      <source src="/niteflirt/audios/callfail.mp3" type="audio/mpeg" />
      <source src="/niteflirt/audios/callfail.ogg" type="audio/ogg" />
      <source src="/niteflirt/audios/callfail.wav" type="audio/wav" />
    </audio>
  </React.Fragment>
);

export default SoundEffects;
