import PropTypes from 'prop-types';

const FeedbackShape = PropTypes.shape({
  advisor_login: PropTypes.string.isRequired,
  object_type: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  object_id: PropTypes.number.isRequired,
  can_leave_feedback: PropTypes.bool.isRequired,
});

export default FeedbackShape;
