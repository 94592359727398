import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStyledBox1 = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  [ theme.breakpoints.up('xs') ]: {
    padding: theme.spacing(2, 3, 3, 3), // top, right, bottom, left
  },
  [ theme.breakpoints.up('md') ]: {
    padding: theme.spacing(2, 20, 3, 3), // top, right, bottom, left
  },
}));

export const FavoritesHeading = () => {
  return (
    <CustomStyledBox1>
      <Typography data-test-id="favorites-heading">
        Favorites is a list of every Flirt with whom you&apos;ve spoken.
        You can also add Flirts to this list by clicking “Add to Favorites” on any Listing.
        Use this list to keep track of and get in touch with all your favorites on NiteFlirt!
      </Typography>
    </CustomStyledBox1>
  );
};

export const NoFavorites = () => {
  return (
    <CustomStyledBox1>
      <Typography data-test-id="no-favorites">
        You don’t have any Favorites yet!
        Use Favorites to organize and easily access your favorite Flirts on NiteFlirt and 1-800-TO-FLIRT!
        Flirt Listings are automatically added to Favorites whenever you place a call and can be added by clicking
        “Add to Favorites” from any Listing.
      </Typography>
    </CustomStyledBox1>
  );
};

export const NoFavoritesAvailable = () => {
  return (
    <CustomStyledBox1>
      <Typography data-test-id="no-favorites-available">
        There are no recently contacted flirts on your favorites list currently available.
      </Typography>
    </CustomStyledBox1>
  );
};
