import React from 'react';

const PendingModerationPost = () => {
  return (
    <div>
      <div className="flux-state-overlay1">
        <h2>Not Published - Pending Review</h2>
        Your post has been submitted and will be published pending review.
      </div>
      <div className="overflow-hidden">
        <div className="slick-item-bg flux-state-item1" style={{ backgroundImage: 'url(/plus_frontend/assets/bg-blur1.png)' }}>&nbsp;</div>
      </div>
    </div>
  );
};

export default PendingModerationPost;
