import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr', // Two columns
  gap: '10px', // Gap between columns
});

const StyledDescription = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 2, // Clamp at 2 lines
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)({
  position: 'relative',
  top: '10px',
  left: '10px',
  marginRight: '20px', // Adding margin to the right
  borderRadius: '0px', // Adding border radius
  width: '80px', // Setting width
  height: '80px', // Setting height
});

const Wrapper = styled('div')({
  display: 'grid',
  gap: '5px', // Gap between rows
  alignItems: 'center', // Align items vertically
});

const AdvisorCard = ({ avatarSrc, name, description, price, type }) => {
  return (
    <StyledCard elevation={0}>
      <div>
        <StyledAvatar alt={name} src={avatarSrc} />
      </div>
      <div>
        <Wrapper>
          <Typography variant="h6" sx={{ color: '#000' }}>{name}</Typography>
          <StyledDescription variant="body2" sx={{ color: '#444' }}>
            {description}
          </StyledDescription>
          <Typography variant="body3" sx={{ color: '#444' }}>
            $
            {price}
            <span style={{ color: '#999' }}>
              &nbsp;{`${type === 'chat' ? 'per volley' : 'per min'}`}
            </span>
          </Typography>
        </Wrapper>
      </div>
    </StyledCard>
  );
};

AdvisorCard.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default AdvisorCard;
