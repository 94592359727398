import React from 'react';
import { PropTypes } from 'prop-types';
import { toCurrency } from 'app/helpers/currencyHelpers';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

const TributeSent = ({
  recurring,
  advisorName,
  tributeAmount,
  closeClick,
}) => {
  return (
    <>
      <DialogTitle>
        {recurring && 'Success'}
        {!recurring && 'Tribute Sent'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeClick}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {recurring && 'Your recurring tribute has been charged.'}
              {!recurring && `Your tribute in the amount of ${toCurrency(tributeAmount)} has been successfully sent to ${advisorName}.`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            closeClick();
            window.dispatchEvent(new CustomEvent('tributeSentSuccessfully', {}));
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

TributeSent.defaultProps = {
  recurring: false,
};

TributeSent.propTypes = {
  recurring: PropTypes.bool,
  advisorName: PropTypes.string.isRequired,
  tributeAmount: PropTypes.string.isRequired,
  closeClick: PropTypes.func.isRequired,
};

export default TributeSent;
