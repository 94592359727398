import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import SignInDialog from 'app/components/SignInDialog';
import DialogsContext from 'app/contexts/DialogsContext';

const SignInLauncher = ({ type }) => {
  const { openDialog } = useContext(DialogsContext);

  useEffect(() => {
    openDialog({
      component: SignInDialog,
      props: { type, hideCancel: true },
    });
  }, []);

  return null;
};

SignInLauncher.propTypes = {
  type: PropTypes.string,
};

SignInLauncher.defaultProps = {
  type: 'web',
};

export default SignInLauncher;
