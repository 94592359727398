import React from 'react';
import { PropTypes } from 'prop-types';

import { SCHEDULED_TAB, PROCESSING_TAB, REMOVED_TAB } from 'app/constants/tabNames';
import { useGetAppConfigQuery } from 'app/api/mainApi';

const PostsQueueTabs = ({ currentTab, setCurrentTab }) => {
  const { data: config } = useGetAppConfigQuery();

  return (
    <div className="container-summary-tags margintop10" style={{ borderBottom: '1px solid #ddd' }}>
      <div className="row middle-xs start-xs">
        <span
          className={`scheduled-tab ${currentTab === SCHEDULED_TAB ? 'active' : ''}`}
          role="link"
          tabIndex={0}
          onClick={() => { setCurrentTab(SCHEDULED_TAB); }}
        >
          Scheduled
        </span>
        <span
          className={`processing-tab ${currentTab === PROCESSING_TAB ? 'active' : ''}`}
          role="link"
          tabIndex={0}
          onClick={() => { setCurrentTab(PROCESSING_TAB); }}
        >
          Processing
        </span>
        <span
          className={`removed-tab ${currentTab === REMOVED_TAB ? 'active' : ''}`}
          role="link"
          tabIndex={0}
          onClick={() => { setCurrentTab(REMOVED_TAB); }}
        >
          Rejected
        </span>
      </div>
    </div>
  );
};

PostsQueueTabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};

export default PostsQueueTabs;
