import React, { useState, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Tab, IconButton, Badge, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';

import { useGoodyBagSearchMutation } from 'app/api/assetApi';
import { useGetAppConfigQuery, useGetAvailableBalanceQuery } from 'app/api/mainApi';
import { toNumber } from 'app/helpers/currencyHelpers';

import DialogsContext from 'app/contexts/DialogsContext';
import CustomTabs from 'app/components/goody_search/CustomTabs';
import AppBody from 'app/components/layout/AppBody';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import TabContent from 'app/redesign/components/TabContent';
import GenderSelectChips from 'app/components/GenderSelectChips';
import GoodyBagFilters from 'app/components/modals/GoodyBagFilters';
import GoodyBags from 'app/components/goody_search/GoodyBags';
import SortControl from 'app/components/goody_search/SortControl';

import {
  setText,
  setSort,
  clearFilters,
  clearAllButText,
  initializeFromParams,
  filtersSelector,
  advancedFilterCountSelector,
  anyFiltersSelector,
  setCategory,
  setResultCounts,
  categorySelector,
  sortSelector,
  urlSelector,
} from 'app/slices/goodyBagFilterSlice';

const CustomStyledBox1 = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(2, 3, 3, 3), // top, right, bottom, left
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 20, 3, 3), // top, right, bottom, left
  },
}));

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '90%',
    right: 0,
  },
}));

const tabIndexFromFragment = (fragment) => {
  switch (fragment) {
  case 'recent_additions':
    return 0;
  case 'top_goodies_this_week':
    return 1;
  case 'most_liked_this_month':
    return 2;
  default:
    return 0;
  }
};

const GoodySearchContainer = () => {
  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const balanceData = currentUser ? useGetAvailableBalanceQuery() : {};
  const rawAvailableBalance = balanceData?.data?.available_balance;
  const availableBalance = toNumber(rawAvailableBalance);

  const fragment = window.location.hash.substring(1);
  const [selectedTab, setSelectedTab] = useState(fragment);
  const { openDialog } = useContext(DialogsContext);
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector) || {};
  const advancedFilterCount = useSelector(advancedFilterCountSelector);
  const anyFilters = !!useSelector(anyFiltersSelector);
  const category = useSelector(categorySelector);
  const sort = useSelector(sortSelector);
  const { text } = filters;

  // Get the URL from the urlSelector
  const urlFromQuery = useSelector(urlSelector);

  // On page load, initialize the filters from the URL params
  const [searchParams] = useSearchParams();
  useEffect(() => {
    dispatch(initializeFromParams(searchParams));
  }, []);

  const [
    goodyBagSearch,
    {
      data: goodyBagSearchResults,
      isSuccess: goodyBagSearchResultsSuccess,
    },
  ] = useGoodyBagSearchMutation();

  const handleTextChange = (evt) => {
    dispatch(setText(evt.target.value));
  };

  const handleFilterClick = () => {
    openDialog({ component: GoodyBagFilters });
  };

  const handleCategoryChange = (category) => {
    dispatch(clearAllButText());
    dispatch(setCategory(category));
  };

  const handleTabChange = (_event, newValue) => {
    const newSelectedTab = [
      'recent_additions',
      'top_goodies_this_week',
      'most_liked_this_month',
    ][newValue] || 'recent_additions';

    window.location.hash = newSelectedTab;
    setSelectedTab(newSelectedTab);
  };

  const triggerGoodyBagSearch = () => {
    // Business rule: They have to use an advanced filter, or type at least 3 characters
    goodyBagSearch({ ...filters, category, sort });
  };

  const resultsBox = (results) => (
    <Box display="flex" justifyContent="start" mt={2}>
      <GoodyBags
        goodyBags={results}
        currentUser={currentUser}
        availableBalance={availableBalance}
      />
    </Box>
  );

  // trigger a search when the filters change
  useEffect(() => {
    const debouncedFunction = debounce(triggerGoodyBagSearch, 500);
    debouncedFunction();

    // Debounce can't be used in the traditional way, because it gets re-initialized with every render!
    // So we're taking advantage of the useEffect cleanup function to cancel old debounces.
    return debouncedFunction.cancel;
  }, [filters, sort]);

  // save facet/filter count data when it is returned along with results
  useEffect(() => {
    if (goodyBagSearchResultsSuccess && goodyBagSearchResults) {
      dispatch(setResultCounts(goodyBagSearchResults.facet));

      // Update the URL without reloading the page
      window.history.pushState(null, '', `?${urlFromQuery}`);
    }
  }, [goodyBagSearchResultsSuccess]);

  return (
    <AppBody>
      <TemplateShell2 header="Goodies">
        <CustomStyledBox1>
          <TextField
            label="Find videos, photos, and other Goodies"
            variable="outlined"
            autoComplete="off"
            fullWidth
            value={text}
            onChange={handleTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  { anyFilters && (
                    <IconButton
                      edge="end"
                      onClick={() => dispatch(clearFilters())}
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    onClick={handleFilterClick}
                    size="small"
                  >
                    <StyledBadge
                      color="secondary"
                      badgeContent={advancedFilterCount}
                      max={99}
                    >
                      <TuneIcon />
                    </StyledBadge>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Stack direction="row" spacing={1} mt={2} pb={2}>
            <GenderSelectChips state={category} setState={handleCategoryChange} />
            { goodyBagSearchResults?.results && (
              <SortControl sort={sort} setSort={(value) => dispatch(setSort(value))} />
            )}
          </Stack>

          { goodyBagSearchResults?.results && (
            resultsBox(goodyBagSearchResults.results)
          )}

          { goodyBagSearchResults?.carousels && (
            <>
              <Box display="flex" justifyContent="start" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs
                  value={tabIndexFromFragment(selectedTab)}
                  onChange={handleTabChange}
                  aria-label="Goody Sort Tabs"
                >
                  <Tab label="Recent Additions" sx={{ flex: 1 }} />
                  <Tab label="Top Goodies" sx={{ flex: 1 }} />
                  <Tab label="Most Liked" sx={{ flex: 1 }} />
                </CustomTabs>
              </Box>

              <Box display="flex" justifyContent="start" mt={2}>
                <TabContent value={tabIndexFromFragment(selectedTab)} index={0}>
                  { resultsBox(goodyBagSearchResults.carousels[0].results) }
                </TabContent>
                <TabContent value={tabIndexFromFragment(selectedTab)} index={1}>
                  { resultsBox(goodyBagSearchResults.carousels[1].results) }
                </TabContent>
                <TabContent value={tabIndexFromFragment(selectedTab)} index={2}>
                  { resultsBox(goodyBagSearchResults.carousels[2].results) }
                </TabContent>
              </Box>
            </>
          )}
        </CustomStyledBox1>
      </TemplateShell2>
    </AppBody>
  );
};

export default GoodySearchContainer;
