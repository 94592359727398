import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TrashIconNF = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="23px" height="24px" viewBox="0 0 23 24">
    <path
      d="M19.5,7.5 L19.5,24 L3,24 L3,7.5 L19.5,7.5 Z M7.68,10.8 L5.28,10.8 L5.28,21.6 L7.68,21.6 L7.68,10.8 Z M12.48,10.8 L10.08,10.8 L10.08,21.6 L12.48,21.6 L12.48,10.8 Z M17.28,10.8 L14.88,10.8 L14.88,21.6 L17.28,21.6 L17.28,10.8 Z"
    />
    <rect id="path-1" x="0" y="3" width="22.5" height="3" />
    <rect id="top" x="6" y="0" width="10.5" height="3" />
  </SvgIcon>
);

export default TrashIconNF;
