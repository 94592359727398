import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import {
  Button,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import PerkList from 'app/components/tributes/PerkList';

const ChangePackageCard = ({
  advisorPackage,
  subscribeToPackageClick,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '10px',
        borderRadius: '0px',
        boxShadow: 'none',
        marginBottom: '20px',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', paddingTop: '10px' }}
      >
        <Typography sx={{ fontSize: '1.125em' }}>
          {`${advisorPackage.rate} per month`}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          size="medium"
          onClick={() => subscribeToPackageClick(advisorPackage.id, advisorPackage.rate)}
        >
          Change
        </Button>
      </Stack>
      <Typography variant="h6" className="name" mt={1} mb={-1} pl={1}>
        Perks:
      </Typography>
      <PerkList packageData={advisorPackage} />
    </Paper>
  );
};

ChangePackageCard.propTypes = {
  advisorPackage: PropTypes.object.isRequired,
  subscribeToPackageClick: PropTypes.func.isRequired,
};

export default ChangePackageCard;
