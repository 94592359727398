import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneShape from 'app/shapes/PhoneShape';
import ListingShape from 'app/shapes/ListingShape';

import {
  Box,
} from '@mui/material';

import Connecting from 'app/components/call/callInProgress/Icons/Connecting';

import CreatedStep from 'app/components/call/callInProgress/CreatedStep';
import SeekerAnsweredStep from 'app/components/call/callInProgress/SeekerAnsweredStep';
import AdvisorAnsweredStep from 'app/components/call/callInProgress/AdvisorAnsweredStep';
import BillingStartedStep from 'app/components/call/callInProgress/BillingStartedStep';
import LessThanOneMinuteLeft from 'app/components/call/callInProgress/LessThanOneMinuteLeft';
import ErrorStep from 'app/components/call/callInProgress/ErrorStep';
import GenericStep from 'app/components/call/callInProgress/GenericStep';

const selectStatusFromCallState = (callState, selectedPhone, listing, balance, isLoading, triggerCall, currentUser) => {
  switch (callState.status) {
  case 'created':
    return <CreatedStep selectedPhone={selectedPhone} />;
  case 'seeker_answered':
    return <SeekerAnsweredStep listing={listing} />;
  case 'advisor_answered':
    return <AdvisorAnsweredStep listing={listing} />;
  case 'billing_started':
    return <BillingStartedStep />;
  case 'billing_ended':
    return <GenericStep callState={callState} />;
  case 'less_than_one_minute':
    return (
      <LessThanOneMinuteLeft
        currentUser={currentUser}
      />
    );
  case 'error':
    return (
      <ErrorStep
        callState={callState}
        selectedPhone={selectedPhone}
        listing={listing}
        balance={balance}
        isLoading={isLoading}
        triggerCall={triggerCall}
      />
    );
  default:
    return <GenericStep callState={callState} />;
  }
};

const CallInProgress = ({
  triggerGetCallStatus,
  callState,
  isCreateCallLoading,
  pollingInterval,
  selectedPhone,
  listing,
  balance,
  isLoading,
  triggerCall,
  currentUser,
}) => {
  // NOTE: Every 15 seconds, attempt to refetch the call status
  useEffect(() => {
    const interval = setInterval(triggerGetCallStatus, pollingInterval);

    return () => {
      clearInterval(interval);
    };
  }, [triggerGetCallStatus]);

  return (
    <>
      { isCreateCallLoading && (
        <Box textAlign="center">
          <Connecting />
        </Box>
      )}
      { !isCreateCallLoading && (
        <Box mt={3}>
          { selectStatusFromCallState(callState, selectedPhone, listing, balance, isLoading, triggerCall, currentUser) }
        </Box>
      )}
    </>
  );
};

CallInProgress.defaultProps = {
  pollingInterval: 15000,
};

CallInProgress.propTypes = {
  pollingInterval: PropTypes.number,
  triggerGetCallStatus: PropTypes.func.isRequired,
  callState: PropTypes.object.isRequired,
  isCreateCallLoading: PropTypes.bool.isRequired,
  selectedPhone: PhoneShape.isRequired,
  listing: ListingShape.isRequired,
  balance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  triggerCall: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default CallInProgress;
