import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const CustomTabs = styled(Tabs)(() => ({
  '&.MuiTabs-root': {
    margin: '0 12px 0 -12px',
    display: 'flex',
    '& .MuiTab-root': {
      flexGrow: '1',
      minWidth: 'auto',
    },
    '& .MuiButtonBase-root': {
      padding: '0px',
    },
  },
  width: '100%',
}));

export default CustomTabs;
