import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetFavoriteListingsQuery, useRemoveFavoriteListingsMutation, useUpdateFavoriteListingMutation } from 'app/api/mainApi';
import BoxedPagination from 'app/components/shared/BoxedPagination';
import FavoriteListings from 'app/components/FavoriteListings';
import TemplateShell2 from 'app/components/layout/TemplateShell2';
import AppBody from 'app/components/layout/AppBody';
import FavoriteListingsControlsHeader from 'app/components/favorite_listings/FavoriteListingsControlsHeader';
import { NoFavorites, FavoritesHeading, NoFavoritesAvailable } from 'app/components/favorite_listings/FavoriteListingsTexts';
import FavoriteListingsContext from 'app/contexts/FavoriteListingsContext';

const MY_SORT = '1';

const FavoriteListingsContainer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = parseInt(searchParams.get('page')) || 1;
  const sort = searchParams.get('sort') || MY_SORT;
  const [selectedListings, setSelectedListings] = useState({});
  const [updateFavoriteListing, { isLoading: isUpdatingFavoriteListing }] = useUpdateFavoriteListingMutation();
  const [removeFavoriteListings, { isLoading: isRemovingFavoriteListings }] = useRemoveFavoriteListingsMutation();
  const { data: favoriteListingsData } = useGetFavoriteListingsQuery({ page, sort });
  if (!favoriteListingsData) {
    return null;
  }
  const { favorite_listings: favoriteListings } = favoriteListingsData;

  const handlePageChange = (newPage) => {
    setSelectedListings({});
    navigate(`/account/favorite?sort=${sort}&page=${newPage}`);
  };

  const handleSortChange = (newSort) => {
    setSelectedListings({});
    navigate(`/account/favorite?sort=${newSort}`);
  };

  const handleListingCheckBox = (listingId) => {
    setSelectedListings(prevSelectedListings => {
      const newSelectedListings = {
        ...prevSelectedListings,
        [listingId]: !prevSelectedListings[listingId],
      };
      return newSelectedListings;
    });
  };

  const FavoriteListingsContextObj = {
    updateFavoriteListing,
    removeFavoriteListings,
    handleListingCheckBox,
    handleSortChange,
    setSelectedListings,
    selectedListings,
    favoriteListingsData,
    sort,
    isSortable: sort === MY_SORT,
    isUpdating: isUpdatingFavoriteListing || isRemovingFavoriteListings,
    debug: localStorage.getItem('debug') !== 'false',
  };

  return (
    <FavoriteListingsContext.Provider value={FavoriteListingsContextObj}>
      <AppBody>
        <TemplateShell2 header="Favorites" goBackPath="/account">
          <FavoritesHeading />
          {favoriteListingsData.has_favorites ? (
            <>
              <FavoriteListingsControlsHeader />
              {favoriteListings.length === 0 ? (
                <NoFavoritesAvailable />
              ) : (
                <FavoriteListings />
              )}
            </>
          ) : (
            <NoFavorites />
          )}
          <div style={{ maxWidth: '870px', marginTop: '10px' }}>
            <BoxedPagination count={favoriteListingsData.pagination.total_pages} onPageChange={handlePageChange} />
          </div>
        </TemplateShell2>
      </AppBody>
    </FavoriteListingsContext.Provider>
  );
};

export default FavoriteListingsContainer;
