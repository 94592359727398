import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import ChatListingCard from 'app/components/ChatListingCard';
import DeprecatedChatListingCard from 'app/components/DeprecatedChatListingCard';

const ChatListingContainer = ({ advisor }) => {
  const newCardsEnabled = featureFlag.enabled('NEW_CARDS_20908');

  if (newCardsEnabled) {
    return <ChatListingCard advisor={advisor} />;
  }

  return <DeprecatedChatListingCard advisor={advisor} />;
};

ChatListingContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ChatListingContainer;
