import React from 'react';
import { PropTypes } from 'prop-types';

const FileUploadSuccess = ({ fileName }) => {
  return (
    <div className="container-alert success inline marginbot10 margintop10">
      <div className="container-max">
        <div className="container-fluid padding0">
          <div className="row middle-xs">
            <div className="col-xs-10">
              {fileName}
              &nbsp;has been successfully uploaded
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileUploadSuccess.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileUploadSuccess;
