import React from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import TributeSent from 'app/components/modals/tributes/TributeSent';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const TributeSentContainer = ({ advisor }) => {
  const { closeDialogAndResetTributeSlice } = useRecurringTributeManagement({ advisor });

  const {
    recurring,
    tributeAmount,
  } = useSelector(state => state.tributes);

  const closeClick = () => {
    closeDialogAndResetTributeSlice();
  };

  return (
    <TributeSent
      recurring={recurring}
      advisorName={advisor.login}
      tributeAmount={tributeAmount}
      closeClick={closeClick}
    />
  );
};

TributeSentContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default TributeSentContainer;
