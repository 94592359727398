import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import MonetaryForm from 'app/components/modals/tributes/MonetaryForm';

import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

const MonetaryFormContainer = ({ advisor }) => {
  const [messageOpen, setMessageOpen] = useState(false);

  const {
    amountError,
    amountInvalid,
    currentUserHasCard,
    dispatch,
    setAmount,
    setAmountError,
    setCurrentStep,
    setMessage,
    tributeAmount,
    tributeMessage,
  } = useRecurringTributeManagement({ advisor });

  const sendTributeClick = () => {
    if (!currentUserHasCard) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
    }

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CREATE_MONETARY_TRIBUTE,
    }));
  };

  return (
    <MonetaryForm
      amountError={amountError}
      amountInvalid={amountInvalid}
      messageOpen={messageOpen}
      sendTributeClick={sendTributeClick}
      setAmount={setAmount}
      setAmountError={setAmountError}
      setMessage={setMessage}
      setMessageOpen={setMessageOpen}
      tributeAmount={tributeAmount}
      tributeMessage={tributeMessage}
    />
  );
};

MonetaryFormContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default MonetaryFormContainer;
