import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useGetAppConfigQuery } from 'app/api/mainApi';
import { APP_CONFIG_TAG } from 'app/api/sharedApiTags';
import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const ManageCardsModalContainer = ({ closeModal, afterSuccessAction }) => {
  const { data } = useGetAppConfigQuery();
  const txnUrl = data.txn_url;
  const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

  const onMessageReceived = (event) => {
    if (!event.data.src || event.data.src !== 'niteflirt_txn') return;

    dispatchInvalidateCacheTagsEvent([APP_CONFIG_TAG]);
    closeModal();
    afterSuccessAction();
  };

  // registers on mount and unregisters on unmount
  useEffect(() => {
    window.addEventListener('message', onMessageReceived);
    return () => { window.removeEventListener('message', onMessageReceived); };
  }, []);

  return (
    <div className="modal-body">
      <iframe
        title="Manage Credit Cards"
        id="txnIframe"
        src={`${txnUrl}/embedded/cards`}
        style={{ width: '100%', height: '650px', border: 0 }}
      />
    </div>
  );
};

ManageCardsModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  afterSuccessAction: PropTypes.func.isRequired,
};

export default ManageCardsModalContainer;
