import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@mui/material';

import CloseDialogButton from 'app/components/modals/CloseDialogButton';

const ConfirmCancelPackageTribute = ({
  activePackageTribute,
  closeDialog,
  onKeepTributeClick,
  onCancelTributeClick,
}) => {
  return (
    <>
      <DialogTitle>
        Cancel Package?
      </DialogTitle>
      <CloseDialogButton closeDialog={closeDialog} />
      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              You will no longer be charged for this Package and the current paid period will expire on&nbsp;
              {activePackageTribute.renewalDate}
              . Are you sure you want to cancel?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onKeepTributeClick}
        >
          Keep Package
        </Button>
        <Button
          variant="contained"
          onClick={onCancelTributeClick}
        >
          Cancel Package
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmCancelPackageTribute.propTypes = {
  activePackageTribute: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onCancelTributeClick: PropTypes.func.isRequired,
  onKeepTributeClick: PropTypes.func.isRequired,
};

export default ConfirmCancelPackageTribute;
