import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { useFavoriteListingMutation } from 'app/api/mainApi';
import FavoriteAdvisorModal from 'app/components/modals/FavoriteAdvisorModal';

const FavoriteAdvisorModalContainer = ({ listing, closeModal }) => {
  const [favoriteListing, { isSuccess: favoriteSuccess }] = useFavoriteListingMutation();

  const onClickAddFavorite = async () => {
    await favoriteListing({ listing });
  };

  useEffect(() => {
    // this is not a completely react-based page, so we need to reload to update the UI
    if (favoriteSuccess) {
      closeModal();
      document.location.reload();
    }
  }, [favoriteSuccess]);

  return (
    <FavoriteAdvisorModal onClickAddFavorite={onClickAddFavorite} />
  );
};

FavoriteAdvisorModalContainer.propTypes = {
  listing: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FavoriteAdvisorModalContainer;
