import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';

import TOS from 'app/components/support/TOS';
import PrivacyPolicy from 'app/components/support/PrivacyPolicy';
import Statement2257 from 'app/components/support/Statement2257';
import About from 'app/components/support/About';
import AffiliateAgreement from 'app/components/support/AffiliateAgreement';
import TrademarkNotice from 'app/components/support/TrademarkNotice';

export const SUPPORT_TABS = [
  { index: 0, title: 'About NiteFlirt', path: 'about', component: About },
  { index: 1, title: 'Terms of Service', path: 'tos', component: TOS },
  { index: 2, title: 'Privacy', path: 'privacy_policy', component: PrivacyPolicy },
  { index: 3, title: '2257 Statement', path: 'statement2257', component: Statement2257 },
  { index: 4, title: 'Trademark Notice', path: 'trademark_notice', component: TrademarkNotice },
  { index: 5, title: 'Affiliate Agreement', path: 'affiliate_agreement', component: AffiliateAgreement },
];

const BoxSupportContent = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    flexGrow: 1,
    minHeight: '100vh',
    fontSize: '0.875em',
    lineHeight: '1.625em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.938em',
      lineHeight: '1.688em',
    },
  },
}));

const Support = ({ children, title, index }) => {
  // The About page is unique in that, as the Support landing page,
  //  in the mobile view, it should start with the nav in front
  //  instead of the content.
  const onFirstSupportPage = index === 0;
  const [contentInFront, setContentInFront] = useState(!onFirstSupportPage);
  const navigate = useNavigate();

  useEffect(() => {
    // we have to do this to both elements. only one of them matters - but which one depends on screen size.
    document.getElementById('supportContent').scrollTo(0, 0);
    document.getElementById('supportContainer').scrollTo(0, 0);
  }, [index]);

  const loadTab = ({ path }) => {
    setContentInFront(true);
    navigate(`/help/${path}`);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box id="supportContainer" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5} md={4} lg={3} style={{ borderRight: '1px solid var(--color_ddd)' }} className={`pos__Menu ${contentInFront ? 'zIndexBehind' : 'zIndexFront'}`}>
          <Box sx={{ position: { sm: 'sticky', xs: 'block' }, top: { sm: '0', xs: 'auto' } }}>
            <Box className="subnavigation-tabs" pb={{ xs: 10, sm: 0 }}>
              <Box className="subnavHeader">
                About
              </Box>
              <Tabs value={index} aria-label="Support Pages" orientation="vertical" TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}>
                {SUPPORT_TABS.map((tab) => (
                  <Tab
                    key={tab.path}
                    label={tab.title}
                    value={tab.index}
                    onClick={() => loadTab(tab)}
                  />
                ))}
              </Tabs>
              <a href="//support.niteflirt.com/hc/en-us/articles/212830488-Satisfaction-Guarantee" rel="noreferrer" target="_blank">
                <List id="nav-new-window">
                  <ListItem>
                    Satisfaction Guarantee
                  </ListItem>
                </List>
              </a>
              <a href="//phonesexblog.niteflirt.com" rel="noreferrer" target="_blank">
                <List id="nav-new-window">
                  <ListItem>
                    NiteFlirt Blog
                  </ListItem>
                </List>
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid id="supportContent" item xs={12} sm={7} md={8} lg={9} sx={{ overflow: 'auto', borderRight: { sm: '1px solid var(--color_ddd)', xs: 'none' } }} className={`pos__Body ${contentInFront ? 'zIndexFront' : 'zIndexBehind'}`}>
          <div className="container-hdr-sticky2">
            <div className="row middle-xs start-xs nowrap-xs">
              <div className="col-xs-12">
                <div className="nav-icon-sticky">
                  <a href="#" onClick={() => setContentInFront(false)}>
                    <img src="/plus_frontend/assets/svg/ico-back-arrow.svg" alt="Back" className="mobile__arrow" />
                  </a>
                  &nbsp;
                </div>
                <h2>{ title }</h2>
              </div>
            </div>
          </div>
          <BoxSupportContent px={{ xs: 3, sm: 5 }} pt={{ xs: 5, sm: 4 }} pb={{ xs: 20, sm: 15 }}>
            { children }
          </BoxSupportContent>
        </Grid>
      </Grid>
    </Box>
  );
};

Support.defaultProps = {
};

Support.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Support;
