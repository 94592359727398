import { useGetAppConfigQuery } from 'app/api/mainApi';

const flagReferenceCount = {};
let featureFlagsCache = null;

export const featureFlag = {
  enabled(flagName) {
    const upperCaseFlagName = flagName.toUpperCase();
    const appConfig = useGetAppConfigQuery().data;

    featureFlagsCache = appConfig?.feature_flags || {};
    flagReferenceCount[upperCaseFlagName] = (flagReferenceCount[upperCaseFlagName] || 0) + 1;

    if (!appConfig) {
      return false;
    }

    const { feature_flags: featureFlags } = appConfig;

    if (!(upperCaseFlagName in featureFlags)) {
      console.log(`Warning: Feature flag "${upperCaseFlagName}" is not defined. Returning false.`);
      return false;
    }

    return featureFlags[upperCaseFlagName];
  },

  // used for debugging
  flagStatus() {
    const flagsWithStatus = {};

    if (featureFlagsCache) {
      for (const flag in featureFlagsCache) {
        flagsWithStatus[flag] = {
          enabled: featureFlagsCache[flag],
          referenceCount: flagReferenceCount[flag] || 0,
        };
      }
    }

    for (const flag in flagReferenceCount) {
      if (!(flag in featureFlagsCache)) {
        flagsWithStatus[flag] = {
          error: `Feature flag "${flag}" is not defined`,
          referenceCount: flagReferenceCount[flag],
        };
      }
    }

    return flagsWithStatus;
  },
};

if (process.env.NODE_ENV === 'development') {
  window.featureFlag = featureFlag;
}
