import PropTypes from 'prop-types';

const SocialMediaEventTemplateShape = PropTypes.shape({
  id: PropTypes.number,
  event_type: PropTypes.number.isRequired,
  content: PropTypes.string,
  send_to_twitter: PropTypes.bool,
  send_to_tumblr: PropTypes.bool,
  errors: PropTypes.object,
  valid_substitutions: PropTypes.arrayOf(PropTypes.string),
});

export default SocialMediaEventTemplateShape;
