import React from 'react';

import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import {
  grey,
  red,
} from '@mui/material/colors';

import SetupSocialMediaConnection from 'app/components/social_shouts/SetupSocialMediaConnection';
import UnlinkSocialMediaConnection from 'app/components/social_shouts/UnlinkSocialMediaConnection';
import IconSocialTwitter from 'app/components/customSvgs/IconSocialTwitter';

const TwitterSetupCard = ({ connection }) => {
  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <IconSocialTwitter alt="Twitter" style={{ height: '32px', width: '32px' }} />
            </Box>
            <Box>
              <Typography>
                X (formerly Twitter)
              </Typography>
              { connection?.username && (
                <Typography variant="body1">
                  <a href={`https://twitter.com/${connection.username}`}>{connection.username}</a>
                </Typography>
              )}
              <Typography variant="body3" sx={{ color: grey[600] }}>
                Limited to 10 tweets per day
              </Typography>
              { connection?.authentication_error && (
                <Typography variant="body2" sx={{ color: red[600], fontSize: '0.875em', mt: 0.4 }}>
                  We’re sorry, this account is not working. Please try to link the account later.
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4} className="pull-right">
          { (connection?.id && !connection?.authentication_error && (
            <UnlinkSocialMediaConnection connection={connection} />
          )) || (
            <SetupSocialMediaConnection connection={connection} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

TwitterSetupCard.defaultProps = {
  connection: null,
};

TwitterSetupCard.propTypes = {
  connection: SocialMediaConnectionShape,
};

export default TwitterSetupCard;
