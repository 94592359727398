import React from 'react';

const TrademarkNotice = () => (
  <div className="body">
    NiteFlirt and Speak to Your Desire are trademarks or servicemarks of NF Entertainment, Inc.
  </div>

);

export default TrademarkNotice;
