import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const NoHoverIconButton = styled(IconButton)({
  border: '1px solid #ccc',
  margin: '-1px -9px',
  padding: '0px',
  borderRadius: '3px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const NumberInput = ({ initialValue, maxValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(Number(newValue));
  };

  const handleIncrement = () => {
    if (Number(value) < maxValue) {
      handleValueChange(String(Number(value) + 1)); // Increment and update
    }
  };

  const handleDecrement = () => {
    if (Number(value) > 1) {
      handleValueChange(String(Number(value) - 1)); // Decrement and update only if greater than 1
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      handleValueChange(inputValue.slice(0, 5)); // Accept up to 5 digits
    }
  };

  return (
    <TextField
      // label="Number"
      variant="outlined"
      type="tel" // Set type to tel
      value={value}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <NoHoverIconButton
                aria-label="increment"
                onClick={handleIncrement}
                size="small"
                disableRipple // Remove ripple effect
              >
                <KeyboardArrowUpIcon />
              </NoHoverIconButton>
              <NoHoverIconButton
                aria-label="decrement"
                onClick={handleDecrement}
                size="small"
                disableRipple // Remove ripple effect
              >
                <KeyboardArrowDownIcon />
              </NoHoverIconButton>
            </div>
          </InputAdornment>
        ),
        maxLength: 5, // Restrict maximum length to 5 characters
      }}
    />
  );
}

export default NumberInput;
