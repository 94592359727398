import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography, Box } from '@mui/material';
import PageBackground from 'app/components/layout/PageBackground';

const PasswordResetConfirmation = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <PageBackground backgroundColor="white">
      <Box px={{ xs: 3, sm: 4 }} style={{ maxWidth: '500px', margin: '100px auto' }}>
        <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
          <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
          <Typography variant="h3">
            Success!
          </Typography>
          <Typography variant="body1">
            Your password has been reset. Please sign in with the new password.
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleLoginRedirect}
          >
            Back to Sign In
          </Button>
        </Stack>
      </Box>
    </PageBackground>
  );
};

export default PasswordResetConfirmation;
