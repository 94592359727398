import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useGetAvailabilityQuery } from 'app/api/mainApi';

import SeekerPhones from 'app/components/availabilityMenu/SeekerPhones';
import SeekerChatPhoneStatusMenu from 'app/components/availabilityMenu/SeekerChatPhoneStatusMenu';
import QuietHoursToggle from 'app/redesign/components/availability/QuietHoursToggle';

const SeekerAvailabilityMenuContainer = ({ onClick }) => {
  const { data: availability } = useGetAvailabilityQuery();
  const phones = availability?.phones || [];
  const currentChatPhone = (availability?.phones?.find((phone) => phone.is_current_chat_phone)) || null;

  return (
    <Box data-test-id="seeker-availability-menu-container" sx={{ mr: 2 }}>
      <SeekerPhones
        phones={phones}
        onClick={onClick}
      />
      {currentChatPhone && (
        <Accordion
          disableGutters
          sx={{ boxShadow: 'none' }}
        >
          <AccordionSummary
            sx={{ padding: 0, fontSize: '0.875rem' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Chat Availability Menu"
          >
            <Typography style={{ color: 'rgb(0, 102, 204)' }}>
              More options
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <SeekerChatPhoneStatusMenu
              phone={currentChatPhone}
              chatStatuses={availability?.chat_statuses}
            />
            <QuietHoursToggle availability={availability} />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

SeekerAvailabilityMenuContainer.defaultProps = {
  onClick: () => { },
};

SeekerAvailabilityMenuContainer.propTypes = {
  onClick: PropTypes.func,
};

export default SeekerAvailabilityMenuContainer;
