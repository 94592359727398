import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

import ModalsContext from 'app/contexts/ModalsContext';
import ActionRequiredModal from 'app/components/modals/ActionRequiredModal';
import ManageCardsModalContainer from 'app/containers/modals/ManageCardsModalContainer';

import {
  useFollowAdvisorMutation,
  usePerformCreateCommentMutation,
} from 'app/api/feedsApi';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import CommentsComposeBox from 'app/components/comments/CommentsComposeBox';
import { resetPostComments } from 'app/slices/resetCommentsSlice';

const CommentsComposeBoxContainer = ({ post }) => {
  const dispatch = useDispatch();
  const appConfig = useGetAppConfigQuery();
  const [body, setBody] = useState('');
  const postId = post?.id;
  const { openModal } = useContext(ModalsContext);
  const [performCreateComment, { status: createCommentStatus, error: createCommentErrors }] = usePerformCreateCommentMutation();
  const [followAdvisor, { status: createFollowStatus }] = useFollowAdvisorMutation();
  const currentUser = appConfig?.data?.current_user;
  const errors = createCommentErrors?.data.errors;

  const postComment = () => {
    performCreateComment({ body, postId });
  };

  useEffect(() => {
    if (createCommentStatus === 'fulfilled') {
      dispatch(resetPostComments({ postId }));
      setBody(''); // clear the local state
    }
  }, [createCommentStatus]);

  const followClickAction = () => {
    followAdvisor({ advisorId: post.account.main_user_id, postId, refetchPost: true });
  };

  const addCardClickAction = () => {
    openModal({
      component: ManageCardsModalContainer,
      props: {
        afterCloseAction: followClickAction,
      },
    });
  };

  const onSubmit = () => {
    // TODO: CTK 2022-07-08 per discussion with Jim, let's move this boolean to the backend
    // controller and then just handle error responses here, displaying whatever needs to be
    // displayed.  That keeps the business logic in one place, DRY, and makes it easier to adjust
    // if we need to change the behavior.

    // handled blocked users in the backend
    if (post.commentable || post.blocked) {
      postComment();
    } else {
      const hasCardOnFile = Object.keys(currentUser.default_card || {}).length > 0;

      if (hasCardOnFile) {
        openModal({
          component: ActionRequiredModal,
          props: {
            onClickAction: followClickAction,
            copy: {
              headerCopy: 'Follow this Flirt?',
              bodyCopy: `You must follow ${post.account.login}
                to leave comments on this post.`,
              submitButtonCopy: 'Follow this Flirt',
            },
          },
        });
      } else {
        openModal({
          component: ActionRequiredModal,
          props: {
            onClickAction: addCardClickAction,
            copy: {
              headerCopy: 'Follow this Flirt?',
              bodyCopy: `You must follow ${post.account.login}
                and have a credit card on file to leave comments.`,
              submitButtonCopy: 'Add Card',
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (createFollowStatus === 'fulfilled') {
      postComment();
    }
  }, [createFollowStatus]);

  return (
    <CommentsComposeBox
      body={body}
      setBody={setBody}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

CommentsComposeBoxContainer.propTypes = {
  post: PropTypes.object.isRequired,
};

export default CommentsComposeBoxContainer;
