import React from 'react';
import { PropTypes } from 'prop-types';

const VideoMediaPreview = ({ url }) => {
  return (
    <div className="slick-item-bg">
      <div className="slick-black">
        <div className="video-container">
          <img alt="preview" src={url} />
        </div>
      </div>
    </div>
  );
};

VideoMediaPreview.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoMediaPreview;
