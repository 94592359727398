import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useGetAppConfigQuery, useGetAdvisorQuery } from 'app/api/mainApi';
import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import ProfilePageHeader from 'app/redesign/components/ProfilePageHeader';
import ProfileTabsWithContent from 'app/redesign/components/ProfileTabsWithContent';
import ProfileContext from 'app/contexts/ProfileContext';

const ProfilePageContainer = (props) => {
  const [stickyNavVisible, setStickyNavVisible] = useState(false);
  const { login: advisorLogin } = useParams();

  const {
    data: advisor,
    error: advisorError,
    isLoading: advisorLoading,
  } = useGetAdvisorQuery({ login: advisorLogin });

  const appConfig = useGetAppConfigQuery();
  const currentUser = appConfig?.data?.current_user;
  const viewingSelf = (currentUser?.id === advisor?.id);

  useEffect(() => {
    const onScroll = (e) => {
      setStickyNavVisible(e.target.scrollTop > 180);
    };

    const bodyContent = document.getElementById('body-content');
    if (bodyContent) { bodyContent.addEventListener('scroll', onScroll); }

    return () => {
      bodyContent.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (advisorError?.status === 404) {
      window.location.href = '/landing/missing/profile';
    }
  }, [advisorError]);

  return (
    <AppBodyWithSidebar>
      <ProfileContext.Provider value={props}>
        <ProfilePageHeader
          advisor={advisor}
          currentUser={currentUser}
          stickyNavVisible={stickyNavVisible}
          viewingSelf={viewingSelf}
        />
        <ProfileTabsWithContent
          stickyNavVisible={stickyNavVisible}
          advisor={advisor}
          advisorLoading={advisorLoading}
          viewingSelf={viewingSelf}
          currentUser={currentUser}
        />
      </ProfileContext.Provider>
    </AppBodyWithSidebar>
  );
};

export default ProfilePageContainer;
