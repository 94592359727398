import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const usePaginatedRecords = ({
  resetRecordsRequest,
  shouldResetRecordsTestFunc,
  doneResettingRecordsFunc,
}) => {
  const dispatch = useDispatch();

  // used as placeholder from which we'll add to our cursors
  // collection. adding to cursors will make a new PostsPageContainer
  // and each PostsPageContainer will load a page of posts based on
  // this cursor
  const oldestCursor = useRef(null);

  // each cursor in this collection represents a page
  // we can remove pages by removing the cursors, allowing
  // the component to rerender itself. this should also
  // give RTK the opportunity to let go of cached data
  const [cursors, setCursors] = useState(['']);

  const [moreRecords, setMoreRecords] = useState(false);

  // after a page of data loads, we store the cursor from the oldest post in
  // the oldestCursor Ref. when the user scrolls to the bottom of the page,
  // concat the oldestCursor to the cursors collection to add a new page and
  // trigger the fetching of an older page of posts
  const setOldestCursor = (cursor) => {
    oldestCursor.current = cursor;
  };

  // adding to our cursors collection is what triggers
  // rerendering, with an additional PostsPageContainer for
  // the cursor stored in the oldestCursor Ref
  const loadMoreRecords = () => {
    if (!moreRecords) return;

    const uniqCursors = new Set(cursors.concat(oldestCursor.current));
    setCursors([...uniqCursors]);
  };

  const onBodyScroll = (e) => {
    const element = e.target;
    const diff = element.scrollHeight - Math.ceil(element.scrollTop) - element.clientHeight;

    if (diff <= 2 && element.scrollTop > 0) {
      loadMoreRecords();
    }
  };

  const initScrollHandler = () => {
    const bodyContent = document.getElementById('body-content');
    bodyContent.onscroll = onBodyScroll;
  };

  const removeScrollHandler = () => {
    const bodyContent = document.getElementById('body-content');
    if (!bodyContent) { return; }
    bodyContent.removeEventListener('scroll', onBodyScroll);
  };

  // this resets the whole feed, and allows RTK to throw away cached data.
  // after we reset the cursors to [''], set the reset flag back to null
  useEffect(() => {
    if (shouldResetRecordsTestFunc()) {
      removeScrollHandler();
      setCursors([]);
      document.getElementById('body-content').scrollTo(0, 0);
      dispatch(doneResettingRecordsFunc());

      // a timeout is necessary for react to throw away the cache,
      // so we can fetch new records
      setTimeout(() => { setCursors(['']); }, 500);
    } else {
      initScrollHandler();
    }
  }, [resetRecordsRequest]);

  // listen for scroll events
  useEffect(() => {
    initScrollHandler();

    return () => {
      removeScrollHandler();
    };
  });

  return {
    setOldestCursor,
    cursors,
    setCursors,
    initScrollHandler,
    removeScrollHandler,
    setMoreRecords,
  };
};

export default usePaginatedRecords;
