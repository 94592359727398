import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useUpdateRecurringMonetaryTributeMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import ConfirmUpdateTribute from 'app/components/modals/tributes/ConfirmUpdateTribute';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

import {
  setCurrentStep,
} from 'app/slices/tributesSlice';

const ConfirmUpdateTributeContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const cardType = currentUser?.default_card?.card_type;
  const lastFour = currentUser?.default_card?.last_four;

  const {
    activeTributes,
    closeDialogAndResetTributeSlice,
    fromNfChat,
    showBillingError,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const {
    tributeAmount,
    tributeMessage,
  } = useSelector(state => state.tributes);

  const [
    updateRecurringMonetaryTribute,
    updateRecurringMonetaryTributeResult,
  ] = useUpdateRecurringMonetaryTributeMutation();

  const confirmTributeClick = () => {
    updateRecurringMonetaryTribute({
      advisorId: advisor.id,
      rate: parseFloat(tributeAmount),
      message: tributeMessage,
      parentMonetaryTributeId: activeTributes.monetaryTribute.id,
      fromNfChat,
    });
  };

  const showTributeSuccess = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS,
    }));
  };

  useEffect(() => {
    if (updateRecurringMonetaryTributeResult.isSuccess) {
      showTributeSuccess();
    }

    if (updateRecurringMonetaryTributeResult.isError) {
      showBillingError();
    }
  }, [updateRecurringMonetaryTributeResult]);

  return (
    <ConfirmUpdateTribute
      cardType={cardType}
      closeDialog={closeDialogAndResetTributeSlice}
      confirmTributeClick={confirmTributeClick}
      isLoading={updateRecurringMonetaryTributeResult.isLoading}
      lastFour={lastFour}
      tributeAmount={tributeAmount}
      useDifferentCardClick={useDifferentCardClick}
    />
  );
};

ConfirmUpdateTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmUpdateTributeContainer;
