import React from 'react';

const FeedPlaceholder = () => (
  <div className="container-start-to-earn">
    <div className="row">
      <div className="col-sm-6 col-xs-12 padding30 paddingtop10">
        <h2>When you are following Flirts, their posts will appear here in your feed.</h2>
        <p>Check out the Explore tab or search above to find some Flirts to follow.</p>
      </div>
      <div className="col-sm-6 end-sm col-xs-12 center-xs padding30">
        <img src="/plus_frontend/assets/flirt/sample-plus2.png" className="img-sample" alt="Sample" />
      </div>
    </div>
  </div>
);

FeedPlaceholder.defaultProps = {};
FeedPlaceholder.propTypes = {};

export default FeedPlaceholder;
