import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogContent, Link, Stack, Typography, Box } from '@mui/material';
import { FormCheckbox, FormWrapper, FormLevelErrors } from 'app/components/form_components';
import { useGetNewUserQuery, useCreateUserMutation, useGetAppConfigQuery } from 'app/api/mainApi';
import useServerErrors from 'app/hooks/form/useServerErrors';
import LoginFieldContainer from 'app/containers/account_info/LoginFieldContainer';
import DateOfBirth from 'app/components/account_info/DateOfBirth';
import EmailField from 'app/components/account_info/EmailField';
import PasswordFields from 'app/components/account_info/PasswordFields';
import AdvisorInfoCardContainer from 'app/containers/account_info/AdvisorInfoCardContainer';
import SignupStepper from 'app/components/account_info/SignupStepper';

const defaultValues = {
  // Set a default empty value
  login: '',
  email: '',
  password: '',
  memberagmt: false,
  enable_platphorm_offers: false,
  login_or_email_pending: false,
};

const getFieldValues = (record) => ({
  login: record.login,
  email: record.email,
  login_or_email_pending: record.login_or_email_pending,
  enable_platphorm_offers: record.enable_platphorm_offers,
});

const TOSLabel = (
  <>
    I agree to the
    {' '}
    <Link href="/help/tos" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </Link>
  </>
);

const SignupCTA = ({ phoneRegistered, listingId, chatProfileName }) => {
  let headingText = 'Register to Get Started';

  if (phoneRegistered && listingId) {
    headingText = 'Complete your registration to start your call with...';
  } else if (phoneRegistered && chatProfileName) {
    headingText = 'Complete your registration to start your chat with...';
  } else if (phoneRegistered) {
    headingText = 'Complete Registration';
  } else if (listingId) {
    headingText = 'Register to start your call with...';
  } else if (chatProfileName) {
    headingText = 'Register to start your chat with...';
  }

  return (
    <>
      <Typography variant="h3">
        {headingText}
      </Typography>
      <AdvisorInfoCardContainer
        listingId={listingId}
        chatProfileName={chatProfileName}
      />
      {phoneRegistered && (
        <Typography variant="body1">
          You have already registered on 1-800-TO-FLIRT.
          Please complete your online registration so you can take advantage of everything NiteFlirt has to offer.
        </Typography>
      )}
      { (listingId || chatProfileName) && (
        <SignupStepper activeStep={0} />
      )}
    </>
  );
};

const SignupContainer = () => {
  const appConfig = useGetAppConfigQuery().data;
  const { current_user: currentUser } = appConfig || {};
  const recordKey = 'user';
  const query = useGetNewUserQuery();
  const record = query.data && query.data[recordKey];
  const [update, updateStatus] = useCreateUserMutation();
  const queryParameters = new URLSearchParams(window.location.search);
  const listingId = queryParameters.get('listing_id');
  const chatProfileName = queryParameters.get('user_login'); // not a great param name, fixing it here

  // set up the form and provide some functions
  const form = useForm({
    mode: 'onBlur', // validate fields on blur
    defaultValues,
  });

  // handle server errors
  useServerErrors(updateStatus, recordKey, form);

  useEffect(() => {
    if (currentUser && currentUser.id && !query.isLoading && !record.login_or_email_pending) {
      window.location.href = '/account';
    }
  }, [currentUser, record, query]);

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    if (record) {
      const initialFieldValues = getFieldValues(record);
      setInitialValues(initialFieldValues);
      form.reset(initialFieldValues);
    }
  }, [record, form.reset]);

  const handleSignIn = () => {
    let url = 'login';
    if (listingId) {
      url += `?listing_id=${listingId}`;
    }
    if (chatProfileName) {
      url += `?user_login=${chatProfileName}`;
    }
    window.location.href = url;
  };

  const onSubmit = (formData) => {
    update(formData);
  };
  useEffect(() => {
    if (updateStatus.isSuccess) {
      window.location.href = '/';
    }
  }, [updateStatus]);

  return (
    <DialogContent>
      <Box mx={{ xs: 0, sm: 12, md: 17 }}>
        <FormWrapper form={form}>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <img src="/plus_frontend/assets/svg//niteflirt-logo.svg" alt="NiteFlirt" style={{ width: '180px' }} />
            <SignupCTA
              phoneRegistered={initialValues.login_or_email_pending}
              listingId={listingId}
              chatProfileName={chatProfileName}
            />
          </Stack>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center" mb={3}>
            <LoginFieldContainer initialValue={initialValues.login} required />
            <EmailField />
            <PasswordFields />
          </Stack>
          <Stack direction="column" spacing={1}>
            <DateOfBirth />
            <FormCheckbox
              id="user-accept-tos"
              name="memberagmt"
              label={TOSLabel}
              validation={{ required: 'To continue check the box to accept our Terms of Service.' }}
            />
            <FormCheckbox
              id="user-enable-platphorm-offers"
              name="enable_platphorm_offers"
              label="Yes! Send me NiteFlirt offers and news."
            />
          </Stack>
          <FormLevelErrors />
          <Stack direction="row" justifyContent="center" mb={3}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={form.handleSubmit(onSubmit)}
              disabled={updateStatus.isLoading}
            >
              Register
            </Button>
          </Stack>
        </FormWrapper>
        <Stack direction="column" justifyContent="center" alignItems="center" mb={2}>
          <Typography variant="h5">
            Already have an account?
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={3}>
          <Button
            onClick={handleSignIn}
            variant="outlined"
            size="medium"
            fullWidth
          >
            Sign In
          </Button>
        </Stack>
      </Box>
    </DialogContent>
  );
};

export default SignupContainer;
