import React, { useContext } from 'react';

import AllowPushNotifications from 'app/components/dialogs/AllowPushNotifications';
import DialogsContext from 'app/contexts/DialogsContext';
import { enablePushNotifications } from 'app/helpers/pushNotificationsHelpers';

const AllowPushNotificationsDialogContainer = () => {
  const { closeDialog } = useContext(DialogsContext);

  const yesDefinitelyHandler = () => {
    closeDialog();
    enablePushNotifications();
  };

  const closeAndRemindLaterHandler = () => {
    closeDialog();
    const date = new Date();
    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    localStorage.setItem('pn-remind-later', date.getTime());
  };

  return (
    <AllowPushNotifications
      yesDefinitelyHandler={yesDefinitelyHandler}
      closeAndRemindLaterHandler={closeAndRemindLaterHandler}
    />
  );
};

AllowPushNotificationsDialogContainer.displayName = 'AllowPushNotifications';

AllowPushNotificationsDialogContainer.propTypes = {};

export default AllowPushNotificationsDialogContainer;
