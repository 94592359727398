import React from 'react';

import { Grid } from '@mui/material';

const HorizontalLine = () => {
  return (
    <Grid item xs={12} sx={{ lineHeight: 0.5, backgroundColor: 'var(--color_ddd)' }}>
      &nbsp;
    </Grid>
  );
};

export default HorizontalLine;
