/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resetHomeFeedRequest: false,
  resetFlirtFeedRequest: null,
  resetFlirtMediaRequest: null,
};

const resetRecordsSlice = createSlice({
  name: 'resetRecords',
  initialState,
  reducers: {
    resetHomeFeed: (state) => {
      state.resetHomeFeedRequest = true;
    },
    doneResettingHomeFeed: (state) => {
      state.resetHomeFeedRequest = false;
    },
    resetExploreFeed: (state) => {
      state.resetExploreFeedRequest = true;
    },
    doneResettingExploreFeed: (state) => {
      state.resetExploreFeedRequest = false;
    },
    resetFlirtFeed: (state, action) => {
      state.resetFlirtFeedRequest = action.payload.advisorId;
    },
    doneResettingFlirtFeed: (state) => {
      state.resetFlirtFeedRequest = null;
    },
    resetFlirtMedia: (state, action) => {
      state.resetFlirtMediaRequest = action.payload.advisorId;
    },
    doneResettingFlirtMedia: (state) => {
      state.resetFlirtMediaRequest = null;
    },
  },
});

export default resetRecordsSlice;

export const {
  resetHomeFeed,
  doneResettingHomeFeed,
  resetExploreFeed,
  doneResettingExploreFeed,
  resetFlirtFeed,
  doneResettingFlirtFeed,
  resetFlirtMedia,
  doneResettingFlirtMedia,
} = resetRecordsSlice.actions;
