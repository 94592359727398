import React, { useRef, useState, useEffect } from 'react';

import { PropTypes } from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { usePerformReportContentMutation } from 'app/api/mainApi';
import { useDispatch } from 'react-redux';

import { showFlash } from 'app/slices/flashSlice';

const ReportPostModal = ({ closeModal, postId }) => {
  const dispatch = useDispatch();
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState({});

  const MaximumPostLength = 500;
  const MinimumPostLength = 4;
  const textAreaRef = useRef();

  const [bodyLength, setBodyLength] = useState(0);

  const updateBodyLength = (newBody) => {
    // Count new lines as 2 characters
    const newLines = newBody.match(/\n/g);
    setBodyLength(newBody.length + (newLines ? newLines.length : 0));
  };

  const setBodyAndClearTempState = (body) => {
    setBody(body);
    setErrors({});
  };

  const handleChange = (e) => {
    updateBodyLength(e.target.value);
    setBodyAndClearTempState(e.target.value);
  };

  const errorMessage = errors && errors.length > 0 && (
    <div className="text-error">
      { errors.join(' ') }
    </div>
  );

  const validBodyLength = (
    bodyLength >= MinimumPostLength && bodyLength <= MaximumPostLength
  );

  const [
    performReportContent,
    {
      isSuccess: isReportContentSuccess,
      error: reportContentError,
    },
  ] = usePerformReportContentMutation();

  useEffect(() => {
    setErrors(reportContentError?.data?.errors);
  }, [reportContentError]);

  useEffect(
    () => {
      if (isReportContentSuccess) {
        closeModal();

        dispatch(showFlash({
          flashType: 'success',
          props: { message: 'Your report has been submitted. Thank you for letting us know.' },
        }));
      }
    },
    [isReportContentSuccess],
  );

  return (
    <div className="container-textarea">
      <div className="modal-header">
        <h4>Report this Post to Play Fair</h4>
      </div>
      <div className="modal-body">
        <p>
          You may report Posts that you believe are in violation of our Policies. Please provide a brief description why you are reporting this post.
        </p>

        <div className={`label-float ${(errors && errors.length > 0) ? 'has-error' : ''}`}>
          <TextareaAutosize
            value={body}
            onChange={handleChange}
            minLength={MinimumPostLength}
            maxLength={MaximumPostLength}
            data-lpignore="true"
            ref={textAreaRef}
            className="textarea-grow"
          />
          { errorMessage }
        </div>
        <div className="container-fluid padding-0-8 margintopminus10">
          <div className="row middle-xs">
            <div className="col-xs-5 start-xs" />
            <div className="col-xs-7 end-xs">
              <span className="char-remaining" style={{ color: (validBodyLength ? '#999' : 'red') }}>
                {bodyLength}
                &nbsp;/&nbsp;
                {MaximumPostLength}
                &nbsp;characters
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={() => { closeModal(); }}
        >
          Cancel
        </a>
        <button
          type="button"
          disabled={!validBodyLength}
          className="btn"
          onClick={() => {
            performReportContent({
              contentId: postId,
              contentType: 'post',
              body,
            });
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

ReportPostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  postId: PropTypes.number.isRequired,
};
export default ReportPostModal;
