import React, {useRef} from 'react';
import AppBody from "app/components/layout/AppBody";
import TemplateShell2 from "app/components/layout/TemplateShell2";
import {Box, Typography, Grid, Table, TableCell, TableHead, TableRow, TableBody} from "@mui/material";
import { toCurrency } from "app/helpers/currencyHelpers";
import PropTypes from 'prop-types';
import { useReactToPrint } from "react-to-print";

const Loading = () => {
  return (
    <AppBody>
      <TemplateShell2 header="Form 1099-K">
        <Box px={{ xs: 0, sm: 4 }} pt={{ xs: 4, sm: 4 }} className={'text-muted'}>
          <Typography variant={'h6'}>Loading tax information...</Typography>
        </Box>
      </TemplateShell2>
    </AppBody>
  )
}

const TaxForm = ({ data }) => {
  if (!data) {
    return <Loading />;
  }

  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: '.no-print { display: none; }'
  });

  const {
    order_year,
    short_name,
    data: {
      payee_name,
      address_line1,
      address_line2,
      city,
      state,
      zip,
      TIN: tin,
      apr_5d,
      aug_5h,
      feb_5b,
      jan_5a,
      jul_5g,
      jun_5f,
      mar_5c,
      may_5e,
      nov_5k,
      num_trans_3,
      oct_5j,
      sep_5i,
      total_1a,
      dec_5l
    }
  } = data || { data: {}};

  return (
    <>
      <AppBody>
        <TemplateShell2 header="Form 1099-K">

          <Box px={{xs: 0, sm: 4}} pt={{xs: 4, sm: 4}} ref={contentRef}>
            <a href="#" onClick={handlePrint} className={'no-print pull-right'} style={{float: 'right'}}>Print</a>
            <Typography variant={'h2'}>
              {order_year} {short_name}
            </Typography>
            <br/>
            <Typography variant={'h3'}>
              Payment Card and Third Party Network Transactions
            </Typography>
            <br/>
            <Typography variant={'p'}>
              This is important tax information and is being furnished to the Internal Revenue Service. If you are
              required to file a return, a negligence penalty or other sanction may be imposed on you if taxable income
              results from this transaction and the IRS determines that it has not been reported.
            </Typography>

            <Grid container spacing={1} pt={2}>
              <Grid item xs={4}>
                <strong>Payee's Name:</strong>
                <br/>
                {payee_name}
                <br/>
                {address_line1}
                <br/>
                {
                  address_line2 && (
                    <>
                      {address_line2}
                      <br/>
                    </>
                  )
                }
                {city}, {state} {zip}
              </Grid>
              <Grid item xs={8}>
                <strong>Filer's Name:</strong>
                <br/>
                NF Entertainment, LLC
                <br/>
                182 Howard Street, Suite 418
                <br/>
                San Francisco, CA 94105
                <br/>
                877-527-2555
              </Grid>
            </Grid>

            <Grid container spacing={3} pt={4}>
              <Grid item xs={4}>
                <strong>Payee's federal Identification number:</strong>
                <br/>
                {tin}
              </Grid>
              <Grid item xs={8}>
                <strong>Filer's federal Identification number:</strong>
                <br/>
                30-0714871
              </Grid>
            </Grid>

            <Grid container spacing={1} pt={4}>
              <Grid item xs={4}>
                <strong>Type of Filer:</strong>
                <br/>
                EPF or 3rd Party
              </Grid>
              <Grid item xs={8}>
                <strong>Type of Payment:</strong>
                <br/>
                3rd Party Network
              </Grid>
            </Grid>

            <Grid container spacing={1} sm={12}>
              <Grid item sm={6}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Description
                      </TableCell>
                      <TableCell align={'right'}>
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Box 1a: Gross amount of payment card/third party network transactions</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(total_1a)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5a: Jan.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(jan_5a)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5c: Mar.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(mar_5c)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5e: May.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(may_5e)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5g: Jul.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(jul_5g)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5i: Sep.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(sep_5i)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5k: Nov.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(nov_5k)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Table width={'100%'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Description
                      </TableCell>
                      <TableCell align={'right'}>
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong>Box 3: Number of payment transactions.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {num_trans_3}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5b: Feb.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(feb_5b)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5d: Apr.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(apr_5d)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5f: Jun.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(jun_5f)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5h: Aug.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(aug_5h)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5j: Oct.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(oct_5j)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Box 5l: Dec.</strong>
                      </TableCell>
                      <TableCell align={'right'}>
                        {toCurrency(dec_5l)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <br/>
            <strong style={{fontSize: '1.125em'}}>Instructions for Payee - 1099-K</strong>
            <p>You have received this form because you have either (a) accepted payment cards for payments, or (b)
              received payments through a third party network that exceeded $20,000 in gross total reportable
              transactions
              and the aggregate number of those transactions exceeded 200 for the calendar year. Merchant acquirers and
              third party settlement organizations, as payment settlement entities (PSEs), must report the proceeds of
              payment card and third party network transactions made to you on Form 1099-K under Internal Revenue Code
              section 6050W. The PSE may have contracted with an electronic payment facilitator (EPF) or other third
              party
              payer to make payments to you.</p>
            <p>If you have questions about the amounts reported on this form, contact the FILER whose information is
              shown
              in the upper left corner on the front of this form. If you do not recognize the FILER shown in the upper
              left corner of the form, contact the PSE whose name and phone number are shown in the lower left corner of
              the form above your account number.</p>
            <p>See the separate instructions for your income tax return for using the information reported on this
              form.</p>
            <p>If the Form 1099-K is related to your business, see Pub. 334 for more information. If the Form 1099-K is
              related to your work as part of the gig economy, see <a
                href="https://www.irs.gov/businesses/gig-economy-tax-center" target="_blank">www.IRS.gov/GigEconomy</a>.
            </p>
            <p><strong>Payee's taxpayer identification number (TIN)</strong>. For your protection, this form may show
              only
              the last four digits of your TIN (social security number (SSN), individual taxpayer identification number
              (ITIN), adoption taxpayer identification number (ATIN), or employer identification number (EIN)). However,
              the issuer has reported your complete TIN to the IRS.</p>
            <p><strong>Account number</strong>. May show an account number or other unique number the PSE assigned to
              distinguish your account.</p>
            <p><strong>Box 1a</strong>. Shows the aggregate gross amount of payment card/third party network
              transactions
              made to you through the PSE during the calendar year.</p>
            <p><strong>Box 1b</strong>. Shows the aggregate gross amount of all reportable payment transactions made to
              you through the PSE during the calendar year where the card was not present at the time of the transaction
              or the card number was keyed into the terminal. Typically, this relates to online sales, phone sales, or
              catalogue sales. If the box for third party network is checked, or if these are third party network
              transactions, Card Not Present transactions will not be reported.</p>
            <p><strong>Box 2</strong>. Shows the merchant category code used for payment card/third party network
              transactions (if available) reported on this form.</p>
            <p><strong>Box 3</strong>. Shows the number of payment transactions (not including refund transactions)
              processed through the payment card/third party network.</p>
            <p><strong>Box 4</strong>. Shows backup withholding. Generally, a payer must backup withhold if you did not
              furnish your TIN or you did not furnish the correct TIN to the payer. See Form W-9, Request for Taxpayer
              Identification Number and Certification, and Pub. 505. Include this amount on your income tax return as
              tax
              withheld.</p>
            <p><strong>Boxes 5a–5l</strong>. Shows the gross amount of payment card/third party network transactions
              made
              to you for each month of the calendar year.</p>
            <p><strong>Boxes 6–8</strong>. Shows state and local income tax withheld from the payments.</p>
            <p><strong>Future developments</strong>. For the latest information about developments related to Form
              1099-K
              and its instructions, such as legislation enacted after they were published, go to <a
                href="https://www.irs.gov/forms-pubs/about-form-1099-k" target="_blank">www.irs.gov/Form1099K</a>.</p>
            <p><strong>Free File</strong>. Go to <a
              href="https://www.irs.gov/filing/free-file-do-your-federal-taxes-for-free"
              target="_blank">www.irs.gov/FreeFile</a> to see if you qualify for no-cost online federal tax online
              federal
              tax preparation, e-filing, and direct deposit or payment options.</p>
          </Box>
        </TemplateShell2>
      </AppBody>
    </>
  )
}

TaxForm.propTypes = {
  data: PropTypes.object,
}

export default TaxForm;