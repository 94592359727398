import React, { useState, useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import SanitizedHTML from 'app/helpers/SanitizedHTML';

const HeaderSearchContainer = () => {
  const [queryString, setQueryString] = useState('');
  const [results, setResults] = useState(false);
  const [userIsSearching, setUserIsSearching] = useState(false);
  const containerRef = useRef(null);

  // Submit if they press enter.  Otherwise, if there are at least 3 characters,
  //  then wait half a second and fetch the search results.  If they type more while
  //  waiting, then cancel the previous fetch and start a new one.  (Helps prevent
  //  a flood of requests.)
  const handleKeyPress = (evt) => {
    const inputValue = evt.target.value;
    setQueryString(inputValue);

    if (evt.key === 'Enter') {
      const form = document.getElementById('global_search_form');
      form.submit();
      return;
    }

    // this is a no-op if the timeout doesn't exist
    clearTimeout(window.fetchSearchResults);

    window.fetchSearchResults = setTimeout(() => {
      if (inputValue.length < 3) {
        setResults(false);
        return;
      }

      fetch(`/search/realtime/${encodeURIComponent(inputValue)}`)
        .then((response) => response.text())
        .then(setResults)
        .catch((_error) => {
          setResults(false);
        });
    }, 500);
  };

  const handleClickOutside = (event) => {
    setUserIsSearching(containerRef.current?.contains(event.target));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box ref={containerRef}>
      <form name="form1" id="global_search_form" action="/search" acceptCharset="UTF-8" method="get">
        <input
          name="text"
          size="14"
          maxLength="100"
          id="searchinput"
          className="searchfield"
          style={{ verticalAlign: 'middle' }}
          type="text"
          autoComplete="off"
          onChange={handleKeyPress}
          value={queryString}
        />
        <input id="btn-search-icon" name="Send" type="button" />
      </form>
      { userIsSearching && results && <SanitizedHTML html={results} preset="broad" /> }
    </Box>
  );
};

export default HeaderSearchContainer;
