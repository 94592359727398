import React from 'react';
import { PropTypes } from 'prop-types';
import RetrySubscribeModal from '../../components/modals/subscriptions/RetrySubscribeModal';
import { useSelector } from 'react-redux';

const RetrySubscribeModalContainer = ({
  closeModal,
  currentUser,
  useDifferentCardAction,
  clickAction,
}) => {
  const { loading } = useSelector((state) => state.subscription);

  return (
    <RetrySubscribeModal
      closeModal={closeModal}
      currentUser={currentUser}
      useDifferentCardAction={useDifferentCardAction}
      clickAction={clickAction}
      pendingSubRequest={loading}
    />
  );
};

RetrySubscribeModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  clickAction: PropTypes.func.isRequired,
  useDifferentCardAction: PropTypes.func.isRequired,
};

export default RetrySubscribeModalContainer;
