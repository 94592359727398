import React from 'react';

const AffiliateAgreement = () => (
  <div className="body">
    <p>
      This Affiliate Agreement (&quot;Agreement&quot;) contains the complete terms and conditions that apply to you participating in the NiteFlirt Affiliate Program. The purpose of this Agreement is to allow you to link between a web site owned and controlled by you (the &quot;Affiliate&apos;s Web Site&quot;) and the NiteFlirt.com web site and NiteFlirt 800 numbers  (collectively the &quot;NiteFlirt Site&quot;). This Agreement will become effective upon once you agree to the Agreement (the &quot;Effective Date&quot;). NiteFlirt reserves the right to accept or reject your application in NiteFlirt&apos;s sole discretion. Defined terms shall have the meaning set forth herein or in Exhibit A below.
    </p>

    <ol style={{ listStyle: 'decimal' }}>
      <li>
        Linking to the NiteFlirt Site
        <ol style={{ listStyle: 'decimal' }}>
          <li>
            Maintenance and Monitoring of Placements
            <table>
              <tr>
                <td valign="top">a.&nbsp;&nbsp;</td>
                <td>NiteFlirt has the right to monitor the content on Affiliate&apos;s Web Site in a commercially reasonable manner as NiteFlirt believes necessary to monitor use of the Placements and for compliance with the terms of this Agreement. NiteFlirt has the right to notify you of any reasonable changes that you need to make to comply with the NiteFlirt guidelines for the use of the Placements, and to otherwise comply with the terms of this Agreement, and you agree to promptly make such changes.</td>
              </tr>
              <tr>
                <td valign="top">b.&nbsp;&nbsp;</td>
                <td>You shall cooperate with NiteFlirt in order to establish and maintain any Placements between the NiteFlirt Site and Affiliate&apos;s Web Site.</td>
              </tr>
              <tr>
                <td valign="top">c.&nbsp;&nbsp;</td>
                <td>You agree that NiteFlirt will be allowed to place an identifying tag in each Placement that will identify the origin of a user that arrives directly at the NiteFlirt Site by clicking on the Placement.</td>
              </tr>
            </table>
            <br />
          </li>
        </ol>
      </li>
      <li>
        Payments of Referral Fees from NiteFlirt to You
        <ol style={{ listStyle: 'decimal' }}>
          <li>NiteFlirt will pay a one time Referral Fee for each New Customer who enters the NiteFlirt Site directly from Placements on Affiliate&apos;s Web Site or in email newsletters.</li>
          <li>NiteFlirt will not pay Referral Fees for the registration of any individuals or accounts who have previously registered with NiteFlirt, even if the registration contains a different email address, credit card number, or other information different from the user&apos;s previous registration. NiteFlirt will not pay a Referral Fee for Members who have later re-entered and registered on the NiteFlirt Web Site after previously following a Placement from the Affiliate&apos;s Web Site to the NiteFlirt Web Site. </li>
          <li>Payment of Referral Fees will be a credit to your NiteFlirt Account on a monthly basis and in accordance with NiteFlirt&apos;s Payment Policy. If this Agreement terminates, any Referral Fees due at the time of termination will be paid within the normal payment schedule. </li>
        </ol>
        <br />
      </li>
      <br />

      <li>
        Compliance with Applicable Laws
        <p>You are solely responsible for the accuracy and appropriateness of all materials posted on Affiliate&apos;s Web Site, and for ensuring that your activities, including materials posted on Affiliate&apos;s Web Site and communications with NiteFlirt, other Members, New Customers, and potential customers are not defamatory, in violation of copyright laws or otherwise illegal. You further agree that your activities, including communications with NiteFlirt, other Members, New Customers and potential customers regarding or relating to NiteFlirt in any way, are in full compliance with all applicable laws in your jurisdiction. You further agree to abide by NiteFlirt&apos;s Anti-Spam Policy.You agree to indemnify and hold NiteFlirt harmless for any violations of the foregoing. NiteFlirt disclaims all liability for these matters.</p>
      </li>
      <br />

      <li>
        Press Releases and Other Publicity
        <ol style={{ listStyle: 'decimal' }}>
          <li>You shall not issue any public statement(s) regarding the relationship with NiteFlirt without the prior written approval of NiteFlirt. Notwithstanding the foregoing, NiteFlirt may issue an initial press release regarding the relationship between the parties.</li>
          <li>NiteFlirt prohibits certain forms of advertising on the NiteFlirt Site or using any Placements or other NiteFlirt Marks. Advertising commonly referred to as &quot;spamming&quot; could damage NiteFlirt&apos;s goodwill. You shall not undertake any spamming referring to the NiteFlirt Site, or using any Placements or other NiteFlirt Marks. You shall not refer to the NiteFlirt Site, or use any Placements or other NiteFlirt Marks in any unsolicited commercial email (UCE), postings to non-commercial newsgroups and cross-posting to multiple newsgroups at once. In addition, you shall not advertise in any way that effectively conceals or misrepresents your identity, domain name, or return email address.</li>
          <li>Although NiteFlirt always requires that its written consent be given for the uses above, NiteFlirt generally approves mailings so long as the recipient is already a customer or subscriber of your services, and recipients have the option to remove themselves from future mailings. NiteFlirt generally approves newsgroup postings so long as the news group specifically welcomes commercial messages. In all cases, you must always clearly represent yourself and the Affiliate&apos;s Web Site as independent from NiteFlirt.</li>
        </ol>
        <br />
      </li>
      <br />

      <li>
        Modification
        <br />
        NiteFlirt may modify any of the terms and conditions in this Agreement, at any time in its sole discretion. Modifications may include, but are not limited to, changes in the scope of Referral Fees, payment procedures, and NiteFlirt&apos;s Affiliate Program rules. If any modification is unacceptable to you, you shall have the right to terminate this Agreement as set forth below. Your continued participation in NiteFlirt&apos;s Affiliate Program following the posting of the change notice or new agreement on the NiteFlirt Site will constitute your agreement to the changes.
        <br />
      </li>

      <br />
      <li>
        Term and Termination
        <br />
        The term of this Agreement will begin on the Effective Date and will end when terminated by either party. Either NiteFlirt or you may terminate this Agreement at any time, with or without cause, by giving the other party written notice of termination. Upon the termination of this Agreement for any reason, all licenses granted hereunder shall immediately terminate and you will immediately cease use of, and remove from Affiliate&apos;s Web Site, all links to the NiteFlirt Site, and all NiteFlirt trademarks and logos, other NiteFlirt Marks and all other materials provided in connection with this Agreement.
        <br />
      </li>
      <br />
      <li>
        Grant of Licenses
        <ol style={{ listStyle: 'decimal' }}>
          <li>Subject to the terms of this Agreement, you have the nonexclusive, nontransferable and nonsublicensable right to use and display NiteFlirt trademarks and service marks, only to refer specifically to NiteFlirt services and products in connection with the Placements, and only in the form which NiteFlirt provides you for such limited purposes. Such referential usage must be truthful, fair and not misleading or disparaging. NiteFlirt trademarks, service marks and logos must be used with a minimum spacing surrounding them and not be incorporated into your own product names, trademarks, service names, logos, company names or dba&apos;s. You shall not adopt marks or logos that are confusingly similar to NiteFlirt&apos;s marks or logos.</li>
          <li>You grant to NiteFlirt a non-exclusive, non-transferable, revocable right to utilize your name, title, and logo in the advertising, marketing, promoting, and publicizing, in any manner, related to NiteFlirt&apos;s rights under this Agreement. NiteFlirt is not under any obligation to so advertise, market, promote, or publicize</li>
          <li>Each party agrees not to use the other&apos;s proprietary materials, marks or other intellectual property in any manner that is disparaging or that otherwise portrays the party in a negative light. Each party reserves all of its respective rights in the proprietary materials and intellectual property covered by this license. Other than the license granted in this Agreement, each party retains all right, title, and interest to its respective rights and no right, title, or interest is transferred to the other.</li>
        </ol>
        <br />
      </li>
      <br />
      <li>
        DISCLAIMER
        <p>NITEFLIRT PROVIDES THE NITEFLIRT SITE AND ALL CONTENT, INFORMATION AND SERVICES AVAILABLE THEREIN, &quot;AS IS&quot; AND MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING NITEFLIRT OR ANY SERVICES OR INFORMATION PROVIDED BY ANY MEMBER OF NITEFLIRT. ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT ARE EXPRESSLY DISCLAIMED AND EXCLUDED. IN ADDITION, NITEFLIRT MAKES NO REPRESENTATION THAT THE OPERATION OF THE NITEFLIRT SITE WILL BE UNINTERRUPTED OR ERROR FREE, AND NITEFLIRT WILL NOT BE LIABLE FOR THE CONSEQUENCES OF ANY INTERRUPTIONS, DOWNTIME OR ERRORS.</p>
      </li>
      <br />
      <li>
        Representations, Warranties and Covenants
        <p>You represent and warrant that 1) you have full right, power, and authority to enter into and be bound by the terms and conditions of this Agreement and to perform your obligations under this Agreement, without the approval or consent of any other party; 2) you have sufficient right, title, and interest in and to the rights granted to NiteFlirt in this Agreement; 3) the material posted on Affiliate&apos;s Web Site does not defame any third party or violate or infringe upon the rights of any third party and all applicable copyright and other laws that pertain to it; 4) you shall not make any representations or warranties regarding the services provided by NiteFlirt; and 5) you shall not make or publicize any statements that are disparaging of NiteFlirt or that otherwise portray NiteFlirt in a negative light.</p>
      </li>
      <br />
      <li>
        LIMITATIONS OF LIABILITY
        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT, NiteFlirt WILL NOT BE LIABLE TO YOU WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY, OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF GOODWILL OR ACTUAL OR ANTICIPATED REVENUE, PROFITS OR LOST BUSINESS), EVEN IF NiteFlirt HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT, IN NO EVENT SHALL NiteFlirt&apos;s CUMULATIVE LIABILITY TO YOU ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER BASED IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE AND STRICT LIABILITY) OR OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL REFERRAL FEES PAYABLE TO YOU UNDER THIS AGREEMENT.</p>
      </li>
      <br />
      <li>
        Indemnification
        <p>You agree to indemnify and hold harmless NiteFlirt and its employees, directors, representatives, agents, and affiliates, against any and all claims, suits, actions, or other proceedings brought against NiteFlirt based on or arising from any claim resulting from your breach of the warranties and covenants in Section 9. You agree to pay any and all costs, damages, and expenses, including, but not limited to, reasonable attorneys&apos; fees and costs awarded against or otherwise incurred by the other party in connection with or arising from any such claim, suit, action, or proceeding.</p>
      </li>
      <br />
      <li>
        Confidentiality
        <p>In connection with the activities contemplated by this Agreement, you may acquire confidential technical or business information of NiteFlirt which is not generally known to the public, including without limitation (i) proposals, ideas or research related to possible new products or services; (ii) financial statements and other financial information; (iii) any reporting information required by the Agreement; and (iv) the terms of this Agreement and the relationship between the parties (collectively, &quot;Confidential Information&quot;). You agree not to disclose the Confidential Information or use the Confidential Information for your own benefit or for the benefit of any third party. Your obligations in this Section shall not apply to any information that you can document: (i) was in the public domain at or subsequent to the time it was communicated to you through no fault of yours; (ii) was rightfully in your possession free of any obligation of confidence owed to NiteFlirt at or subsequent to the time it was communicated to you by NiteFlirt; (iii) was in response to a valid order by a court or other governmental body or was otherwise required by law. Upon the termination or expiration of this Agreement, you shall return upon NiteFlirt&apos;s request or otherwise destroy all Confidential Information of NiteFlirt in your possession.</p>
      </li>
      <br />
      <li>
        Miscellaneous
        <ol style={{ listStyle: 'decimal' }}>
          <li>Entire Agreement. This Agreement constitutes and contains the entire agreement between the parties with respect to the subject matter hereof and supersedes any prior oral or written agreements. As a NiteFlirt Member you have also agreed to the NiteFlirt Terms of Service and this still applies to your relationship with NiteFlirt. This Agreement may not be amended except in writing signed by the parties. Each party acknowledges and agrees that the other has not made any representations, warranties or agreements of any kind, except as expressly set forth herein. All exhibits attached to this Agreement are incorporated hereby and shall be treated as if set forth herein.</li>
          <li>Relationship of Parties. The parties shall be deemed to be independent parties with respect to the subject matter of this Agreement, and nothing contained in this Agreement shall be deemed or construed in any manner as creating any partnership, joint venture, employment, contractor, agency, fiduciary, or other similar relationship. You shall be solely responsible for all taxes due on Referral Fees or other payments paid to you under this Agreement.</li>
          <li>Assignment. You may not assign your rights or obligations under this Agreement to any party.</li>
          <li>Applicable Law. This Agreement shall be governed by and interpreted in accordance with the laws of the State of California without regard to the conflicts of laws, rules and principles thereof.</li>
          <li>Severability. If any provision of this Agreement is held to be invalid or unenforceable, that provision shall be eliminated or limited to the minimum extent necessary such that the intent of the parties is effectuated, and the remainder of this agreement shall have full force and effect.</li>
          <li>Survival. Sections 6 and 8-13 shall survive termination of this Agreement.</li>
          <li>Notices. Any notice required under this Agreement may be given by email, fax or written letter to the number or address you provide.</li>
        </ol>
        <br />
      </li>
      <br />
      <li>
        General Conditions
        <p>NiteFlirt reserves the right, at its full discretion, to disqualify any individual it suspects of undermining or manipulating the registration and/or qualifying process, the operation of the Affiliate Program or to be acting in violation of this agreement. If we determine, in our sole discretion that the this Affiliate Program is compromised by virus, bug, robot entries, or other corruption of the administration, security or proper management of the program, then at our sole discretion, we can cancel this program. In the event of your non-compliance with any requirement stated herein, NiteFlirt may withhold any payments due to you until resolution of all disputes.</p>
        <p>YOU HAVE READ THIS AGREEMENT CAREFULLY AND UNDERSTAND, HAVE HAD THE OPPORTUNITY TO CONSULT WITH COUNSEL AND ACCEPT THE OBLIGATIONS, WHICH IT IMPOSES UPON YOU WITHOUT RESERVATION. YOU HAVE ALSO TAKEN INTO ACCOUNT THE LIMITATION OF LIABILITY AND WARRANTY DISCLAIMER PROVISIONS OF THIS AGREEMENT PRIOR TO ACCEPTING THIS AGREEMENT. NO PROMISES OR REPRESENTATIONS HAVE BEEN MADE TO YOU TO INDUCE YOU TO SIGN THIS AGREEMENT. YOU AGREE TO THE TERMS OF THIS AGREEMENT VOLUNTARILY AND FREELY.</p>
      </li>
      <br />
    </ol>
    <br />

    <div style={{ textAlign: 'center' }}>Exhibit A - Definitions</div>

    <br />
    As used in this Agreement, the terms set forth below shall have the following meanings:
    <br />
    <ol style={{ listStyle: 'decimal' }}>
      <li>&quot;NiteFlirt Marks&quot; means the trademarks, trade names, service marks and logos of NiteFlirt that may be made available to you hereunder.</li>
      <li>&quot;Member&quot; means a user of the NiteFlirt Site who has completed NiteFlirt&apos;s registration process in order to use the NiteFlirt Service.</li>
      <li>&quot;NiteFlirt Service&quot; means a personal information exchange service where Members receive personalized advice and answers to their questions either in real-time or through asynchronous means, either free or for a fee.</li>
      <li>&quot;Placements&quot; means (i) graphical links, text links, logos and other promotions that are offered by NiteFlirt now or in the future that link directly from the Affiliate&apos;s Web Site to the NiteFlirt Site and (ii) other email promotions that are offered by you now or in the future and link directly to the NiteFlirt Site.</li>
      <li>&quot;New Customer&quot; means a user that registers as a Member for the first time, who is over 18 years old, enters a valid credit card, and places at least one paid call to another Member through the NiteFlirt Site. A New Customer may not have any other accounts registered on NiteFlirt under a different name, email address, or phone number.</li>
      <li>&quot;Referral Fee&quot; means fees paid to you for each New Customer who comes to the NiteFlirt Site by way of a Placement on the Affiliate&apos;s Web Site who is not already a Member.</li>
    </ol>
    <br />
  </div>
);

export default AffiliateAgreement;
