import React, { useState } from 'react';

import KebobMenu from 'app/components/storefront/KebobMenu';

import PropTypes from 'prop-types';
import { useUpdateShowMemberSinceMutation } from 'app/api/mainApi';

const KebobMenuContainer = ({
  advisor,
  currentUser,
  darkAssets,
}) => {
  const [updateShowMemberSince] = useUpdateShowMemberSinceMutation();

  const [isRecordNameDrawerOpen, setIsRecordNameDrawerOpen] = useState(false);

  const ignoreCloseDrawerEvents = (event) => {
    return event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift');
  };

  const openRecordNameDrawer = () => {
    setIsRecordNameDrawerOpen(true);
  };

  const closeRecordNameDrawer = (event) => {
    if (ignoreCloseDrawerEvents(event)) return;

    setIsRecordNameDrawerOpen(false);
  };

  const handleShareClick = () => navigator.share({
    url: window.location.href,
    text: 'Check out this Flirt on NF+: ',
  });

  const handleToggleMemberSince = () => {
    updateShowMemberSince({
      advisorLogin: currentUser?.login,
      toggleValue: !advisor?.show_member_since,
    });
  };

  const memberSinceToggleText = () => {
    const actionVerb = advisor?.show_member_since ? 'Hide' : 'Show';

    return `${actionVerb} Member Since Date`;
  };

  return (
    <KebobMenu
      advisor={advisor}
      currentUser={currentUser}
      darkAssets={darkAssets}
      openRecordNameDrawer={openRecordNameDrawer}
      closeRecordNameDrawer={closeRecordNameDrawer}
      handleShareClick={handleShareClick}
      handleToggleMemberSince={handleToggleMemberSince}
      memberSinceToggleText={memberSinceToggleText}
      isRecordNameDrawerOpen={isRecordNameDrawerOpen}
    />
  );
};

KebobMenuContainer.defaultProps = {
  advisor: null,
  currentUser: null,
  darkAssets: false,
};

KebobMenuContainer.propTypes = {
  advisor: PropTypes.object,
  currentUser: PropTypes.object,
  darkAssets: PropTypes.bool,
};

export default KebobMenuContainer;
