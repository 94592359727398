import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const DrawerHeader = ({ closeDrawer, headerCopy }) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: 'var(--body_background)',
        padding: '12px',
        borderBottom: '1px solid var(--color_ccc)',
      }}
    >
      <Grid item xs={11}>
        <span className="subhead">{headerCopy}</span>
      </Grid>
      <Grid item xs={1} className="pull-right">
        <a
          role="link"
          tabIndex={0}
          onClick={(e) => { closeDrawer(e); }}
          onKeyDown={(e) => { closeDrawer(e); }}
        >
          <img src="/plus_frontend/assets/svg/x-close.svg" alt="close" />
        </a>
      </Grid>
    </Grid>
  );
};

DrawerHeader.defaultProps = {
};

DrawerHeader.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  headerCopy: PropTypes.string.isRequired,
};

export default DrawerHeader;
