import React from 'react';

import {
  Box,
  Typography,
} from '@mui/material';

import Connected from 'app/components/call/callInProgress/Icons/Connected';

const BillingStartedStep = () => {
  return (
    <Box
      textAlign="center"
      mt={3}
    >
      <Typography variant="h3">
        You are now connected.
      </Typography>
      <Box mt={3} mb={3}>
        <Connected />
      </Box>
      <Typography variant="body1" gutterBottom>
        Enjoy your call!
      </Typography>
    </Box>
  );
};

export default BillingStartedStep;
