import { useEffect, useContext } from 'react';

import DialogsContext from 'app/contexts/DialogsContext';
import VerifyAccountDialog from 'app/components/modals/VerifyAccountDialog';

const VerifyAccountLauncher = () => {
  const { openDialog } = useContext(DialogsContext);

  const onClose = () => {
    history.back();
  }

  useEffect(() => {
    openDialog({
      component: VerifyAccountDialog,
      props: { closeDialog: onClose, hideCancel: true },
    });
  }, []);

  return null;
};

export default VerifyAccountLauncher;
