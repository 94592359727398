import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem } from '@mui/material';

function RoundedPagination({ count, onPageChange }) {
  const [page, setPage] = React.useState(1);

  const scrollToTop = () => {
    const bodyContent = document.getElementById('body-content');
    if (bodyContent) {
      bodyContent.scrollTop = 0;
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    onPageChange(value);
    scrollToTop();
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div id="body-content" style={{ overflowY: 'auto' }}>
      <Pagination
        siblingCount={0}
        count={count}
        page={page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              borderRadius: '4px', // Makes the buttons rounded
              margin: '0 3px', // Adds some space between the buttons
            }}
          />
        )}
      />
    </div>
  );
}

RoundedPagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default RoundedPagination;
