import React from 'react';
import { PropTypes } from 'prop-types';

import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { useGetPackageQuery } from 'app/api/mainApi';
import IndividualPackage from 'app/components/modals/tributes/IndividualPackage';

const IndividualPackageContainer = ({ advisor, packageId }) => {
  const {
    closeDialogAndResetTributeSlice,
    subscribeToPackageClick,
  } = useRecurringTributeManagement({ advisor });
  const { data: advisorPackage, isLoading: advisorPackageLoading } = useGetPackageQuery({ id: packageId });

  if (advisorPackageLoading) return (<div>Loading...</div>);

  return (
    <IndividualPackage
      closeDialog={closeDialogAndResetTributeSlice}
      advisor={advisor}
      advisorPackage={advisorPackage}
      subscribeToPackageClick={subscribeToPackageClick}
    />
  );
};

IndividualPackageContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
  packageId: PropTypes.number.isRequired,
};

export default IndividualPackageContainer;
