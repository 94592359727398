import React from 'react';
import PostTypeTooltip from 'app/components/tooltips/PostTypeTooltip';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
import GroupPreexisting from '../components/customSvgs/GroupPreexisting';

export const deprecatedPostTypeLabel = (post) => {
  switch (post.post_type) {
  case 'free':
    return 'Free';
  case 'subscriber':
    return (
      <>
        <img src="/plus_frontend/assets/svg/ico-lock2.svg" alt="Locked" />
        &nbsp;Preexisting Subscribers
      </>
    );
  case 'welcome_kit':
    return (
      <>
        <img src="/plus_frontend/assets/svg/ico-present.svg" alt="All Subscribers" />
        &nbsp;All Subscribers
      </>
    );
  default:
    return 'Post';
  }
};

export const postTypeLabel = (post) => {
  switch (post.post_type) {
  case 'free':
    return (
      <PostTypeTooltip
        postType="Free Post"
        icon={(
          <PublicIcon style={{ width: '20px', margin: '0 0 -7px 4px', color: '#222', cursor: 'pointer' }} />
        )}
      />
    );
  case 'subscriber':
    return (
      <PostTypeTooltip
        postType="Preexisting Subscribers"
        icon={(
          <GroupPreexisting style={{ width: '20px', margin: '0 0 -7px 4px', cursor: 'pointer' }} />
        )}
      />
    );
  case 'welcome_kit':
    return (
      <PostTypeTooltip
        postType="All Subscribers"
        icon={(
          <GroupIcon style={{ width: '20px', margin: '0 0 -7px 4px', color: '#222', cursor: 'pointer' }} />
        )}
      />
    );
  default:
    return 'Post';
  }
};
