import React from 'react';
import { LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import zxcvbn from 'zxcvbn';

const minLength = 12;
const getPasswordStrength = (password) => {
  const result = zxcvbn(password);
  const { score } = result;
  let progressBarColor;
  let textColor;
  let description;

  if (password.length === 0) {
    progressBarColor = '#eee'; // Light gray for blank progress bar
    textColor = '#333'; // Default text color for blank password
    description = '';
  } else {
    switch (score) {
    case 0:
      progressBarColor = '#d32f2f'; // Red for very weak password
      textColor = '#d32f2f'; // Red for very weak password
      description = 'Very Weak';
      break;
    case 1:
      progressBarColor = '#fca02b'; // Orange for weak password
      textColor = '#fb8c00'; // Orange for weak password
      description = 'Weak';
      break;
    case 2:
      progressBarColor = '#fdd835'; // Yellow for medium password
      textColor = '#fbc02d'; // Yellow for medium password
      description = 'Medium';
      break;
    case 3:
      progressBarColor = '#8bc34a'; // Light green for strong password
      textColor = '#7cb342'; // Light green for strong password
      description = 'Strong';
      break;
    case 4:
      progressBarColor = '#4caf50'; // Green for very strong password
      textColor = '#37973b'; // Green for very strong password
      description = 'Very Strong';
      break;
    default:
      progressBarColor = '#eee'; // Default color for any unforeseen cases
      textColor = '#333'; // Default text color for any unforeseen cases
      description = 'Unknown';
    }
  }

  return { progressBarColor, textColor, description };
};

const PasswordMeter = ({ password }) => {
  const { length } = password;
  const { progressBarColor, textColor, description } = getPasswordStrength(password);

  return (
    <>
      <LinearProgress
        variant="determinate"
        size="small"
        value={Math.min((length * 100) / minLength, 100)}
        sx={{
          bgcolor: '#eee', // Light gray background for progress bar
          '& .MuiLinearProgress-bar': {
            backgroundColor: progressBarColor, // Adjusted color based on password strength
          },
          marginTop: 1, // Add some margin above the progress bar
        }}
      />
      <Typography
        variant="body2"
        sx={{ alignSelf: 'flex-end', color: textColor, marginTop: 0.5 }} // Add some margin above the text
      >
        {description}
      </Typography>
    </>
  );
};

export default PasswordMeter;
