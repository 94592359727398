import React, { useEffect } from 'react';

const PageBackground = ({ backgroundColor = 'white', children }) => {
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = backgroundColor;

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, [backgroundColor]);

  return children;
};

export default PageBackground;
