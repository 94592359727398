import React from 'react';
import Select from 'react-select';
import { PropTypes } from 'prop-types';

const customStyles = {
  control: (base, state) => ({
    ...base,
    '&:hover': {
      borderColor: '#999',
      '& svg': { color: '#999' },
    }, // border style on hover
    boxShadow: 'none', // no box-shadow
    borderColor: state.isFocused ? '#999' : '#ddd',
    '& svg': { color: '#999' },
  }),
  option: (provided) => ({
    ...provided,
  }),
  menuList: base => ({
    ...base,
    maxHeight: '177px',
  }),
  singleValue: (provided) => {
    return { ...provided };
  },
  menu: styles => ({
    ...styles,
    zIndex: 999,
  }),
};

const FileManagerAlbumSelect = ({ albumsQuery, value, onChange, disabled }) => {
  if (albumsQuery.status !== 'fulfilled' || !albumsQuery.data) {
    return null;
  }

  const options = albumsQuery.data.map((el) => ({ value: el.id, label: el.title }));

  return (
    <Select
      styles={customStyles}
      components={{ IndicatorSeparator: () => null }}
      theme={theme => ({ ...theme, borderRadius: 2 })}
      options={options}
      value={value}
      isSearchable={false}
      onChange={onChange}
      className="select-dd"
      isDisabled={disabled}
    />
  );
};

FileManagerAlbumSelect.defaultProps = {
  value: null,
  disabled: false,
};

FileManagerAlbumSelect.propTypes = {
  albumsQuery: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FileManagerAlbumSelect;
