import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';

import {
  useUpdateRecurringPackageTributeMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import ConfirmTribute from 'app/components/modals/tributes/ConfirmTribute';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const ConfirmTributeContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const cardType = currentUser?.default_card?.card_type;
  const lastFour = currentUser?.default_card?.last_four;

  const {
    activePackageTribute,
    closeDialogAndResetTributeSlice,
    packageId,
    packageRate,
    showBillingError,
    setDeclined,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const [
    updateRecurringPackageTribute,
    updateRecurringPackageTributeResult,
  ] = useUpdateRecurringPackageTributeMutation();

  const confirmTributeClick = () => {
    updateRecurringPackageTribute({
      advisorId: advisor.id,
      packageId,
      precedingPackageTributeId: activePackageTribute?.id,
    });
  };

  useEffect(() => {
    if (updateRecurringPackageTributeResult.isSuccess) {
      dispatch(showSnackbarMessage({
        message: `${packageRate} has been sent to ${advisor.login}.`,
        severity: 'success',
      }));

      closeDialogAndResetTributeSlice();
    }

    if (updateRecurringPackageTributeResult.isError) {
      if (updateRecurringPackageTributeResult.error?.data?.error === 'gateway_declined') {
        dispatch(setDeclined({ declined: true }));
        showBillingError();
      }

      if (updateRecurringPackageTributeResult.error?.data?.error === 'User account blocked') {
        closeDialogAndResetTributeSlice();

        dispatch(showSnackbarMessage({
          message: updateRecurringPackageTributeResult.error?.data?.flash?.message,
          severity: 'error',
        }));
      }

      showBillingError();
    }
  }, [updateRecurringPackageTributeResult]);

  return (
    <ConfirmTribute
      amount={packageRate}
      cardType={cardType}
      lastFour={lastFour}
      isLoading={updateRecurringPackageTributeResult.isLoading}
      confirmTributeClick={confirmTributeClick}
      useDifferentCardClick={useDifferentCardClick}
      closeClick={closeDialogAndResetTributeSlice}
    />
  );
};

ConfirmTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default ConfirmTributeContainer;
