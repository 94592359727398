import React from 'react';

const PostContentPlaceholder = () => {
  return (
    <div className="panel">
      <div className="container-fluid container-post-placeholder">
        <div className="row nowrap-xs">
          <div className="loading shape1" />
          <div className="loading shape2" />
        </div>
        <div className="row">
          <div className="loading shape3" />
          <div className="loading shape4" />
        </div>
        <div className="row">
          <div className="loading shape5" />
        </div>
        <div className="row">
          <div className="loading shape6" />
        </div>
      </div>
    </div>
  );
};

export default PostContentPlaceholder;
