import React from 'react';
import PropTypes from 'prop-types';

import { useGetChatProfileQuery } from 'app/api/mainApi';

import IconLink from 'app/components/shared/IconLink';

const ChatListingCard = ({ advisor, style }) => {
  const listingUrl = `/chat/${advisor.slug}`;
  const { data: chatProfile } = useGetChatProfileQuery({ login: advisor.login });

  return (
    <div className="listing" style={style}>
      <div className="container-fluid padding-0-8" role="link" tabIndex="0">
        <div className="row">
          <div className="col-xs-4 padding10 center-xs">
            <a href={listingUrl}>
              <div className="pic">
                <img src={chatProfile?.image_url} alt="Listing Thumbnail" />
              </div>
              { advisor.available_for_chat ? (
                <div className="online-status">Online</div>
              ) : (
                <div className="offline-status">Offline</div>
              )}

            </a>
          </div>
          <div className="col-xs-8 start-xs padding10">
            <div className="container-fluid padding-0-8">
              <div className="row name">
                <a href={listingUrl}>
                  { advisor.login }
                </a>
              </div>
              <div className="row description">
                <a href={listingUrl}>
                  { chatProfile?.description }
                </a>
              </div>
              <div className="row details">
                <div className="col-xs-4 marginlftminus8" />
                <div className="col-xs-8 end-xs price">
                  $0.99 per volley.
                </div>
              </div>
              <div className="row margintop20">
                <IconLink
                  title="Chat Now"
                  href={listingUrl}
                  iconSrc="/plus_frontend/assets/btn-chatnow.svg"
                  altText="Chat Now"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChatListingCard.defaultProps = {
  advisor: {},
  style: {},
};

ChatListingCard.propTypes = {
  advisor: PropTypes.object,
  style: PropTypes.object,
};

export default ChatListingCard;
