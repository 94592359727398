import React from 'react';
import { PropTypes } from 'prop-types';
import Box from '@mui/material/Box';

const CategoryBreadcrumbs = ({ breadcrumbs }) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        padding: '20px',
        zIndex: 2,
      }}
      dangerouslySetInnerHTML={{ __html: breadcrumbs }}
    />
  );
};

CategoryBreadcrumbs.defaultProps = {
  breadcrumbs: '',
};

CategoryBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.string,
};

export default CategoryBreadcrumbs;
