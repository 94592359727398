import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';
import CallQualitySection from 'app/components/feedback/CallQualitySection';
import FeedbackSection from 'app/components/feedback/FeedbackSection';

const CALL_QUALITY_TYPES = [ 'Conference' ];

const LeaveFeedbackModalHeader = ({ feedback }) => {
  const { login, object_title: objectTitle } = feedback;
  return (
    <Typography data-test-id="leave-feedback-modal-header">
      Rate and give feedback for&nbsp;
      <strong>{`${login}'s ${objectTitle}`}</strong>
      &nbsp;listing to assist others in choosing the right Flirt. Be responsible and constructive; ratings and feedback are visible to all members and cannot be edited later,
      per our&nbsp;
      <a href="https://support.niteflirt.com/hc/en-us/articles/212830688-NiteFlirt-Rules">
        Feedback Policy.
      </a>
    </Typography>
  );
};

const LeaveFeedbackModal = ({ data, onSubmit }) => {
  const [ feedback, setFeedback ] = useState(data?.feedback);
  const [ callQualityFeedback, setCallQualityFeedback ] = useState(data?.call_quality_feedback);
  const callQualityOptions = data?.call_quality_options;
  const callFeedback = CALL_QUALITY_TYPES.includes(feedback?.object_type);
  const isFeedbackValid = !feedback || feedback.valid === null || feedback.valid === undefined || feedback.valid;
  const isCallQualityFeedbackValid = !callQualityFeedback || callQualityFeedback.error_type == null || callQualityFeedback.valid;
  const isFormValid = isFeedbackValid && isCallQualityFeedbackValid;
  const isFormChanged = feedback.changed || (callQualityFeedback && callQualityFeedback.changed);
  const submitEnabled = isFormValid && isFormChanged;

  useEffect(() => {
    setFeedback(data?.feedback);
  }, [ data ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ feedback, callQualityFeedback });
  };

  return (
    <>
      <div className="modal-header">
        <h4>Leave Feedback</h4>
      </div>
      <div className="modal-body">
        <div className="row margintop20">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LeaveFeedbackModalHeader feedback={feedback} />
              </Grid>
              <Grid item xs={12}>
                <FeedbackSection
                  feedback={feedback}
                  onFeedbackChange={setFeedback}
                />
              </Grid>
              {callFeedback && (
                <Grid item xs={12}>
                  <CallQualitySection
                    options={callQualityOptions}
                    callQualityFeedback={callQualityFeedback}
                    onChange={setCallQualityFeedback}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button type="submit" variant="contained" color="primary" disabled={!submitEnabled}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

LeaveFeedbackModal.propTypes = {
  data: PropTypes.shape({
    feedback: PropTypes.object,
    call_quality_feedback: PropTypes.object,
    call_quality_options: PropTypes.array,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default LeaveFeedbackModal;
