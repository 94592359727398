import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  clearFilters,
  setText,
  filtersSelector,
  resultCountsSelector,
} from 'app/slices/goodyBagFilterSlice';

import {
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  Button,
  Box,
} from '@mui/material';
import DialogsContext from 'app/contexts/DialogsContext';
import CloseDialogButton from 'app/components/modals/CloseDialogButton';
import SearchIcon from '@mui/icons-material/Search';
import Facets from 'app/components/goody_search/Facets';
import MultiSelectFilter from 'app/components/goody_search/MultiSelectFilter';
import ChipRadioFilter from 'app/components/goody_search/ChipRadioFilter';

const GoodyBagFilters = () => {
  const filters = useSelector(filtersSelector);
  const resultCounts = useSelector(resultCountsSelector);
  const dispatch = useDispatch();
  const { closeDialog } = useContext(DialogsContext);

  const handleTextChange = (evt) => {
    dispatch(setText(evt.target.value));
  };

  const dispatchClearFilters = () => {
    dispatch(clearFilters());
  };

  return (
    <>
      <DialogTitle>
        Filters
      </DialogTitle>

      <CloseDialogButton closeDialog={closeDialog} />

      <DialogContent>
        <TextField
          label="Find videos, photos, and other Goodies"
          variable="outlined"
          autoComplete="off"
          fullWidth
          value={filters.text}
          onChange={handleTextChange}
          InputProps={{
            endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
        />

        { Facets
          .filter((f) => f.type === 'checkbox')
          .map((f) => <MultiSelectFilter facet={f} filters={filters} resultCounts={resultCounts} key={f.id} />) }

        { Facets
          .filter((f) => f.type === 'radio')
          .map((f) => <ChipRadioFilter facet={f} filters={filters} resultCounts={resultCounts} key={f.id} />) }

        <Box display="flex" justifyContent="right" mt={2}>
          <Button variant="outlined" onClick={dispatchClearFilters} sx={{ mr: 2 }}>Reset</Button>
          <Button variant="contained" onClick={closeDialog}>Apply</Button>
        </Box>

      </DialogContent>
    </>
  );
};

export default GoodyBagFilters;
