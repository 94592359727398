import React from 'react';

const TranscodingPost = () => {
  return (
    <div>
      <div className="flux-state-overlay1">
        <h2>Not Published - Processing Media</h2>
        The media in your post is currently processing. We&apos;ll publish the post if it&apos;s approved.
      </div>
      <div className="overflow-hidden">
        <div className="slick-item-bg flux-state-item1" style={{ backgroundImage: 'url(/plus_frontend/assets/bg-blur1.png)' }}>&nbsp;</div>
      </div>
    </div>
  );
};

export default TranscodingPost;
