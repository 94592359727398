import React from 'react';
import AppBodyWithSidebar from "app/components/layout/AppBodyWithSidebar";
import TemplateShell2 from "app/components/layout/TemplateShell2";
import {Box, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
// import proptypes
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

const TaxForms = ({ tax_forms }) => {

  return (
    <AppBodyWithSidebar>
      <TemplateShell2 header="Tax Forms" goBackPath={'/transactions/payment_history'}>
        <Box px={{ xs: 0, sm: 4 }} pt={{ xs: 4, sm: 4 }}>
          <Typography>
            Flirts who are eligible to work in the US and earn $20,000.00 or more prior to 2023 from NiteFlirt in a
            calendar year will be issued a Form 1099-K and your gross earnings will be reported to the IRS. Your gross
            earnings are calculated across all of your NiteFlirt accounts that share the same tax ID. For more
            information please visit the <a target="_blank"
                                            href="https://support.niteflirt.com/hc/en-us/articles/212830828-Tax-Information#ReportedEarnings">Help
            Center</a>.
          </Typography>
          <br />
          <Typography>The available forms below start with the 2021 tax year. For years after 2021 that do not appear here, a
            Form 1099-K was not issued to you because your gross NiteFlirt earnings were less than the IRS minimum and
            your earnings were not reported to the IRS. Please contact <a
              href="mailto:support@niteflirt.com">support@niteflirt.com</a> for forms prior to 2021.
          </Typography>
        </Box>

        <Box p={4}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tax Year</TableCell>
                  <TableCell>Form</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(tax_forms).map((row) => (
                  <TableRow
                    key={row.order_year}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.order_year}
                    </TableCell>
                    <TableCell>
                      <Link to={`/account/tax_forms/${row.id}`}>{row.short_name}</Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </TemplateShell2>
    </AppBodyWithSidebar>
  )
};

// Validate props
TaxForms.propTypes = {
  tax_forms: PropTypes.array.isRequired,
};

export default TaxForms;