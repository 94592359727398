import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelizeKeys from 'app/util/camelizeKeys';
import buildTrackingString from 'app/util/buildTrackingString';

import {
  ADVISOR_TAG,
  APP_CONFIG_TAG,
  AVAILABLE_BALANCE_TAG,
  COMBINED_FEED_CONTENT_AVAILABLE_TAG,
  EXPLORE_FEED_TAG,
  FLIRT_FEED_CONTENT_AVAILABLE_TAG,
  FOLLOW_TAG,
  POST_TAG,
  PACKAGES_TAG,
} from './sharedApiTags';

import useInvalidateCacheTagsDispatcher from 'app/hooks/useInvalidateCacheTagsDispatcher';

const USER_TAG = 'User';
const ACTIVE_TRIBUTES_FOR_ADVISOR_TAG = 'ActiveTributesForAdvisor';
const ADVISOR_LISTINGS_TAG = 'AdvisorListings';
const AVAILABILITY_TAG = 'Availability';
const CHAT_PROFILE_TAG = 'ChatProfile';
const FAVORITE_LISTINGS_TAG = 'FavoriteListings';
const FEEDBACK_TAG = 'Feedback';
const GOODY_BAGS_TAG = 'GoodyBagsTag';
const GOODY_BAGS_PRESENCE_TAG = 'GoodyBagsPresenceTag';
const LISTING_TAG = 'Listing';
const PAYMENT_BUTTONS_TAG = 'PaymentButtonsTag';
const PUBLIC_PACKAGES_FOR_ADVISOR_TAG = 'ActivePackagesForAdvisor';
const RECORDED_NAME_TAG = 'RecordedName';
const SECONDARY_AUTH_TAG = 'HasSecondaryAuth';
const SOCIAL_MEDIA_CONNECTIONS_TAG = 'SocialMediaConnections';
const SOCIAL_MEDIA_EVENT_TEMPLATES_TAG = 'SocialMediaEventTemplates';
const SUBSCRIPTION_TAG = 'Subscription';

const { dispatchInvalidateCacheTagsEvent } = useInvalidateCacheTagsDispatcher();

const mainApi = createApi({
  reducerPath: 'main',

  baseQuery: fetchBaseQuery({ baseUrl: '/client_api' }),

  endpoints: (builder) => ({
    // main app endpoints
    getAccountClosure: builder.query({
      query: () => {
        return '/account/closure/new';
      },
      transformResponse: camelizeKeys,
    }),

    closeAccount: builder.mutation({
      query: () => {
        return {
          url: '/account/closure',
          method: 'POST',
        };
      },
    }),

    getAppConfig: builder.query({
      providesTags: [APP_CONFIG_TAG],
      query: () => {
        return '/app_config';
      },
    }),

    getAvailableBalance: builder.query({
      providesTags: [AVAILABLE_BALANCE_TAG],
      query: () => {
        return '/available_balance';
      },
    }),

    acceptLegalLiability: builder.mutation({
      invalidatesTags: [APP_CONFIG_TAG],
      query: () => {
        return {
          url: '/legal_liability_acceptance',
          method: 'POST',
        };
      },
    }),

    getUser: builder.query({
      providesTags: [USER_TAG],
      query: () => {
        return '/user';
      },
    }),

    getNewUser: builder.query({
      providesTags: [USER_TAG],
      query: () => {
        return '/user/new';
      },
    }),

    getPasswordReset: builder.query({
      query: ({ id, token }) => {
        return `/password_reset?id=${id}&token=${token}`;
      },
    }),

    createPasswordReset: builder.mutation({
      query: ({ email }) => {
        return {
          url: '/password_reset',
          method: 'POST',
          body: { email },
        };
      },
    }),

    updatePasswordReset: builder.mutation({
      invalidatesTags: [APP_CONFIG_TAG, USER_TAG],
      query: ({ id, token, password, password_confirmation }) => ({
        url: '/password_reset',
        method: 'PATCH',
        body: {
          id,
          token,
          user: {
            password,
            password_confirmation,
          },
        },
      }),
    }),

    performLogin: builder.mutation({
      invalidatesTags: [APP_CONFIG_TAG],
      query: ({ login, password, additionalParams = {} }) => {
        return {
          url: '/login',
          method: 'POST',
          body: { type: 'web', login, password, ...additionalParams },
        };
      },
    }),

    performPhoneLogin: builder.mutation({
      invalidatesTags: [APP_CONFIG_TAG],
      query: ({ login, password, additionalParams = {} }) => {
        return {
          url: '/login',
          method: 'POST',
          body: { type: 'phone', login, password, ...additionalParams },
        };
      },
    }),

    performForgotPinLogin: builder.mutation({
      invalidatesTags: [APP_CONFIG_TAG],
      query: ({ phoneNumber, creditCard, zip, additionalParams = {} }) => {
        return {
          url: '/login',
          method: 'POST',
          body: {
            type: 'forgot_pin',
            digits: phoneNumber,
            cc_last_6: creditCard,
            billing_zip: zip,
            ...additionalParams,
          },
        };
      },
    }),

    getUsernameAvailable: builder.query({
      query: ({ login }) => {
        return {
          url: `/username/${login}`,
          method: 'GET',
        };
      },
    }),

    updateUser: builder.mutation({
      invalidatesTags: [USER_TAG, APP_CONFIG_TAG],
      query: (user) => ({
        url: '/user',
        method: 'PATCH',
        body: { user },
      }),
    }),

    createUser: builder.mutation({
      invalidatesTags: [USER_TAG],
      query: (user) => ({
        url: '/user',
        method: 'POST',
        body: { user },
      }),
    }),

    updateSubscriptionOffering: builder.mutation({
      invalidatesTags: [SUBSCRIPTION_TAG, APP_CONFIG_TAG],
      query: ({ newRate }) => {
        return {
          url: '/subscriptions/offerings',
          method: 'POST',
          body: { rate: newRate },
        };
      },
    }),

    getAdvisor: builder.query({
      providesTags: (result) => {
        return result ?
          [{ type: ADVISOR_TAG, id: result.id }] :
          [];
      },
      query: ({ login }) => {
        return `/advisors/${login}`;
      },
    }),

    updateShowMemberSince: builder.mutation({
      invalidatesTags: (result) => {
        return result ? [{
          type: ADVISOR_TAG,
          id: result.advisorId,
        }] : [];
      },
      query: ({ advisorLogin, toggleValue }) => {
        return {
          url: `/advisors/${advisorLogin}/user_detail`,
          method: 'PUT',
          body: { user_detail: { show_member_since: toggleValue } },
        };
      },
    }),

    getAdvisors: builder.query({
      query: ({ ids }) => {
        return `/advisors?ids=${ids.join(',')}`;
      },
    }),

    searchAdvisors: builder.query({
      query: ({ searchString }) => {
        return `/advisor_search?s=${searchString}`;
      },
    }),

    getSubscription: builder.query({
      providesTags: (result, error, args) => {
        return [{ type: SUBSCRIPTION_TAG, id: `advisor-${args.advisorId}` }];
      },
      query: ({ advisorId }) => `/subscriptions/${advisorId}`,
      keepUnusedDataFor: 0,
    }),

    getSubscriptions: builder.query({
      providesTags: [SUBSCRIPTION_TAG],
      query: ({ filter }) => {
        return `/subscriptions?filter=${filter}`;
      },
    }),

    performSubscribe: builder.mutation({
      invalidatesTags: (result, error, args) => {
        if (result) {
          // cross-slice cache tag invalidation
          dispatchInvalidateCacheTagsEvent([
            POST_TAG,
            EXPLORE_FEED_TAG,
            COMBINED_FEED_CONTENT_AVAILABLE_TAG,
            { type: FLIRT_FEED_CONTENT_AVAILABLE_TAG, id: args.advisorId },
            { type: FOLLOW_TAG, id: `advisor-${result.subscription.advisor_id}` },
          ]);

          return [
            SUBSCRIPTION_TAG,
            { type: SUBSCRIPTION_TAG, id: `advisor-${args.advisorId}` },
          ];
        } else {
          return [{ type: SUBSCRIPTION_TAG, id: `advisor-${args.advisorId}` }];
        }
      },
      query: ({ advisorId, offering }) => {
        return {
          url: `/subscriptions/${advisorId}?offering_id=${offering.id}`,
          method: 'POST',
        };
      },
    }),

    performResubscribe: builder.mutation({
      invalidatesTags: (result, error, args) => {
        if (result) {
          // cross-slice cache tag invalidation
          dispatchInvalidateCacheTagsEvent([
            COMBINED_FEED_CONTENT_AVAILABLE_TAG,
            { type: FOLLOW_TAG, id: `advisor-${result.subscription.advisor_id}` },
          ]);

          return [
            SUBSCRIPTION_TAG,
            { type: SUBSCRIPTION_TAG, id: `advisor-${args.advisorId}` },
          ];
        } else {
          return [];
        }
      },
      query: ({ advisorId, subscriptionId }) => {
        return {
          url: `/subscriptions/${advisorId}/resubscribe`,
          method: 'POST',
          body: { subscription_id: subscriptionId },
        };
      },
    }),

    performUnsubscribe: builder.mutation({
      invalidatesTags: [SUBSCRIPTION_TAG],
      query: ({ advisorId }) => ({
        url: `/subscriptions/${advisorId}`,
        method: 'DELETE',
      }),
    }),

    performApprovePriceChange: builder.mutation({
      invalidatesTags: [SUBSCRIPTION_TAG],
      query: ({ advisorId, subscriptionId }) => ({
        url: `/subscriptions/${advisorId}/approve`,
        method: 'POST',
        body: { subscription_id: subscriptionId },
      }),
    }),

    createBonus: builder.mutation({
      query: ({ amount, message, listingId, fromNfChat }) => {
        return {
          url: '/bonuses',
          method: 'POST',
          body: {
            amount_text: amount,
            listing_id: listingId,
            bonus_message: message,
            from_nf_chat: fromNfChat,
          },
        };
      },
    }),

    getPaymentButtons: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [PAYMENT_BUTTONS_TAG] : [];
      },
      query: () => {
        return '/payment_buttons';
      },
    }),

    performReportContent: builder.mutation({
      query: ({ contentId, contentType, body }) => ({
        method: 'POST',
        url: '/report_contents',
        body: {
          report_content: {
            content_id: contentId,
            content_type: contentType,
            description: body,
          },
        },
      }),
    }),

    getChatProfile: builder.query({
      providesTags: (_result, _error, { login }) => {
        return ([{ type: CHAT_PROFILE_TAG, login: login.toLowerCase() }]);
      },
      query: ({ login }) => (`advisors/${login}/chat_profile`),
    }),

    getSocialMediaConnections: builder.query({
      providesTags: [SOCIAL_MEDIA_CONNECTIONS_TAG],
      query: () => 'social_media/connections',
    }),

    destroySocialMediaConnection: builder.mutation({
      invalidatesTags: [SOCIAL_MEDIA_CONNECTIONS_TAG, SOCIAL_MEDIA_EVENT_TEMPLATES_TAG],
      query: ({ id }) => ({
        url: `/social_media/connections/${id}`,
        method: 'DELETE',
      }),
    }),

    getSocialMediaEventTemplates: builder.query({
      providesTags: () => {
        return [{ type: SOCIAL_MEDIA_EVENT_TEMPLATES_TAG }];
      },
      query: () => '/social_media/event_templates',
    }),

    updateSocialMediaEventTemplates: builder.mutation({
      invalidatesTags: (result, error, _args) => {
        return result && !error ? [SOCIAL_MEDIA_EVENT_TEMPLATES_TAG] : [];
      },
      query: (userSocialMediaEventTemplatesData) => ({
        url: '/social_media/event_templates',
        method: 'PATCH',
        body: userSocialMediaEventTemplatesData,
      }),
    }),

    createSocialMediaManualShout: builder.mutation({
      query: ({ manualShout }) => ({
        url: '/social_media/manual_shouts',
        method: 'POST',
        body: manualShout,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),

    getUnreadMailCount: builder.query({
      query: () => 'unread_mail_count',
    }),

    updateAvailability: builder.mutation({
      invalidatesTags: [AVAILABILITY_TAG, APP_CONFIG_TAG],
      query: ({ availability, silent }) => ({
        url: '/availabilities',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          silent,
          // TODO: Move this into a utility function
          availability: Object.entries(availability).reduce((acc, [key, value]) => {
            const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
            if (value !== undefined) {
              acc[snakeCaseKey] = value;
            }
            return acc;
          }, {}),
        },
      }),
    }),

    getAvailability: builder.query({
      providesTags: [AVAILABILITY_TAG],
      query: () => '/availabilities',
    }),

    createPhoneVerification: builder.mutation({
      query: ({ phoneId }) => ({
        url: `/phone/${phoneId}/phone_verifications`,
        method: 'POST',
        body: '',
      }),
    }),

    verifyPhone: builder.mutation({
      invalidatesTags: [AVAILABILITY_TAG],
      query: ({ phoneId, code }) => ({
        url: `/phone/${phoneId}/phone_verifications/${code}`,
        method: 'PATCH',
        body: '',
      }),
    }),

    getCountries: builder.query({
      query: () => '/countries',
    }),

    getPhones: builder.query({
      query: () => '/phone',
    }),

    createPhone: builder.mutation({
      invalidatesTags: [AVAILABILITY_TAG],
      query: ({ digits, phoneType }) => ({
        url: '/phone',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { digits, phone_type: phoneType },
      }),
    }),

    deletePhone: builder.mutation({
      invalidatesTags: [AVAILABILITY_TAG, APP_CONFIG_TAG],
      query: (id) => ({
        url: `/phone/${id}`,
        method: 'DELETE',
      }),
    }),

    getAdvisorListings: builder.query({
      providesTags: (_result, _error, { login }) => {
        return ([{ type: ADVISOR_LISTINGS_TAG, id: login.toLowerCase() }]);
      },
      query: ({ login }) => (`advisors/${login}/listings`),
    }),

    getTaxForms: builder.query({
      query: () => '/account/tax_forms',
    }),

    getTaxForm: builder.query({
      query: (id) => `/account/tax_forms/${id}`,
    }),

    getListing: builder.query({
      providesTags: (result, _error, { id }) => {
        return ([
          { type: LISTING_TAG, id },
          { type: ADVISOR_LISTINGS_TAG, id: result.login.toLowerCase() },
        ]);
      },
      query: ({ id }) => ({
        url: `/listings/${id}`,
      }),
    }),

    getSecondaryAuth: builder.query({
      providesTags: [SECONDARY_AUTH_TAG],
      query: () => 'secondary_auth',
    }),

    createSecondaryAuth: builder.mutation({
      invalidatesTags: [SECONDARY_AUTH_TAG, AVAILABILITY_TAG],
      query: ({ password }) => ({
        url: 'secondary_auth.json',
        body: { password },
        method: 'POST',
      }),
    }),

    getFavoriteListings: builder.query({
      query: ({ sort, page, perPage }) => ({
        url: `/favorite_listings?sort=${sort || '1'}&page=${page || 1}&per_page=${perPage || 20}`,
      }),
      providesTags: (_result, _error, { page }) => [{ type: FAVORITE_LISTINGS_TAG, id: page }],
    }),

    favoriteListing: builder.mutation({
      invalidatesTags: (_result, _response, args) => (
        [{
          type: LISTING_TAG,
          id: args.listing,
        }]
      ),
      query: ({ listing }) => ({
        url: '/favorite_listings',
        params: { listing_id: listing },
        method: 'POST',
      }),
    }),

    updateFavoriteListing: builder.mutation({
      query: ({ id, position }) => ({
        url: `/favorite_listings/${id}`,
        method: 'PATCH',
        body: { position },
      }),
      invalidatesTags: [FAVORITE_LISTINGS_TAG],
    }),

    removeFavoriteListings: builder.mutation({
      query: ({ listings }) => ({
        url: '/favorite_listings/bulk_destroy',
        method: 'DELETE',
        body: { listing_ids: listings },
      }),
      invalidatesTags: (_result, _error, { listings }) => [
        ...listings.map(listingId => ({ type: LISTING_TAG, id: listingId })),
        FAVORITE_LISTINGS_TAG,
      ],
    }),

    advisorHomepage: builder.query({
      query: ({ login }) => `advisors/${login}/homepage`,
    }),

    getAdvisorFeedbacks: builder.query({
      query: ({ page, perPage }) => ({
        url: `/account/feedbacks.json?page=${page || 1}&per_page=${perPage || 40}`,
      }),
      keepUnusedDataFor: 0,
    }),

    // these are pending seeker feedbacks not the same as the NewFeedbacks
    getFeedbacks: builder.query({
      providesTags: [FEEDBACK_TAG],
      query: () => ({
        url: '/feedbacks.json',
      }),
    }),

    getNewFeedback: builder.query({
      query: ({ objectType, objectId }) => {
        if (objectType == null || objectId == null) {
          throw new Error('getNewFeedback query called with undefined or null parameters');
        }
        return {
          url: `/feedbacks/new.json?object_type=${objectType}&object_id=${objectId}`,
        };
      },
      providesTags: (_result, _error, { objectType, objectId }) => [{ type: FEEDBACK_TAG, id: `${objectType}-${objectId}` }],
    }),

    upsertFeedback: builder.mutation({
      query: ({ id, feedbackAndCallQualityData }) => {
        return {
          url: `/feedbacks/${id}`,
          method: id ? 'PATCH' : 'POST',
          body: feedbackAndCallQualityData,
        };
      },
      invalidatesTags: (result, error, { feedbackAndCallQualityData }) => {
        const objectType = feedbackAndCallQualityData.feedback.object_type;
        const objectId = feedbackAndCallQualityData.feedback.object_id;
        return [{ type: FEEDBACK_TAG, id: `${objectType}-${objectId}` }, { type: FEEDBACK_TAG }];
      },
    }),

    getFileManagerToken: builder.query({
      query: () => 'file_manager_token',
    }),

    getPubNubConfig: builder.query({
      query: () => 'pubnub_config',
    }),

    getRecordedName: builder.query({
      providesTags: [RECORDED_NAME_TAG],
      query: () => 'recorded_name',
    }),

    uploadRecordedName: builder.mutation({
      invalidatesTags: [RECORDED_NAME_TAG],
      query: (formData) => ({
        url: 'recorded_name',
        method: 'POST',
        body: formData,
        formData: true,
      }),
    }),

    createRecurringMonetaryTribute: builder.mutation({
      invalidatesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorId, rate, message, fromNfChat }) => ({
        url: '/recurring_tributes/monetary_tributes',
        method: 'POST',
        body: {
          recurring_monetary_tribute: {
            advisor_id: advisorId,
            message,
            rate,
            from_nf_chat: fromNfChat,
          },
        },
      }),
    }),

    updateRecurringMonetaryTribute: builder.mutation({
      invalidatesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorId, rate, message, parentMonetaryTributeId, fromNfChat }) => ({
        url: '/recurring_tributes/monetary_tributes',
        method: 'PUT',
        body: {
          recurring_monetary_tribute: {
            rate,
            message,
            advisor_id: advisorId,
            parent_monetary_tribute_id: parentMonetaryTributeId,
            from_nf_chat: fromNfChat,
          },
        },
      }),
    }),

    destroyRecurringMonetaryTribute: builder.mutation({
      invalidatesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorId }) => ({
        url: '/recurring_tributes/monetary_tributes',
        method: 'DELETE',
        body: {
          recurring_monetary_tribute: {
            advisor_id: advisorId,
          },
        },
      }),
    }),

    getActiveTributesForAdvisor: builder.query({
      providesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorLogin }) => ({
        url: '/recurring_tributes/active_tributes_for_advisor',
        method: 'POST',
        body: {
          advisor_login: advisorLogin,
        },
      }),
      transformResponse: camelizeKeys,
    }),

    getMyPackages: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [PACKAGES_TAG] : [];
      },
      query: () => ('recurring_tributes/packages'),
      transformResponse: camelizeKeys,
    }),

    getPackage: builder.query({
      query: ({ id }) => ({
        url: `/recurring_tributes/packages/${id}`,
      }),
      transformResponse: camelizeKeys,
    }),

    getPublicPackagesForAdvisor: builder.query({
      providesTags: [PUBLIC_PACKAGES_FOR_ADVISOR_TAG],
      query: ({ advisorId }) => ({
        url: 'recurring_tributes/public_packages_for_advisor',
        method: 'POST',
        body: {
          advisor_id: advisorId,
        },
      }),
      transformResponse: camelizeKeys,
    }),

    getGoodyBagsForCurrentUser: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [GOODY_BAGS_TAG] : [];
      },
      query: () => {
        return '/goody_bags';
      },
    }),

    getGoodyBagsPresence: builder.query({
      providesTags: (result, _error, _args) => {
        return result ? [GOODY_BAGS_PRESENCE_TAG] : [];
      },
      query: ({ login }) => {
        return `/advisors/${login}/goody_bags_presence`;
      },
    }),

    createRecurringPackageTribute: builder.mutation({
      invalidatesTags: (result, error, args) => {
        if (result) {
          // cross-slice cache tag invalidation
          dispatchInvalidateCacheTagsEvent([
            ACTIVE_TRIBUTES_FOR_ADVISOR_TAG,
            POST_TAG,
            EXPLORE_FEED_TAG,
            COMBINED_FEED_CONTENT_AVAILABLE_TAG,
            { type: FLIRT_FEED_CONTENT_AVAILABLE_TAG, id: args.advisorId },
            { type: FOLLOW_TAG, id: `advisor-${args.advisor_id}` },
          ]);
        }
      },

      query: ({ advisorId, packageId }) => ({
        url: '/recurring_tributes/package_tributes',
        method: 'POST',
        body: {
          recurring_package_tribute: {
            advisor_id: advisorId,
            package_id: packageId,
          },
        },
      }),
    }),

    updateRecurringPackageTribute: builder.mutation({
      invalidatesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorId, packageId, precedingPackageTributeId, action }) => ({
        url: '/recurring_tributes/package_tributes',
        method: 'PUT',
        body: {
          recurring_package_tribute: {
            advisor_id: advisorId,
            package_id: packageId,
            preceding_package_tribute_id: precedingPackageTributeId,
            action,
          },
        },
      }),
    }),

    destroyRecurringPackageTribute: builder.mutation({
      invalidatesTags: [ACTIVE_TRIBUTES_FOR_ADVISOR_TAG],
      query: ({ advisorId }) => ({
        url: '/recurring_tributes/package_tributes',
        method: 'DELETE',
        body: {
          recurring_package_tribute: {
            advisor_id: advisorId,
          },
        },
      }),
    }),

    createPackage: builder.mutation({
      invalidatesTags: [PACKAGES_TAG, APP_CONFIG_TAG],
      query: ({
        status,
        rate,
        feedAccess,
        freeMinutes,
        freeVolleys,
        callsPerSessionAmount,
        callsLimitByPeriod,
        callsPerPeriodAmount,
        volleysPerPeriodAmount,
      }) => {
        return {
          url: '/recurring_tributes/packages',
          method: 'POST',
          body: {
            rate,
            status,
            feed_access: feedAccess,
            free_minutes: freeMinutes,
            free_volleys: freeVolleys,
            calls_per_session_amount: callsPerSessionAmount,
            calls_limit_by_period: callsLimitByPeriod,
            calls_per_period_amount: callsPerPeriodAmount,
            volleys_per_period_amount: volleysPerPeriodAmount,
          },
        };
      },
    }),

    updatePackage: builder.mutation({
      invalidatesTags: [PACKAGES_TAG],
      query: ({ id, status }) => {
        return {
          url: `/recurring_tributes/packages/${id}`,
          method: 'PATCH',
          body: { id, status },
        };
      },
    }),

    destroyPackage: builder.mutation({
      invalidatesTags: [PACKAGES_TAG],
      query: ({ id }) => {
        return {
          url: `/recurring_tributes/packages/${id}`,
          method: 'DELETE',
        };
      },
    }),

    getHomepageListings: builder.query({
      query: ({ page, perPage }) => `/homepage_listings?page=${page}&per_page=${perPage}`,
      // we can't do this until the card components support camelCase
      // transformResponse: (response) => camelizeKeys(response),
    }),

    getCategoryListings: builder.query({
      query: ({ category, page, listingType, sort, perPage, decisionScreen }) => {
        let url = `/categories/${category}?page=${page}&per_page=${perPage}&type=${listingType}&sort=${sort}`;
        if (decisionScreen) { url = `${url}&decision_screen=${decisionScreen}`; }
        return url;
      },
      // we can't do this until the card components support camelCase
      // transformResponse: (response) => camelizeKeys(response),
    }),

    getCallListing: builder.query({
      query: ({ id }) => `/call_listings/${id}`,
    }),

    getTopLevelCategories: builder.query({
      query: () => '/top_level_categories',
    }),

    getListingSearchResults: builder.query({
      query: ({
        searchQueryText = null,
        advancedSearch = null,
        filters = [],
        categoryId = null,
        limitToAvailable = null,
        sort = null,
        type = null,
        page = 0,
      }) => {
        if (limitToAvailable === 'true') {
          filters.push('available');
        }

        const cleanParams = (params) => {
          return Object.fromEntries(
            Object.entries(params).filter(
              ([_, value]) => value !== null &&
                value !== undefined &&
                !(Array.isArray(value) && value.length === 0),
            ),
          );
        };

        const params = cleanParams({
          text: searchQueryText,
          category_id: categoryId,
          advanced_search: advancedSearch,
          'filters[]': filters,
          limit_to_available: limitToAvailable,
          sort,
          type,
          page,
        });

        const trackingString = buildTrackingString(params);

        // Manually serialize the query parameters
        const urlParams = new URLSearchParams();

        // Append each parameter to URLSearchParams
        Object.entries({
          ...params,
          s: trackingString,
        }).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => urlParams.append(key, v));
          } else {
            urlParams.append(key, value);
          }
        });

        const queryString = urlParams.toString();
        const finalUrl = `/search?${queryString}`;

        return {
          url: finalUrl,
        };
      },
    }),

    getCallSystemStatus: builder.query({
      query: () => '/call_system_status',
    }),

    getCallStatus: builder.query({
      query: (params) => ({
        // NOTE: We send a timestamp over to prevent caching in this case.
        url: `/listings/${params.listingId}/calls/${params.ngtoken}?fake_status=${params.fakeStatus}&call_type=${params.callType}&timestamp=${params.timestamp}`,
        method: 'GET',
      }),
      transformResponse: camelizeKeys,
    }),

    createCall: builder.mutation({
      query: (body) => ({
        url: `/listings/${body.listingId}/calls`,
        method: 'POST',
        body: {
          listing_id: body.listingId,
          data: body.data,
          signal: body.signal,
          selected_phone: body.selectedPhone,
          fake_status: body.fakeStatus,
          call_type: body.callType,
        },
        transformResponse: camelizeKeys,
      }),
    }),

    verifyAccount: builder.mutation({
      query: ({ data, type }) => {
        return {
          url: '/account/verifications',
          method: 'POST',
          body: { data, type },
        };
      },
    }),
  }),
});

export default mainApi;

export const {
  useAcceptLegalLiabilityMutation,
  useAdvisorHomepageQuery,
  useCloseAccountMutation,
  useCreateBonusMutation,
  useCreateCallMutation,
  useCreatePackageMutation,
  useCreatePhoneMutation,
  useCreatePhoneVerificationMutation,
  useCreateRecurringMonetaryTributeMutation,
  useCreateRecurringPackageTributeMutation,
  useCreateSecondaryAuthMutation,
  useCreateSocialMediaManualShoutMutation,
  useCreateUserMutation,
  useDeletePhoneMutation,
  useDestroyPackageMutation,
  useDestroyRecurringMonetaryTributeMutation,
  useDestroyRecurringPackageTributeMutation,
  useDestroySocialMediaConnectionMutation,
  useFavoriteListingMutation,
  useGetAccountClosureQuery,
  useGetActiveTributesForAdvisorQuery,
  useGetAdvisorFeedbacksQuery,
  useGetAdvisorListingsQuery,
  useGetAdvisorQuery,
  useGetAdvisorsQuery,
  useGetAppConfigQuery,
  useGetAvailabilityQuery,
  useGetAvailableBalanceQuery,
  useGetCategoryListingsQuery,
  useGetCallListingQuery,
  useGetCallSystemStatusQuery,
  useGetChatProfileQuery,
  useGetCountriesQuery,
  useGetFavoriteListingsQuery,
  useGetFeedbacksQuery,
  useGetFileManagerTokenQuery,
  useGetGoodyBagsForCurrentUserQuery,
  useGetGoodyBagsPresenceQuery,
  useGetHomepageListingsQuery,
  useGetListingQuery,
  useGetMyPackagesQuery,
  useGetNewFeedbackQuery,
  useGetNewUserQuery,
  useGetPackageQuery,
  useGetPasswordResetQuery,
  useGetPaymentButtonsQuery,
  useGetPhonesQuery,
  useGetPubNubConfigQuery,
  useGetPublicPackagesForAdvisorQuery,
  useGetRecordedNameQuery,
  useGetSecondaryAuthQuery,
  useGetSocialMediaConnectionsQuery,
  useGetSocialMediaEventTemplatesQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionsQuery,
  useGetTaxFormQuery,
  useGetTaxFormsQuery,
  useGetTopLevelCategoriesQuery,
  useGetUnreadMailCountQuery,
  useGetUserQuery,
  useLazyGetCallStatusQuery,
  useLazyGetGoodyBagsPresenceQuery,
  useLazyGetListingSearchResultsQuery,
  useLazyGetUsernameAvailableQuery,
  usePerformApprovePriceChangeMutation,
  usePerformForgotPinLoginMutation,
  usePerformLoginMutation,
  usePerformPhoneLoginMutation,
  usePerformReportContentMutation,
  usePerformResubscribeMutation,
  usePerformSubscribeMutation,
  usePerformUnsubscribeMutation,
  useRemoveFavoriteListingsMutation,
  useSearchAdvisorsQuery,
  useUpdateAvailabilityMutation,
  useUpdateFavoriteListingMutation,
  useUpdatePackageMutation,
  useCreatePasswordResetMutation,
  useUpdatePasswordResetMutation,
  useUpdateRecurringMonetaryTributeMutation,
  useUpdateRecurringPackageTributeMutation,
  useUpdateShowMemberSinceMutation,
  useUpdateSocialMediaEventTemplatesMutation,
  useUpdateSubscriptionOfferingMutation,
  useUpdateUserMutation,
  useUploadRecordedNameMutation,
  useUpsertFeedbackMutation,
  useVerifyPhoneMutation,
  useVerifyAccountMutation,
} = mainApi;

export { ADVISOR_LISTINGS_TAG, LISTING_TAG, AVAILABILITY_TAG };
