import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMatch } from 'react-router-dom';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import PostsQueueTabs from 'app/components/nav/PostsQueueTabs';
import BackArrow from 'app/components/shared/BackArrow';
import SearchControlsContainer from 'app/containers/SearchControlsContainer';
import SearchToggle from 'app/components/SearchToggle';
import SearchContainer from 'app/containers/SearchContainer';
import ScheduledPostsContainer from './posts_queue/ScheduledPostsContainer';
import ProcessingPostsContainer from './posts_queue/ProcessingPostsContainer';
import RemovedPostsContainer from './posts_queue/RemovedPostsContainer';
import { SCHEDULED_TAB, PROCESSING_TAB, REMOVED_TAB } from 'app/constants/tabNames';

const PostsQueueContainer = () => {
  const { tabName, login: advisorLogin } = useParams();
  const currentTab = tabName;
  const navigate = useNavigate();
  const viewingScheduled = useMatch(`/${advisorLogin}/feed/queue/scheduled`);
  const viewingProcessing = useMatch(`/${advisorLogin}/feed/queue/processing`);
  const viewingRejected = useMatch(`/${advisorLogin}/feed/queue/removed`);
  const inStorefront = viewingScheduled || viewingProcessing || viewingRejected;

  const setCurrentTab = (tabName) => {
    if (inStorefront) {
      navigate(`/${advisorLogin}/feed/queue/${tabName}`);
    } else {
      navigate(`/feed/queue/${tabName}`);
    }
  };

  return (
    <AppBodyWithSidebar>
      {!inStorefront && (
        <SearchContainer>
          <div className="container-hdr-sticky2">
            <div className="row middle-xs start-xs nowrap-xs">
              <div className="col-xs-10">
                <BackArrow />
                <h2>Posts Queue</h2>
              </div>
              <SearchToggle />
            </div>
          </div>
          <SearchControlsContainer />
        </SearchContainer>
      )}
      <PostsQueueTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />

      {currentTab === SCHEDULED_TAB && (
        <ScheduledPostsContainer />
      )}

      {currentTab === PROCESSING_TAB && (
        <ProcessingPostsContainer />
      )}

      {currentTab === REMOVED_TAB && (
        <RemovedPostsContainer />
      )}
    </AppBodyWithSidebar>
  );
};

export default PostsQueueContainer;
