import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showFlash } from 'app/slices/flashSlice';
import { errorMessageFromObject } from 'app/helpers/apiMessageHelpers';
import { usePerformDeletePostMutation } from 'app/api/feedsApi';

const useDeletePost = () => {
  const dispatch = useDispatch();
  const [
    performDeletePost,
    {
      isSuccess: isDeletionSuccess,
      isError: isDeletionError,
      error: postDeleteError,
    },
  ] = usePerformDeletePostMutation();

  useEffect(() => {
    if (isDeletionSuccess || isDeletionError) {
      const message = isDeletionSuccess ?
        'Post successfully deleted.' :
        errorMessageFromObject(postDeleteError, 'Error while trying to delete the post.');

      const flashType = isDeletionError ? 'error' : 'success';

      dispatch(showFlash({
        flashType,
        props: { message },
      }));
    }
  }, [isDeletionSuccess, isDeletionError]);

  return {
    deletePost: performDeletePost,
  };
};

export default useDeletePost;
