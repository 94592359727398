import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const IconSocialTumblr = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SvgIcon {...props} width="32px" height="32px" viewBox="0 0 32 32">
    {/* Your SVG Path */}
    <g id="icons2" stroke="none" strokeWidth="1" fill="none">
      <g id="ico-tumblr" fillRule="nonzero">
        <path d="M16,0 C7.1625,0 0,7.1625 0,16 C0,24.8375 7.1625,32 16,32 C24.8375,32 32,24.8375 32,16 C32,7.1625 24.8375,0 16,0 Z" id="Path" fill="#000000" />
        <path d="M21.7460938,24.0164063 L21.7320313,24.0164063 C20.9585938,24.375 20.2695313,24.6351563 19.64375,24.7757813 C19.0179688,24.9234375 18.3429688,24.99375 17.61875,24.99375 C16.7960938,24.99375 16.0648438,24.8882813 15.4390625,24.684375 C14.80625,24.4734375 14.2648438,24.178125 13.821875,23.7984375 C13.371875,23.4117188 13.0625,23.0039063 12.89375,22.5679688 C12.7179688,22.1320313 12.6335938,21.50625 12.6335938,20.6835938 L12.6335938,14.3695313 L10.64375,14.3695313 L10.64375,11.8242188 C11.3539063,11.5921875 11.9585938,11.2617188 12.4648438,10.8328125 C12.9710938,10.4039063 13.371875,9.890625 13.68125,9.2859375 C13.9835938,8.68828125 14.1945313,7.921875 14.3140625,6.99375 L16.8734375,6.99375 L16.8734375,11.5429688 L21.1414063,11.5429688 L21.1414063,14.3695313 L16.8734375,14.3695313 L16.8734375,18.9820312 C16.8734375,20.0296875 16.9296875,20.6976562 17.0421875,20.9929687 C17.1546875,21.2882812 17.3585938,21.5273438 17.6609375,21.703125 C18.0546875,21.9421875 18.5117188,22.0617188 19.025,22.0617188 C19.9390625,22.0617188 20.8390625,21.7664063 21.7460938,21.1757812 L21.7460938,24.0164063 L21.7460938,24.0164063 Z" id="Path" fill="#FFFFFF" />
      </g>
    </g>
  </SvgIcon>
);

export default IconSocialTumblr;
