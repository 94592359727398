import { useState, useEffect, useContext } from 'react';

import NetworkStatus from 'app/components/dialogs/NetworkStatus';
import DialogsContext from 'app/contexts/DialogsContext';

const NetworkStatusContainer = () => {
  const { openDialog, closeDialog } = useContext(DialogsContext);
  const [dataUrl, setDataUrl] = useState();

  window.addEventListener('offline', () => {
    openDialog({
      component: NetworkStatus,
      props: {
        closeDialog,
        dataUrl,
      },
    });
  });

  window.addEventListener('online', () => {
    closeDialog();
  });

  // we need to preload the image if we
  // want it to show when we're offline!
  useEffect(() => {
    const toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));

    toDataURL('/plus_frontend/assets/svg/niteflirt-logo.svg').then(dataUrl => {
      setDataUrl(dataUrl);
    });
  }, []);

  return null;
};

export default NetworkStatusContainer;
