import React from 'react';
import { PropTypes } from 'prop-types';

import useCreditCardManagement from 'app/hooks/useCreditCardManagement';
import { userHasCard } from 'app/helpers/userHelper';

const AskToAddMoneyModal = ({
  goodyBag,
  currentUser,
  openModal,
  closeModal,
  availableBalance,
  onAddToBalanceSuccess,
}) => {
  let openAddToBalanceModalWithDeficitAlias = null;
  const currentUserHasCard = userHasCard(currentUser);
  const onTryAgainSuccess = () => {};
  const onManageCardsSuccess = () => {
    openAddToBalanceModalWithDeficitAlias();
  };
  const {
    openManageCardsModal,
    openAddToBalanceModal,
  } = useCreditCardManagement({
    openModal,
    currentUser,
    guard: null,
    setGuard: null,
    onTryAgainSuccess,
    onManageCardsSuccess,
    onAddToBalanceSuccess,
  });

  const openAddToBalanceModalWithDeficit = () => {
    const deficit = (goodyBag.price - availableBalance).toFixed(2);
    openAddToBalanceModal(deficit, 'goody-bag', 'goody bag');
  };

  openAddToBalanceModalWithDeficitAlias = openAddToBalanceModalWithDeficit;

  const resolveMoneyManagementFlow = () => {
    closeModal();

    if (currentUserHasCard) {
      openAddToBalanceModalWithDeficit();
    } else {
      openManageCardsModal();
    }
  };

  const onAddMoneyClick = () => {
    resolveMoneyManagementFlow();
  };

  return (
    <div>
      <div className="modal-header">
        <h4>Add Money to your account</h4>
      </div>
      <div className="modal-body">
        <div>
          Sorry, you don&apos;t have enough funds in your account. Please add to your account to complete this purchase.
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
        <button
          type="button"
          className="btn"
          onClick={onAddMoneyClick}
        >
          Add Money
        </button>
      </div>
    </div>
  );
};

AskToAddMoneyModal.defaultProps = {
  currentUser: {},
};

AskToAddMoneyModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  goodyBag: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  availableBalance: PropTypes.number.isRequired,
  onAddToBalanceSuccess: PropTypes.func.isRequired,
};

export default AskToAddMoneyModal;
