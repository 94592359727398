import React from 'react';
import { PropTypes } from 'prop-types';

const GoodyBagPurchaseBlocked = ({ closeModal }) => {
  return (
    <div>
      <div className="modal-header">
        <h4>Purchase Failed</h4>
      </div>

      <div className="modal-body">
        <div>
          We&apos;re sorry, you cannot purchase this Goody Bag because you have been blocked by this Flirt.
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn"
          onClick={closeModal}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

GoodyBagPurchaseBlocked.defaultProps = {
};

GoodyBagPurchaseBlocked.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default GoodyBagPurchaseBlocked;
