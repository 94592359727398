import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { useGetAppConfigQuery } from 'app/api/mainApi';

const AddToBalanceModalContainer = ({ closeModal, afterCloseAction, deficit, context, assetName }) => {
  const { data } = useGetAppConfigQuery();
  const txnUrl = data.txn_url;

  const onMessageReceived = (event) => {
    if (!event.data.src || event.data.src !== 'niteflirt_txn') return;

    closeModal();
    afterCloseAction();
  };

  // registers on mount and unregisters on unmount
  useEffect(() => {
    window.addEventListener('message', onMessageReceived);
    return () => { window.removeEventListener('message', onMessageReceived); };
  }, []);

  const amt = deficit ? `$${deficit}` : 'some funds';
  const flashErrorText = `We're sorry! You don't have enough money in your account. Please add ${amt} to your account to cover the ${assetName}.`;
  const modalContext = context || '';

  return (
    <div className="modal-body">
      <iframe
        id="txnIframe"
        title="Manage Credit Cards"
        style={{ width: '100%', height: '650px', border: 0 }}
        src={`${txnUrl}/embedded/purchase/new?flash_error=${flashErrorText}&context=${modalContext}`}
      />
    </div>
  );
};

AddToBalanceModalContainer.defaultProps = {
  deficit: null,
  context: null,
  assetName: 'tribute',
};

AddToBalanceModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  afterCloseAction: PropTypes.func.isRequired,
  deficit: PropTypes.number,
  context: PropTypes.string,
  assetName: PropTypes.string,
};

export default AddToBalanceModalContainer;
