import React from 'react';
import { PropTypes } from 'prop-types';
import usePostUtilities from 'app/hooks/usePostUtilities';

const LikesCommentsCountsLink = ({ post, type, currentUser }) => {
  const {
    navigateToPostComments,
    redirectToLogin,
    showPostLikes,
  } = usePostUtilities({ post });

  const isMyPost = currentUser?.id === post?.main_user_id;

  if (post[ `${type}_count` ] === 0) return null;

  let clickAction;

  if (type === 'comments') {
    clickAction = currentUser ? navigateToPostComments : redirectToLogin;
  }

  if (type === 'likes') {
    clickAction = isMyPost ? showPostLikes : null;
  }

  let label;
  if (type === 'likes') {
    label = post.likes_count === 1 ? 'Like' : 'Likes';
  } else {
    label = post.comments_count === 1 ? 'Comment' : 'Comments';
  }

  return (
    <a
      data-test-id={`${type}-count-link`}
      role="button"
      tabIndex="0"
      onClick={clickAction}
    >
      {post[ `${type}_count` ]}
      &nbsp;
      {label}
    </a>
  );
};

LikesCommentsCountsLink.defaultProps = {
  currentUser: null,
};

LikesCommentsCountsLink.propTypes = {
  post: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
};

export default LikesCommentsCountsLink;
