import React, { useContext } from 'react';
import FeedbackShape from 'app/shapes/FeedbackShape';

import { Button } from '@mui/material';

import ModalsContext from 'app/contexts/ModalsContext';
import LeaveFeedbackModalContainer from 'app/containers/modals/LeaveFeedbackModalContainer';

const LeaveFeedbackButton = ({ feedback }) => {
  const { openModal } = useContext(ModalsContext);

  const openLeaveFeedbackModalContainer = () => {
    openModal({
      component: LeaveFeedbackModalContainer,
      props: {
        objectType: feedback.object_type,
        objectId: feedback.object_id,
      },
    });
  };

  return (
    <Button
      data-test-id="leave-feedback-button"
      variant="contained"
      size="small"
      onClick={openLeaveFeedbackModalContainer}
    >
      Leave Feedback
    </Button>
  );
};

LeaveFeedbackButton.propTypes = {
  feedback: FeedbackShape.isRequired,
};

export default LeaveFeedbackButton;
