import React from 'react';
import { PropTypes } from 'prop-types';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CloseDialogButton = ({ closeDialog }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={closeDialog}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

CloseDialogButton.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default CloseDialogButton;
