import React from 'react';
import { PropTypes } from 'prop-types';

const PremierListFollowedModal = ({
  closeModal,
}) => {
  return (
    <>
      <div className="modal-header">
        <h4>Welcome to your Feed!</h4>
      </div>
      <div className="modal-body">
        <p>
          To give you a taste of what's in store, you are already following some of our most popular Flirts.
          Now follow your favorites and subscribe to them for exclusive content.
          For more information about managing your feed, check out the&nbsp;
          <a href="https://support.niteflirt.com/hc/en-us" target="_blank">
            Help Center
          </a>
        </p>
      </div>
      <div className="modal-footer">
        <a
          role="link"
          tabIndex={0}
          className="btn secondary"
          onClick={closeModal}
        >
          OK
        </a>
      </div>
    </>
  );
};

PremierListFollowedModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default PremierListFollowedModal;
