import React from 'react';

import Cookie from 'app/util/cookie';

import {
  Box,
  Grid,
  Divider,
} from '@mui/material';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import { styled } from '@mui/material/styles';

import {
  useGetAvailabilityQuery,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import AppBodyWithSidebar from 'app/components/layout/AppBodyWithSidebar';
import Phones from 'app/redesign/components/availability/Phones';
import CurrentCallPhoneStatus from 'app/redesign/components/availability/CurrentCallPhoneStatus';
import BusyStatus from 'app/redesign/components/availability/BusyStatus';
import CallMonitorToggle from 'app/redesign/components/availability/CallMonitorToggle';
import QuietHours from 'app/redesign/components/availability/QuietHours';
import ChatStatus from 'app/redesign/components/availability/ChatStatus';

const DividerAvail = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    [theme.breakpoints.up('xs')]: {
      margin: '0px',
      borderWidth: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '15px 0px 15px 0px',
      borderWidth: '0.5px',
      borderColor: '#f0f0f0',
    },
  },
}));

const CustomStyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(3, 0, 8, 0), // top, right, bottom, left
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 5, 8, 5), // top, right, bottom, left
  },
}));

const AvailabilityContainer = () => {
  const appConfig = useGetAppConfigQuery().data;
  const { current_user: currentUser } = appConfig || {};
  const redirectToLogin = () => {
    window.location.href = `/login?return_url=${encodeURIComponent('/availability')}`;
  };

  if (appConfig?.current_user === null) {
    redirectToLogin();
    return null;
  }

  // NOTE: Don't try to render if we have not done secondary auth. The backend will also protect us from malicious users.
  if (!Cookie.hasCookie('valid_secondary_auth')) {
    return null;
  }

  const { data: availability } = useGetAvailabilityQuery();
  const currentCallPhone = (availability?.phones?.find((phone) => phone.is_current_call_phone));
  const currentChatPhone = (availability?.phones?.find((phone) => phone.is_current_chat_phone)) || null;

  // The Chat Status section is always available to seekers- but advisors only see it if they have a phone number
  let bodyContent;
  if (availability && (!currentUser?.is_advisor || availability.phones.length > 0)) {
    bodyContent = (
      <>
        <Phones currentUser={currentUser} phones={availability?.phones} />
        <DividerAvail />
        {currentUser?.is_advisor && (
          <>
            <CurrentCallPhoneStatus phone={currentCallPhone} callStatuses={availability?.call_statuses} displaySecureNumber={false} />
            <Grid container sx={{ alignItems: 'center', px: { xs: 3, sm: 0 }, mt: { xs: 2, sm: 1 }, mb: { xs: 1, sm: 0 } }}>
              <Grid item sm={6} xs={12} order={{ xs: 1, sm: 2 }} sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-start' } }}>
                <BusyStatus availability={availability} />
              </Grid>
              <Grid item sm={6} xs={12} order={{ xs: 2, sm: 1 }}>
                <CallMonitorToggle availability={availability} />
              </Grid>
            </Grid>
            <DividerAvail />
          </>
        )}
        { /* As of bug ticket NF-21583 we will never be able to reach the truthy outcome of zeroStateCopy */ }
        <ChatStatus
          zeroStateCopy={
            availability.phones.length > 0 ?
              'Click the Chat icon above to select or verify a phone number. Only US/Canada phone numbers.' :
              'Never miss a message! Add a phone number to set up your Chat Alert preferences.'
          }
          phone={currentChatPhone}
          displaySecureNumber={false}
          currentUser={currentUser}
          availability={availability}
        />
        {availability.phones.length > 0 && <QuietHours availability={availability} />}
      </>
    );
  } else {
    bodyContent = <Phones currentUser={currentUser} />;
  }

  return (
    <AppBodyWithSidebar>
      <Grid container alignItems="center" className="container-hdr-sticky2">
        <Grid item p={1}>
          <span role="button" onClick={() => window.history.back()} tabIndex={0}>
            <ArrowBackIosSharpIcon
              htmlColor="white"
            />
          </span>
        </Grid>
        <Grid item>
          {currentUser?.is_advisor ? 'Availability' : 'Contact Settings'}
        </Grid>
      </Grid>
      <CustomStyledBox>
        {bodyContent}
      </CustomStyledBox>
    </AppBodyWithSidebar>
  );
};

export default AvailabilityContainer;
