import React from 'react';
import PropTypes from 'prop-types';

import { featureFlag } from 'app/util/featureFlag';

import { useGetListingQuery } from 'app/api/mainApi';
import PhoneListingCard from 'app/components/PhoneListingCard';
import DeprecatedPhoneListingCard from 'app/redesign/components/DeprecatedPhoneListingCard';

const ListingCardContainer = ({ listingId, style }) => {
  const { data: listing, isLoading: listingLoading } = useGetListingQuery({ id: listingId });
  const useNewCards = featureFlag.enabled('NEW_CARDS_20908');

  if (listingLoading) return (<div>Loading...</div>);

  if (useNewCards) {
    return (
      <PhoneListingCard listing={listing} style={style} viewingFavorites />
    );
  }

  return (
    <DeprecatedPhoneListingCard listing={listing} style={style} />
  );
};

ListingCardContainer.defaultProps = {
  style: {},
};

ListingCardContainer.propTypes = {
  listingId: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default ListingCardContainer;
