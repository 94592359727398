/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';

const initialState = {
  currentStep: TRIBUTE_WORKFLOW_STEPS.INITIALIZING,
  lastStep: '',
  packageId: '',
  packageRate: '',
  recurring: false,
  tributeAmount: '',
  tributeMessage: '',
  tributeDeficit: '',
  declined: false,
};

const tributesSlice = createSlice({
  name: 'tributes',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.lastStep = state.currentStep;
      state.currentStep = action.payload.currentStep;
    },
    setPackageId: (state, action) => {
      state.packageId = action.payload.packageId;
    },
    setPackageRate: (state, action) => {
      state.packageRate = action.payload.packageRate;
    },
    setRecurring: (state, action) => {
      state.recurring = action.payload.recurring;
    },
    setTributeAmount: (state, action) => {
      state.tributeAmount = action.payload.tributeAmount;
    },
    setTributeMessage: (state, action) => {
      state.tributeMessage = action.payload.tributeMessage;
    },
    setTributeDeficit: (state, action) => {
      state.tributeDeficit = action.payload.tributeDeficit;
    },
    setDeclined: (state, action) => {
      state.declined = action.payload.declined;
    },
    resetTributeSliceState: () => initialState,
  },
});

export default tributesSlice;

export const {
  setCurrentStep,
  setDeclined,
  setPackageId,
  setPackageRate,
  setRecurring,
  setTributeAmount,
  setTributeMessage,
  setTributeDeficit,
  resetTributeSliceState,
} = tributesSlice.actions;
