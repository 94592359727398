import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CallStatusMenuItem from './CallStatusMenuItem';
import MenuAvailabilityList from 'app/components/availabilityMenu/MenuAvailabilityList';

import { useUpdateAvailabilityMutation } from 'app/api/mainApi';
import PhoneShape from 'app/shapes/PhoneShape';

const AdvisorCallStatusMenu = ({ phone, callStatuses, onClick }) => {
  const [ updateCurrentCallPhoneStatus ] = useUpdateAvailabilityMutation();

  const handleListItemClick = (value) => {
    if (value === phone.call_phone_status) { return; }

    updateCurrentCallPhoneStatus({ availability: { call_status: value } });
  };

  return (
    <Box data-test-id="advisor-call-status-menu">
      <Grid container sx={{ mb: 1, alignItems: 'center' }}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            Call Status
          </Typography>
        </Grid>
        <Grid item xs={6} className="pull-right hide-manage-link">
          <Typography variant="body3">
            <a href="/availability" className="bluelink" onClick={onClick}>
              Manage
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Paper sx={{ p: 0 }} variant="outlined">
        <MenuAvailabilityList aria-label="section1">
          {callStatuses[ 0 ] && (
            <CallStatusMenuItem
              onClick={() => { handleListItemClick(callStatuses[ 0 ].value); }}
              selected={callStatuses[ 0 ].value === phone.call_phone_status}
              primaryText={callStatuses[ 0 ].title}
              secondaryText={phone.secure_number}
            />
          )}
          <Divider />
          {callStatuses[ 1 ] && (
            <CallStatusMenuItem
              onClick={() => { handleListItemClick(callStatuses[ 1 ].value); }}
              selected={callStatuses[ 1 ].value === phone.call_phone_status}
              primaryText={callStatuses[ 1 ].title}
            />
          )}
        </MenuAvailabilityList>
      </Paper>
    </Box>
  );
};

AdvisorCallStatusMenu.defaultProps = {
  phone: {},
  callStatuses: [],
  onClick: () => { },
};

AdvisorCallStatusMenu.propTypes = {
  phone: PhoneShape,
  callStatuses: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    title: PropTypes.string,
  })),
  onClick: PropTypes.func,
};

export default AdvisorCallStatusMenu;
