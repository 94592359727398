import React, { useContext } from 'react';

import FileManagerAlbumSelect from './FileManagerAlbumSelect';
import AddMediaContext from 'app/contexts/AddMediaContext';
import FileUploadProgress from './FileUploadProgress';
import UploadWatermarkTooltip from 'app/components/tooltips/UploadWatermarkTooltip';
import FileUploadError from './FileUploadError';
import FileUploadSuccess from './FileUploadSuccess';

const UploadFilesTab = () => {
  const {
    openNativeFileDialog,
    albumsQuery,
    selectedAlbum,
    setSelectedAlbum,
    onFilesSelected,
    filesToUpload,
    onRemoveUploadClick,
    uploadInProgress,
    updateIndividualFileData,
    currentUser,
    watermarkCheckbox,
    profileImageMode,
  } = useContext(AddMediaContext);

  return (
    <>
      {profileImageMode ? (
        <input
          type="file"
          id="fileInput"
          name="files[]"
          accept="image/jpeg,image/pjpeg,image/png"
          onChange={onFilesSelected}
          style={{ opacity: 0, display: 'none' }}
        />
      ) : (
        <div>
          <div className="container-fm-folders">
            <div className="row middle-xs">
              <div className="col-sm-2 start-sm col-xs-4 center-xs">Upload to:</div>
              <div className="col-sm-5 col-xs-8">
                <FileManagerAlbumSelect
                  albumsQuery={albumsQuery}
                  value={selectedAlbum}
                  onChange={setSelectedAlbum}
                  disabled={uploadInProgress}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid padding-0-8 marginbot10">
            <div className="row middle-xs">
              <div className="col-sm-3 col-xs-5">
                <a
                  href="#"
                  className={`select-link ${uploadInProgress ? 'disabled' : ''}`}
                  onClick={openNativeFileDialog}
                >
                  <img src="/plus_frontend/assets/svg/ico-add-blue.svg" alt="+" className="select-plus" />
                  Select Files
                </a>
              </div>
              <div className="col-sm-5 col-xs-7 paddinglft25">
                <div className="row">
                  <div>
                    <label className="checkbox-container">
                      Add watermark
                      <input
                        ref={watermarkCheckbox}
                        type="checkbox"
                        defaultChecked
                        disabled={uploadInProgress}
                      />
                      <span
                        className={`checkmark ${uploadInProgress ? 'disabled' : ''}`}
                      />
                    </label>
                  </div>
                  <div>
                    <UploadWatermarkTooltip currentUser={currentUser} />
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    name="files[]"
                    multiple="multiple"
                    accept="image/*,video/*,audio/*"
                    onChange={onFilesSelected}
                    style={{ opacity: 0, display: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid fm-scrolling1">
        {filesToUpload && filesToUpload.length > 0 && (
          filesToUpload.map((f) => {
            if (f.uploadResponse) {
              if (f.uploadFailed) {
                return (
                  <FileUploadError key={f.file.name} fileName={f.file.name} errorMessage={f.uploadResponse}/>
                );
              } else {
                return (
                  <FileUploadSuccess key={f.file.name} fileName={f.file.name} />
                );
              }
            } else {
              return (
                <FileUploadProgress
                  key={f.file.name}
                  file={f}
                  onRemoveClick={onRemoveUploadClick}
                  updateIndividualFileData={updateIndividualFileData}
                  uploadInProgress={uploadInProgress}
                />
              );
            }
          })
        )}
        {(!filesToUpload || filesToUpload.length === 0) && (
          <div className="row center-xs margintop40">
            <a
              href="#"
              className={`select-link big ${uploadInProgress ? 'disabled' : ''}`}
              onClick={openNativeFileDialog}
            >
              <img src="/plus_frontend/assets/svg/ico-add-blue.svg" alt="+" className="select-plus" />
              Select Files
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFilesTab;
