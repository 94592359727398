import React from 'react';
import { PropTypes } from 'prop-types';

const FileUploadError = ({ fileName, errorMessage }) => {
  return (
    <div className="container-alert error inline marginbot10" style={{ lineHeight: 'normal' }}>
      <div className="container-max">
        <div className="container-fluid padding0">
          <div className="row middle-xs">
            <div className="col-xs-10">
              We&apos;re sorry, the following file(s) could not be uploaded:
              <br />
              <ul style={{ margin: '10px 0 0 15px', padding: 0 }}>
                <li style={{ marginBottom: '10px' }}>
                  {fileName}
                  <br />
                  {errorMessage}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileUploadError.propTypes = {
  fileName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default FileUploadError;
