import PropTypes from 'prop-types';

const SocialMediaConnectionShape = PropTypes.shape({
  id: PropTypes.number,
  provider: PropTypes.string.isRequired,
  authentication_error: PropTypes.string,
  username: PropTypes.string,
});

export default SocialMediaConnectionShape;
