import React from 'react';
import PropTypes from 'prop-types';

import PlayableGoodyBagItem from './PlayableGoodyBagItem';
import ImageGoodyBagItem from './ImageGoodyBagItem';

import GoodyBagItemShape from 'app/shapes/GoodyBagItemShape';

const GoodyBagItem = ({ goodyBagItem, onGoodyBagItemClick }) => {
  // TODO: Maybe these could be created from a HoC
  if (goodyBagItem.item_type === 'video' || goodyBagItem.item_type === 'audio') return <PlayableGoodyBagItem goodyBagItem={goodyBagItem} onGoodyBagItemClick={onGoodyBagItemClick} />;
  return <ImageGoodyBagItem goodyBagItem={goodyBagItem}  onGoodyBagItemClick={onGoodyBagItemClick} />;
};

GoodyBagItem.propTypes = {
  goodyBagItem: GoodyBagItemShape.isRequired,
  onGoodyBagItemClick: PropTypes.func.isRequired,
};

export default GoodyBagItem;
