import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import { useCreateRecurringMonetaryTributeMutation,
  useGetAppConfigQuery,
} from 'app/api/mainApi';

import BillingError from 'app/components/modals/tributes/BillingError';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';

import {
  setCurrentStep,
  setDeclined,
} from 'app/slices/tributesSlice';

const BillingErrorContainer = ({ advisor }) => {
  const dispatch = useDispatch();
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;
  const cardType = currentUser?.default_card?.card_type;
  const lastFour = currentUser?.default_card?.last_four;

  const {
    closeDialogAndResetTributeSlice,
    fromNfChat,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const {
    tributeAmount,
    tributeMessage,
    declined,
  } = useSelector(state => state.tributes);

  const [
    createRecurringMonetaryTribute,
    createRecurringMonetaryTributeResult,
  ] = useCreateRecurringMonetaryTributeMutation();

  const retryTributeClick = () => {
    createRecurringMonetaryTribute({
      advisorId: advisor.id,
      rate: parseFloat(tributeAmount),
      message: tributeMessage,
      fromNfChat,
    });
  };

  const closeClick = () => {
    closeDialogAndResetTributeSlice();
  };

  const errorText = declined
    ? 'We\'re sorry, your bank has declined the transaction.'
    : 'We\'re sorry, we encountered a system error.';

  useEffect(() => {
    if (createRecurringMonetaryTributeResult.isSuccess) {
      dispatch(setDeclined({
        declined: false,
      }));
      dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.TRIBUTE_SUCCESS,
      }));
    }

    if (createRecurringMonetaryTributeResult.isError) {
      if (createRecurringMonetaryTributeResult.error?.data?.error === 'gateway_declined') {
        dispatch(setDeclined({
          declined: true,
        }));
      }

      dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.USE_DIFFERENT_CARD,
      }));
    }
  }, [createRecurringMonetaryTributeResult]);

  return (
    <BillingError
      errorText={errorText}
      cardType={cardType}
      lastFour={lastFour}
      retryTributeClick={retryTributeClick}
      useDifferentCardClick={useDifferentCardClick}
      closeClick={closeClick}
    />
  );
};

BillingErrorContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default BillingErrorContainer;
