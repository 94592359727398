import PropTypes from 'prop-types';
import PhoneShape from './PhoneShape';

const AvailabilityShape = PropTypes.shape({
  phones: PropTypes.arrayOf(PhoneShape),
  call_monitor_on: PropTypes.bool,
  call_statuses: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    title: PropTypes.string,
  })),
  chat_quiet_hours_enabled: PropTypes.bool,
  chat_statuses: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.string,
  })),
  busy_on_call: PropTypes.bool,
  enrolled_in_chat: PropTypes.bool,
  quiet_hours_start_local: PropTypes.number,
  quiet_hours_end_local: PropTypes.number,
  has_international_phone: PropTypes.bool,
  chat_forwarding_on: PropTypes.bool,
  has_verified_chat_phone: PropTypes.bool,
  explicitly_unenrolled_in_chat: PropTypes.bool,
  chat_profile_id: PropTypes.number,
});

export default AvailabilityShape;
