import { isRejectedWithValue } from '@reduxjs/toolkit';
import { showFlash } from 'app/slices/flashSlice';

export const GOODY_BAG_FUNDS_ERROR = 403;
export const GOODY_BAG_BLOCKED_ERROR = 5001;
export const clientErrorsMiddleware = (store) => (next) => (action) => {
  const UNAUTHORIZED = 401;
  const FORBIDDEN = 403;
  const NOT_FOUND = 404;
  const UNHANDLED_ERROR = 500;
  const PARSING_ERROR = 'PARSING_ERROR';

  if (isRejectedWithValue(action)) {
    // /feed/:flirt/:postId
    const match = window.location.href.match(/\/plus\/([^/]*)\/([^/]*)/);
    const flirt = match?.[1];
    const postId = match?.[2];
    const endpoint = action.meta.arg.endpointName;

    switch (action.payload.status) {
    case UNAUTHORIZED:
      window.location.href = `/login?return_url=${encodeURIComponent(window.location.pathname)}`;
      break;
    case FORBIDDEN:
      const reason = action.payload?.data?.code;
      const purchasingGoodyBag = reason === GOODY_BAG_FUNDS_ERROR || reason === GOODY_BAG_BLOCKED_ERROR;
      if (purchasingGoodyBag) {
        // no-op and let the workflow handle it
      } else if (!flirt) {
        // handle the incorrect case of forbidden when not authorized
        window.location.href = `/login?return_url=${encodeURIComponent(window.location.pathname)}`;
      } else if (postId) {
        window.location.href = `/feed/${flirt}/`;
      } else {
        window.location.href = '/feed/home';
      }
      break;
    case NOT_FOUND:
      if (postId) {
        window.location.href = `/feed/${flirt}/`;
      } else if (endpoint === 'getAdvisorListings' || endpoint === 'getAdvisor') {
        window.location.href = '/feed/missing/profile';
      }
      break;
    case PARSING_ERROR:
    case UNHANDLED_ERROR:
      if (action.payload?.flash || action.payload?.data?.flash || action.payload?.data?.inline_error) break;
      store.dispatch(showFlash({
        flashType: 'error',
        autoCloseDur: 30000,
        props: { message: 'Sorry, something went wrong. Please try refreshing the page.' },
      }));
      break;
    default:
      // no-op
    }
  }

  return next(action);
};
