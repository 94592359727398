import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SocialMediaEventTemplateShape from 'app/shapes/SocialMediaEventTemplateShape';
import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import {
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import IconSocialTwitter from 'app/components/customSvgs/IconSocialTwitter';
import IconSocialTumblr from 'app/components/customSvgs/IconSocialTumblr';
import SocialMediaEventTemplate from 'app/components/social_shouts/SocialMediaEventTemplate';

const SocialMediaEventTemplates = ({
  socialMediaEventTemplates,
  updateSocialMediaEventTemplate,
  handleCancelUpdateSocialMediaEventTemplates,
  handleUpdateSocialMediaEventTemplates,
  twitterConnection,
  tumblrConnection,
  socialMediaTemplateFormData,
}) => {
  // NOTE: This attaches the social media icons to the top of the page when scrolling.
  // I had fought trying to pass a ref down from AppBody to this component via the useContext hook.
  // I could not get it to work, so now we are just going to use some old fashioned DOM querying to attach a scroll
  // listener.
  // NOTE 2: This uses a sibling element to determine if we should make the icons sticky. Think of it as using a point of
  // reference to determine if the icons should be sticky.
  const socialMediaIconsRef = useRef(null);
  const socialMediaHeaderRef = useRef(null);
  const [socialMediaHeaderIsSticky, setSocialMediaHeaderIsSticky] = useState(false);
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setSocialMediaHeaderIsSticky(false);
      } else {
        setSocialMediaHeaderIsSticky(true);
      }
    });
  }, { rootMargin: '-98px' });

  useEffect(() => {
    const handleScroll = () => {
      if (socialMediaIconsRef.current) {
        observer.observe(socialMediaHeaderRef.current);
      }
    };
    window.document.querySelector('#body-content').addEventListener('scroll', handleScroll);

    // NOTE: Cleanup our observer and our scroll event listener.
    return () => {
      window.document.querySelector('#body-content').removeEventListener('scroll', handleScroll);
      observer.unobserve(socialMediaIconsRef.current);
    };
  }, []);
  return (
    socialMediaEventTemplates?.length > 0 ? (
      <>
        <Grid container sx={{ alignItems: 'center', px: { xs: 2.5, sm: 1 }, py: { xs: 1, sm: 1 } }}>
          <Grid item xs={12}>
            <Typography>
              ...then choose events for automatic posting. Customize text, if desired. Text within curly braces will be substituted when sent.
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" mb={-3}>
          <Grid
            item
            sm={10}
            xs={9}
            ref={socialMediaHeaderRef}
          />
          <Grid
            item
            sm={2}
            xs={3}
            alignItems="center"
            ref={socialMediaIconsRef}
            sx={socialMediaHeaderIsSticky ? { position: 'sticky', paddingTop: '2px', top: '44px', backgroundColor: 'white', zIndex: 1 } : {}}
          >
            <Stack
              direction="row"
              spacing={2.7}
              justifyContent="left"
              alignItems="center"
              sx={{ px: { xs: 0.7, sm: 1.5 } }}
            >
              <IconSocialTwitter alt="Twitter" style={{ height: '20px', width: '20px' }} />
              <IconSocialTumblr alt="Tumblr" style={{ height: '20px', width: '20px' }} />
            </Stack>
          </Grid>

          {socialMediaTemplateFormData?.map((eventTemplate) => (
            <Grid item xs={12} key={eventTemplate.id}>
              <SocialMediaEventTemplate
                key={eventTemplate.id}
                eventTemplate={eventTemplate}
                updateSocialMediaEventTemplate={updateSocialMediaEventTemplate}
                twitterConnection={twitterConnection}
                tumblrConnection={tumblrConnection}
              />
            </Grid>
          ))}
        </Grid>

        <Paper sx={{ p: 2, mt: 3 }} elevation={0}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              size="medium"
              onClick={handleCancelUpdateSocialMediaEventTemplates}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={handleUpdateSocialMediaEventTemplates}
            >
              Save
            </Button>
          </Stack>
        </Paper>
      </>
    ) : null
  );
};

SocialMediaEventTemplates.defaultProps = {
  socialMediaEventTemplates: [],
  twitterConnection: null,
  tumblrConnection: null,
  socialMediaTemplateFormData: [],
};

SocialMediaEventTemplates.propTypes = {
  socialMediaEventTemplates: PropTypes.arrayOf(SocialMediaEventTemplateShape),
  updateSocialMediaEventTemplate: PropTypes.func.isRequired,
  handleCancelUpdateSocialMediaEventTemplates: PropTypes.func.isRequired,
  handleUpdateSocialMediaEventTemplates: PropTypes.func.isRequired,
  twitterConnection: SocialMediaConnectionShape,
  tumblrConnection: SocialMediaConnectionShape,
  socialMediaTemplateFormData: PropTypes.arrayOf(SocialMediaEventTemplateShape),
};

export default SocialMediaEventTemplates;
