import React from 'react';
import SocialMediaConnectionShape from 'app/shapes/SocialMediaConnectionShape';

import { capitalize } from 'app/helpers/stringHelpers';

import {
  Button,
} from '@mui/material';

const SetupSocialMediaConnection = ({ connection }) => {
  return (
    <a href={`/auth/${connection.provider}`}>
      <Button variant="outlined" size="small">
        { connection?.authentication_error ? 'Link' : `Setup ${capitalize(connection.provider)}` }
      </Button>
    </a>
  );
};

SetupSocialMediaConnection.propTypes = {
  connection: SocialMediaConnectionShape.isRequired,
};

export default SetupSocialMediaConnection;
