import React from 'react';
import { PropTypes } from 'prop-types';

import { profileFollowButtonSrc } from 'app/helpers/followButtonHelpers';
import AuthenticatedLink from 'app/components/shared/AuthenticatedLink';

const FollowButton = ({ follow, followButtonClick }) => {
  return (
    <AuthenticatedLink role="button" tabIndex="0" onClick={followButtonClick}>
      <img
        src={profileFollowButtonSrc(follow)}
        alt=""
        style={{ cursor: 'pointer' }}
        data-test-id="follow-button"
      />
    </AuthenticatedLink>
  );
};

FollowButton.defaultProps = {
  follow: null,
};

FollowButton.propTypes = {
  follow: PropTypes.object,
  followButtonClick: PropTypes.func.isRequired,
};

export default FollowButton;
