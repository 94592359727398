import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';

import {
  currentSystemPushPermissions,
  enablePushNotifications,
  pushPermissionFromNavigator,
  getDeviceType,
  IOS,
  ANDROID,
  SAFARI_DESKTOP,
  CHROME_DESKTOP,
  FIREFOX,
  OTHER,
} from 'app/helpers/pushNotificationsHelpers';

import {
  isPWA,
} from 'app/helpers/pwaHelpers';

const NotificationsContainer = () => {
  const [currentPermissions, setCurrentPermissions] = useState('default');

  const deviceType = getDeviceType();

  const isSafariMobile = deviceType === IOS && !isPWA && navigator.userAgent.match(/Safari/);
  // Show some special instructions for Safari Web Mobile because we just can't prompt for notifications.
  if (isSafariMobile) {
    return (
      <Typography>
        Please install the Niteflirt Progressive Web App (PWA) to get push notifications.&nbsp;
        <a href="https://support.niteflirt.com/hc/en-us/sections/28710878443917">
        Learn more about installing the Niteflirt PWA.
        </a>
      </Typography>
    )
  }

  useEffect(async () => {
    try {
      // Use the Notification.permission API to get the current permission state as it is more reliable on
      // IOS pwa.
      if (deviceType === IOS && isPWA) {
        setCurrentPermissions(currentSystemPushPermissions());
        return;
      }

      const sysPermission = await pushPermissionFromNavigator();
      setCurrentPermissions(sysPermission.state);
    } catch (e) {
      console.error('Error getting system permissions:', e);
    }
  }, []);

  if (currentPermissions === 'granted') {
    return (
      <Typography>
        Your browser notifications are currently enabled.&nbsp;
        <a href="https://support.niteflirt.com/hc/en-us/sections/28710878443917">Learn more about notifications.</a>
      </Typography>
    )
  } else if (currentPermissions === 'denied') {
    return <InstructionsPerDevice deviceType={deviceType} />;
  }

  return (
    <div>
      <Typography>
        Never miss a chat! Enable browser notifications to be alerted whenever you receive a new message.
      </Typography>
      <Grid container mt={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">
        <Grid item xs={12} sm="auto" sx={{ order: { xs: 1, sm: 2 } }}>
          <Button variant="contained" fullWidth={false} sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={enablePushNotifications}>
            Enable
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const InstructionsPerDevice = ({ deviceType }) => {
  let link = '';

  switch (deviceType) {
    case IOS:
      link = 'https://support.niteflirt.com/hc/en-us/articles/28710784518797';
      break;
    case ANDROID:
      link = 'https://support.niteflirt.com/hc/en-us/articles/28710835728269';
      break;
    case SAFARI_DESKTOP:
      link = 'https://support.niteflirt.com/hc/en-us/articles/28710662464653';
      break;
    case CHROME_DESKTOP:
      link = 'https://support.niteflirt.com/hc/en-us/articles/28710697161229';
      break;
    case FIREFOX:
      link = 'https://support.niteflirt.com/hc/en-us/articles/28710728083469';
      break;
    case OTHER:
    default:
      link = 'https://support.niteflirt.com/hc/en-us/sections/28710878443917';
  }

  return (
    <Typography>
      You have blocked browser notifications. To enable them, go to your browser settings and allow notifications for this site.&nbsp;
      <a href={link}>Enable Notifications.</a>
    </Typography>
  )
}

export default NotificationsContainer;
