import React from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Slider from 'react-slick';

import GoodyBagShape from 'app/shapes/GoodyBagShape';
import GoodyBagItemShape from 'app/shapes/GoodyBagItemShape';
import GoodyBagReviewShape from 'app/shapes/GoodyBagReviewShape';

import GoodyBagMeta from 'app/redesign/components/goodyBagDetailsDrawer/GoodyBagMeta';
import PurchaseGoodyBag from 'app/redesign/components/goodyBagDetailsDrawer/PurchaseGoodyBag';
import GoodyBagDescription from 'app/redesign/components/goodyBagDetailsDrawer/GoodyBagDescription';
import IncludedGoodyBagItems from 'app/redesign/components/goodyBagDetailsDrawer/IncludedGoodyBagItems';
import GoodyBagReviews from 'app/redesign/components/goodyBagDetailsDrawer/GoodyBagReviews';
import HorizontalLine from '../HorizontalLine';
import ImageMedia from 'app/components/media/ImageMedia';
import VideoMediaPreview from 'app/components/media/VideoMediaPreview';
import AudioMedia from 'app/components/media/AudioMedia';
import GoodyBagCallToAction from 'app/components/media/GoodyBagCallToAction';
import DrawerHeader from '../shared/DrawerHeader';
import ImageNotPublicPreviewable from 'app/components/media/ImageNotPublicPreviewable';

const GoodyBagDetailsDrawer = ({
  goodyBag,
  goodyBagItems,
  goodyBagReviews,
  currentUser,
  previewableItems,
  notPreviewableItemCount,
  anchor,
  isOpen,
  closeDrawer,
  openRatingsDrawer,
  advisor,
  onGoodyBagItemClick,
  pauseAllPlayers,
  onBuyGoodyBagButtonClick,
}) => {
  const getSliderComponent = (item) => {
    switch (item && item.type) {
    case 'image':
      if (item.publicPreviewable) {
        return (
          <ImageMedia
            key={item.id}
            item={item}
            onImageClick={() => { onGoodyBagItemClick(item); }}
          />
        );
      } else {
        return <ImageNotPublicPreviewable />;
      }
    case 'audio':
      return (
        <AudioMedia
          key={item.id}
          item={item}
          onImageClick={() => { onGoodyBagItemClick(item); }}
        />
      );
    case 'video':
      return item.previewUrls.map((url) => (
        <VideoMediaPreview
          key={item.id}
          url={url}
          onImageClick={() => { onGoodyBagItemClick(item); }}
        />
      ));
    default:
      return <GoodyBagCallToAction key={`gb-cta-${goodyBag.id}`} itemCount={notPreviewableItemCount} />;
    }
  };

  const reviewsWithCopy = goodyBagReviews.filter((review) => review.approve && review?.review?.length > 0).slice(0, 3);
  const showMore = goodyBagReviews.length > reviewsWithCopy.length;
  // TODO: Can we move these classes into an override? or Component maybe more variants?
  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={closeDrawer}
    >
      <Grid
        container
        sx={{
          width: { xs: '100vw' },
          maxWidth: { xs: '100vw', sm: '600px' },
        }}
      >
        <DrawerHeader
          closeDrawer={closeDrawer}
          headerCopy="Goody Details"
        />
        <Grid container className="container-goodies-details">
          <Grid item xs={12} className="container-goodie-item">
            <Slider
              dots={false}
              infinite
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              afterChange={() => { pauseAllPlayers(); }}
            >
              { previewableItems.flatMap(getSliderComponent) }
            </Slider>
          </Grid>
          <Grid container px={{ xs: 0.5, sm: 2 }} pt={{ xs: 0.5, sm: 1 }}>
            <GoodyBagMeta advisor={advisor} goodyBag={goodyBag} openRatingsDrawer={openRatingsDrawer} />
            <PurchaseGoodyBag
              goodyBag={goodyBag}
              closeDrawer={closeDrawer}
              advisor={advisor}
              onBuyGoodyBagButtonClick={onBuyGoodyBagButtonClick}
            />
            <GoodyBagDescription description={goodyBag.description} />
          </Grid>
          {reviewsWithCopy.length > 0 && (
            <>
              <HorizontalLine />
              <Grid item xs={12}>
                <GoodyBagReviews
                  goodyBag={goodyBag}
                  goodyBagReviews={reviewsWithCopy}
                  currentUser={currentUser}
                  openRatingsDrawer={openRatingsDrawer}
                  showMore={showMore}
                />
              </Grid>
            </>
          )}
          <HorizontalLine />
          <Grid item xs={12}>
            <IncludedGoodyBagItems
              goodyBag={goodyBag}
              goodyBagItems={goodyBagItems}
              onGoodyBagItemClick={onGoodyBagItemClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

GoodyBagDetailsDrawer.defaultProps = {
  goodyBag: {},
  goodyBagReviews: [],
  currentUser: {},
  goodyBagItems: [],
  previewableItems: [],
  advisor: {},
};

GoodyBagDetailsDrawer.propTypes = {
  goodyBag: GoodyBagShape,
  goodyBagReviews: PropTypes.arrayOf(GoodyBagReviewShape),
  currentUser: PropTypes.object,
  goodyBagItems: PropTypes.arrayOf(GoodyBagItemShape),
  previewableItems: PropTypes.array,
  notPreviewableItemCount: PropTypes.number.isRequired,
  anchor: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  openRatingsDrawer: PropTypes.func.isRequired,
  advisor: PropTypes.object,
  onGoodyBagItemClick: PropTypes.func.isRequired,
  pauseAllPlayers: PropTypes.func.isRequired,
  onBuyGoodyBagButtonClick: PropTypes.func.isRequired,
};

export default GoodyBagDetailsDrawer;
