/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { TextField, FormControl, FormHelperText } from '@mui/material';

export const FormTextField = ({
  name,
  validation,
  label,
  onBlur,
  onChange,
  onFocus,
  size,
  fullWidth,
  helperText,
  registerOptions,
  children,
  ...textFieldProps
}) => {
  const formContext = useFormContext();

  if (!formContext) {
    return (
      <TextField
        label={label}
        size={size}
        fullWidth={fullWidth}
        helperText="FormTextField must be used within a FormWrapper."
        {...textFieldProps}
      />
    );
  }

  const { watch, register, formState: { errors }, clearErrors } = formContext;
  const { onBlur: rhfOnBlur, onChange: rhfOnChange, onFocus: rhfOnFocus, ...rhfProps } = register(name, { ...validation, ...registerOptions });

  const handleBlur = (event) => {
    rhfOnBlur(event);
    onBlur?.(event);
  };

  const handleChange = (event) => {
    rhfOnChange(event);
    onChange?.(event);
    clearErrors(name);
  };

  const handleFocus = (event) => {
    rhfOnFocus?.(event);
    onFocus?.(event);
  };

  const value = watch(name);

  // You can pass " " for helperText if you want to reserve the space
  const helperTextValue = errors[name] ? errors[name].message : helperText;

  return (
    <FormControl fullWidth error={!!errors[name]}>
      <>
        <TextField
          label={label}
          size={size}
          value={value || ''}
          error={!!errors[name]}
          {...textFieldProps}
          {...rhfProps}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {children}
        <FormHelperText>{helperTextValue}</FormHelperText>
      </>
    </FormControl>
  );
};

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  validation: PropTypes.object,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  registerOptions: PropTypes.object,
  children: PropTypes.node,
};

FormTextField.defaultProps = {
  onBlur: null,
  onChange: null,
  onFocus: null,
  validation: {},
  size: 'small',
  fullWidth: true,
  helperText: undefined,
  registerOptions: {},
  children: null,
};
