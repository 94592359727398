import React from 'react';

import { useGetAppConfigQuery } from 'app/api/mainApi';
import { useGetScheduledPostsQuery } from 'app/api/feedsApi';
import QueuedPostContainer from 'app/containers/posts_queue/QueuedPostContainer';
import QueuedPostPlaceholder from 'app/components/QueuedPostPlaceholder';

const ScheduledPostsContainer = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  const currentUser = appConfig?.current_user;

  const { data: posts } = useGetScheduledPostsQuery({ userId: currentUser?.id }, { refetchOnMountOrArgChange: true });

  if (!posts || posts.length === 0) {
    return (
      <QueuedPostPlaceholder placeholderText="You have no scheduled posts." />
    );
  }

  return (
    <div className="container-fluid padding10">
      <div className="row">
        <div className="col-xs-12 start-xs">
          <div className="container-table-queue">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="3">Scheduled Time</th>
                </tr>
              </thead>
              <tbody>
                {posts.map((post) => {
                  return <QueuedPostContainer key={post.id} post={post} displayScheduledDate />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledPostsContainer;
