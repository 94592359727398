import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { errorMessageFromObject } from 'app/helpers/apiMessageHelpers';
import { showFlash } from 'app/slices/flashSlice';
import { usePerformPublishPostMutation } from 'app/api/feedsApi';

const usePublishPost = () => {
  const dispatch = useDispatch();

  const [
    performPublishPost,
    {
      isSuccess: isPublishSuccess,
      isError: isPublishError,
      error: publishError,
      data: publishData,
    },
  ] = usePerformPublishPostMutation();

  useEffect(() => {
    if (isPublishSuccess || isPublishError) {
      let message = isPublishSuccess ?
        'Your post has been published.' :
        errorMessageFromObject(publishError, 'Error while trying to publish the post.');

      if (isPublishSuccess && publishData.pendingPublication) {
        message = 'Your post is currently processing. We\'ll publish it if it\'s approved.';
      }

      const flashType = isPublishError ? 'error' : 'success';

      dispatch(showFlash({
        flashType,
        props: { message },
      }));
    }
  }, [isPublishSuccess, isPublishError]);

  return {
    publishPost: performPublishPost,
  };
};

export default usePublishPost;
