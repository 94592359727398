import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';

import MenuSelect from 'app/components/shared/MenuSelect';

const sortOptions = {
  0: 'Top Available',
  1: 'Highest Rating',
  2: 'Less Than $3',
  3: 'Less Than $2',
};

const ListingSort = ({
  sort,
  handleSortChange,
}) => {
  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {sortOptions[sort]}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <MenuSelect
          onSelect={handleSortChange}
          menuItems={sortOptions}
          defaultValue={Object.keys(sortOptions)[1]}
        />
      </Grid>
    </Grid>
  );
};

ListingSort.propTypes = {
  sort: PropTypes.number.isRequired,
  handleSortChange: PropTypes.func.isRequired,
};

export default ListingSort;
