import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useLazyGetUsernameAvailableQuery } from 'app/api/mainApi';
import { FormTextField } from 'app/components/form_components';

const loginValidation = {
  maxLength: {
    value: 25,
    message: 'We\'re sorry, but the Member Name you chose is too long. Member names may only contain up to 25 symbols.',
  },
  pattern: {
    value: /^[A-Za-z0-9_ ]+$/,
    message: 'Please update your Member Name so that it only includes names, numbers and spaces.',
  },
};

const LoginFieldContainer = ({ initialValue, required }) => {
  const { setError, getValues } = useFormContext();
  const [triggerUsernameAvailable, usernameAvailable] = useLazyGetUsernameAvailableQuery();
  const [lastCheckedUsername, setLastCheckedUsername] = useState('');

  const checkUsernameAvailable = (event) => {
    const login = event.target.value;
    if (login && login !== initialValue && login !== lastCheckedUsername) {
      triggerUsernameAvailable({ login });
      setLastCheckedUsername(login);
    }
  };

  useEffect(() => {
    const { data } = usernameAvailable;
    if (data && data.username === lastCheckedUsername && !data.available) {
      setError('login', { type: 'server', message: 'This Member Name is already taken. Please enter another one.' });
    }
  }, [usernameAvailable, lastCheckedUsername, setError]);

  const validation = required
    ? { ...loginValidation, required: 'Please choose a Member Name (Example: "jsmith").' }
    : { ...loginValidation };

  return (
    <FormTextField
      label="Member Name"
      name="login"
      validation={validation}
      onBlur={checkUsernameAvailable}
    />
  );
};

LoginFieldContainer.propTypes = {
  initialValue: PropTypes.string,
};

LoginFieldContainer.defaultProps = {
  initialValue: '',
};

export default LoginFieldContainer;
