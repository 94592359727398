import PropTypes from 'prop-types';

const GoodyBagReviewShape = PropTypes.shape({
  id: PropTypes.number,
  review: PropTypes.string,
  approve: PropTypes.bool,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
});

export default GoodyBagReviewShape;
