import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Box } from '@mui/material';

export const FormLevelErrors = () => {
  const { formState: { errors } } = useFormContext();

  const formError = errors?.form?.message;

  if (!formError) return null;

  return (
    <Box mb={2}>
      <Typography color="error">
        {formError}
      </Typography>
    </Box>
  );
};

export default FormLevelErrors;
