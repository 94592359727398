import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import ForgotPin from 'app/components/dialogs/ForgotPin';
import DialogsContext from 'app/contexts/DialogsContext';

const ForgotPinLauncher = ({ type }) => {
  const { openDialog } = useContext(DialogsContext);

  useEffect(() => {
    openDialog({
      component: ForgotPin,
      props: { type, hideCancel: true },
    });
  }, []);

  return null;
};

ForgotPinLauncher.propTypes = {
  type: PropTypes.string,
};

ForgotPinLauncher.defaultProps = {
  type: 'web',
};

export default ForgotPinLauncher;
