import React from 'react';

const About = () => (
  <>
    <img src="/niteflirt/images/redesign/hero3/bg-pane2.jpg" style={{ width: '100%' }} alt="" />
    <p>Back in the day, finding hot phone sex was like navigating a maze. You&apos;d dial a number, holding your breath for a mystery voice on the other end. Reaching the same person again? A shot in the dark. The cost? A mystery until your phone bill showed up.</p>
    <p>NiteFlirt changed all that. When we entered the scene over 20 years ago, we didn&apos;t just flip the script; we rewrote the entire steamy narrative. NiteFlirt was the first to offer a unique online marketplace, uniting discerning individuals with eager folks ready to engage. Your journey here is more than a transaction – it&apos;s an exploration of desires, a meeting of minds, and an escape into a world where connection is key.</p>
    <p>NiteFlirt is committed to authenticity. No scripts, no actors – just real people ready to share moments of intimacy with you. Our platform is a dynamic stage where personalities shine through via profiles, feeds, photos, and videos, allowing you to explore your desires.</p>
    <p>And we go beyond the spoken word. Build deeper connections by sexting with your favorites. Interact socially by following their feeds. Dive into a treasure trove of digital media – videos and pictures capturing the essence of these captivating creators. NiteFlirt makes it easy to not just hear their voices but to experience their allure.</p>
    <p>Privacy is our pledge. Your personal information is never revealed and we ensure discreet billing as &quot;NF Services.&quot; With RapidSSL secured, encrypted servers and over 20 years of established trust, your NiteFlirt journey is safe, secure, and discreet.</p>
    <p>So, whether you&apos;re exploring the realms of live phone passion, engaging in titillating sexting adventures, or discovering the digital media of our enchanting creators, NiteFlirt invites you to step into a world where connection is an art form, desire is celebrated, and satisfaction is not just a promise but a guarantee. Welcome to NiteFlirt - Speak to Your Desire.</p>
  </>
);

export default About;
