import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import {
  useUpdateRecurringPackageTributeMutation,
} from 'app/api/mainApi';

import EditPackageTribute from 'app/components/modals/tributes/EditPackageTribute';
import { TRIBUTE_WORKFLOW_STEPS } from 'app/constants/tributeWorkflowSteps';
import useRecurringTributeManagement from 'app/hooks/useRecurringTributeManagement';
import { showSnackbarMessage } from 'app/slices/snackbarMessageSlice';

const EditPackageTributeContainer = ({ advisor }) => {
  const {
    activePackageTribute,
    closeDialogAndResetTributeSlice,
    currentUserHasCard,
    dispatch,
    publicPackages,
    setCurrentStep,
    setPackageId,
    setPackageRate,
    showBillingError,
    useDifferentCardClick,
  } = useRecurringTributeManagement({ advisor });

  const [
    updateRecurringPackageTribute,
    updateRecurringPackageTributeResult,
  ] = useUpdateRecurringPackageTributeMutation();

  const otherPackages = publicPackages.filter((p) => {
    return p.id !== activePackageTribute?.package?.id;
  });

  const onCancelTributeClick = () => {
    dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CONFIRM_CANCEL_PACKAGE_TRIBUTE,
    }));
  };

  const onRenewTributeClick = () => {
    updateRecurringPackageTribute({
      advisorId: advisor.id,
      packageId: activePackageTribute?.id,
      precedingPackageTributeId: activePackageTribute?.id,
      action: 'renew',
    });
  };

  const closeDialog = () => {
    closeDialogAndResetTributeSlice();
  };

  const changePackageClick = (packageId, packageRate) => {
    dispatch(setPackageId({ packageId }));
    dispatch(setPackageRate({ packageRate }));

    if (!currentUserHasCard) {
      return dispatch(setCurrentStep({
        currentStep: TRIBUTE_WORKFLOW_STEPS.MANAGE_CARDS,
      }));
    }

    return dispatch(setCurrentStep({
      currentStep: TRIBUTE_WORKFLOW_STEPS.CHANGE_PACKAGE_TRIBUTE,
    }));
  };

  useEffect(() => {
    if (updateRecurringPackageTributeResult.isSuccess) {
      closeDialogAndResetTributeSlice();

      dispatch(showSnackbarMessage({
        message: 'Your Package has been resumed.',
        severity: 'success',
      }));
    }

    if (updateRecurringPackageTributeResult.isError) {
      if (updateRecurringPackageTributeResult.error?.data?.error === 'User account blocked') {
        closeDialogAndResetTributeSlice();

        dispatch(showSnackbarMessage({
          message: updateRecurringPackageTributeResult.error?.data?.flash?.message,
          severity: 'error',
        }));
      }

      showBillingError();
    }
  }, [updateRecurringPackageTributeResult]);

  return (
    <EditPackageTribute
      activePackageTribute={activePackageTribute}
      closeDialog={closeDialog}
      onCancelTributeClick={onCancelTributeClick}
      onRenewTributeClick={onRenewTributeClick}
      otherPackages={otherPackages}
      changePackageClick={changePackageClick}
      useDifferentCardClick={useDifferentCardClick}
    />
  );
};

EditPackageTributeContainer.propTypes = {
  advisor: PropTypes.object.isRequired,
};

export default EditPackageTributeContainer;
