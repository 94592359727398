import React from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@mui/material/Grid';

const GoodyBagCallToAction = ({ itemCount }) => {
  const ctaText = itemCount === 1 ? '...plus 1 hot Goody not available for preview.' : `...plus ${itemCount} hot Goodies not available for preview.`;
  return (
    <div>
      <div className="slick-blur">&nbsp;</div>
      <div className="slick-blur2" />
      <div className="slick-item-bg">
        <Grid
          container
          rowSpacing={0}
          columnSpacing={0}
          direction="column"
          style={{ color: 'white' }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            xs={12}
            item
          >
            {ctaText}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

GoodyBagCallToAction.propTypes = {
  itemCount: PropTypes.number.isRequired,
};

export default GoodyBagCallToAction;
