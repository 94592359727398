import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInterval from 'app/hooks/useInterval';
import Cookie from 'app/util/cookie';
import { useNavigate } from 'react-router';

import {
  useLazyHomeFeedNewPostsCheckQuery,
  useGetHomeFeedQuery,
  useGetCombinedFeedContentAvailableQuery,
} from 'app/api/feedsApi';

import { showFlash } from 'app/slices/flashSlice';
import { doneResettingHomeFeed } from 'app/slices/resetRecordsSlice';
import { NEW_POSTS_FLASH } from 'app/components/flashes/NewPostsFlash';
import PostsPageContainer from './PostsPageContainer';
import usePaginatedRecords from 'app/hooks/usePaginatedRecords';
import FeedPlaceholder from 'app/components/FeedPlaceholder';

const CombinedFeedContainer = () => {
  const ENABLE_EMPTY_FOLLOWS_EXPIRATION_DAYS = 365;
  const POLLING_INTERVAL = 5 * 60 * 1000; // every 5 mins

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // used to poll for more recent posts
  const newestCursor = useRef(null);

  // the query used to check for new posts, but the LAZY version of it (doesn't fire immediately)
  const [checkNewPostsTrigger, newPostsCheckResult] = useLazyHomeFeedNewPostsCheckQuery();
  const newPostsQueryStatus = newPostsCheckResult?.status;
  const newPostsResult = newPostsCheckResult?.data?.new_posts;
  const contentAvailableResult = useGetCombinedFeedContentAvailableQuery();
  const contentAvailable = contentAvailableResult?.data?.content_available;
  const loadedContentAvailable = contentAvailableResult?.status === 'fulfilled';

  // reaches into the slice to determine if the flag has been set telling
  // us to reload the entire feed
  const { resetHomeFeedRequest } = useSelector(state => state.resetRecords);

  const shouldResetFeedTestFunc = () => {
    return resetHomeFeedRequest;
  };

  // custom hook for managing our cursors
  const {
    cursors,
    setOldestCursor,
    setMoreRecords,
  } = usePaginatedRecords({
    resetRecordsRequest: resetHomeFeedRequest,
    doneResettingRecordsFunc: doneResettingHomeFeed,
    shouldResetRecordsTestFunc: shouldResetFeedTestFunc,
  });

  useEffect(() => {
    if (!contentAvailable && loadedContentAvailable && !Cookie.hasCookie('enable_empty_follows')) {
      Cookie.setCookie('enable_empty_follows', 'true', ENABLE_EMPTY_FOLLOWS_EXPIRATION_DAYS);
      navigate('/feed/explore');
    }
  }, [contentAvailableResult]);

  useInterval(() => {
    checkNewPostsTrigger({ cursor: newestCursor.current });
  }, POLLING_INTERVAL);

  // dispatch the flash modal if we detect fresh posts
  useEffect(() => {
    if (newPostsQueryStatus === 'fulfilled' && newPostsResult) {
      dispatch(showFlash({
        component: NEW_POSTS_FLASH,
        autoCloseDur: 5000,
        flashType: 'feed',
      }));
    }
  }, [newPostsResult, newPostsQueryStatus]);

  const setNewestCursor = (cursor) => {
    // we only want to do this when we load the very first page of posts
    // it represents the most recent post across all loaded pages
    if (cursors.length !== 1) { return; }

    newestCursor.current = cursor;
  };

  return (
    <>
      {!contentAvailable && loadedContentAvailable && (
        <FeedPlaceholder />
      )}

      {contentAvailable && cursors.map((cursor) => {
        return (
          <PostsPageContainer
            key={cursor}
            setOldestCursor={setOldestCursor}
            setNewestCursor={setNewestCursor}
            feedQuery={() => (useGetHomeFeedQuery({ cursor }))}
            setMoreRecords={setMoreRecords}
          />
        );
      })}
    </>
  );
};

export default CombinedFeedContainer;
