import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { featureFlag } from 'app/util/featureFlag';
import PackagesIconNF from 'app/components//customSvgs/PackagesIconNF';
import { isSubscriptionPriceSet } from 'app/helpers/onboardingHelpers';

const StyledSpan = styled('span')({
  display: 'block',
  float: 'right',
  '.MuiButtonBase-root': {
    fontSize: '0.750em',
    lineHeight: '1.5em',
  },
});

const AdvisorCenterNav = ({ /* balance, */ closeAndNavigateTo, currentUser }) => {
  const createPostEnabled = currentUser?.is_advisor && isSubscriptionPriceSet(currentUser?.onboarding_step);

  return (
    <>
      <li>
        <a
          title="Feed"
          role="link"
          tabIndex={0}
          onClick={() => {
            closeAndNavigateTo(`/feed/${currentUser.login}`);
          }}
        >
          <img
            src="/plus_frontend/assets/svg/ico-feed.svg"
            alt="Feed"
            className="nav-icon"
          />
          <span className="nav-text">Feed</span>
        </a>

        { createPostEnabled && (
          <a
            title="New Post"
            onClick={() => closeAndNavigateTo('/feed/create_post')}
            style={{ pointerEvents: 'auto' }}
            role="link"
            tabIndex={0}
          >
            <StyledSpan sx={{
              marginRight: {
                xs: '18px',
                md: '4px',
              },
            }}
            >
              <Button variant="outlined" size="small">
                New Post
              </Button>
            </StyledSpan>
          </a>
        )}
      </li>
      {featureFlag.enabled('RECURRING_PACKAGE_TRIBUTES_19537') && (
        <li>
          <a
            title="Packages"
            onClick={() => closeAndNavigateTo('/feed/packages')}
            role="link"
            tabIndex={0}
          >
            <PackagesIconNF alt="packages" className="nav-icon" />
            <span className="nav-text">Manage Packages</span>
          </a>
        </li>
      )}
      {!currentUser && (
        <li>
          <a
            title="Sign In"
            onClick={() => closeAndNavigateTo(
              `/login?return_url=${
                window.location.pathname + window.location.search
              }`,
              true,
            )}
            role="link"
            tabIndex={0}
          >
            <img
              src="/plus_frontend/assets/svg/ico-login.svg"
              alt="Sign In"
              className="nav-icon"
            />
            <span className="nav-text">Sign In</span>
          </a>
        </li>
      )}
    </>
  );
};

AdvisorCenterNav.defaultProps = {
  // balance: '',
  currentUser: null,
};

AdvisorCenterNav.propTypes = {
  // balance: PropTypes.string,
  closeAndNavigateTo: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default AdvisorCenterNav;
