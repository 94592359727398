import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import PackagesIconNF from 'app/components/customSvgs/PackagesIconNF';
import PerkList from 'app/components/tributes/PerkList';

const PackageCtaCard = ({
  advisorPackage,
  subscribeToPackageClick,
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '0 10px 10px 10px',
        borderRadius: '0px',
        boxShadow: 'none',
        marginBottom: '20px',
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <Typography variant="h6" className="name" mt={2} mb={-1} pl={1}>Perks:</Typography>
      </Box>
      <PerkList packageData={advisorPackage} />
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          style={{ maxWidth: '230px' }}
          onClick={() => subscribeToPackageClick(advisorPackage.id, advisorPackage.rate)}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <PackagesIconNF style={{ marginRight: '5px' }} />
            <Typography sx={{ fontSize: '1.125em' }}>
              {advisorPackage.rate}
            </Typography>
            <Typography sx={{ fontWeight: '300', fontSize: '0.938em', pt: 0.4 }}>
              per month
            </Typography>
          </Stack>
        </Button>
      </Box>
    </Paper>
  );
};

PackageCtaCard.defaultProps = {
  advisorPackage: null,
};

PackageCtaCard.propTypes = {
  advisorPackage: PropTypes.shape({
    id: PropTypes.number,
    rate: PropTypes.string,
    status: PropTypes.string,
    feedPerk: PropTypes.shape({
      id: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    callPerk: PropTypes.shape({
      id: PropTypes.number,
      perSessionAmount: PropTypes.number,
      limitByPeriod: PropTypes.bool,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
    volleyPerk: PropTypes.shape({
      id: PropTypes.number,
      perPeriodAmount: PropTypes.number,
      perkDescription: PropTypes.string,
    }),
  }),
  subscribeToPackageClick: PropTypes.func.isRequired,
};

export default PackageCtaCard;
