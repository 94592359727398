import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

import ListingTypeTabs from 'app/components/listing_search/ListingTypeTabs';
import ListingSearchResults from 'app/components/listing_search/ListingSearchResults';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: '50%',
    right: -3,
  },
}));

const ListingSearch = forwardRef((props, ref) => {
  const {
    activeFilterCount,
    searchQueryText,
    searchError,
    selectedCategory,
    typeId,
    setTypeId,
    page,
    setPage,
    handleSearchChange,
    handleSearchSubmit,
    handleFilterClick,
    handleCategoryClick,
    handleSortChange,
    sort,
    isLoading,
    TABS,
    showTabs,
    searchResults,
    pageForwardClick,
    pageBackClick,
  } = props;

  return (
    <section className="body-content" ref={ref}>
      <div className="padding20 paddingtop0">
        <Box sx={{ flexGrow: 1 }} className="padding20">
          <Grid container rowSpacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                placeholder="Find Flirts for phone sex"
                value={searchQueryText}
                onChange={handleSearchChange}
                onKeyPress={handleSearchSubmit}
                variant="outlined"
                error={!!searchError}
                helperText={searchError}
                autoComplete="off"
                autoCapitalize="none"
                autoCorrect="off"
                spellCheck="false"
                InputProps={{
                  name: 'listing-search',
                  inputMode: 'search',
                  autoComplete: 'off',
                  autoCapitalize: 'none',
                  autoCorrect: 'off',
                  spellCheck: 'false',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleFilterClick}
                        size="small"
                      >
                        <StyledBadge
                          color="secondary"
                          badgeContent={activeFilterCount}
                          max={99}
                        >
                          <TuneIcon />
                        </StyledBadge>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {['Women', 'Men', 'Trans'].map((categoryLabel) => (
                  <Grid item key={categoryLabel}>
                    <Chip
                      key={categoryLabel}
                      label={categoryLabel}
                      clickable
                      color={selectedCategory === categoryLabel ? 'secondary' : 'default'}
                      onClick={() => handleCategoryClick(categoryLabel)}
                      size="small"
                      variant={selectedCategory === categoryLabel ? 'filled' : 'outlined'}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {showTabs ? (
                <ListingTypeTabs
                  TABS={TABS}
                  typeId={typeId}
                  setTypeId={setTypeId}
                  page={page}
                  setPage={setPage}
                  isLoading={isLoading}
                  searchResults={searchResults}
                  handleSortChange={handleSortChange}
                  sort={sort}
                  pageForwardClick={pageForwardClick}
                  pageBackClick={pageBackClick}
                />
              ) : (
                <ListingSearchResults
                  isLoading={isLoading}
                  cardType="live"
                  records={searchResults?.listings}
                  page={page}
                  lastPage={searchResults?.last_page}
                  pageBackClick={pageBackClick}
                  pageForwardClick={pageForwardClick}
                />
              )}
            </Grid>

          </Grid>
        </Box>
      </div>
    </section>
  );
});

ListingSearch.displayName = 'ListingSearch';

ListingSearch.defaultProps = {
  searchResults: {},
};

ListingSearch.propTypes = {
  activeFilterCount: PropTypes.number.isRequired,
  searchQueryText: PropTypes.string.isRequired,
  searchError: PropTypes.string.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  typeId: PropTypes.number.isRequired,
  setTypeId: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  handleCategoryClick: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  TABS: PropTypes.object.isRequired,
  showTabs: PropTypes.bool.isRequired,
  searchResults: PropTypes.object,
  pageForwardClick: PropTypes.func.isRequired,
  pageBackClick: PropTypes.func.isRequired,
};

export default ListingSearch;
