import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { toCurrency } from '../../helpers/currencyHelpers';

const GoodyBagsPickerModal = ({
  goodyBags,
  onSave,
  closeModal,
}) => {
  const [selectedButton, setSelectedButton] = useState(null);

  return (
    <div>
      <div className="modal-header">
        <h4>Select Goody Bag Button</h4>
        <p>
          Select a Goody Bag to preview in a post.
          To manage your Goody Bags,
          {' '}
          <a href="https://www.niteflirt.com/account/filemanager#tab-goody" target="_blank" rel="noreferrer">visit File Manager</a>
        </p>
      </div>
      <div className="modal-body">
        <div className="scrollable-height-300">
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: '55%' }}>
                  Subject
                </th>
                <th>
                  Type
                </th>
                <th>
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {goodyBags.map((button) => (
                <tr
                  key={button.id}
                  onClick={() => setSelectedButton(button.id)}
                  style={{ cursor: 'pointer' }}
                  className={selectedButton === button.id ? 'selected' : ''}
                >
                  <td>{button.title}</td>
                  <td>Goody Bag</td>
                  <td>{toCurrency(button.price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="btn secondary btn-cancel" onClick={closeModal}>Cancel</a>
        <button
          type="button"
          className="btn"
          disabled={!selectedButton}
          onClick={() => { onSave(selectedButton); closeModal(); }}
        >
          Share Goody Bag
        </button>
      </div>
    </div>
  );
};

GoodyBagsPickerModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  goodyBags: PropTypes.array.isRequired,
};

export default GoodyBagsPickerModal;
