import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import useProfileImages from 'app/hooks/useProfileImages';

const SearchResult = ({ flirt, navigateToFlirt }) => {
  const { acceptedAvatarImage: avatarImage } = useProfileImages(flirt);

  return (
    <a
      onClick={() => { navigateToFlirt(`/feed/${flirt.login}`); }}
      role="button"
      tabIndex={0}
    >
      <div className="container-fluid container-post-by search">
        <div className="row middle-xs nowrap-xs">
          <div
            className="col-xs-3 nfp-avatar-post"
            style={{ backgroundImage: `url('${avatarImage}')` }}
          />
          <div className="col-xs-9">
            <div className="post-name search-result">
              {flirt.login}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

SearchResult.propTypes = {
  flirt: PropTypes.object.isRequired,
  navigateToFlirt: PropTypes.func.isRequired,
};

const EmptyResults = () => {
  return (
    <div className="container-fluid search">
      <div className="row middle-xs nowrap-xs">
        <div className="col-xs-9">
          <div
            className="empty-search-result"
            style={{ color: 'gray', paddingTop: 20 }}
          >
            No Results
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchControls = ({
  searchString,
  searchInpRef,
  searchContainerRef,
  flirts,
  clearSearchField,
  updateSearchField,
  onSearchInputFocus,
  navigateToFlirt,
}) => {
  return (
    <div
      className="container-fluid container-search paddingbot20"
      style={{ display: 'block' }}
    >
      <div
        className="row middle-xs center-xs nowrap-xs paddingtop5"
        ref={searchContainerRef}
      >
        <form style={{ width: '80%' }}>
          <TextField
            type="text"
            id="search"
            name="search"
            fullWidth
            data-lpignore="true"
            inputRef={searchInpRef}
            onFocus={onSearchInputFocus}
            onChange={updateSearchField}
            autoComplete="off"
            placeholder="Find Flirts to follow"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchString && (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchField}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {searchInpRef?.current?.value && (
          <div className="clear-search">
            <a role="link" tabIndex={0} onClick={clearSearchField}>
              <img src="/plus_frontend/assets/svg/x-close-search.svg" alt="Clear" />
            </a>
          </div>
        )}
      </div>
      <div className="row-search" style={{ display: `${flirts ? 'block' : 'none'}` }}>
        <div className="container-fluid padding0">
          <div className="row">
            <div className="col-xs-12">
              {flirts && flirts.map((flirt) => (
                <SearchResult key={flirt.id} flirt={flirt} navigateToFlirt={navigateToFlirt} />
              ))}
              {flirts && flirts.length === 0 && <EmptyResults />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchControls.defaultProps = {
  searchString: '',
  flirts: null,
};

SearchControls.propTypes = {
  searchString: PropTypes.string,
  searchInpRef: PropTypes.object.isRequired,
  searchContainerRef: PropTypes.object.isRequired,
  flirts: PropTypes.array,
  clearSearchField: PropTypes.func.isRequired,
  updateSearchField: PropTypes.func.isRequired,
  onSearchInputFocus: PropTypes.func.isRequired,
  navigateToFlirt: PropTypes.func.isRequired,
};

export default SearchControls;
