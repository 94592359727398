import React from 'react';
import { PropTypes } from 'prop-types';

import { followButtonSrc } from 'app/helpers/followButtonHelpers';
import AuthenticatedLink from './shared/AuthenticatedLink';

const FollowButtonDeprecated = ({ follow, followButtonClick }) => {
  return (
    <AuthenticatedLink role="button" tabIndex="0" onClick={followButtonClick}>
      <img src={followButtonSrc(follow)} alt="" className="nav-hero-icon2" />
    </AuthenticatedLink>
  );
};

FollowButtonDeprecated.defaultProps = {
  follow: null,
};

FollowButtonDeprecated.propTypes = {
  follow: PropTypes.object,
  followButtonClick: PropTypes.func.isRequired,
};

export default FollowButtonDeprecated;
